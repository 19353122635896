import { documentConstants } from '../constants/document.constants';

export function document(state = {loading: false, error: undefined, saveDone: false}, action) {
  switch (action.type) {
    case documentConstants.SAVE_SUCCESS:
        return {
          loading: false,
          error: undefined,
          saveDone: true
        };
    case documentConstants.SAVE_REQUEST:
          return {
              loading: true,
              error: undefined,
              saveDone: false
          };
    case documentConstants.SAVE_FAILURE:
        return {
          error: action.payload.data,
          loading: false,
          saveDone: false
        };
    default:
      return state
  }
}