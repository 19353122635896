import { zulassungConstants, verkuerzungauswahlConstants, tipfehlerConstants } from '../constants';
import { ZulassungClass, VerkuerzungAuswahlClass, TipFehlerClass } from '../models/zulassung';

export function zulassung(state = { loading: false, done: false, error: undefined, showMenu: false }, action) {
  switch (action.type) {
    case zulassungConstants.GET_REQUEST:
      return {
        zulassung: new ZulassungClass(),
        done: false,
        loading: true,
        error: undefined,
      };
    case zulassungConstants.GET_SUCCESS:
      return {
        zulassung: action.payload.data,
        loading: false,
        done: true,
        error: undefined,
      };
    case zulassungConstants.GET_FAILURE:
      return {
        error: action.payload,
        zulassung: Object.keys({ ...state.zulassung }).length === 0 ?  "" : { ...state.zulassung },
        done: true,
        loading: false
      };
    case zulassungConstants.GET_FAILURE_DONE:
      return {
        zulassung: Object.keys({ ...state.zulassung }).length === 0 ?  "" : { ...state.zulassung },
        error: Object.keys({ ...state.error }).length === 0 ?  "" : { ...state.error },
        loading: false,
        done: false,
      };
    case zulassungConstants.SAVE_REQUEST:
      return {
        zulassung: action.payload,
        loading: true,
        done: false,
        error: undefined
      };
    case zulassungConstants.SAVE_SUCCESS:
      return {
        zulassung: action.payload.data,
        loading: false,
        done: true,
        error: undefined
      };
    case zulassungConstants.SAVE_TEMP_SUCCESS:
      return {
        zulassung: action.payload.data,
        loading: false,
        done: false,
        error: undefined
      };
    case zulassungConstants.SAVE_FAILURE:
      return {
        error: action.payload[0],
        zulassung: action.payload[1],
        loading: false,
        done: true
      };
    case zulassungConstants.SAVE_TEMP_FAILURE:
      return {
        error: action.payload[0],
        zulassung: action.payload[1],
        loading: false,
        done: false
      };
    case zulassungConstants.GET_DONE:
      return {
        zulassung: Object.keys({ ...state.zulassung }).length === 0 ?  "" : { ...state.zulassung },
        error: undefined,
        loading: false,
        done: false,
      };
    case zulassungConstants.SAVE_FAILURE_DONE:
      return {
        error: Object.keys({ ...state.error }).length === 0 ?  "" : { ...state.error },
        zulassung: Object.keys({ ...state.zulassung }).length === 0 ?  "" : { ...state.zulassung },
        loading: false,
        done: false
      };
    case zulassungConstants.SAVE_TEMP:
      return {
        zulassung: action.payload,
        loading: false
      };
    case zulassungConstants.CLEAR:
      return {};
    case zulassungConstants.CHECK:
      return {
        done: true,
        loading: false,
        error: Object.keys({ ...state.error }).length === 0 ?  "" : { ...state.error },
        zulassung: Object.keys({ ...state.zulassung }).length === 0 ?  "" : { ...state.zulassung },
        showMenu: action.payload
      };
    default:
      return state
  }
}

export function verkuerzungauswahl(state = {}, action) {
  switch (action.type) {
    case verkuerzungauswahlConstants.GET_REQUEST:
      return {
        verkuerzungauswahl: new VerkuerzungAuswahlClass(),
      };
    default:
      return state
  }
}

export function tipfehler(state = {}, action) {
  switch (action.type) {
    case tipfehlerConstants.GET_REQUEST:
      return {
        tipfehler: new TipFehlerClass(),
      };
    default:
      return state
  }
}