import { topmenuConstants } from "../constants/topmenu.constants";

export const topMenu = (state = 1, action) => {
    switch (action.type) {
        case topmenuConstants.ZULASSUNGANTRAG_STELLEN:
            return 1;

        case topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN:
            return 2;

        case topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM:
            return 3;

        case topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM:
            return 4;

        case topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM:
            return 5;

        case topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM:
            return 6;

        case topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES:
            return 7;

        case topmenuConstants.MODULPRUEFUNG:
            return 8;

        case topmenuConstants.GESAMTPRUEFUNG:
            return 9;

        case topmenuConstants.KONTAKTDATEN_AENDERN:
            return 10;

        case topmenuConstants.KENNWORT_AENDERN:
            return 11;

        // case topmenuConstants.EINSTELLUNGEN_AENDERN:
        //     return 11;

        case topmenuConstants.DIGITALE_AKTE:
            return 12;

        default:
            return state;
    }
}