export const modulConstants = {
    SAVE_REQUEST: 'MODUL_SAVE_REQUEST',
    SAVE_SUCCESS: 'MODUL_SAVE_SUCCESS',
    SAVE_FAILURE: 'MODUL_SAVE_FAILURE',

    SAVE_RUECKNAHME_REQUEST: 'RUECKNAHME_SAVE_REQUEST',
    SAVE_RUECKNAHME_SUCCESS: 'RUECKNAHME_SAVE_SUCCESS',
    SAVE_RUECKNAHME_FAILURE: 'RUECKNAHME_SAVE_FAILURE',

    SAVE_GESAMTPRUEFUNG_REQUEST: 'GESAMTPRUEFUNG_SAVE_REQUEST',
    SAVE_GESAMTPRUEFUNG_SUCCESS: 'GESAMTPRUEFUNG_SAVE_SUCCESS',
    SAVE_GESAMTPRUEFUNG_FAILURE: 'GESAMTPRUEFUNG_SAVE_FAILURE',

    GET_REQUEST: 'MODUL_GET_REQUEST',
    GET_SUCCESS: 'MODUL_GET_SUCCESS',
    GET_FAILURE: 'MODUL_GET_FAILURE',

    GET_DONE: 'MODUL_GET_DONE',
    GET_FAILURE_DONE: 'MODUL_GET_FAILURE_DONE',

    GET_RUECKNAHME_DONE: 'GET_RUECKNAHME_DONE',
    GET_RUECKNAHME_FAILURE_DONE: 'GET_RUECKNAHME_FAILURE_DONE',

    GET_GESAMTPRUEFUNG_DONE: 'GET_GESAMTPRUEFUNG_DONE',
    GET_GESAMTPRUEFUNG_FAILURE_DONE: 'GET_GESAMTPRUEFUNG_FAILURE_DONE',

    SAVE_TEMP: 'MODUL_TEMPSAVE',
};