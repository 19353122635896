import { MenuRight } from './MenuRight';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { ZulassungAntragRuecknehmen } from './zulassung/ZulassungAntragRuecknehmen';
import { ModulNochNichtAnwendbar, ModulanmeldungHinweisText, ErklaerungTextZulassungRueckname, GesamtPruefungEignungText, GesamtPruefungText, ModulanmeldungHinweisTextEP } from './HinweisTexte';
import { Spinner } from "./spinner/Spinner";
import { rightmenuEignungAntragConstants, topmenuConstants } from '../constants';
import { EignungAntragStellen } from './zulassung/Eignunganmeldung';
import { eignungActions, modulActions, modulEPActions, rightMenuActions, topMenuActions, zulassungsblockActions } from '../actions';
import { GesamtPruefung } from './ruecktritt/GesamtPruefung';
import { EignungAntragRuecknehmen } from './zulassung/EignungAntragRuecknehmen';
import { GesamtPruefungEP } from './ruecktritt/GesamtPruefungEP';
import { util } from '../services';

export const ZulassungEignung = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authentication.user);
	const f = useSelector(state => state.topMenu);
	const [form, setForm] = useState(f);
	const t = useSelector(state => state.rightMenu);
	const [tab, setTab] = useState(t);
	const tz = useSelector(state => state.rightmenuEignungAntrag);
	const [tabZulassungsantrag, setTabZulassungsantrag] = useState(tz)
	const e = useSelector(state => state.eignung.eignung);
	const [eignung, setEignung] = useState(e);
	const mlbep = useSelector(state => state.modulEPList.modulEPListBefore);
	const [modulEPBefore, setModulEPBefore] = useState(mlbep);
	const [showSpinner, setShowSpinner] = useState(true);
	const loading = useSelector((state) => state.eignung.loading);
	const [nachweise, setNachweise] = useState([]);
	const [wahlfach, setWahlfach] = useState([]);
	const [zeugnisse, setZeugnisse] = useState([]);
	const [verkuerzungauswahl, setVerkuerzungauswahl] = useState({ disabled1: false, disabled2: false, disabled3: false, disabled4: false, disabled5: false, disabled6: false });	

	var subTitle = "";

	useEffect(() => {		
		dispatch(zulassungsblockActions.getZulassungsblock());
		dispatch(eignungActions.getEignung());
		dispatch(modulEPActions.getModulEPList());
		dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ZULASSUNGSANTRAG));
		dispatch(topMenuActions.changeForm(topmenuConstants.ZULASSUNGANTRAG_STELLEN));
	}, []);

	if (form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR)
		subTitle = "Zulassungsantrag Eignungsprüfung";
	else if (form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR)
		subTitle = "Rücknahme des Zulassungsantrags";
	else if (form === topmenuConstants.GESAMTPRUEFUNG_NR)
		subTitle = "Rücktritt von der Gesamtprüfung";

	useEffect(() => {
		setModulEPBefore(mlbep);
	}, [mlbep]);

	useEffect(() => {
		setEignung(e);
	}, [e]);

	useEffect(() => {
		setForm(f);
	}, [f]);

	useEffect(() => {
		setTab(t);
	}, [t]);

	useEffect(() => {
		setTabZulassungsantrag(tz);
	}, [tz]);

	useEffect(() => {
		if (!loading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [loading]);

	/* 
		eignung.zwischengespeichert = 0 : Eignungsantrag noch nicht abgesendet
		eignung.zwischengespeichert = 1 : Eignungsantrag schon abgesendet aber noch nicht geprüft
		eignung.zwischengespeichert = 2 : Eignungsantrag schon abgesendet und schon geprüft
	*/

	if (!modulEPBefore) {
		return (
			<div className="container  container-small-view">
				<div className="row">

					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<Spinner show={showSpinner} />
							{/* Form Typ */}
							{form == topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <EignungAntragStellen
								nachweise={nachweise}
								wahlfach={wahlfach}
								zeugnisse={zeugnisse}
								setNachweise={setNachweise}
								setWahlfach={setWahlfach}
								setZeugnisse={setZeugnisse}
								verkuerzungauswahl={verkuerzungauswahl}
								setVerkuerzungauswahl={setVerkuerzungauswahl}
							/>}

							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && <EignungAntragRuecknehmen />}

							{form === topmenuConstants.GESAMTPRUEFUNG_NR && <GesamtPruefungEP/>}
						</div>
					</div>
				</div>
			</div>
		);
	} else if (modulEPBefore && modulEPBefore.zwischegespeichert && modulEPBefore.zwischegespeichert > 0) {
		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
								{form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <ModulanmeldungHinweisTextEP />}
								{form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <ModulNochNichtAnwendbar />}
							</div>

							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && <EignungAntragRuecknehmen />}
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && tab === 1 && modulEPBefore.zwischegespeichert === 1 && <MenuRight />}
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && tab === 2 && modulEPBefore.zwischegespeichert === 1 && <MenuRight />}

							{form === topmenuConstants.GESAMTPRUEFUNG_NR && <GesamtPruefungEP />}
							{form === topmenuConstants.GESAMTPRUEFUNG_NR && tab === 1 && modulEPBefore.zwischegespeichert === 2 && <MenuRight />}
							{form === topmenuConstants.GESAMTPRUEFUNG_NR && tab === 2 && modulEPBefore.zwischegespeichert === 2 && <MenuRight />}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
								{form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <ModulanmeldungHinweisTextEP />}
								{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && <ErklaerungTextZulassungRueckname />}
								{form === topmenuConstants.GESAMTPRUEFUNG_NR && <GesamtPruefungEignungText />}
								<ModulNochNichtAnwendbar />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
