export const rightmenuConstants = {
    AUSWAHL_MODUL : 'AUSWAHL_MODUL ',
    ZUSAMMENFASSUNG : 'ZUSAMMENFASSUNG',
};

export const rightmenuZulassungsAntragConstants = {
    MODULANMELDUNG : 'MODULANMELDUNG',
    KONTAKTDATEN : 'KONTAKTDATEN',
    BISHERIGE_ZULASSUNGSANTRAEGE : 'BISHERIGE_ZULASSUNGSANTRAEGE',
    AKADEMISCHE_VORBILDUNG : 'AKADEMISCHE_VORBILDUNG',
    PRAKTISCHE_VORBILDUNG : 'PRAKTISCHE_VORBILDUNG',
    PRUEFUNGSVERUERZUNG : 'PRUEFUNGSVERUERZUNG',
    ABSENDEN : 'ABSENDEN'
};

export const rightmenuEignungAntragConstants = {
    ZULASSUNGSANTRAG : 'ZULASSUNGSANTRAG',
    KONTAKTDATEN : 'KONTAKTDATEN',
    VERKUERZUNG : 'VERKUERZUNG',
    ABSENDEN : 'ABSENDEN'
};

export const rightmenuDokumenteConstants = {
    AKTENEINGANG : 'AKTENEINGANG',
    MANTELAKTE : 'MANTELAKTE',
    MODULPRUEFUNG_WP : 'MODULPRUEFUNG_WP',
    MODULPRUEFUNG_WR : 'MODULPRUEFUNG_WR',
    MODULPRUEFUNG_BWL : 'MODULPRUEFUNG_BWL',
    MODULPRUEFUNG_STR : 'MODULPRUEFUNG_STR',
};