export default class UserClass {

    constructor(){
        this.nachname = "";
        this.vorname = "";
        this.email=  "";
        this.passwort=  "";
    }

    
}