import { alertConstants, userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';
import { getErrorMessage, history } from '../helpers';

export const userActions = {
    login,
    logout,
    session,
    getUser,
    saveUser,
    saveTempUser,
    register,
    resetPassword,
    confirmRegistration
};

function login(username, password, token) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password, token)
            .then(
                res => { 
                    sessionStorage.setItem('user', JSON.stringify(res.data));
                    dispatch(success(res));
                    history.push('/examensportal/start');
                }
            ).catch(res => {
                dispatch(failure(getErrorMessage(res)));
                dispatch(error(getErrorMessage(res)));
            });
    };

    function request(payload) { return { type: userConstants.LOGIN_REQUEST, payload } }
    function success(payload) { return { type: userConstants.LOGIN_SUCCESS, payload } }
    function failure(payload) { return { type: userConstants.LOGIN_FAILURE, payload } }
    function error(payload) { return { type: alertConstants.ERROR, payload } }
}

function session(){
    return dispatch => {
        dispatch(request({ "username" : "" }));
        dispatch(success(JSON.parse(sessionStorage.getItem('user'))))
    };
    function request(payload) { return { type: userConstants.LOGIN_REQUEST, payload } }
    function success(payload) { return { type: userConstants.LOGIN_SESSION, payload } }
}

function register(newUser) {
    return dispatch => {

        userService.register(newUser)
            .then(
                res => { 
                    history.push('/examensportal/registriert');
                }
            ).catch(res => {
                dispatch(failure(getErrorMessage(res)));
            }
        );
    };

    function failure(payload) { return { type: alertConstants.ERROR, payload } }
}

function resetPassword(email) {
    return dispatch => {

        userService.resetPassword(email)
            .then(
                res => { 
                    history.push('/examensportal/passwortzurueckgesetzt');
                }
            ).catch(res => {
                dispatch(alertActions.error(res));
            }
        ); 
    };
}

function confirmRegistration(token) {
    return dispatch => {

        userService.confirmRegistration(token)
            .then(
                res => { 
                    //history.push('/examensportal/registrierungbestaetigt');
                }
            ).catch(res => {
                dispatch(alertActions.error(res));
            }
        )
    };
}

function logout() {
    history.push('/examensportal/login');
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getUser() {
    
    return dispatch => {
        dispatch({ type: userConstants.GET_USER_REQUEST });

        userService.getUser()
            .then(
                res => {
                    dispatch({ type: userConstants.GET_USER_SUCCESS, payload: res })
                }
            ).catch(
                res => dispatch({ type: userConstants.GET_USER_FAILURE, payload: res })
            );
    };
}

function saveTempUser(user) {
    return { type: userConstants.SAVE_TEMPUSER, user }
}

function saveUser(user) {
    return dispatch => {
        dispatch(request());

        userService.saveUser(user)
            .then(
                res => dispatch(success(res))
            ).catch(
                res => dispatch(failure(res))
            );
    };

    function request() { return { type: userConstants.SAVE_USER_REQUEST } }
    function success(res) { return { type: userConstants.SAVE_USER_SUCCESS, payload: res } }
    function failure(res) { return { type: userConstants.SAVE_USER_FAILURE, payload: res } }
}