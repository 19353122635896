export const eignungConstants = {
    SAVE_REQUEST: 'EIGNUNG_SAVE_REQUEST',
    SAVE_SUCCESS: 'EIGNUNG_SAVE_SUCCESS',
    SAVE_TEMP_SUCCESS: 'EIGNUNG_TEMP_SAVE_SUCCESS',
    SAVE_FAILURE: 'EIGNUNG_SAVE_FAILURE',
    SAVE_TEMP_FAILURE: 'EIGNUNG_TEMP_SAVE_FAILURE',

    GET_REQUEST: 'EIGNUNG_GET_REQUEST',
    GET_SUCCESS: 'EIGNUNG_GET_SUCCESS',
    GET_FAILURE: 'EIGNUNG_GET_FAILURE',

    SAVE_TEMP: 'EIGNUNG_TEMPSAVE',

    GET_DONE: 'EIGNUNG_GET_DONE',
    GET_FAILURE_DONE: 'EIGNUNG_GET_FAILURE_DONE',
    SAVE_FAILURE_DONE: 'EIGNUNG_SAVE_FAILURE_DONE',

    CHECK: 'EIGNUNG_CHECK',
    CLEAR: 'EIGNUNG_CLEAR'
};
