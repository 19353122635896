import { MenuRight } from './MenuRight';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, rightMenuActions, topMenuActions, zulassungActions, zulassungsblockActions } from '../actions';
import { ZulassungAntragRuecknehmen } from './zulassung/ZulassungAntragRuecknehmen';
import { ZulassungAntragStellen } from './zulassung/ZulassungAntragStellen';
import { ModulNochNichtAnwendbar, ModulanmeldungHinweisText, ErklaerungTextZulassungRueckname } from './HinweisTexte';
import { rightmenuConstants, rightmenuZulassungsAntragConstants, topmenuConstants } from '../constants';
import { Spinner } from "./spinner/Spinner";

export const Zulassung = () => {
	const dispatch = useDispatch();
	const f = useSelector(state => state.topMenu);
	const [form, setForm] = useState(f)
	const t = useSelector(state => state.rightMenu);
	const [tab, setTab] = useState(t);
	const tz = useSelector(state => state.rightmenuZulassungsAntrag);
	const [tabZulassungsantrag, setTabZulassungsantrag] = useState(tz)
	const z = useSelector(state => state.zulassung.zulassung);
	const [zulassung, setZulassung] = useState(z);
	const mlb = useSelector(state => state.modulList.modulListBefore);
	const [modul, setModul] = useState(mlb)
	const [showSpinner, setShowSpinner] = useState(true);
	const loading = useSelector((state) => state.zulassung.loading);
	const [lebenslauf, setLebenslauf] = useState([]);
	const [attest, setAttest] = useState([]);
	const [zeugnisse, setZeugnisse] = useState([]);
	const [agbescheinigungen, setAgbescheinigungen] = useState([]);
	const [stbescheinigungen, setStbescheinigungen] = useState([]);
	const [agbescheinigungen2, setAgbescheinigungen2] = useState([]);
	const [stbescheinigungen2, setStbescheinigungen2] = useState([]);
	const [verkuerzungbescheinigungen, setVerkuerzungbescheinigungen] = useState([]);
	const [verkuerzungauswahl, setVerkuerzungauswahl] = useState({ disabled1: false, disabled2: false, disabled3: false, disabled4: false, disabled5: false, disabled6: false });


	var subTitle = "";

	useEffect(() => {
		dispatch(zulassungsblockActions.getZulassungsblock());
		dispatch(zulassungActions.getZulassung());
		dispatch(modulActions.getModulList());
		dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.MODULANMELDUNG)) 
		dispatch(topMenuActions.changeForm(topmenuConstants.ZULASSUNGANTRAG_STELLEN))
	}, []);

	useEffect(() => {
		setModul(mlb);
	}, [mlb]);

	if (form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR)
		subTitle = "Zulassungsantrag";
	else if (form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR)
		subTitle = "Rücknahme des Zulassungsantrags";

	useEffect(() => {
		setZulassung(z);
	}, [z]);

	useEffect(() => {
		setForm(f);
	}, [f]);

	useEffect(() => {
		setTab(t);
	}, [t]);

	useEffect(() => {
		setTabZulassungsantrag(tz);
	}, [tz]);



	useEffect(() => {
		if (!loading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [loading]);

	/* 
		Zulassung.zwischengespeichert = 0 : Zulassungsantrag noch nicht abgesendet
		Zulassung.zwischengespeichert = 1 : Zulassungsantrag schon abgesendet aber noch nicht geprüft
		Zulassung.zwischengespeichert = 2 : Zulassungsantrag schon abgesendet und schon geprüft
	*/


	if (!modul) {
		return (
			<div className="container  container-small-view">
				<div className="row">

					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<Spinner show={showSpinner} />
							{/* Form Typ */}
							{form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <ZulassungAntragStellen
								lebenslauf={lebenslauf}
								attest={attest}
								zeugnisse={zeugnisse}
								agbescheinigungen={agbescheinigungen}
								stbescheinigungen={stbescheinigungen}
								agbescheinigungen2={agbescheinigungen2}
								stbescheinigungen2={stbescheinigungen2}
								verkuerzungbescheinigungen={verkuerzungbescheinigungen}
								setLebenslauf={setLebenslauf}
								setAttest={setAttest}
								setZeugnisse={setZeugnisse}
								setAgbescheinigungen={setAgbescheinigungen}
								setStbescheinigungen={setStbescheinigungen}
								setAgbescheinigungen2={setAgbescheinigungen2}
								setStbescheinigungen2={setStbescheinigungen2}
								setVerkuerzungbescheinigungen={setVerkuerzungbescheinigungen}
								verkuerzungauswahl={verkuerzungauswahl}
								setVerkuerzungauswahl={setVerkuerzungauswahl}
							/>}
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && <ZulassungAntragRuecknehmen />}
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && tab === 1 && <MenuRight />}
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && tab === 2 && <MenuRight />}
						</div>
					</div>
				</div>
			</div>
		);
	} else if (modul && modul.zwischegespeichert && modul.zwischegespeichert > 0) {
		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
								{form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <ModulanmeldungHinweisText />}
								{form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <ModulNochNichtAnwendbar />}
							</div>
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && <ZulassungAntragRuecknehmen />}
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && tab === 1 && <MenuRight />}
							{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && tab === 2 && <MenuRight />}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
								{form === topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR && <ModulanmeldungHinweisText />}
								{form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR && <ErklaerungTextZulassungRueckname />}
								<ModulNochNichtAnwendbar />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
