import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { userActions } from '../../actions';

export const Bestaetigung = () => {
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(userActions.confirmRegistration(params.token));
    }, []);

    return(<h1>Ihre E-Mail wurde bestätigt. Vielen Dank!</h1>);
}

export default Bestaetigung;