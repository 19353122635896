import React from 'react';
import moment from 'moment';
import { getErrorMessage } from '../helpers';
import { dokumenteService } from '../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { rightmenuConstants, topmenuConstants } from '../constants';
import { rightMenuActions, topMenuActions } from '../actions';
import { useState, useEffect } from 'react';

export const DokumentItem = ({ titel, items, setFehler }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const schritt = useSelector(state => state.rightmenuDokumente);
    const m = useSelector(state => (user && user.art && user.art === 'WP') ? state.modulList.modulList : state.modulEPList.modulEPList);
    const [modul, setModul] = useState(m);

    useEffect(() => {
        setModul(m);
    }, [m]);

    const downloadFile = (e, documentId) => {


        dokumenteService.getDokument(documentId).then(res => {
            e.preventDefault();

            let fileName = "data.dat";
            if (res.headers['content-disposition']) {
                fileName = res.headers['content-disposition'].split("=")[1];
            }

            const blob = new Blob([res.data], {
                type: res.headers['content-type']
            });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);

            link.setAttribute('download', fileName);
            link.setAttribute('target', '_blank');
            if (document.createEvent) {
                const event = document.createEvent('MouseEvents');
                event.initEvent('click', true, true);
                link.dispatchEvent(event);
            } else {
                link.click();
            }

        }).catch(function (error) {
            setFehler(getErrorMessage(error));
        });

    };


    const deleteFile = (documentId) => {
        const message = 'Möchten Sie dieses Dokument wirklich löschen ? <br/> Diese Aktion kann nicht rückgängig gemacht werden. <br />';
        Swal.fire({
            html: message,
            width: 500,
            confirmButtonColor: '#4C82A8',
            showCancelButton: true,
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Swal.fire(
                //     'Deleted!',
                //     'Your file has been deleted.',
                //     'success'
                // )
                dokumenteService.deleteDokument(documentId);
                refreshPage();
            }
        })
    }

    function refreshPage() {
        // history.push("/examensportal/meinedaten");
        window.location.reload();
        dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
        dispatch(topMenuActions.changeForm(topmenuConstants.DIGITALE_AKTE));
    }

    function containsKeywords(name) {
        const lowerCaseName = name.toLowerCase();
        const keywords = ['zusammenfassung', 'triftigergrund'];

        if (lowerCaseName.includes(keywords[0]) && lowerCaseName.includes(keywords[1])) {
            return true; 
        }

        return false;
    }


    return (
        <>
            <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>{titel}</h3>
                </div>
            </div>
            {
                items.map(item => {
                    return (
                        <div className="row" key={item.documentId}>
                            <div className="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8"><a href="#" onClick={(e) => downloadFile(e, item.documentId)}>{item.originalFileName}</a></div>
                            <div className="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-3">{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</div>
                            {schritt === 1 && Object.keys(modul).length === 0 && !containsKeywords(item.originalFileName) && <div className="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1"><a href="#"><FontAwesomeIcon icon={faTrash} onClick={(e) => { e.preventDefault(); deleteFile(item.documentId); }} /></a></div>}
                        </div>
                    );
                })
            }
        </>
    );
}