import { th } from "date-fns/locale";

export class ModulClass {

    constructor(){
        this.dmsId = 0;
        this.antragsdatum = "";
        this.zulassungsdatum = "";
        this.zulassungsblock = "";
        this.lgs = 0;
        this.zwischegespeichert = 0;
        this.antragsnr = 0;

        this.modulanmeldungen = new ModulAnmeldungenClass();
        this.modulabmeldungen = new ModulAbmeldungenClass();
        this.modulverschiebungen = new ModulVerschiebungenClass();
        this.modulanrechnungen = new ModulAnrechnungenClass();
        this.modulverhindert = new ModulVerhindertClass();
        this.modulgeladenAm = new ModulGeladenAmClass();
        this.kontaktdaten = new ModulKontaktdatenClass();
    }
}

export class ModulEPClass {

    constructor() {
        this.dmsId = 0;
        this.antragsdatum = "";
        this.zulassungsdatum = "";
        this.zulassungsblock = "";
        this.lgs = 0;
        this.zwischegespeichert = 0;
        this.antragsnr = 0;

        this.modulverhindert = new ModulEPVerhindertClass();
        this.kontaktdaten = new ModulKontaktdatenClass();
    }
}

export class ModulAnmeldungenClass {
    constructor(){
        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpVorzeitig = false;
        this.wrVorzeitig = false;
        this.bwlVorzeitig = false;
        this.stVorzeitig = false;
    }
}

export class WPSModulAnmeldungenClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = null;
        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpVorzeitig = false;
        this.wrVorzeitig = false;
        this.bwlVorzeitig = false;
        this.stVorzeitig = false;
    }
}

export class ModulAbmeldungenClass {
    constructor(){
        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpVorzeitig = false;
        this.wrVorzeitig = false;
        this.bwlVorzeitig = false;
        this.stVorzeitig = false;
    }
}

export class WPSModulAbmeldungenClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = null;

        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpVorzeitig = false;
        this.wrVorzeitig = false;
        this.bwlVorzeitig = false;
        this.stVorzeitig = false;
    }
}

export class ModulVerschiebungenClass {
    constructor(){
        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpVorzeitig = false;
        this.wrVorzeitig = false;
        this.bwlVorzeitig = false;
        this.stVorzeitig = false;
    }
}

export class WPSModulVerschiebungenClass {
    constructor(){
        this.zulassungsblock = "";
        this.verschobenSemester = "";
        this.art = "";
        this.abgesendetAm = null;

        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpVorzeitig = false;
        this.wrVorzeitig = false;
        this.bwlVorzeitig = false;
        this.stVorzeitig = false;
    }
}

export class ModulAnrechnungenClass {
    constructor(){
        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
    }
}

export class WPSModulAnrechnungenClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = null;
        
        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
    }
}

export class ModulVerhindertClass {
    constructor(){
        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpmuendlich = false;
        this.wrmuendlich = false;
        this.bwlmuendlich = false;
        this.stmuendlich = false;
    }
}

export class ModulEPVerhindertClass {
    constructor() {
        this.awr = false;
        this.ast = false;
        this.mp = false;
    }
}

export class WPSModulVerhindertClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = null;

        this.wp = false;
        this.wr = false;
        this.bwl = false;
        this.st = false;
        this.wpmuendlich = false;
        this.wrmuendlich = false;
        this.bwlmuendlich = false;
        this.stmuendlich = false;
    }
}

export class WPSModulEPVerhindertClass {
    constructor() {
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = null;

        this.awr = false;
        this.ast = false;
        this.mp = false;
    }
}

export class ModulGeladenAmClass {
    constructor(){
        this.wp = "";
        this.wr =  "";
        this.bwl =  "";
        this.st = "";
    }
}

export class WPSGesamtPruefungClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = "";
    }
}

export class WPSModulGeladenAmClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = null;

        this.wp = "";
        this.wr =  "";
        this.bwl =  "";
        this.st = "";

        this.wpGeladenAm = "";
        this.wrGeladenAm =  "";
        this.bwlGeladenAm =  "";
        this.stGeladenAm = "";
    }
}

export class ModulKontaktdatenClass {
    constructor(){
        this.name = "";
        this.vorname = "";
        this.geburtsname = "";
        this.anrede = ""; 
        this.geburtsdatum = null;
        this.geburtsort = "";
        this.strasse = "";
        this.hausnummer = "";
        this.plz = "";
        this.ort = "";
        this.land = "";
        this.telefon = "";
        this.mobil = "";
        this.email = "";
        this.iban = "";
        this.bic = ""; 
        this.behinderung = false;
        this.aktArbeitgeber = "";
        this.lebenslauf = "";
        this.attest = "";
    }
}

export class WPSModulKontaktdatenClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = "";

        this.name = "";
        this.vorname = "";
        this.weitereVornamen = "";
        this.geburtsname = "";
        this.anrede = ""; 
        this.geburtsdatum = null;
        this.geburtsort = "";
        this.strasse = "";
        this.hausnummer = "";
        this.staatsangehoerigkeit = "";
        this.plz = "";
        this.ort = "";
        this.land = "";
        this.telefon = "";
        this.mobil = "";
        this.email = "";
        this.iban = "";
        this.bic = ""; 
        this.behinderung = false;
        this.aktArbeitgeber = "";
        // this.lebenslauf = "";
        // this.attest = "";
    }
}

