import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, rightMenuActions, zulassungActions } from '../../../actions';
import { modulConstants, rightmenuConstants } from '../../../constants';
import { util, modulService } from '../../../services';
import { WPSModulAnmeldungenClass } from '../../../models/modul';
import { Spinner } from "../../spinner/Spinner";
import { ModulNochNichtAnwendbar, ErlaueterungForAuswahlAnmeldung } from '../../HinweisTexte';
import { eventConstants } from "../../../constants";

const ModulListWinter = ({ modul, handleChange, kannAnmelden }) => {
    if (!modul || !modul.modulanmeldungen || !kannAnmelden) return (<div />);
    else {
        return (
            <>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulanmeldungen.wp" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.wp} checked={modul.modulanmeldungen.wp} onChange={e => handleChange(e)} disabled={!kannAnmelden.wp} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulanmeldungen.bwl" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.bwl} checked={modul.modulanmeldungen.bwl} onChange={e => handleChange(e)} disabled={!kannAnmelden.bwl} /> Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulanmeldungen.wr" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.wr} checked={modul.modulanmeldungen.wr} onChange={e => handleChange(e)} disabled={!kannAnmelden.wr} /> Wirtschaftsrecht
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulanmeldungen.st" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.st} checked={modul.modulanmeldungen.st} onChange={e => handleChange(e)} disabled={!kannAnmelden.st} /> Steuerrecht
                    </div>
                </div>
            </>
        );
    }
}

const ModulListSommer = ({ aktuelSemester, modul, handleChange, kannAnmelden }) => {

    if (!modul || !modul.modulanmeldungen || !kannAnmelden) return (<div />);
    else {
        return (
            <>
                <div className="row">
                    <div className="col-10 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulanmeldungen.wpVorzeitig" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.wpVorzeitig} checked={modul.modulanmeldungen.wpVorzeitig} onChange={e => handleChange(e)} disabled={!kannAnmelden.wp} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht (Juni)
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulanmeldungen.wp" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.wp} checked={modul.modulanmeldungen.wp} onChange={e => handleChange(e)} disabled={!kannAnmelden.wp} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht (August)
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulanmeldungen.bwlVorzeitig" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.bwlVorzeitig} checked={modul.modulanmeldungen.bwlVorzeitig} onChange={e => handleChange(e)} disabled={!kannAnmelden.bwl} /> Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre (Juni)
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                        <input type="checkbox" name="modulanmeldungen.bwl" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.bwl} checked={modul.modulanmeldungen.bwl} onChange={e => handleChange(e)} disabled={!kannAnmelden.bwl} /> Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre (August)
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulanmeldungen.wrVorzeitig" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.wrVorzeitig} checked={modul.modulanmeldungen.wrVorzeitig} onChange={e => handleChange(e)} disabled={!kannAnmelden.wr} /> Wirtschaftsrecht (Juni)
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulanmeldungen.wr" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.wr} checked={modul.modulanmeldungen.wr} onChange={e => handleChange(e)} disabled={!kannAnmelden.wr} /> Wirtschaftsrecht (August)
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulanmeldungen.stVorzeitig" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.stVorzeitig} checked={modul.modulanmeldungen.stVorzeitig} onChange={e => handleChange(e)} disabled={!kannAnmelden.wr} /> Steuerrecht (Juni)
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <input type="checkbox" name="modulanmeldungen.st" style={{ marginRight: '15px' }} value={modul.modulanmeldungen.st} checked={modul.modulanmeldungen.st} onChange={e => handleChange(e)} disabled={!kannAnmelden.st} /> Steuerrecht (August)
                    </div>
                </div>
            </>
        );
    }
}

const AuswahlModul = ({ aktuelSemester, handleSubmit, semester, modul, setModul, kannAnmelden, modulLoading, showSpinner, setShowSpinner }) => {
    const dispatch = useDispatch();

    if (modulLoading) {
        setShowSpinner(true)
    } else {
        setShowSpinner(false)
    }

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("modulanmeldungen") > -1) {
            setModul({
                ...modul,
                modulanmeldungen: {
                    ...modul.modulanmeldungen,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setModul({ ...modul, [e.target.name]: v });
        }
    };

    if (!modul || !kannAnmelden) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
                    <Spinner show={showSpinner} />
                    <h2 style={{ paddingTop: '25px' }}>Auswahl Modul(e)</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich melde mich zur Modulprüfung im Prüfungstermin {aktuelSemester} an:</span>
                            {semester === 0 && (<ModulListWinter
                                modul={modul}
                                handleChange={handleChange}
                                kannAnmelden={kannAnmelden}
                            />)}
                            {semester === 1 && (<ModulListWinter
                                modul={modul}
                                handleChange={handleChange}
                                kannAnmelden={kannAnmelden}
                            />)}
                            {semester === 2 && (<ModulListSommer
                                aktuelSemester={aktuelSemester}
                                modul={modul}
                                handleChange={handleChange}
                                kannAnmelden={kannAnmelden}
                            />)}
                        </div>
                    </div>
                    <span className="formLabel"></span>

                    <div className="row">
                        <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                        </div>
                        <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                    </div>

                    <br />

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const Zusammenfassung = ({ aktuelSemester, semester, modul, modulBefore, kannAnmelden, showSpinner, setShowSpinner }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const d = useSelector((state) => state.modulList.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.modulList.error);
    const [error, setError] = useState(e);

    const WP = () => {
        return (
            <li>
                <p>Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht</p>
            </li>
        );
    }

    const VWPS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht (Juni)</p>
                </li>
            );
        }
    }

    const WPS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht (August)</p>
                </li>
            );
        }
    }

    const BWL = () => {
        return (
            <li>
                <p>Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre</p>
            </li>
        );
    }

    const VBWLS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre (Juni)</p>
                </li>
            );
        }

    }

    const BWLS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre (August)</p>
                </li>
            );
        }
    }

    const WR = () => {
        return (
            <li>
                <p>Wirtschaftsrecht</p>
            </li>
        );
    }

    const VWRS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Wirtschaftsrecht (Juni)</p>
                </li>
            );
        }
    }

    const WRS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Wirtschaftsrecht (August)</p>
                </li>
            );
        }
    }

    const ST = () => {
        return (
            <li>
                <p>Steuerrecht</p>
            </li>
        );
    }

    const VSTS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Steuerrecht (Juni)</p>
                </li>
            );
        }
    }

    const STS = () => {
        if (!modul) return (<div />);
        else {
            return (
                <li>
                    <p>Steuerrecht (August)</p>
                </li>
            );
        }
    }

    useEffect(() => {
        if (done) {
            if (error) {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_FAILURE_DONE });
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.error("Sie haben sich nicht erfolgreich angemeldet.");
            } else {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_DONE });
                dispatch(modulActions.getModulList());
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.success("Sie haben sich erfolgreich angemeldet.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        var anm = new WPSModulAnmeldungenClass();
        anm.art = eventConstants.MODULPRUEFUNG_ANMELDUNG;
        anm.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
        anm.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
        anm.wp = modul.modulanmeldungen.wp;
        anm.wpVorzeitig = modul.modulanmeldungen.wpVorzeitig;
        anm.wr = modul.modulanmeldungen.wr;
        anm.wrVorzeitig = modul.modulanmeldungen.wrVorzeitig;
        anm.bwl = modul.modulanmeldungen.bwl;
        anm.bwlVorzeitig = modul.modulanmeldungen.bwlVorzeitig;
        anm.st = modul.modulanmeldungen.st;
        anm.stVorzeitig = modul.modulanmeldungen.stVorzeitig;
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return false
            else return value;
        }

        var modulAnmeldungInfos = JSON.parse(JSON.stringify(anm, replacer));

        // console.log(modulAnmeldungInfos);

        setShowSpinner(true);
        dispatch(modulActions.saveEventInfos(modul, modulBefore, modulAnmeldungInfos));
        // toast.success("Ihr Zulassungsantrag wurde abgeschickt");
        // history.push("/examensportal/start");

    };

    const handleBack = (e) => {
        e.preventDefault();
        dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
    };

    if (!modul || !kannAnmelden) return (<div />);
    else {

console.log("MODUL_Zusammen: ", modul);

        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
                    <Spinner show={showSpinner} />
                    <h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>

                    <span className="formLabel">Ich melde mich hiermit verbindlich zu folgenden Modulprüfungen im Prüfungstermin {aktuelSemester} an:</span>
                    <ul>
                        
                        {modul.modulanmeldungen && modul.modulanmeldungen.wp && semester === 0 && kannAnmelden.wp && <WP />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.wp && semester === 1 && kannAnmelden.wp && <WP />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.wpVorzeitig && semester === 2 && <VWPS />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.wp && semester === 2 && kannAnmelden.wp && <WPS />}
                        
                        
                        {modul.modulanmeldungen && modul.modulanmeldungen.bwl && semester === 0 && kannAnmelden.bwl && <BWL />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.bwl && semester === 1 && kannAnmelden.bwl && <BWL />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.bwlVorzeitig && semester === 2 && <VBWLS />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.bwl && semester === 2 && kannAnmelden.bwl && <BWLS />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.wr && semester === 0 && kannAnmelden.wr && <WR />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.wr && semester === 1 && kannAnmelden.wr && <WR />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.wrVorzeitig && semester === 2 && <VWRS />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.wr && semester === 2 && kannAnmelden.wr && <WRS />}
                        
                        {modul.modulanmeldungen && modul.modulanmeldungen.st && semester === 0 && kannAnmelden.st && <ST />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.st && semester === 1 && kannAnmelden.st && <ST />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.stVorzeitig && semester === 2 && <VSTS />}
                        {modul.modulanmeldungen && modul.modulanmeldungen.st && semester === 2 && kannAnmelden.st && <STS />}
                    </ul>
                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
                        <input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const MenuRight = ({ tab, handleSubmit }) => {
    const dispatch = useDispatch();
    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
            <p className="menu_right_header">Anmeldung zur Modulprüfung</p>
            <ul id="menu_right">
                <li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>Auswahl Modul(e)</a>
                </li>
                <li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={(e) => { handleSubmit(e) ? dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG)) : dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)) }} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
                </li>
            </ul>
            <br /><br />
        </div>
    );
}

export const AnmeldungForm = () => {
    const dispatch = useDispatch();
    const ml = useSelector(state => state.modulList.modulList);
    const [modul, setModul] = useState(ml);
    const mlb = useSelector(state => state.modulList.modulListBefore);
    const [modulBefore, setModulBefore] = useState(mlb)
    const tab = useSelector(state => state.rightMenu);
    const z = useSelector(state => state.zulassung.zulassung);
    const [zulassung, setZulassung] = useState(z);
    const modulLoading = useSelector((state) => state.modulList.loading);
    const zulassungLoading = useSelector((state) => state.zulassung.loading);
    const [showSpinner, setShowSpinner] = useState(true);
    const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
    const [zulassungsblock, setZulassungsblock] = useState(zb);
    var kannAnmelden; var aktuelSemester; var semester;

    if (modul && modulBefore) {
        aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);
        if (modulBefore.modulanmeldungen) {
            kannAnmelden = modulBefore.modulanmeldungen;
        }
        if (aktuelSemester !== "SEM") {
            semester = parseInt(aktuelSemester.split('/')[0], 10);
        } else {
            semester = 0;
        }
    }

    useEffect(() => {
        if (modul && modulBefore && modul.modulanmeldungen && modulBefore.modulanmeldungen) {
            modul.modulanmeldungen.wpVorzeitig = false;
            modul.modulanmeldungen.wp = modulBefore.modulanmeldungen.wp;
            modul.modulanmeldungen.wr = modulBefore.modulanmeldungen.wr;
            modul.modulanmeldungen.wrVorzeitig = false;
            modul.modulanmeldungen.bwl = modulBefore.modulanmeldungen.bwl;
            modul.modulanmeldungen.bwlVorzeitig = false;
            modul.modulanmeldungen.st = modulBefore.modulanmeldungen.st;
            modul.modulanmeldungen.stVorzeitig = false;
            setModul({
                ...modul
            });
        }
    }, []);


    useEffect(() => {
        if (!modulLoading && !zulassungLoading) {
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [modulLoading, zulassungLoading]);


    useEffect(() => {
        setModul(ml);
    }, [ml]);

    useEffect(() => {
        setModulBefore(mlb);
    }, [mlb]);

    useEffect(() => {
        setZulassungsblock(zb);
    }, [zb]);

    const ErlaueterungForZusammenfassung = () => {
        return (
            <div className="hinweisText" style={{ marginBottom: '20px' }}>
                <p>Ihre Anmeldung wird hier zusammengefasst. Bitte prüfen Sie die Auswahl, bevor Sie Ihre Anmeldung verbindlich absenden.</p>

                <p>Ihre Anmeldung wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.</p>
            </div>
        );
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (semester === 1 &&
            !kannAnmelden.wp &&
            !kannAnmelden.wr &&
            !kannAnmelden.bwl &&
            !kannAnmelden.st) {
            toast.error("Sie können sich für keine Modulprüfung anmelden.")
            return false;
        }

        if (semester === 2 &&
            !kannAnmelden.wp &&
            !kannAnmelden.wpVorzeitig &&
            !kannAnmelden.wr &&
            !kannAnmelden.wrVorzeitig &&
            !kannAnmelden.bwl &&
            !kannAnmelden.bwlVorzeitig &&
            !kannAnmelden.st &&
            !kannAnmelden.stVorzeitig) {
            toast.error("Sie können sich an keine Modulprüfung anmelden.")
            return false;
        }


        if ((modul.modulanmeldungen.wp && modul.modulanmeldungen.wpVorzeitig) ||
            (modul.modulanmeldungen.st && modul.modulanmeldungen.stVorzeitig) ||
            (modul.modulanmeldungen.wr && modul.modulanmeldungen.wrVorzeitig) ||    
            (modul.modulanmeldungen.bwl && modul.modulanmeldungen.bwlVorzeitig)) {
            toast.error("Sie können nicht gleichzeitig 2 Modulen von BWL oder WR auswählen.")
            return false;
        }

        if (semester === 1 &&
            !modul.modulanmeldungen.wp &&
            !modul.modulanmeldungen.wr &&
            !modul.modulanmeldungen.bwl &&
            !modul.modulanmeldungen.st) {
            toast.error("Sie haben keine Modulprüfung ausgewählt.")
            return false;
        }

        if (semester === 2 &&
            !modul.modulanmeldungen.wp &&
            !modul.modulanmeldungen.wpVorzeitig &&
            !modul.modulanmeldungen.wr &&
            !modul.modulanmeldungen.wrVorzeitig &&
            !modul.modulanmeldungen.bwl &&
            !modul.modulanmeldungen.bwlVorzeitig &&
            !modul.modulanmeldungen.st &&
            !modul.modulanmeldungen.stVorzeitig) {
            toast.error("Sie haben keine Modulprüfung ausgewählt")
            return false;
        }

        if (semester === 1 &&
            modul.modulanmeldungen.wp ||
            modul.modulanmeldungen.wr ||
            modul.modulanmeldungen.bwl ||
            modul.modulanmeldungen.st) {
            toast.info("Die Daten wurden zwischengespeichert.");
            // dispatch(modulActions.saveTempModul(modul));
            dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
            return true;
        }

        if (semester === 2 &&
            (modul.modulanmeldungen.wp && !modul.modulanmeldungen.wpVorzeitig) ||
            (modul.modulanmeldungen.wpVorzeitig && !modul.modulanmeldungen.wp) ||
            (modul.modulanmeldungen.wr && !modul.modulanmeldungen.wrVorzeitig) ||
            (modul.modulanmeldungen.wrVorzeitig && !modul.modulanmeldungen.wr) ||
            (modul.modulanmeldungen.bwl && !modul.modulanmeldungen.bwlVorzeitig) ||
            (modul.modulanmeldungen.bwlVorzeitig && !modul.modulanmeldungen.bwl) ||
            (modul.modulanmeldungen.st && !modul.modulanmeldungen.stVorzeitig) ||
            (modul.modulanmeldungen.stVorzeitig && !modul.modulanmeldungen.st)) {
            toast.info("Die Daten wurden zwischengespeichert.");
            // dispatch(modulActions.saveTempModul(modul));
            dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
            return true;
        }
    };

    if (!modul) {
        return (
            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <ErlaueterungForAuswahlAnmeldung />
                <ModulNochNichtAnwendbar />
            </div>
        );
    }
    else {
        if (modulBefore && modulBefore.zwischegespeichert === 2) {
            return (
                <>
                    <form>
                        <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            {tab === 1 && <ErlaueterungForAuswahlAnmeldung />}
                            {tab === 2 && <ErlaueterungForZusammenfassung />}

                            {tab === 1 && (<AuswahlModul
                                aktuelSemester={aktuelSemester}
                                semester={semester}
                                modul={modul}
                                setModul={setModul}
                                kannAnmelden={kannAnmelden}
                                modulLoading={modulLoading}
                                showSpinner={showSpinner}
                                setShowSpinner={setShowSpinner}
                                handleSubmit={handleSubmit}
                            />)}
                            {tab === 2 && (<Zusammenfassung
                                aktuelSemester={aktuelSemester}
                                semester={semester}
                                modul={modul}
                                modulBefore={modulBefore}
                                kannAnmelden={kannAnmelden}
                                showSpinner={showSpinner}
                                setShowSpinner={setShowSpinner}
                            />)}
                        </div>
                    </form>
                    {tab === 1 && (<MenuRight
                        tab={tab}
                        handleSubmit={handleSubmit}
                    />)}
                    {tab === 2 && (<MenuRight
                        tab={tab}
                        handleSubmit={handleSubmit}
                    />)}
                </>
            );
        } else {
            return (
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <ErlaueterungForAuswahlAnmeldung />
                    <ModulNochNichtAnwendbar />
                </div>
            );
        }
    }

}
