import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { eignungActions, modulActions, modulEPActions, rightMenuActions, topMenuActions, zulassungActions, zulassungsblockActions } from '../actions';
import { MenuRight } from './MenuRight';
import { ModulpruefungRuecktritt } from './ruecktritt/ModulpruefungRuecktritt';
import { GesamtPruefung } from './ruecktritt/GesamtPruefung';
import { Spinner } from "./spinner/Spinner";
import { GesamtPruefungText, ModulNochNichtAnwendbar, RuecktrittText } from './HinweisTexte';
import { rightmenuConstants, topmenuConstants } from '../constants';

export const Ruecktritt = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authentication.user);
	const form = useSelector(state => state.topMenu);
	const tab = useSelector(state => state.rightMenu);
	const z = useSelector(state => state.zulassung.zulassung);
	const [zulassung, setZulassung] = useState(z);
	const m = useSelector(state => state.modulList.modulListBefore);
	const [modul, setModul] = useState(m);
	const [showSpinner, setShowSpinner] = useState(true);
	const loading = useSelector((state) => state.zulassung.loading);

	var subTitle = "";
	if (form === topmenuConstants.MODULPRUEFUNG_NR)
		subTitle = "Rücktritt von einer Modulprüfung";
	else if (form === topmenuConstants.GESAMTPRUEFUNG_NR)
		subTitle = "Rücktritt von der Gesamtprüfung";

	useEffect(() => {
		dispatch(zulassungsblockActions.getZulassungsblock());
		dispatch(zulassungActions.getZulassung());
		dispatch(modulActions.getModulList());
		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); 
		dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG)); 
	}, []);

	useEffect(() => {
		setModul(m);
	}, [m]);

	useEffect(() => {
		setZulassung(z);
	}, [z]);

	useEffect(() => {
		if (!loading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [loading]);


	if (!modul)
		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
								{form === topmenuConstants.MODULPRUEFUNG_NR && <RuecktrittText />}
								{form === topmenuConstants.GESAMTPRUEFUNG_NR && <GesamtPruefungText />}
								<ModulNochNichtAnwendbar />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	else {
		if (modul && modul.zwischegespeichert && modul.zwischegespeichert === 2) {
			return (
				<div className="container  container-small-view">
					<div className="row">
						<div className="col-xs-12">
							<h1>{subTitle}</h1>
						</div>
						<div className="container  container-small-view">
							<div className="row">
								<Spinner show={showSpinner} />
								{form === topmenuConstants.MODULPRUEFUNG_NR && <ModulpruefungRuecktritt />}
								{form === topmenuConstants.GESAMTPRUEFUNG_NR && <GesamtPruefung />}

								{tab === 1 && form !== topmenuConstants.MODULPRUEFUNG_NR && (modul.zwischegespeichert && modul.zwischegespeichert === 2) && <MenuRight />}
								{tab === 2 && form !== topmenuConstants.MODULPRUEFUNG_NR && (modul.zwischegespeichert && modul.zwischegespeichert === 2) && <MenuRight />}
							</div>
						</div>


					</div>
				</div>
			);
		}

		// For Security purpose
		if (zulassung && ((!zulassung.zwischegespeichert && zulassung.zwischegespeichert === 0) ||
			(zulassung.zwischegespeichert && zulassung.zwischegespeichert === 1))) {
			return (
				<div className="container  container-small-view">
					<div className="row">
						<div className="col-xs-12">
							<h1>{subTitle}</h1>
						</div>
						<div className="container  container-small-view">
							<div className="row">
								<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
									{form === topmenuConstants.MODULPRUEFUNG_NR && <RuecktrittText />}
									{form === topmenuConstants.GESAMTPRUEFUNG_NR && <GesamtPruefungText />}
									<ModulNochNichtAnwendbar />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<Spinner show={showSpinner} />
							{form === topmenuConstants.MODULPRUEFUNG_NR && <ModulpruefungRuecktritt />}
							{form === topmenuConstants.GESAMTPRUEFUNG_NR && <GesamtPruefung />}

							{tab === 1 && form !== topmenuConstants.MODULPRUEFUNG_NR && (modul.zwischegespeichert && modul.zwischegespeichert === 2) ? <MenuRight /> : <div />}
							{tab === 2 && form !== topmenuConstants.MODULPRUEFUNG_NR && (modul.zwischegespeichert && modul.zwischegespeichert === 2) ? <MenuRight /> : <div />}
						</div>
					</div>
				</div>
			</div>
		);
	}
};