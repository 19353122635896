import { userConstants } from '../constants';
import UserClass from '../models/user';

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_USER_REQUEST:
      return {
        user: new UserClass(),
        loading: true
      };
    case userConstants.GET_USER_SUCCESS:
      return {
        user: action.payload.data,
        loading: false
      };
    case userConstants.GET_USER_FAILURE:
      return { 
        error: action.payload.data,
        loading: false
      };
    case userConstants.SAVE_TEMPUSER:
      return {
        user: action.user,
        loading: false
      };
    case userConstants.SAVE_USER_REQUEST:
      return {
        loading: true
      };
    case userConstants.SAVE_USER_SUCCESS:
      return {
        user: action.payload.data,
        loading: false
      };
    case userConstants.SAVE_USER_FAILURE:
      return { 
        error: action.payload.data,
        loading: false
      };
    case userConstants.SAVE_PASSWORD_REQUEST:
        return {
          loading: true
        };
     case userConstants.SAVE_PASSWORD_SUCCESS:
        return {
          loading: false
        };
    case userConstants.SAVE_PASSWORD_FAILURE:
        return { 
          error: action.payload.data,
          loading: false
        };
    default:
      return state
  }
}