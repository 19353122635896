import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, zulassungActions, rightMenuActions } from '../../../actions';
import { rightmenuConstants, modulConstants, eventConstants } from '../../../constants';
import { util, modulService } from '../../../services';
import { Spinner } from "../../spinner/Spinner";
import { ModulNochNichtAnwendbar, VerschiebungText } from '../../HinweisTexte';
import { WPSModulVerschiebungenClass } from '../../../models/modul';

const ModulListWinter = ({ modul, modulBefore, kannVerschieben, handleChange, verschobenSemester }) => {
	if (!modul || !modulBefore) return (<div />);
	else {
		return (
			<>
				<div className="row">
					<div className="col-6 col-xs-12 col-sm-12 col-md-11 col-lg-11">
						<input type="checkbox" name="modulverschiebungen.wp" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.wp} checked={modul.modulverschiebungen.wp} onChange={e => handleChange(e)} disabled={!kannVerschieben.wp} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht
					</div>
				</div>
				<div className="row">
					<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
						<input type="checkbox" name="modulverschiebungen.st" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.st} checked={modul.modulverschiebungen.st} onChange={e => handleChange(e)} disabled={!kannVerschieben.st} /> Steuerrecht auf
					</div>
				</div>
				<span className="formLabel">verschieben.</span>
			</>
		);
	}
}

const ModulListSommer = ({ modul, modulBefore, kannVerschieben, handleChange, verschobenSemester }) => {
	if (!modul || !modulBefore) return (<div />);
	else {
		return (
			<>
				<div className="row">
					<div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="checkbox" name="modulverschiebungen.wpVorzeitig" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.wpVorzeitig} checked={modul.modulverschiebungen.wpVorzeitig} onChange={e => handleChange(e)} disabled={!kannVerschieben.wpVorzeitig} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht im Juni
					</div>
				</div>
				

				<div className="row">
					<div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="checkbox" name="modulverschiebungen.bwlVorzeitig" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.bwlVorzeitig} checked={modul.modulverschiebungen.bwlVorzeitig} onChange={e => handleChange(e)} disabled={!kannVerschieben.bwlVorzeitig} /> Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre im Juni
					</div>
				</div>
				
				<div className="row">
					<div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="checkbox" name="modulverschiebungen.wrVorzeitig" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.wrVorzeitig} checked={modul.modulverschiebungen.wrVorzeitig} onChange={e => handleChange(e)} disabled={!kannVerschieben.wrVorzeitig} /> Wirtschaftsrecht im Juni
					</div>
				</div>
				

				<div className="row">
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="checkbox" name="modulverschiebungen.stVorzeitig" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.stVorzeitig} checked={modul.modulverschiebungen.stVorzeitig} onChange={e => handleChange(e)} disabled={!kannVerschieben.stVorzeitig} /> Steuerrecht im Juni
					</div>
				</div>
				
				<div className="row">
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">&nbsp;</div>
				</div>
				
				<div className="row">
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="checkbox" name="modulverschiebungen.wp" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.wp} checked={modul.modulverschiebungen.wp} onChange={e => handleChange(e)} disabled={!kannVerschieben.wp} /> Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht im August
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="checkbox" name="modulverschiebungen.bwl" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.bwl} checked={modul.modulverschiebungen.bwl} onChange={e => handleChange(e)} disabled={!kannVerschieben.bwl} /> Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre im August
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-612">
						<input type="checkbox" name="modulverschiebungen.wr" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.wr} checked={modul.modulverschiebungen.wr} onChange={e => handleChange(e)} disabled={!kannVerschieben.wr} /> Wirtschaftsrecht im August
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="checkbox" name="modulverschiebungen.st" style={{ marginRight: '15px' }} value={modul.modulverschiebungen.st} checked={modul.modulverschiebungen.st} onChange={e => handleChange(e)} disabled={!kannVerschieben.st} /> Steuerrecht im August
					</div>
				</div>



				<span className="formLabel">verschieben.</span>
			</>
		);
	}
}

const Verschieben = ({ aktuelSemester, semester, modul, modulBefore, kannVerschieben, verschobenSemester, handleChange, handleSubmit }) => {

	if (!modul || !modul.modulverschiebungen || !modulBefore) return (<div />);
	else {
		return (
			<>
				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

					<h2 style={{ paddingTop: '25px' }}>Verschiebung Modul(e)</h2>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Ich möchte folgende Modulprüfung(en), vorgesehen im Prüfungstermin {aktuelSemester}, verschieben auf den Prüfungstermin {verschobenSemester}</span>
							{semester === 0 && <ModulListWinter
								modul={modul}
								modulBefore={modulBefore}
								kannVerschieben={kannVerschieben}
								handleChange={handleChange}
								verschobenSemester={verschobenSemester}
							/>}
							{semester === 1 && <ModulListWinter
								modul={modul}
								modulBefore={modulBefore}
								kannVerschieben={kannVerschieben}
								handleChange={handleChange}
								verschobenSemester={verschobenSemester}
							/>}
							{semester === 2 && <ModulListSommer
								modul={modul}
								modulBefore={modulBefore}
								kannVerschieben={kannVerschieben}
								handleChange={handleChange}
								verschobenSemester={verschobenSemester}
							/>}


						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
						</div>
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
					</div>

					<br />

				</div>

				<div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
					</div>

				</div>
			</>
		);
	}
}

const Zusammenfassung = ({ aktuelSemester, semester, modul, modulBefore, kannVerschieben, verschobenSemester, handleBack, showSpinner, setShowSpinner }) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const d = useSelector((state) => state.modulList.done);
	const [done, setDone] = useState(d)
	const e = useSelector((state) => state.modulList.error);
	const [error, setError] = useState(e);


	useEffect(() => {
		if (done) {
			if (error) {
				setShowSpinner(false);
				dispatch({ type: modulConstants.GET_FAILURE_DONE });
				dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
				toast.error("Die Verschiebung wurde nicht erfolgreich abgesendet.");
			} else {
				setShowSpinner(false);
				dispatch({ type: modulConstants.GET_DONE });
				dispatch(modulActions.getModulList());
				dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
				toast.success("Die Verschiebung wurde erfolgreich abgesendet.");
				history.push("/examensportal/start");
			}
		}
	}, [d, history]);

	const VWP = () => {
		if (!modul) return (<div />);
		else {
			return (
				<li>
					<p>Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht (Juni {util.getJahr(verschobenSemester)})</p>
				</li>
			);
		}
	}

	const WP = () => {
		return (
			<li>
				<p>Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht</p>
			</li>
		);
	}

	const VBWLS = () => {
		if (!modul) return (<div />);
		else {
			return (
				<li>
					<p>Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre (Juni {util.getJahr(verschobenSemester)})</p>
				</li>
			);
		}
	}

	const BWLS = () => {
		if (!modul) return (<div />);
		else {
			return (
				<li>
					<p>Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre (August {util.getJahr(verschobenSemester)})</p>
				</li>
			);
		}
	}

	const VWRS = () => {
		if (!modul) return (<div />);
		else {
			return (
				<li>
					<p>Wirtschaftsrecht (Juni {util.getJahr(verschobenSemester)})</p>
				</li>
			);
		}
	}

	const WRS = () => {
		if (!modul) return (<div />);
		else {
			return (
				<li>
					<p>Wirtschaftsrecht (August {util.getJahr(verschobenSemester)})</p>
				</li>
			);
		}
	}

	const VST = () => {
		if (!modul) return (<div />);
		else {
			return (
				<li>
					<p>Steuerrecht (Juni {util.getJahr(verschobenSemester)})</p>
				</li>
			);
		}
	}

	const ST = () => {
		return (
			<li>
				<p>Steuerrecht</p>
			</li>
		);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		var mv = new WPSModulVerschiebungenClass();
		mv.art = eventConstants.MODULPRUEFUNG_VERSCHIEBUNG;
		mv.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
		mv.verschobenSemester = util.changeFormatSemester2(verschobenSemester);
		mv.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
		if(semester === 1){
			mv.wp = modul.modulverschiebungen.wp;
			mv.wpVorzeitig = null;
			mv.st = modul.modulverschiebungen.st;
			mv.stVorzeitig = null;
			mv.wr = null;
			mv.wrVorzeitig = null;
			mv.bwl = null;
			mv.bwlVorzeitig = null;
		}else if(semester === 2){
			mv.wr = modul.modulverschiebungen.wr;
			mv.wrVorzeitig = modul.modulverschiebungen.wrVorzeitig;
			mv.bwl = modul.modulverschiebungen.bwl;
			mv.bwlVorzeitig = modul.modulverschiebungen.bwlVorzeitig;
			mv.wp = modul.modulverschiebungen.wp;
			mv.wpVorzeitig = modul.modulverschiebungen.wpVorzeitig;
			mv.st = modul.modulverschiebungen.st;
			mv.stVorzeitig = modul.modulverschiebungen.stVorzeitig;
		}
		const replacer = (key, value) => {
			if (typeof value === 'undefined')
				return false
			else return value;
		}
		var modulVerschiebungInfos = JSON.parse(JSON.stringify(mv, replacer));

		setShowSpinner(true);
		dispatch(modulActions.saveEventInfos(modul, modulBefore, modulVerschiebungInfos));
		// toast.success("Ihr Zulassungsantrag wurde abgeschickt");
		// history.push("/examensportal/start");

	};

	if (!modul || !modul.modulverschiebungen || !modulBefore) return (<div />);
	else {
		return (
			<>
				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
					<h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>
					<Spinner show={showSpinner} />
					<span className="formLabel">Ich möchte meine Modulprüfung(en) erst im Prüfungstermin {verschobenSemester} fortsetzen:</span>
					<ul>
						{modul.modulverschiebungen.wpVorzeitig && semester === 2 && <VWP />}
						{modul.modulverschiebungen.wp && kannVerschieben.wp && semester === 1 && <WP />}
						{modul.modulverschiebungen.bwlVorzeitig && semester === 2 && <VBWLS />}
						{modul.modulverschiebungen.bwl && semester === 2 && kannVerschieben.bwl && <BWLS />}
						{modul.modulverschiebungen.wrVorzeitig && semester === 2 && <VWRS />}
						{modul.modulverschiebungen.wr && semester === 2 && kannVerschieben.wr && <WRS />}
						{modul.modulverschiebungen.stVorzeitig && semester === 2 && <VST />}
						{modul.modulverschiebungen.st && kannVerschieben.st && semester === 1 && <ST />}
					</ul>
				</div>

				<div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
						<input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
					</div>
				</div>
			</>
		);
	}
}

const MenuRight = ({ tab, handleSubmit }) => {
    const dispatch = useDispatch();
    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
            <p className="menu_right_header">Rücknahme der Anmeldung zu einer Modulprüfung</p>
            <ul id="menu_right">
                <li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>Auswahl Modul(e)</a>
                </li>
                <li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={(e) => { handleSubmit(e) ? dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG)) : dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)) }} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
                </li>
            </ul>
            <br /><br />
        </div>
    );
}

export const VerschiebenForm = () => {
	const dispatch = useDispatch();
	const tab = useSelector(state => state.rightMenu);
	const ml = useSelector(state => state.modulList.modulList);
	const [modul, setModul] = useState(ml);
	const mlb = useSelector(state => state.modulList.modulListBefore);
	const [modulBefore, setModulBefore] = useState(mlb)
	const modulLoading = useSelector((state) => state.modulList.loading);
	const zulassungLoading = useSelector((state) => state.zulassung.loading);
	const [showSpinner, setShowSpinner] = useState(true);
	const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
    const [zulassungsblock, setZulassungsblock] = useState(zb);

	const z = useSelector(state => state.zulassung.zulassung);
	const [zulassung, setZulassung] = useState(z);

	var kannVerschieben; var aktuelSemester; var semester; var verschobenSemester;
	if (modul && modulBefore) {
		kannVerschieben = modulBefore.modulverschiebungen;
		aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);
		if (aktuelSemester !== "SEM") {
			semester = parseInt(aktuelSemester.split('/')[0], 10);
			verschobenSemester = util.updateSemester(aktuelSemester, 2);
		} else {
			semester = 0;
			verschobenSemester = "SEM";
		}
	}

	//console.log(modul)

	useEffect(() => {
		if (!modulLoading && !zulassungLoading) {
			setShowSpinner(false);

			//console.log("Modul geladen")
			// Add two attribute data bwlVorzeitig and wrVorzeitig for sending data to WPS
			// setModul({
			// 	...modul,
			// 	modulverschiebungen: {
			// 		...modul.modulverschiebungen,
			// 		["bwlVorzeitig"]: false,
			// 		["wrVorzeitig"]: false,
			// 		["wpVorzeitig"]: false,
			// 		["stVorzeitig"]: false,
			// 	}
			// })
		} else {
			setShowSpinner(true);
		}
	}, [modulLoading, zulassungLoading]);

	useEffect(() => {
		setModul(ml);
	}, [ml]);

	useEffect(() => {
		setModulBefore(mlb);
	}, [mlb]);

	useEffect(() => {
		setZulassungsblock(zb);
	}, [zb]);

	const handleChange = (e) => {
		let v = false;
		if (e.target.type === "checkbox" || e.target.type === "radio") {
			if (e.target.value === "false" || e.target.value === false) {
				v = true;
			}
		} else {
			v = e.target.value;
		}

		if (e.target.name.indexOf("modulverschiebungen") > -1) {
			setModul({
				...modul,
				modulverschiebungen: {
					...modul.modulverschiebungen,
					[e.target.name.split(".")[1]]: v,
				},
			});
		} else {
			setModul({ ...modul, [e.target.name]: v });
		}
	};

	const handleBack = (e) => {
		e.preventDefault();
		//dispatch(modulActions.saveTempModul(modul));
		toast.info("Die Daten wurden zwischengespeichert.");
		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (semester === 1 && 
			!kannVerschieben.wp &&
            !kannVerschieben.st) {
            toast.error("Sie können keine Modulprüfung verschieben.")
            return false;
        }

		if (semester === 2 &&
			!kannVerschieben.wp &&
            !kannVerschieben.wpVorzeitig && 
            !kannVerschieben.wr &&
            !kannVerschieben.wrVorzeitig &&
            !kannVerschieben.bwl &&
            !kannVerschieben.bwlVorzeitig &&
			!kannVerschieben.st &&
            !kannVerschieben.stVorzeitig) {
            toast.error("Sie können keine Modulprüfung verschieben.")
            return false;
        }


        if ((modul.modulverschiebungen.wp && modul.modulverschiebungen.wpVorzeitig) ||
			(modul.modulverschiebungen.st && modul.modulverschiebungen.stVorzeitig) ||
			(modul.modulverschiebungen.wr && modul.modulverschiebungen.wrVorzeitig) ||
            (modul.modulverschiebungen.bwl && modul.modulverschiebungen.bwlVorzeitig)) {
            toast.error("Sie können nicht gleichzeitig Module von WP, WR, BWL oder ST auswählen.")
            return false;
        }

		if(semester === 1 && !modul.modulverschiebungen.wp && !modul.modulverschiebungen.st){
			toast.error("Sie haben keine Modulprüfung ausgewählt.")
            return false;
		}

		if (semester === 2 &&
			!modul.modulverschiebungen.wp &&
            !modul.modulverschiebungen.wpVorzeitig &&
            !modul.modulverschiebungen.wr &&
            !modul.modulverschiebungen.wrVorzeitig &&
            !modul.modulverschiebungen.bwl &&
            !modul.modulverschiebungen.bwlVorzeitig &&
			!modul.modulverschiebungen.st &&
            !modul.modulverschiebungen.stVorzeitig) {
            toast.error("Sie haben keine Modulprüfung ausgewählt.")
            return false;
        }

		if(semester === 1 && (modul.modulverschiebungen.wp || modul.modulverschiebungen.st)){
			dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
			toast.info("Die Daten wurden zwischengespeichert.");
			return true;
		}

		if (semester === 2 &&
			(modul.modulverschiebungen.wp && !modul.modulverschiebungen.wpVorzeitig) ||
            (modul.modulverschiebungen.wpVorzeitig && !modul.modulverschiebungen.wp) ||
			(modul.modulverschiebungen.st && !modul.modulverschiebungen.stVorzeitig) ||
            (modul.modulverschiebungen.stVorzeitig && !modul.modulverschiebungen.st) ||
			(modul.modulverschiebungen.wr && !modul.modulverschiebungen.wrVorzeitig) ||
            (modul.modulverschiebungen.wrVorzeitig && !modul.modulverschiebungen.wr) ||
            (modul.modulverschiebungen.bwl && !modul.modulverschiebungen.bwlVorzeitig) ||
            (modul.modulverschiebungen.bwlVorzeitig && !modul.modulverschiebungen.bwl)){
			
			dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
            toast.info("Die Daten wurden zwischengespeichert.");
            return true;
        }
	};

	const ZusammenfassungText = () => {
		return (

			<div className="hinweisText" style={{ marginBottom: '20px' }}>
				<p>
					Ihre Bitte um Verschiebung einer Modulprüfung wird hier zusammengefasst.
					Bitte prüfen Sie die Auswahl, bevor Sie Ihre Mitteilung verbindlich absenden.
					Ihre Mitteilung wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.
				</p>
			</div>
		);
	}

	if (!modul)
		return (
			<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
				<VerschiebungText />

				<ModulNochNichtAnwendbar />
			</div>
		);
	else {
		if (modulBefore && modulBefore.zwischegespeichert === 2) {
			return (
				<>
					<form>
						<Spinner show={showSpinner} />
						<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
							{tab === 1 && <VerschiebungText />}
							{tab === 2 && <ZusammenfassungText />}

							{tab === 1 && <Verschieben
								aktuelSemester={aktuelSemester}
								semester={semester}
								modul={modul}
								modulBefore={modulBefore}
								kannVerschieben={kannVerschieben}
								verschobenSemester={verschobenSemester}
								handleChange={handleChange}
								handleSubmit={handleSubmit}
							/>}

							{tab === 2 && <Zusammenfassung
								aktuelSemester={aktuelSemester}
								semester={semester}
								modul={modul}
								modulBefore={modulBefore}
								kannVerschieben={kannVerschieben}
								verschobenSemester={verschobenSemester}
								handleBack={handleBack}
								showSpinner={showSpinner}
								setShowSpinner={setShowSpinner}
							/>}
						</div>
					</form>
					{tab === 1 && (<MenuRight
						tab={tab}
						handleSubmit={handleSubmit}
					/>)}
					{tab === 2 && (<MenuRight
						tab={tab}
						handleSubmit={handleSubmit}
					/>)}
				</>
			);
		} else {
			return (
				<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
					<VerschiebungText />

					<ModulNochNichtAnwendbar />
				</div>
			);
		}

	}
}