export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_SESSION: 'USERS_LOGIN_SESSION',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    GET_USER_REQUEST: 'USERS_GET_REQUEST',
    GET_USER_SUCCESS: 'USERS_GET_SUCCESS',
    GET_USER_FAILURE: 'USERS_GET_FAILURE',

    SAVE_USER_REQUEST: 'USERS_SAVE_REQUEST',
    SAVE_USER_SUCCESS: 'USERS_SAVE_SUCCESS',
    SAVE_USER_FAILURE: 'USERS_SAVE_FAILURE',

    SAVE_TEMPUSER: 'USERS_TEMPSAVE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

};
