import { authHeader } from '../helpers';
import axios from "axios";

export const eignungService = {
    getEignung,
    saveEignung,
    saveTempEignung,
    checkEignung,
    getZulassungsblock
};

function getEignung() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/eignung`, requestOptions);
}

function checkEignung() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/eignung/check`, requestOptions);

}

function saveEignung(eignung, nachweise, wahlfach, zeugnisse) {
    const formData = new FormData();

    const requestOptions = {
        headers: { ...authHeader() },
    };

    if(nachweise){
        for(let a = 0; a < nachweise.length; a++){
            formData.append("nachweise", nachweise[a]);
        }
    }else{
        formData.append("nachweise", []);
    }

    if(zeugnisse){
        for(let a = 0; a < zeugnisse.length; a++){
            formData.append("zeugnis", zeugnisse[a]);
        }
    }else{
        formData.append("zeugnis", []);
    }

    if(wahlfach){
        for(let a = 0; a < wahlfach.length; a++){
            formData.append("wahlfach", wahlfach[a]);
        }
    }else{
        formData.append("wahlfach", []);
    }
    
    
    formData.append("eignung", JSON.stringify(eignung));

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/eignung/files`, formData, requestOptions);
}

function saveTempEignung(eignung, nachweise, wahlfach, zeugnisse) {
    const formData = new FormData();

    const requestOptions = {
        headers: { ...authHeader() },
    };

    if(nachweise){
        for(let a = 0; a < nachweise.length; a++){
            formData.append("nachweise", nachweise[a]);
        }
    }else{
        formData.append("nachweise", []);
    }

    if(zeugnisse){
        for(let a = 0; a < zeugnisse.length; a++){
            formData.append("zeugnis", zeugnisse[a]);
        }
    }else{
        formData.append("zeugnis", []);
    }

    if(wahlfach){
        for(let a = 0; a < wahlfach.length; a++){
            formData.append("wahlfach", wahlfach[a]);
        }
    }else{
        formData.append("wahlfach", []);
    }
    
    formData.append("eignung", JSON.stringify(eignung));

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/eignung/zwischenspeichern`, formData, requestOptions);
}

function getZulassungsblock() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/eignung/zulassungsblock`, requestOptions);
}