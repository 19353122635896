import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { zulassungActions, modulpruefungTopMenuActions, rightMenuActions } from '../actions';
import { topmenuConstants, rightmenuConstants, rightmenuDokumenteConstants, rightmenuZulassungsAntragConstants } from '../constants';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';

export const MenuRight = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const tab = useSelector(state => state.rightMenu);
    const tabZulassungstrag = useSelector(state => state.rightmenuZulassungsAntrag);
    const tabDokumente = useSelector(state => state.rightmenuDokumente)
    const form = useSelector(state => state.topMenu);
    var descriptionTab = "";
    var subTitle = "";

    switch (form) {
        case topmenuConstants.ZULASSUNGANTRAG_STELLEN_NR:
            subTitle = "Zulassungsantrag stellen";
            break;
        case topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR:
            subTitle = "Rücknahme des Zulassungsantrags";
            descriptionTab = "Erklärung";
            break;
        case topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM_NR:
            subTitle = "Anmeldung zur Modulprüfung";
            descriptionTab = "Auswahl Modul(e)";
            break;
        case topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM_NR:
            subTitle = "Rücknahme der Anmeldung zu einer Modulprüfung";
            descriptionTab = "Auswahl Modul(e)";
            break;
        case topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM_NR:
            subTitle = "Verschiebung einer Modulprüfung"
            descriptionTab = "Verschiebung Modul(e)";
            break;
        case topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM_NR:
            subTitle = "Prüfungsäquivalente Leistung anrechnen";
            descriptionTab = "Auswahl Modul(e)";
            break;

        case topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES_NR:
            subTitle = "Mitteilung eines triftigen Grundes";
            descriptionTab = "Grund";
            break;
        case topmenuConstants.MODULPRUEFUNG_NR:
            subTitle = "Rücktritt von einer Modulprüfung";
            descriptionTab = "Modulauswahl";
            break;

        case topmenuConstants.GESAMTPRUEFUNG_NR:
            subTitle = "Rücktritt von der Gesamtprüfung";
            descriptionTab = "Erklärung";
            break;

        case topmenuConstants.KONTAKTDATEN_AENDERN_NR:
            subTitle = "Meine Daten";
            descriptionTab = "Meine persönlichen Daten";
            break;

        case topmenuConstants.KENNWORT_AENDERN_NR:
            subTitle = "Meine Daten";
            descriptionTab = "Zugangsdaten ändern";
            break;

        // case 11:
        //     descriptionTab = "Einstellungen";
        //     break;

        case topmenuConstants.DIGITALE_AKTE_NR:
            subTitle = "Digitale elektronische Akte"
            descriptionTab = "Digitale Akte";
            break;

        default:
            descriptionTab = "Menü";
    }

    if (form === topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN_NR || form === topmenuConstants.GESAMTPRUEFUNG_NR){
        return (
            <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
                <p className="menu_right_header">{subTitle}</p>
                <ul id="menu_right">
                    <li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>{descriptionTab}</a>
                    </li>
                    <li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG))} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
                    </li>
                </ul>
                <br /><br />
            </div>
        );
    } else if (form === topmenuConstants.KENNWORT_AENDERN_NR) {
        return (
            <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide">
                
            </div>
        );
    } else if (form === topmenuConstants.DIGITALE_AKTE_NR) {
        return (
            <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide">
                <p className="menu_right_header">Digitale elektronische Akte
                </p>
                <ul id="menu_right">
                    <li style={tabDokumente === 1 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuDokumenteConstants.AKTENEINGANG))} style={tabDokumente === 1 ? { background: '#a0bcd1' } : {}}>Akteneingang</a>
                    </li>
                    <li style={tabDokumente === 2 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuDokumenteConstants.MANTELAKTE))} style={tabDokumente === 2 ? { background: '#a0bcd1' } : {}}>{user.art === 'EIGNUNG' ? 'Akte' : 'Mantelakte'}</a>
                    </li>
                    {user.art === 'WP' &&
                    <li style={tabDokumente === 3 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuDokumenteConstants.MODULPRUEFUNG_WP))} style={tabDokumente === 3 ? { background: '#a0bcd1' } : {}}>Modulprüfung WP</a>
                    </li>
                    }
                    {user.art === 'WP' &&
                    <li style={tabDokumente === 4 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuDokumenteConstants.MODULPRUEFUNG_WR))} style={tabDokumente === 4 ? { background: '#a0bcd1' } : {}}>Modulprüfung WR</a>
                    </li>
                    }
                    {user.art === 'WP' &&
                    <li style={tabDokumente === 5 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuDokumenteConstants.MODULPRUEFUNG_BWL))} style={tabDokumente === 5 ? { background: '#a0bcd1' } : {}}>Modulprüfung BWL</a>
                    </li>
                    }
                    {user.art === 'WP' &&
                    <li style={tabDokumente === 6 ? { background: '#a0bcd1' } : {}}>
                        <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuDokumenteConstants.MODULPRUEFUNG_STR))} style={tabDokumente === 6 ? { background: '#a0bcd1' } : {}}>Modulprüfung StR</a>
                    </li>
                    }
                </ul>
                <br /><br />
            </div>
        );
    }else{
        return (<div/>);
    }
};