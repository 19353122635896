import { KontaktdatenClass } from "./kontaktdaten";

export class EignungClass {

    constructor(){
        this.id = 0;
        this.zulassungsblock = "";
        this.lgs = 0;
        this.land = "";
        this.zwischegespeichert = 0;
        this.pruefungstyp = "";
        this.kontaktdaten = new KontaktdatenClass();
        this.bishierigeZulassungenEP = new ZulassungenEPClass();
        this.verkuerzung = new VerkuerzungEPClass();
        // this.datumNichtBestanden1 = null;
        // this.datumNichtBestanden2 = null;
        // this.verkuerzung = 0
        // this.nachweis = 0;
    }
}

class ZulassungenEPClass {

    constructor() {
        this.art = 0;
        this.datumNichtBestanden1 = null;
        this.datumNichtBestanden2 = null;
    }
}

class VerkuerzungEPClass {

    constructor() {
        this.id = 0;
        this.verkuerzung1 = false;
        this.verkuerzung2 = false;
        this.verkuerzung3 = false;
        this.verkuerzung4 = false;
        this.verkuerzung5 = false;
    }
}

export class VerkuerzungAuswahlEPClass {
    constructor() {
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = false;
        this.disabled4 = false;
        this.disabled5 = false;
    }
}
