export const zulassungConstants = {
    SAVE_REQUEST: 'ZULASSUNG_SAVE_REQUEST',
    SAVE_SUCCESS: 'ZULASSUNG_SAVE_SUCCESS',
    SAVE_TEMP_SUCCESS: 'ZULASSUNG_TEMP_SAVE_SUCCESS',
    SAVE_FAILURE: 'ZULASSUNG_SAVE_FAILURE',
    SAVE_TEMP_FAILURE: 'ZULASSUNG_TEMP_SAVE_FAILURE',

    GET_REQUEST: 'ZULASSUNG_GET_REQUEST',
    GET_SUCCESS: 'ZULASSUNG_GET_SUCCESS',
    GET_FAILURE: 'ZULASSUNG_GET_FAILURE',

    SAVE_TEMP: 'ZULASSUNG_TEMPSAVE',

    GET_DONE: 'ZULASSUNG_GET_DONE',
    GET_FAILURE_DONE: 'ZULASSUNG_GET_FAILURE_DONE',
    SAVE_FAILURE_DONE: 'ZULASSUNG_SAVE_FAILURE_DONE',

    CHECK: 'ZULASSUNG_CHECK',
    CLEAR: 'ZULASSUNG_CLEAR'
};
