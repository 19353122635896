import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, zulassungActions, rightMenuActions, modulEPActions } from '../../actions';
import { rightmenuConstants, eventConstants, modulConstants, dateConstants, modulEPConstants } from '../../constants';
import { Spinner } from "../spinner/Spinner";
import { ModulNochNichtAnwendbar, GesamtPruefungText, GesamtPruefungEignungText } from '../HinweisTexte';
import { util } from '../../services';
import { WPSGesamtPruefungClass } from '../../models/modul';

const GesamtPruefungForm = ({ modulEPBefore, zulassungsdatum, handleChange, handleSubmit }) => {

    if (!modulEPBefore) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

                    <h2 style={{ paddingTop: '25px' }}>Erklärung</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich wurde mit Schreiben vom {zulassungsdatum} zur Eignungsprüfung zugelassen.</span>

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <input type="checkbox" name="gesamtpruefung" style={{ marginRight: '15px' }} value={true} checked={true} onChange={e => handleChange(e)} />
                                    Hiermit trete ich verbindlich von der Prüfung zurück.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                        </div>
                        <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                    </div>

                    <br />

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const Zusammenfassung = ({ aktuelSemester, modulEPBefore, zulassungsdatum, handleBack, showSpinner, setShowSpinner }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const d = useSelector((state) => state.gesamtpruefung.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.gesamtpruefung.error);
    const [error, setError] = useState(e);

    useEffect(() => {
        if (d) {
            if (e) {
                setShowSpinner(false);
                dispatch({ type: modulEPConstants.GET_GESAMTPRUEFUNG_FAILURE_DONE });
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.error("Die GesamtPrüfungsrücknahme wurde nicht erfolgreich abgesendet.");
            } else {
                setShowSpinner(false);
                dispatch({ type: modulEPConstants.GET_GESAMTPRUEFUNG_DONE });
                dispatch(modulEPActions.getModulEPList());
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.success("Die GesamtPrüfungsrücknahme wurde erfolgreich abgesendet.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        var gp = new WPSGesamtPruefungClass();
        gp.art = eventConstants.PRUEFUNGEP_RUECKTRITT;
        gp.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
        gp.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return false
            else return value;
        }
        var gesamtPruefungInfos = JSON.parse(JSON.stringify(gp, replacer));

        // console.log(zulassungRuecknahmeInfos);

        setShowSpinner(true);
        dispatch(modulActions.saveGesamtPruefungInfos(gesamtPruefungInfos));
        // toast.success("Sie haben sich erfolgreich angemeldet");
        // history.push("/examensportal/start");

    }

    if (!modulEPBefore) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
                    <Spinner show={showSpinner} />
                    <h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich wurde mit Schreiben vom {zulassungsdatum} zur Eignungsprüfung zugelassen. Hiermit trete ich verbindlich von der Prüfung zurück.</span>

                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
                        <input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

export const GesamtPruefungEP = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const tab = useSelector(state => state.rightMenu);
    const mlep = useSelector(state => state.modulEPList.modulEPList);
    const [modulEP, setModulEP] = useState(mlep);
    const modulLoading = useSelector((state) => state.modulEPList.loading);
    const eignungLoading = useSelector((state) => state.eignung.loading);
    const [showSpinner, setShowSpinner] = useState(true);
    const mlbep = useSelector(state => state.modulEPList.modulEPListBefore);
    const [modulEPBefore, setModulEPBefore] = useState(mlbep);
    const e = useSelector(state => state.eignung.eignung);
    const [eignung, setEignung] = useState(e);
    const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
    const [zulassungsblock, setZulassungsblock] = useState(zb);
    var zulassungsdatum; var aktuelSemester;

    if (modulEPBefore) {
        aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);
        zulassungsdatum = util.changeDateDEToENGFormat(modulEPBefore.ersteszulassungsdatum);
    }

    //console.log(modul);

    useEffect(() => {
        if (!modulLoading && !eignungLoading) {
            //console.log("Modul geladen")
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [modulLoading, eignungLoading]);


    useEffect(() => {
        setEignung(e);
    }, [e]);

    useEffect(() => {
        setModulEP(mlep);
    }, [mlep]);

    useEffect(() => {
        setZulassungsblock(zb);
    }, [zb]);

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("modulverschiebungen") > -1) {
            setModulEP({
                ...modulEP,
                modulverschiebungen: {
                    ...modulEP.modulverschiebungen,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setModulEP({ ...modulEP, [e.target.name]: v });
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        //dispatch(modulActions.saveTempModul(modul));
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //dispatch(zulassungActions.saveTempZulassung(zulassung));
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
    };

    const ZusammenfassungText = () => {
        return (
            <div className="hinweisText" style={{ marginBottom: '20px' }}>
                <p>
                    Ihre Erklärung zum Rücktritt von der gesamten Prüfung wird hier zusammengefasst.
                    Bitte prüfen Sie Ihre Angaben, bevor Sie Ihren Rücktritt verbindlich absenden.
                </p>

                <p>Ihr Rücktritt wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.</p>
            </div>
        );
    }

    if (!modulEPBefore) {
        return (
            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <GesamtPruefungEignungText />

                <ModulNochNichtAnwendbar />
            </div>
        );
    } else {
        if (user && user.art && user.art === 'EIGNUNG') {
            if ((modulEPBefore && modulEPBefore.zwischegespeichert === 2)
               && (util.existsDatum(modulEPBefore.ersteszulassungsdatum))
                // For Test purpose, the condition below could be removed temporally
               && (modulEPBefore.zwischegespeichert && modulEPBefore.zwischegespeichert == 2 && util.isWithinThreeDays(modulEPBefore.ersteszulassungsdatum) == dateConstants.AFTER_REACHING)
            ) {
                return (
                    <form onSubmit={e => handleSubmit(e)}>
                        <Spinner show={showSpinner} />
                        <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            {tab === 1 && <GesamtPruefungEignungText />}
                            {tab === 2 && <ZusammenfassungText />}

                            {tab === 1 && <GesamtPruefungForm
                                modulEPBefore={modulEPBefore}
                                zulassungsdatum={zulassungsdatum}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                            />}
                            {tab === 2 && <Zusammenfassung
                                aktuelSemester={aktuelSemester}
                                modulEPBefore={modulEPBefore}
                                zulassungsdatum={zulassungsdatum}
                                handleBack={handleBack}
                                showSpinner={showSpinner}
                                setShowSpinner={setShowSpinner}
                            />}

                        </div>
                    </form>
                );
            } else {
                return (
                    <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <GesamtPruefungEignungText />

                        <ModulNochNichtAnwendbar />
                    </div>
                );
            }
        }
    }
}