import React, { useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password'
import { toast } from 'react-toastify';
import { userService } from '../services';
import { userActions } from '../actions';

const Register = (props) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.alert.message);
  const reRef = useRef();
  YupPassword(Yup);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { vorname: "", nachname: "", email: "", art:"WP", passwort: "", passwort1: "", token: "" },
    validationSchema: Yup.object({
      token: Yup.string().required("reCaptcha konnte nicht validiert werden"),
      vorname: Yup.string()
        .min(2, 'Der Vorname muss aus mindesten 2 Buchstaben bestehen')
        .required('Das Feld darf nicht leer sein'),
      nachname: Yup.string()
        .min(2, 'Der Nachname muss aus mindesten 2 Buchstaben bestehen')
        .required('Das Feld darf nicht leer sein'),
      email: Yup.string().email('Die E-Mail-Adresse ist nicht gültig').required('Das Feld darf nicht leer sein'),
      passwort: Yup.string()
        .min(8, "Das Passwort muss aus mindesten 8 Buchstaben bestehen")
        .minUppercase(1, "Das Passwort muss mindestens einen Großbuchstaben enthalten")
        .minLowercase(1, "Das Passwort muss mindestens einen Kleinbuchstaben enthalten")
        .minNumbers(1, "Das Passwort muss mindestens eine Zahl enthalten")
        .minSymbols(1, "Das Passwort muss mindestens ein Sonderzeichen enthalten")
        .required('Das Feld darf nicht leer sein'),
      passwort1: Yup.string().oneOf([Yup.ref('passwort'), null], 'Die Passwörter müssen übereinstimmen'),
      art: Yup.string().required("Die Art der Registrierung konnte nicht validiert werden")
    }),
    onSubmit: async(values) => {
      dispatch(userActions.register(values));
    },
  });

  const handleBlur = async () => {
      if(!formik.values.token){
        const token = await reRef.current.executeAsync();
        reRef.current.reset();
        formik.setFieldValue("token", token);
      }
  }



  return (
    <div className="container container-small-view">
      <div className="row">
        <div className="col-xs-12">
          <h1>Registrierung</h1>
        </div>
        <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
          <div className="hinweisText">
            <p>
              Bitte geben Sie hier Ihre Daten für die Registrierung ein.
            </p>
            <p>
              Ihr Passwort muss mindestens:
            </p>
            <ul>
              <li>aus 8 Zeichen bestehen sowie</li>
              <li>einen Großbuchstaben,</li>
              <li>einen Kleinbuchstaben,</li>
              <li>eine Ziffer und</li>
              <li>ein Sonderzeichen enthalten.</li>
            </ul>

            <p>Nach dem SENDEN Ihrer Angaben erhalten Sie eine E-Mail, um Ihre Registrierung zu bestätigen. Erst danach können Sie sich im Onlineportal „Wirtschaftsprüfungsexamen“ anmelden.</p>
          </div>

          <h1 style={{ marginBbottom: '0.2em' }}>Registrierung</h1>

          <form onSubmit={formik.handleSubmit}>

            <div style={{ marginBottom: '0.5em', paddingBottom: '1em' }} className="col-12 col-sm-12 col-md-12 col-lg-12 form-Box-Hellblau">

              {!message ? null : (
                <div
                  className="row"
                  style={{ paddingTop: "20px", paddingBottom: "20px" }}
                >
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span className="pflichtfeld2">{message}</span>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="formLabel">Anmeldung zu*</span>
                  <select name="art" value={formik.values.art} className="inputText100percent" onChange={formik.handleChange} onBlur={() => {handleBlur()}}>
                    <option value="WP">Wirtschaftsprüfungsexamen</option>
                    <option value="EIGNUNG">Eignungsprüfung</option>
                  </select>
                  
                </div>
              </div>
              
              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="formLabel">Vorname*</span>
                  <input type="text" name="vorname" value={formik.values.vorname} className="inputText100percent" onChange={formik.handleChange} onBlur={() => {handleBlur()}} />
                  {formik.touched.vorname && formik.errors.vorname ? (
                    <div className="pflichtfeld">{formik.errors.vorname}</div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="formLabel">Nachname*</span>
                  <input id="nachname" type="text" name="nachname" value={formik.values.nachname} className="inputText100percent" onChange={formik.handleChange} onBlur={() => {handleBlur()}} />
                  {formik.touched.nachname && formik.errors.nachname ? (
                    <div className="pflichtfeld">{formik.errors.nachname}</div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="formLabel">E-Mail*</span>
                  <input type="text" name="email" value={formik.values.email} className="inputText100percent" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="pflichtfeld">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="formLabel">Passwort*</span>
                  <input type="password" name="passwort" autoComplete="off" value={formik.values.passwort} className="inputText100percent" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {formik.touched.passwort && formik.errors.passwort ? (
                    <div className="pflichtfeld">{formik.errors.passwort}</div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <span className="formLabel">Passwort wiederholen*</span>
                  <input type="password" name="passwort1" autoComplete="off" value={formik.values.passwort1} className="inputText100percent" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {formik.touched.passwort1 && formik.errors.passwort1 ? (
                    <div className="pflichtfeld">{formik.errors.passwort1}</div>
                  ) : null}
                </div>
              </div>


              <div className="row">
                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                </div>
                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
              </div>

              <div className="row">
                <div style={{ paddingTop: '0.3em' }} className="col-3 col-xs-4 col-sm-3 col-md-3 col-lg-3"></div>
                <div style={{ paddingTop: '0.3em' }} className="col-9 col-xs-8 col-sm-9 col-md-9 col-lg-9">
                  <Link to="/examensportal/login" className="button">Abbrechen</Link>
                  <button type="submit" className="button">Senden</button>

                </div>
                <div style={{ paddingTop: '0.3em' }} className="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
              </div>

              <ReCAPTCHA 
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                size='invisible'
                ref={reRef}
                
              />


            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const connectedRegistrierungPage = connect(null)(Register);
export { connectedRegistrierungPage as Register }; 