import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { zulassungActions, modulActions, zulassungsblockActions, eignungActions, modulEPActions} from '../actions';
import { Spinner } from "./spinner/Spinner";
import bmwi from '../assets/bmwi.png';


// Startseite wird nur angezeigt, wenn Modul und Zulassungsantrag geladen werden
export const Start = () => {
	const user = useSelector((state) => state.authentication.user);
	const dispatch = useDispatch();
	const z = useSelector(state => state.zulassung.zulassung);
	const [zulassung, setZulassung] = useState(z);
	const ml = useSelector(state => state.modulList.modulList);
	const [modul, setModul] = useState(ml);
	const [showSpinner, setShowSpinner] = useState(false);
	const modulLoading = useSelector((state) => state.modulList.loading);
	const zulassungLoading = useSelector((state) => state.zulassung.loading);

	useEffect(() => {
		dispatch(zulassungsblockActions.getZulassungsblock());
		(user && user.art && user.art === 'WP') ? dispatch(zulassungActions.getZulassung()) : dispatch(eignungActions.getEignung());
		(user && user.art && user.art === 'WP') ? dispatch(modulActions.getModulList()) : dispatch(modulEPActions.getModulEPList());
	}, []);

	useEffect(() => {
		setZulassung(z);
	}, [z]);

	useEffect(() => {
		setModul(ml);
	}, [ml]);
	
	return (
		<div className="container container-small-view">
			<Spinner show={showSpinner} />
			<div className="row">
				<div className="col-12 col-sm-12 col-md-10 col-lg-10">

					<h1>Willkommen im Onlineportal <b>Wirtschaftsprüfungsexamen!</b></h1>
					<p>Sie können hier</p>

					<ul>
						<li>einen <b>Zulassungsantrag</b> stellen oder zurücknehmen, </li>
						{user && user.art && user.art === 'WP' && <li>sich zu einer <b>Modulprüfung</b> an- oder abmelden, unter bestimmten Voraussetzungen eine Modulprüfung verschieben oder Angaben zur Anrechnung anderer Prüfungsleistungen machen,</li>}
						{user && user.art && user.art === 'WP' && <li>einen <b>triftigen Grund</b> mitteilen, wenn Sie an einer Prüfung nicht teilnehmen können,</li>}
						{user && user.art && user.art === 'EIGNUNG' && <li>einen <b>triftigen Grund</b> mitteilen, wenn Sie an der Prüfung nicht teilnehmen können,</li>}
						{user && user.art && user.art === 'WP' && <li>Ihren <b>Rücktritt</b> von einer Modul– oder der Gesamtprüfung erklären.</li>}
						{user && user.art && user.art === 'EIGNUNG' && <li>Ihren <b>Rücktritt</b> von der Prüfung erklären.</li>}
					</ul>
					<p>Unter <b>Meine Daten</b> können Sie Ihre persönlichen Daten pflegen und Ihre Digitale Akte einsehen.</p>

					{user && user.art && user.art === 'WP' &&
						<p>
							Bei Fragen zur Zulassung und zur Prüfung können Sie sich an die
							<a href="https://www.wpk.de/wpk/organisation/haupt-und-landesgeschaeftsstellen/" target="_blank">{" "}Landesgeschäftsstelle der Wirtschaftsprüferkammer</a>{" "}
							wenden, bei der Sie Ihren Zulassungsantrag stellen und die Prüfung ablegen, oder an die Prüfungsstelle
							{" ("}<a href="mailto:pruefungsstelle@wpk.de">pruefungsstelle@wpk.de</a>{")."}
						</p>
					}

					{user && user.art && user.art === 'EIGNUNG' &&
						<p>
							Bei Fragen zur Zulassung und zur Prüfung können Sie sich an die
							<a href="https://www.wpk.de/wpk/organisation/haupt-und-landesgeschaeftsstellen/" target="_blank">{" "}Landesgeschäftsstelle Berlin, Brandenburg,
								<br/>Sachsen und Sachsen-Anhalt der Wirtschaftsprüferkammer</a>{" "}
								wenden oder an die Prüfungsstelle{" ("}<a href="mailto:pruefungsstelle@wpk.de">pruefungsstelle@wpk.de</a>{")."}
						</p>
					}

					<p>
						Ansprechpartner für technische Fragen zum Onlineportal Wirtschaftsprüfungsexamen ist die processware GmbH 
						{" ("}<a href="mailto:support@processware.de">support@processware.de</a>{", "}
						Telefon: <a href="tel:+49 221 299 429 84">+49 221 299 429 84</a>{")."}
					</p>

					<div style={{ clear: 'both' }}></div>

				</div>
				<div className="col-12 col-sm-12 col-md-2 col-lg-2">
					<img src={bmwi} alt="Logo Bundesministerium für Wirtschaft und Energie" style={{"width":"195px"}}/>
				</div>
			</div>
		</div>
	);
};