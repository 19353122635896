import { authHeader } from '../helpers';
import axios from "axios";

export const zulassungService = {
    getZulassung,
    saveZulassung,
    saveTempZulassung,
    checkZulassung,
    getZulassungsblock,
    checkPruefungsblock
};

function checkPruefungsblock() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/zulassung/zulassungsblock`, requestOptions);
}

function getZulassung() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/zulassung`, requestOptions);
}

function checkZulassung() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/zulassung/check`, requestOptions);

}

function saveZulassung(zulassung, lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, stbescheinigungen2, verkuerzungbescheinigungen) {
    const formData = new FormData();

    const requestOptions = {
        headers: { ...authHeader() },
    };

    /*
    if(lebenslauf){
        for(let a = 0; a < lebenslauf.length; a++){
            formData.append("lebenslauf", lebenslauf[a]);
        }
    }else{
        formData.append("lebenslauf", []);
    }

    if(zeugnisse){
        for(let a = 0; a < zeugnisse.length; a++){
            formData.append("zeugnis", zeugnisse[a]);
        }
    }else{
        formData.append("zeugnis", []);
    }

    if(attest){
        for(let a = 0; a < attest.length; a++){
            formData.append("attest", attest[a]);
        }
    }else{
        formData.append("attest", []);
    }

    if(agbescheinigungen){
        for(let a = 0; a < agbescheinigungen.length; a++){
            formData.append("agbescheinigung", agbescheinigungen[a]);
        }
    }else{
        formData.append("agbescheinigung", []);
    }

    if(stbescheinigungen){
        for(let a = 0; a < stbescheinigungen.length; a++){
            formData.append("stbescheinigung", stbescheinigungen[a]);
        }
    }else{
        formData.append("stbescheinigung", []);
    }

    if(agbescheinigungen2){
        for(let a = 0; a < agbescheinigungen2.length; a++){
            formData.append("agbescheinigung2", agbescheinigungen2[a]);
        }
    }else{
        formData.append("agbescheinigung2", []);
    }

    if(stbescheinigungen2){
        for(let a = 0; a < stbescheinigungen2.length; a++){
            formData.append("stbescheinigung2", stbescheinigungen2[a]);
        }
    }else{
        formData.append("stbescheinigung2", []);
    }

    if(verkuerzungbescheinigungen){
        for(let a = 0; a < verkuerzungbescheinigungen.length; a++){
            formData.append("verkuerzungbescheinigung", verkuerzungbescheinigungen[a]);
        }
    }else{
        formData.append("verkuerzungbescheinigung", []);
    }
    */
    
    
    formData.append("zulassung", JSON.stringify(zulassung));

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/zulassung/files`, formData, requestOptions);
}

function saveTempZulassung(zulassung, lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, stbescheinigungen2, verkuerzungbescheinigungen) {
    const formData = new FormData();

    const requestOptions = {
        headers: { ...authHeader() },
    };

    if(lebenslauf){
        for(let a = 0; a < lebenslauf.length; a++){
            formData.append("lebenslauf", lebenslauf[a]);
        }
    }else{
        formData.append("lebenslauf", []);
    }

    if(zeugnisse){
        for(let a = 0; a < zeugnisse.length; a++){
            formData.append("zeugnis", zeugnisse[a]);
        }
    }else{
        formData.append("zeugnis", []);
    }

    if(attest){
        for(let a = 0; a < attest.length; a++){
            formData.append("attest", attest[a]);
        }
    }else{
        formData.append("attest", []);
    }

    if(agbescheinigungen){
        for(let a = 0; a < agbescheinigungen.length; a++){
            formData.append("agbescheinigung", agbescheinigungen[a]);
        }
    }else{
        formData.append("agbescheinigung", []);
    }

    if(stbescheinigungen){
        for(let a = 0; a < stbescheinigungen.length; a++){
            formData.append("stbescheinigung", stbescheinigungen[a]);
        }
    }else{
        formData.append("stbescheinigung", []);
    }

    if(agbescheinigungen2){
        for(let a = 0; a < agbescheinigungen2.length; a++){
            formData.append("agbescheinigung2", agbescheinigungen2[a]);
        }
    }else{
        formData.append("agbescheinigung2", []);
    }

    if(stbescheinigungen2){
        for(let a = 0; a < stbescheinigungen2.length; a++){
            formData.append("stbescheinigung2", stbescheinigungen2[a]);
        }
    }else{
        formData.append("stbescheinigung2", []);
    }

    if(verkuerzungbescheinigungen){
        for(let a = 0; a < verkuerzungbescheinigungen.length; a++){
            formData.append("verkuerzungbescheinigung", verkuerzungbescheinigungen[a]);
        }
    }else{
        formData.append("verkuerzungbescheinigung", []);
    }
    
    formData.append("zulassung", JSON.stringify(zulassung));

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/zulassung/zwischenspeichern`, formData, requestOptions);
}

function getZulassungsblock() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/zulassung/zulassungsblock`, requestOptions);
}