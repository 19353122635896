import React from 'react';

export const EULaenderkuerzel = (props) => {

    return (
        <select
            autoFocus={props.autoFokus}
            name={props.name}
            autoComplete={props.autoComplete}
            value={props.value}
            onChange={props.onChange}
        >
            <option value="DE" selected>DE - Deutschland</option>

            <option value='AFG' >Afghanistan</option>

            <option value='AUO' >Australisch Ozeanien</option>

            <option value='AL' >Albanien</option>

            <option value='DZ' >Algerien</option>

            <option value='AJI' >Amerik.Jungfern-Inseln</option>

            <option value='AMO' >Amerikanisch Ozeanien</option>

            <option value='AND' >ANDORRA</option>

            <option value='ANG' >Angola</option>

            <option value='RA' >Argentinien</option>

            <option value='AUS' >Australien</option>

            <option value='ET' >Ägypten</option>

            <option value='AEG' >Äquatorial-Guinea</option>

            <option value='ETH' >Äthiopien</option>

            <option value='BS' >Bahamas</option>

            <option value='BRN' >Bahrain</option>

            <option value='BAN' >Bangladesch</option>

            <option value='BDS' >Barbados</option>

            <option value='B' >Belgien</option>

            <option value='BEL' >Belize</option>

            <option value='DY' >Benin Dahome</option>

            <option value='BER' >Bermuda</option>

            <option value='BHU' >Bhutan</option>

            <option value='BUR' >Birma</option>

            <option value='BOL' >Bolivien</option>

            <option value='RB' >Botsuana</option>

            <option value='BR' >Brasilien</option>

            <option value='BIO' >Brit. Gebiete Ind. Ozean</option>

            <option value='BRO' >Britisch Ozeanien</option>

            <option value='BRU' >Brunei</option>

            <option value='BG' >Bulgarien</option>

            <option value='RU' >Burundi</option>

            <option value='CEU' >Ceuta+Maltlla</option>

            <option value='RCH' >Chile</option>

            <option value='CR' >Costa Rica</option>

            <option value='DK' >Dänemark</option>

            <option value='DE' >Deutschland</option>

            <option value='DOM' >Dominikanische Republik</option>

            <option value='EC' >Ecuador</option>

            <option value='ELS' >El Salvador</option>

            <option value='CI' >Elfenbeinküste</option>

            <option value='FAL' >Falklandinseln + Gebiete</option>

            <option value='FAR' >Färöer</option>

            <option value='FJI' >Fidschi</option>

            <option value='SF' >Finnland</option>

            <option value='F' >Frankreich</option>

            <option value='FAI' >Französ. Afar- + Issa-Geb.</option>

            <option value='FGU' >Französ. Guayana</option>

            <option value='FPO' >Französisch-Polynesien</option>

            <option value='GAB' >Gabun</option>

            <option value='WAG' >Gambia</option>

            <option value='GRO' >Grönland</option>

            <option value='GUA' >Guadeloupe</option>

            <option value='GH' >Ghana</option>

            <option value='GBZ' >Gibraltar</option>

            <option value='WG' >Grenada</option>

            <option value='GR' >Griechenland</option>

            <option value='GB1' >Großbr. Insel Alderney</option>

            <option value='GB2' >Großbr. Insel Guernsey</option>

            <option value='GB3' >Großbr. Insel Jersey</option>

            <option value='GB4' >Großbr. Insel Man</option>

            <option value='GB5' >Großbritannien + Nordirland</option>

            <option value='GCA' >Guatemala</option>

            <option value='GUB' >Guinea-Bissau</option>

            <option value='HK' >Hong-Kong</option>

            <option value='IND' >Indien + Sikkim</option>

            <option value='RI' >Indonesien</option>

            <option value='IRQ' >Irak</option>

            <option value='IR' >Iran</option>

            <option value='IS' >Island</option>

            <option value='IL' >Israel</option>

            <option value='I' >Italien</option>

            <option value='JA' >Jamaika</option>

            <option value='J' >Japan</option>

            <option value='JOR' >JJordanien</option>

            <option value='YU' >Jugoslawien</option>

            <option value='KAI' >Kaiman-Inseln</option>

            <option value='K' >Kambodscha</option>

            <option value='WAC' >Kamerun</option>

            <option value='CDN' >Kanada</option>

            <option value='KAN' >Kanarische Inseln</option>

            <option value='KAT' >Katar</option>

            <option value='EAK' >Kenia</option>

            <option value='CO' >Kolumbien</option>

            <option value='KOM' >Komoren</option>

            <option value='RCB' >Kongo Brazzaville</option>

            <option value='HR' >Kroatien</option>

            <option value='C' >Kuba</option>

            <option value='KWT' >Kuwait</option>

            <option value='LAO' >Laos</option>

            <option value='LS' >Lesotho</option>

            <option value='LIB' >Libanon</option>

            <option value='LB' >Liberia</option>

            <option value='LAR' >Libyen</option>

            <option value='FL' >Liechtenstein</option>

            <option value='L' >Luxemburg</option>

            <option value='MAC' >Macau</option>

            <option value='RM' >Madagaskar</option>

            <option value='MW' >Malawi</option>

            <option value='MAL' >Malaysia</option>

            <option value='MAV' >Malediven</option>

            <option value='RMM' >Mali</option>

            <option value='M' >Malta</option>

            <option value='MA' >Marokko</option>

            <option value='MAR' >Martinique</option>

            <option value='RIM' >Mauretanien</option>

            <option value='MS' >Mauritius</option>

            <option value='MAY' >Mayotte</option>

            <option value='MEX' >Mexiko</option>

            <option value='MC' >Monaco</option>

            <option value='MOV' >Mongolische Volksrepubl</option>

            <option value='MOS' >Mosambik</option>

            <option value='NA' >Namibia</option>

            <option value='NAU' >Nauru</option>

            <option value='NEP' >Nepal</option>

            <option value='HEB' >Neue Hebriden</option>

            <option value='NEK' >Neukaledonien + Gebiete</option>

            <option value='NZ' >Neuseeland</option>

            <option value='NEO' >Neuseeländisch Ozeanien</option>

            <option value='NIC' >Nicaragua</option>

            <option value='NA2' >Niederl. Antillen</option>

            <option value='NL' >Niederlande</option>

            <option value='NIG' >Niger</option>

            <option value='WAN' >Nigeria</option>

            <option value='NJE' >Nordjemen</option>

            <option value='NOK' >Nordkorea</option>

            <option value='N' >Norwegen/Spitzbergen</option>

            <option value='OV' >Obervolta</option>

            <option value='OMA' >Oman</option>

            <option value='A' >Österreich</option>

            <option value='PAK' >Pakistan</option>

            <option value='PA' >Panama</option>

            <option value='PA2' >Panama-Kanal-Zone</option>

            <option value='PAP' >Papua-Neuguinea</option>

            <option value='PY' >Paraguay</option>

            <option value='PE' >Peru</option>

            <option value='PP' >Philippinen</option>

            <option value='POL' >Polargebiete</option>

            <option value='PL' >Polen</option>

            <option value='P' >Portugal + Azoren + Madeira</option>

            <option value='GUI' >Republik Guinea</option>

            <option value='RGU' >Republik Guyana</option>

            <option value='RH' >Republik Haiti</option>

            <option value='HON' >Republik Honduras</option>

            <option value='KAV' >Republik Kap Verde</option>

            <option value='ZA' >Republik Südafrika</option>

            <option value='IRL' >Repuplik Irland</option>

            <option value='REU' >Reunion</option>

            <option value='RSR' >Rhodesien</option>

            <option value='RWA' >Ruanda</option>

            <option value='R' >Rumänien</option>

            <option value='URS' >Russland</option>

            <option value='SAT' >Sad Tome + Principe</option>

            <option value='Z' >Sambia</option>

            <option value='RSM' >San Marino</option>

            <option value='SAR' >Saudi-Arabien</option>

            <option value='S' >Schweden</option>

            <option value='CH' >Schweiz</option>

            <option value='SN' >Senegal</option>

            <option value='SY' >Seychellen + Gebiete</option>

            <option value='WA1' >Sierra Leone</option>

            <option value='SGP' >Singapur</option>

            <option value='SK' >Slowakei</option>

            <option value='SP' >Somalia</option>

            <option value='SU' >Sowjetunion</option>

            <option value='E' >Spanien</option>

            <option value='CL' >Sri Lanka</option>

            <option value='HEL' >St.Helena + Gebiete</option>

            <option value='SPM' >St.Pierre + Miquelon</option>

            <option value='SUD' >Sudan</option>

            <option value='SME' >Surinam</option>

            <option value='ADN' >Südjemen</option>

            <option value='ROK' >Südkorea</option>

            <option value='SD' >Swasiland</option>

            <option value='SYR' >Syrien</option>

            <option value='RC' >Taiwan</option>

            <option value='EAT' >Tansania</option>

            <option value='T' >Thailand</option>

            <option value='TG' >Togo</option>

            <option value='TON' >Tonga</option>

            <option value='TT' >Trinidad und Tobago</option>

            <option value='TSA' >Tschad</option>

            <option value='CZ' >Tschechien</option>

            <option value='CS' >Tschechische Republik</option>

            <option value='TN' >Tunesien</option>

            <option value='TUC' >Turks- + Caicosinseln</option>

            <option value='TR' >Türkei</option>

            <option value='EAU' >Uganda</option>

            <option value='?' >Ukraine</option>

            <option value='H' >Ungarn</option>

            <option value='U' >Uruguay</option>

            <option value='USA' >USA</option>

            <option value='V' >Vatikanstadt</option>

            <option value='YV' >Venezuela</option>

            <option value='VAE' >Vereinigte Arab. Emirate</option>

            <option value='VN' >Vietnam</option>

            <option value='VRC' >Volksrepublik China</option>

            <option value='WA2' >Wallis und Futuna</option>

            <option value='WEI' >Westindien</option>

            <option value='WS' >Westsamoa</option>

            <option value='CGO' >Zaire</option>

            <option value='RCA' >Zentralafrik. Republik</option>

            <option value='CY' >Zypern</option>
        </select>
    );
}

export const Laenderkuerzel = (props) => {

    return(
        <select
            autoFocus={props.autoFokus}
            name={props.name}
            autoComplete={props.autoComplete}
            value={props.value}
            onChange={props.onChange}
        >
            <option value="DE" defaultValue>Deutschland</option>
            <option value="AF">Afghanistan</option>
            <option value="EG">Ägypten</option>
            <option value="AX">Ålandinseln</option>
            <option value="AL">Albanien</option>
            <option value="DZ">Algerien</option>
            <option value="AS">Amerikanisch Samoa</option>
            <option value="AD">Andorra</option>
            <option value="AO">Angola</option>
            <option value="AI">Anguilla</option>
            <option value="AQ">Antarktis</option>
            <option value="AG">Antigua und Barbuda</option>
            <option value="GQ">Äquatorialguinea</option>
            <option value="AR">Argentinien</option>
            <option value="AM">Armenien</option>
            <option value="AW">Aruba</option>
            <option value="AZ">Aserbaidschan</option>
            <option value="ET">Äthiopien</option>
            <option value="AU">Australien</option>
            <option value="BS">Bahamas</option>
            <option value="BH">Bahrain</option>
            <option value="BD">Bangladesch</option>
            <option value="BB">Barbados</option>
            <option value="BY">Belarus</option>
            <option value="BE">Belgien</option>
            <option value="BZ">Belize</option>
            <option value="BJ">Benin</option>
            <option value="BM">Bermuda</option>
            <option value="BT">Bhutan</option>
            <option value="BO">Bolivien</option>
            <option value="BA">Bosnien und Herzegowina</option>
            <option value="BW">Botswana</option>
            <option value="BV">Bouvetinsel</option>
            <option value="BR">Brasilien</option>
            <option value="IO">Britisches Territorium im Indischen Ozean</option>
            <option value="BN">Brunei</option>
            <option value="BG">Bulgarien</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CK">Cookinseln</option>
            <option value="CR">Costa Rica</option>
            <option value="CW">Curaçao</option>
            <option value="DK">Dänemark</option>
            <option value="CD">Demokratische Republik Kongo</option>
            <option value="DE">Deutschland</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominikanische Republik</option>
            <option value="DJ">Dschibuti</option>
            <option value="EC">Ecuador</option>
            <option value="SV">El Salvador</option>
            <option value="CI">Elfenbeinküste</option>
            <option value="ER">Eritrea</option>
            <option value="EE">Estland</option>
            <option value="SZ">Eswatini</option>
            <option value="FK">Falklandinseln</option>
            <option value="FO">Färöer-Inseln</option>
            <option value="FJ">Fidschi</option>
            <option value="FI">Finnland</option>
            <option value="FM">Föderierte Staaten von Mikronesien</option>
            <option value="FR">Frankreich</option>
            <option value="GF">Französisch-Guayana</option>
            <option value="PF">Französisch-Polynesien</option>
            <option value="TF">Französische Süd- und Antarktisgebiete</option>
            <option value="MC">Fürstentum Monaco</option>
            <option value="GA">Gabun</option>
            <option value="GM">Gambia</option>
            <option value="GE">Georgien</option>
            <option value="GH">Ghana</option>
            <option value="GI">Gibraltar</option>
            <option value="GD">Grenada</option>
            <option value="GR">Griechenland</option>
            <option value="GL">Grönland</option>
            <option value="GP">Guadeloupe</option>
            <option value="GU">Guam</option>
            <option value="GT">Guatemala</option>
            <option value="GG">Guernsey</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="GY">Guyana</option>
            <option value="HT">Haiti</option>
            <option value="HM">Heard und McDonald Inseln</option>
            <option value="HN">Honduras</option>
            <option value="HK">Hongkong</option>
            <option value="IN">Indien</option>
            <option value="ID">Indonesien</option>
            <option value="IM">Insel Man</option>
            <option value="IQ">Irak</option>
            <option value="IR">Iran</option>
            <option value="IE">Irland</option>
            <option value="IS">Island</option>
            <option value="IL">Israel</option>
            <option value="IT">Italien</option>
            <option value="JM">Jamaika</option>
            <option value="JP">Japan</option>
            <option value="YE">Jemen</option>
            <option value="JE">Jersey</option>
            <option value="JO">Jordanien</option>
            <option value="VG">Jungferninseln (UK)</option>
            <option value="VI">Jungferninseln (US)</option>
            <option value="KY">Kaimaninseln</option>
            <option value="KH">Kambodscha</option>
            <option value="CM">Kamerun</option>
            <option value="CA">Kanada</option>
            <option value="CV">Kap Verde</option>
            <option value="BQ">Karibische Niederlande</option>
            <option value="KZ">Kasachstan</option>
            <option value="QA">Katar</option>
            <option value="KE">Kenia</option>
            <option value="KG">Kirgisistan</option>
            <option value="KI">Kiribati</option>
            <option value="UM">Kleinere abgelegene Inseln der Vereinigten Staaten</option>
            <option value="CC">Kokosinseln</option>
            <option value="CO">Kolumbien</option>
            <option value="KM">Komoren</option>
            <option value="XK">Kosovo</option>
            <option value="HR">Kroatien</option>
            <option value="CU">Kuba</option>
            <option value="KW">Kuwait</option>
            <option value="LA">Laos</option>
            <option value="LS">Lesotho</option>
            <option value="LV">Lettland</option>
            <option value="LB">Libanon</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libyen</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Litauen</option>
            <option value="LU">Luxemburg</option>
            <option value="MO">Macau</option>
            <option value="MG">Madagaskar</option>
            <option value="MW">Malawi</option>
            <option value="MY">Malaysia</option>
            <option value="MV">Malediven</option>
            <option value="ML">Mali</option>
            <option value="MT">Malta</option>
            <option value="MA">Marokko</option>
            <option value="MH">Marshallinseln</option>
            <option value="MQ">Martinique</option>
            <option value="MR">Mauretanien</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MX">Mexiko</option>
            <option value="MD">Moldawien</option>
            <option value="MN">Mongolei</option>
            <option value="ME">Montenegro</option>
            <option value="MS">Montserrat</option>
            <option value="MZ">Mosambik</option>
            <option value="MM">Myanmar</option>
            <option value="NA">Namibia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NC">Neukaledonien</option>
            <option value="NZ">Neuseeland</option>
            <option value="NI">Nicaragua</option>
            <option value="NL">Niederlande</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="NU">Niue</option>
            <option value="KP">Nordkorea</option>
            <option value="MP">Nördliche Marianen</option>
            <option value="MK">Nordmazedonien</option>
            <option value="NF">Norfolkinsel</option>
            <option value="NO">Norwegen</option>
            <option value="OM">Oman</option>
            <option value="AT">Österreich</option>
            <option value="TL">Osttimor</option>
            <option value="PK">Pakistan</option>
            <option value="PS">Palästina</option>
            <option value="PW">Palau</option>
            <option value="PA">Panama</option>
            <option value="PG">Papua-Neuguinea</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Peru</option>
            <option value="PH">Philippinen</option>
            <option value="PN">Pitcairninseln</option>
            <option value="PL">Polen</option>
            <option value="PT">Portugal</option>
            <option value="PR">Puerto Rico</option>
            <option value="CG">Republik Kongo</option>
            <option value="RE">Réunion</option>
            <option value="RW">Ruanda</option>
            <option value="RO">Rumänien</option>
            <option value="RU">Russland</option>
            <option value="MF">Saint Martin</option>
            <option value="SB">Salomonen</option>
            <option value="ZM">Sambia</option>
            <option value="WS">Samoa</option>
            <option value="SM">San Marino</option>
            <option value="BL">Sankt Bartholomäus</option>
            <option value="ST">São Tomé und Príncipe</option>
            <option value="SA">Saudi-Arabien</option>
            <option value="SE">Schweden</option>
            <option value="CH">Schweiz</option>
            <option value="SN">Senegal</option>
            <option value="RS">Serbien</option>
            <option value="SC">Seychellen</option>
            <option value="SL">Sierra Leone</option>
            <option value="ZW">Simbabwe</option>
            <option value="SG">Singapur</option>
            <option value="SX">Sint Maarten</option>
            <option value="SK">Slowakei</option>
            <option value="SI">Slowenien</option>
            <option value="SO">Somalia</option>
            <option value="ES">Spanien</option>
            <option value="LK">Sri Lanka</option>
            <option value=" Ascension und Tristan da Cunha">St. Helena</option>
            <option value="KN">St. Kitts und Nevis</option>
            <option value="LC">St. Lucia</option>
            <option value="PM">St. Pierre und Miquelon</option>
            <option value="VC">St. Vincent und die Grenadinen</option>
            <option value="ZA">Südafrika</option>
            <option value="SD">Sudan</option>
            <option value="GS">Südgeorgien und die Südlichen Sandwichinseln</option>
            <option value="KR">Südkorea</option>
            <option value="SS">Südsudan</option>
            <option value="SR">Suriname</option>
            <option value="SJ">Svalbard und Jan Mayen</option>
            <option value="SY">Syrien</option>
            <option value="TJ">Tadschikistan</option>
            <option value="TW">Taiwan</option>
            <option value="TZ">Tansania</option>
            <option value="TH">Thailand</option>
            <option value="TG">Togo</option>
            <option value="TK">Tokelau</option>
            <option value="TO">Tonga</option>
            <option value="TT">Trinidad und Tobago</option>
            <option value="TD">Tschad</option>
            <option value="CZ">Tschechien</option>
            <option value="TN">Tunesien</option>
            <option value="TR">Türkei</option>
            <option value="TM">Turkmenistan</option>
            <option value="TC">Turks und Caicosinseln</option>
            <option value="TV">Tuvalu</option>
            <option value="UG">Uganda</option>
            <option value="UA">Ukraine</option>
            <option value="HU">Ungarn</option>
            <option value="UY">Uruguay</option>
            <option value="UZ">Usbekistan</option>
            <option value="VU">Vanuatu</option>
            <option value="VA">Vatikanstadt</option>
            <option value="VE">Venezuela</option>
            <option value="AE">Vereinigte Arabische Emirate</option>
            <option value="US">Vereinigte Staaten von Amerika</option>
            <option value="GB">Vereinigtes Königreich</option>
            <option value="VN">Vietnam</option>
            <option value="WF">Wallis und Futuna</option>
            <option value="CX">Weihnachtsinsel</option>
            <option value="EH">Westsahara</option>
            <option value="CF">Zentralafrikanische Republik</option>
            <option value="CY">Zypern</option>
        </select>
    );
}