import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, zulassungActions, rightMenuActions } from '../../actions';
import { eventConstants, rightmenuConstants, modulConstants } from '../../constants';
import { util, modulService } from '../../services';
import { Spinner } from "../spinner/Spinner";
import { ModulNochNichtAnwendbar, RuecktrittText } from '../HinweisTexte';
import { WPSModulGeladenAmClass } from '../../models/modul';


const ModulpruefungRuecknahme = ({ modul, modulBefore, schonGeladen, handleChange, handleSubmit }) => {

    const WP = () => {
        if (!modul || !modul.modulgeladenAm || !modulBefore) return (<div />);
        else {
            return (
                <>
                    <div className="row">
                        <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input type="checkbox" name="modulgeladenAm.wp" style={{ marginRight: '15px' }} value={modul.modulgeladenAm.wp} checked={modul.modulgeladenAm.wp && true} disabled={!(schonGeladen.wp && true)} onChange={e => handleChange(e)} /> {schonGeladen.wp ? "Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht, geladen am " + util.changeDateDEFormat(schonGeladen.wp) : "Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht"}
                        </div>
                    </div>
                </>
            );
        }
    }

    const BWL = () => {
        if (!modul || !modul.modulgeladenAm || !modulBefore) return (<div />);
        else {
            return (
                <>
                    <div className="row">
                        <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input type="checkbox" name="modulgeladenAm.bwl" style={{ marginRight: '15px' }} value={modul.modulgeladenAm.bwl} checked={modul.modulgeladenAm.bwl && true} disabled={!(schonGeladen.bwl && true)} onChange={e => handleChange(e)} /> {schonGeladen.bwl ? "Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre, geladen am " + util.changeDateDEFormat(schonGeladen.bwl) : "Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre"}
                        </div>
                    </div>
                </>
            );
        }
    }

    const WR = () => {
        if (!modul || !modul.modulgeladenAm || !modulBefore) return (<div />);
        else {
            return (
                <>
                    <div className="row">
                        <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <input type="checkbox" name="modulgeladenAm.wr" style={{ marginRight: '15px' }} value={modul.modulgeladenAm.wr} checked={modul.modulgeladenAm.wr && true} disabled={!(schonGeladen.wr && true)} onChange={e => handleChange(e)} /> {schonGeladen.wr ? "Wirtschaftsrecht, geladen am " + util.changeDateDEFormat(schonGeladen.wr) : "Wirtschaftsrecht"}
                        </div>
                    </div>
                </>
            );
        }
    }

    const ST = () => {
        if (!modul || !modul.modulgeladenAm || !modulBefore) return (<div />);
        else {
            return (
                <>
                    <div className="row">
                        <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <input type="checkbox" name="modulgeladenAm.st" style={{ marginRight: '15px' }} value={modul.modulgeladenAm.st} checked={modul.modulgeladenAm.st && true} disabled={!(schonGeladen.st && true)} onChange={e => handleChange(e)} /> {schonGeladen.st ? "Steuerrecht, geladen am " + util.changeDateDEFormat(schonGeladen.st) : "Steuerrecht"}
                        </div>
                    </div>
                </>
            );
        }
    }

    if (!modulBefore) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

                    <h2 style={{ paddingTop: '25px' }}>Modulauswahl</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich trete hiermit von der/den Modulprüfung(en)</span>

                            {<WP />}
                            {<BWL />}
                            {<WR />}
                            {<ST />}

                            <span className="formLabel">verbindlich zurück.</span>
                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const Zusammenfassung = ({ schonGeladen, aktuelSemester, modul, modulBefore, handleBack, showSpinner, setShowSpinner }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const d = useSelector((state) => state.modulList.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.modulList.error);
    const [error, setError] = useState(e);


    const ZWP = () => {
        if (!modulBefore) return (<div />);
        else {
            return (
                <li>
                    <p>Wirtschaftliches Prüfungswesen, geladen am {util.changeDateDEFormat(schonGeladen.wp)}</p>
                </li>
            );
        }
    }

    const ZBWL = () => {
        if (!modulBefore) return (<div />);
        else {
            return (
                <li>
                    <p>Angewandte Betriebswirtschaftslehre, geladen am {util.changeDateDEFormat(schonGeladen.bwl)}</p>
                </li>
            );
        }
    }

    const ZWR = () => {
        if (!modulBefore) return (<div />);
        else {
            return (
                <li>
                    <p>Wirtschaftsrecht, geladen am {util.changeDateDEFormat(schonGeladen.wr)}</p>
                </li>
            );
        }
    }

    const ZST = () => {
        if (!modulBefore) return (<div />);
        else {
            return (
                <li>
                    <p>Steuerrecht, geladen am {util.changeDateDEFormat(schonGeladen.st)}</p>
                </li>
            );
        }
    }

    useEffect(() => {
        if (done) {
            if (error) {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_FAILURE_DONE });
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.error("Der Rücktritt wurde nicht erfolgreich abgesendet.");
            } else {
                setShowSpinner(false);
                dispatch({ type: modulConstants.GET_DONE });
                dispatch(modulActions.getModulList());
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.success("Der Rücktritt wurde erfolgreich abgesendet.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleSubmit = (e) => {
        e.preventDefault();
        var mpr = new WPSModulGeladenAmClass();
        mpr.art = eventConstants.MODULPRUEFUNG_RUECKTRITT;
        mpr.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
        mpr.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
        mpr.wpGeladenAm = schonGeladen.wp;
        mpr.wrGeladenAm = schonGeladen.wr;
        mpr.bwlGeladenAm = schonGeladen.bwl;
        mpr.stGeladenAm = schonGeladen.st;
        mpr.wp = modul.modulgeladenAm.wp ? true : false;
        mpr.wr = modul.modulgeladenAm.wr ? true : false;
        mpr.bwl = modul.modulgeladenAm.bwl ? true : false;
        mpr.st = modul.modulgeladenAm.st ? true : false;
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return false
            else return value;
        }
        var modulPruefungRuecktrittInfos = JSON.parse(JSON.stringify(mpr, replacer));

        setShowSpinner(true);
        dispatch(modulActions.saveEventInfos(modul, modulBefore, modulPruefungRuecktrittInfos));
        // toast.success("Ihr Zulassungsantrag wurde abgeschickt");
        // history.push("/examensportal/start");

    };

    if (!modul || !modul.modulgeladenAm || !modulBefore) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
                    <Spinner show={showSpinner} />
                    <h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich trete hiermit von der/die Modulprüfung(en) : </span>
                            <ul>
                                {modul.modulgeladenAm.wp && <ZWP />}
                                {modul.modulgeladenAm.bwl && <ZBWL />}
                                {modul.modulgeladenAm.wr && <ZWR />}
                                {modul.modulgeladenAm.st && <ZST />}
                            </ul>
                            <span className="formLabel">verbindlich zurück.</span>
                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
                        <input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const ZusammenfassungText = () => {

    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Ihre Erklärung zum Rücktritt von einer Modulprüfung wird hier zusammengefasst.
                Bitte prüfen Sie Ihre Angaben, bevor Sie Ihren Rücktritt von einer Modulprüfung verbindlich absenden.
            </p>
            <p>
                Ihr Rücktritt wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.
            </p>
        </div>
    );
}



const MenuRight = ({ tab, handleSubmit }) => {
    const dispatch = useDispatch();
    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
            <p className="menu_right_header">Rücktritt von einer Modulprüfung</p>
            <ul id="menu_right">
                <li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>Auswahl Modul(e)</a>
                </li>
                <li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={(e) => { handleSubmit(e) ? dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG)) : dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)) }} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
                </li>
            </ul>
            <br /><br />
        </div>
    );
}

export const ModulpruefungRuecktritt = () => {
    const dispatch = useDispatch();
    const tab = useSelector(state => state.rightMenu);
    const ml = useSelector(state => state.modulList.modulList);
    const [modul, setModul] = useState(ml);
    const mlb = useSelector(state => state.modulList.modulListBefore);
    const [modulBefore, setModulBefore] = useState(mlb)
    const modulLoading = useSelector((state) => state.modulList.loading);
    const zulassungLoading = useSelector((state) => state.zulassung.loading);
    const [showSpinner, setShowSpinner] = useState(true);

    const z = useSelector(state => state.zulassung.zulassung);
    const [zulassung, setZulassung] = useState(z);
    const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
    const [zulassungsblock, setZulassungsblock] = useState(zb);

    var schonGeladen; var aktuelSemester;
    if (modul && modulBefore) {
        schonGeladen = modulBefore.modulgeladenAm;
        aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);
    }

    useEffect(() => {
        if (!modulLoading && !zulassungLoading) {
            //console.log("Modul geladen")
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [modulLoading, zulassungLoading]);

    useEffect(() => {
        setZulassung(z);
    }, [z]);

    useEffect(() => {
        setModul(ml);
    }, [ml])

    useEffect(() => {
        setModulBefore(mlb);
    }, [mlb])

    useEffect(() => {
        setZulassungsblock(zb);
    }, [zb]);

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("modulgeladenAm") > -1) {
            setModul({
                ...modul,
                modulgeladenAm: {
                    ...modul.modulgeladenAm,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setModul({ ...modul, [e.target.name]: v });
        }
    };

    const handleBack = (e) => {
        e.preventDefault();
        //dispatch(modulActions.saveTempModul(modul));
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!schonGeladen.wp &&
            !schonGeladen.wr &&
            !schonGeladen.bwl &&
            !schonGeladen.st) {
            toast.error("Es gibt zur Zeit keine Modulprüfung, von der Sie zurücktreten können.")
            return false;
        }

        if (!(modul.modulgeladenAm.wp && true) &&
            !(modul.modulgeladenAm.wr && true) &&
            !(modul.modulgeladenAm.bwl && true) &&
            !(modul.modulgeladenAm.st && true)
        ) {
            toast.error("Sie haben keine Modulprüfung ausgewählt.")
            return false;
        }

        if ((modul.modulgeladenAm.wp && true) ||
            (modul.modulgeladenAm.wr && true) ||
            (modul.modulgeladenAm.bwl && true) ||
            (modul.modulgeladenAm.st && true)
        ) {
            toast.info("Die Daten wurden zwischengespeichert.");
            dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
            return true;
        }
    };

    if (!modul)
        return (
            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <RuecktrittText />

                <ModulNochNichtAnwendbar />
            </div>
        );
    else {
        if (modulBefore && modulBefore.zwischegespeichert === 2) {
            return (
                <>
                    <form>
                        <Spinner show={showSpinner} />
                        <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            {tab === 1 && <RuecktrittText />}
                            {tab === 2 && <ZusammenfassungText />}

                            {tab === 1 && <ModulpruefungRuecknahme
                                schonGeladen={schonGeladen}
                                modul={modul}
                                modulBefore={modulBefore}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                            />}
                            {tab === 2 && <Zusammenfassung
                                aktuelSemester={aktuelSemester}
                                schonGeladen={schonGeladen}
                                showSpinner={showSpinner}
                                setShowSpinner={setShowSpinner}
                                modul={modul}
                                modulBefore={modulBefore}
                                handleBack={handleBack}
                            />}
                        </div>
                    </form>
                    {tab === 1 && (<MenuRight
                        tab={tab}
                        handleSubmit={handleSubmit}
                    />)}
                    {tab === 2 && (<MenuRight
                        tab={tab}
                        handleSubmit={handleSubmit}
                    />)}
                </>
            );
        } else {
            return (
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <RuecktrittText />

                    <ModulNochNichtAnwendbar />
                </div>
            );
        }

    }
}