import React from 'react';
import {Router, Route} from 'react-router-dom';
import RegisterSuccess from './components/RegisterSuccess';
import { PrivateRoute } from './components/PrivateRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { history } from './helpers';
import { connect } from 'react-redux';
import { alertActions } from './actions';
import { 
        PasswortZurueckgesetzt, 
        Meinedaten, 
        Kontaktdaten, 
        Start, 
        Menu, 
        Login, 
        Register, 
        PasswortVergessen,
        Zulassung,
        Ruecktritt,
        TriftigerGrund,
        Modulpruefung,
        ConfirmEmail,
        ConfirmPassword,
        Zugangsdaten, 
        Dokumente
    } from './components';
import Bestaetigung from './components/registrierung/Bestaetigung';
import { ZulassungEignung } from './components/ZulassungEignung';
import { TriftigerGrundEP } from './components/TriftigerGrundEP';
import { KontaktdatenEP } from './components/KontaktdatenEP';

const App = (props) => {

    const { dispatch } = props;
    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });

    return(
        <div>
            <Router history={history} basename="/examensportal">
                <Menu />
                <Route path="/examensportal" exact component={Login} />
                <Route path="/examensportal/login" exact component={Login} />
                <Route path="/examensportal/registrierung" exact component={Register} />
                <Route path="/examensportal/registriert" exact component={RegisterSuccess} />
                <Route path="/examensportal/confirmemail/:token" exact component={ConfirmEmail} />
                <Route path="/examensportal/confirmresetpassword/:token" exact component={ConfirmPassword} />
                <Route path="/examensportal/passwortvergessen" exact component={PasswortVergessen} />
                <Route path="/examensportal/passwortzurueckgesetzt" exact component={PasswortZurueckgesetzt} />
                <Route path="/examensportal/registrierungbestaetigen/" exact component={Bestaetigung} />
                
                <PrivateRoute path="/examensportal/zulassung" exact component={Zulassung} />
                <PrivateRoute path="/examensportal/zulassungeignung" exact component={ZulassungEignung} />
                <PrivateRoute path="/examensportal/modulpruefung" exact component={Modulpruefung} />
                <PrivateRoute path="/examensportal/ruecktritt" exact component={Ruecktritt} />
                <PrivateRoute path="/examensportal/triftigergrund" exact component={TriftigerGrund} />
                <PrivateRoute path="/examensportal/triftigergrundeignung" exact component={TriftigerGrundEP} />
                <PrivateRoute path="/examensportal/meinedaten" exact component={Meinedaten} />
                <PrivateRoute path="/examensportal/kontaktdaten" exact component={Kontaktdaten} />
                <PrivateRoute path="/examensportal/kontaktdateneignung" exact component={KontaktdatenEP} />
                <PrivateRoute path="/examensportal/passwort" exact component={Zugangsdaten} />
                <PrivateRoute path="/examensportal/dokumente" exact component={Dokumente} />
                <PrivateRoute path="/examensportal/einstellungen" exact component={Meinedaten} />
                
                <PrivateRoute exact path="/examensportal/start" component={Start} />
            </Router>
            <ToastContainer hideProgressBar={true}/>
        </div>
    );
}

const connectedApp = connect(null)(App);
export { connectedApp as App };