import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { modulActions, zulassungActions, rightMenuActions, modulEPActions } from '../../../actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { eventConstants, rightmenuConstants, modulConstants, modulEPConstants } from '../../../constants';
import { util, modulService } from '../../../services';
import { Spinner } from "../../spinner/Spinner";
import { ModulNochNichtAnwendbar, GrundText, GrundEignungText } from '../../HinweisTexte';
import FileUpload from '../../fileupload/fileupload';
import FileUploadList from '../../fileupload/fileuploadlist';
import { WPSModulEPVerhindertClass, WPSModulVerhindertClass } from '../../../models/modul';


const GrundEignung = ({
    grund,
    setGrund,
    zeugnis,
    setZeugnis,
    aktuelSemester,
    semester,
    modulEP,
    setModulEP,
    schonVerhindert,
    modulLoading,
    showSpinner,
    setShowSpinner,
    handleSubmit
}) => {

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("modulverhindert") > -1) {
            setModulEP({
                ...modulEP,
                modulverhindert: {
                    ...modulEP.modulverhindert,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setModulEP({ ...modulEP, [e.target.name]: v });
        }
    };

    if (!modulEP || !modulEP.modulverhindert || !schonVerhindert) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

                    <h2 style={{ paddingTop: '25px' }}>Abwesenheitsgrund</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich kann an der</span>
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.awr" style={{ marginRight: '15px' }} value={modulEP.modulverhindert.awr} checked={modulEP.modulverhindert.awr} onChange={e => handleChange(e)} disabled={!schonVerhindert.awr} /> Aufsichtsarbeit Wirtschaftsrecht
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input type="checkbox" name="modulverhindert.ast" style={{ marginRight: '15px' }} value={modulEP.modulverhindert.ast} checked={modulEP.modulverhindert.ast} onChange={e => handleChange(e)} disabled={!schonVerhindert.ast} /> Aufsichtsarbeit Steuerrecht I
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <input type="checkbox" name="modulverhindert.mp" style={{ marginRight: '15px' }} value={modulEP.modulverhindert.mp} checked={modulEP.modulverhindert.mp} onChange={e => handleChange(e)} disabled={!schonVerhindert.mp} /> mündlichen Prüfung
                                </div>
                            </div>
                            <span className="formLabel">nicht teilnehmen.</span>

                            <div className="row">
                                <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Ich bin erkrankt. Ein amtsärztliches Zeugnis füge ich bei.</span>
                                    <FileUpload files={zeugnis} setFiles={setZeugnis} />
                                </div>
                            </div>
                            <FileUploadList files={zeugnis} setFiles={setZeugnis} label={'Zeugnis'} />
                            

                            <div className="row">
                                <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Hierfür gibt es einen sonstigen Grund. Eine Begründung und ggf. Nachweise füge ich bei.</span>
                                    <FileUpload files={grund} setFiles={setGrund} />
                                </div>
                            </div>
                            <FileUploadList files={grund} setFiles={setGrund} label={'Begründung / ggf. Nachweis'} />
                                                 

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                            </div>

                            <br />


                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const Zusammenfassung = ({
    aktuelSemester,
    semester,
    modulEP,
    modulEPBefore,
    schonVerhindert,
    grund,
    zeugnis,
    showSpinner,
    setShowSpinner,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const d = useSelector((state) => state.modulEPList.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.modulEPList.error);
    const [error, setError] = useState(e);

    const AWR = () => {
        return (
            <li>
                <p>Aufsichtsarbeit Wirtschaftsrecht</p>
            </li>
        );
    }

    const AST = () => {
        return (
            <li>
                <p>Aufsichtsarbeit Steuerrecht I</p>
            </li>
        );
    }

    const MP = () => {
        return (
            <li>
                <p>mündlichen Prüfung</p>
            </li>
        );
    }

    useEffect(() => {
        if (done) {
            if (error) {
                setShowSpinner(false);
                dispatch({ type: modulEPConstants.GET_FAILURE_DONE });
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.error("Der Triftige Grunde wurde nicht erfolgreich abgesendet.");
            } else {
                setShowSpinner(false);
                dispatch({ type: modulEPConstants.GET_DONE });
                dispatch(modulEPActions.getModulEPList());
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.success("Der Triftige Grunde wurde erfolgreich abgesendet.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleBack = (e) => {
        e.preventDefault();
        //dispatch(modulActions.saveTempModul(modul));
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var mv = new WPSModulEPVerhindertClass();
        mv.art = eventConstants.TRIFFTIGERGRUNDEP;
        mv.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
        mv.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
        mv.awr = modulEP.modulverhindert.awr;
        mv.ast = modulEP.modulverhindert.ast;
        mv.mp = modulEP.modulverhindert.mp;
        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return false
            else return value;
        }
        var modulEPVerhindertInfos = JSON.parse(JSON.stringify(mv, replacer));

        setShowSpinner(true);
        dispatch(modulActions.saveVerhindertInfos(modulEP, modulEPBefore, modulEPVerhindertInfos, zeugnis, grund));
        // toast.success("Ihr Zulassungsantrag wurde abgeschickt");
        // history.push("/examensportal/start");

    };

    if (!modulEP || !modulEP.modulverhindert || !modulEPBefore) return (<div />);
    else {
        return (
            <>
                <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

                    <h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>

                    <div className="row">
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <span className="formLabel">Ich kann an der</span>

                            {<ul>
                                    {modulEP.modulverhindert.awr && schonVerhindert.awr && <AWR />}
                                    {modulEP.modulverhindert.ast && schonVerhindert.ast && <AST />}
                                    {modulEP.modulverhindert.mp && schonVerhindert.mp && <MP />}
                            </ul>}

                            <span className="formLabel">nicht teilnehmen.</span>

                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <b>Ich bin erkrankt. Ein amtsärztliches Zeugnis füge ich bei:</b>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {zeugnis
                                        ? zeugnis.map((f) => {
                                            return <div>{f.name}</div>;
                                        })
                                        : null}
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <b>Hierfür gibt es einen sonstigen Grund. Eine Begründung und ggf. Nachweise füge ich bei:</b>
                                </div>
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {grund
                                        ? grund.map((f) => {
                                            return <div>{f.name}</div>;
                                        })
                                        : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                            </div>

                            <br />


                        </div>
                    </div>

                </div>

                <div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
                        <input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
                    </div>
                </div>
            </>
        );
    }
}

const MenuRight = ({ tab, handleSubmit }) => {
    const dispatch = useDispatch();
    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide" >
            <p className="menu_right_header">Rücknahme der Anmeldung zu einer Modulprüfung</p>
            <ul id="menu_right">
                <li style={tab === 1 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={() => dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))} style={tab === 1 ? { background: '#a0bcd1' } : {}}>Auswahl Modul(e)</a>
                </li>
                <li style={tab === 2 ? { background: '#a0bcd1' } : {}}>
                    <a href="#" onClick={(e) => { handleSubmit(e) ? dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG)) : dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)) }} style={tab === 2 ? { background: '#a0bcd1' } : {}}>Zusammenfassung und Abschicken</a>
                </li>
            </ul>
            <br /><br />
        </div>
    );
}

export const GrundFormEP = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const tab = useSelector(state => state.rightMenu);
    const mlep = useSelector(state => state.modulEPList.modulEPList);
    const [modulEP, setModulEP] = useState(mlep);
    const mlb = useSelector(state => state.modulList.modulListBefore);
    const mlbep = useSelector(state => state.modulEPList.modulEPListBefore);
    const [modulEPBefore, setModulEPBefore] = useState(mlbep);
    const modulLoading = useSelector((state) => state.modulEPList.loading);
    const eignungLoading = useSelector((state) => state.eignung.loading);
    const [showSpinner, setShowSpinner] = useState(true);
    const [zeugnis, setZeugnis] = useState([]);
    const [grund, setGrund] = useState([]);
    const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
    const [zulassungsblock, setZulassungsblock] = useState(zb);
    const e = useSelector(state => state.eignung.eignung);
    const [eignung, setEignung] = useState(e);

    var schonVerhindert; var aktuelSemester; var semester;
    if (modulEP && modulEPBefore) {
        schonVerhindert = modulEPBefore.modulverhindert;
        aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);
        if (aktuelSemester !== "SEM") {
            semester = parseInt(aktuelSemester.split('/')[0], 10);
        } else {
            semester = 0;
        }
    }

    //console.log(modul);

    useEffect(() => {
        if (!modulLoading && !eignungLoading) {
            //console.log("Modul geladen")
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [modulLoading, eignungLoading]);

    useEffect(() => {
        setModulEP(mlep);
    }, [mlep]);

    useEffect(() => {
        setModulEPBefore(mlbep);
    }, [mlbep]);

    useEffect(() => {
        setZulassungsblock(zb);
    }, [zb]);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (!modulEP.modulverhindert.awr &&
            !modulEP.modulverhindert.ast &&
            !modulEP.modulverhindert.mp
        ) {
            toast.error("Sie können momentan von keiner Modulprüfung zurücktreten.")
            return false;
        }

        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
        return true;
    };


    const ZusammenfassungText = () => {
        return (
            <div className="hinweisText" style={{ marginBottom: '20px' }}>
                <p>
                    Ihre Mitteilung eines triftigen Grundes wird hier zusammengefasst.
                    Bitte prüfen Sie Ihre Angaben, bevor Sie Ihre Mitteilung verbindlich absenden.
                </p>
                <p>
                    Ihre Mitteilung wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.
                </p>
            </div>
        );
    }

    if (!modulEPBefore) {
        return (
            <form>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <GrundEignungText />

                    <ModulNochNichtAnwendbar />
                </div>
            </form>
        );
    }
    else {
        if (user && user.art && user.art === 'EIGNUNG') {
            if (modulEPBefore && modulEPBefore.zwischegespeichert === 2) {
                return (
                    <>
                        <form>
                            <Spinner show={showSpinner} />
                            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                                {tab === 1 && <GrundEignungText />}

                                {tab === 2 && <ZusammenfassungText />}
                           
                                {tab === 1 && <GrundEignung
                                    grund={grund}
                                    setGrund={setGrund}
                                    zeugnis={zeugnis}
                                    setZeugnis={setZeugnis}
                                    aktuelSemester={aktuelSemester}
                                    semester={semester}
                                    modulEP={modulEP}
                                    setModulEP={setModulEP}
                                    schonVerhindert={schonVerhindert}
                                    modulLoading={modulLoading}
                                    showSpinner={showSpinner}
                                    setShowSpinner={setShowSpinner}
                                    handleSubmit={handleSubmit}
                                />}
                                {tab === 2 && <Zusammenfassung
                                    aktuelSemester={aktuelSemester}
                                    semester={semester}
                                    modulEP={modulEP}
                                    modulEPBefore={modulEPBefore}
                                    schonVerhindert={schonVerhindert}
                                    grund={grund}
                                    zeugnis={zeugnis}
                                    showSpinner={showSpinner}
                                    setShowSpinner={setShowSpinner}
                                />}
                            </div>
                        </form>
                        {tab === 1 && (<MenuRight
                            tab={tab}
                            handleSubmit={handleSubmit}
                        />)}
                        {tab === 2 && (<MenuRight
                            tab={tab}
                            handleSubmit={handleSubmit}
                        />)}
                    </>
                );
            } else {
                return (
                    <form>
                        <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <GrundEignungText />

                            <ModulNochNichtAnwendbar />
                        </div>
                    </form>
                );
            }
        }
    }
}