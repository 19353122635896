import React from 'react';
import { Link } from 'react-router-dom';



const RegisterSuccess = () => {
    return (
        <div className="container container-small-view">
    <div className="row">
      <div className="col-12 col-sm-12 col-md-10 col-lg-8">
        <h1 style={{marginBbottom: '0.2em'}}>Vielen Dank, dass Sie sich registriert haben!</h1>
        <p>Sie erhalten eine E-Mail mit einem Bestätigungslink. Wenn Sie diesen Link anklicken, wird Ihr Konto freigeschaltet</p>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-10 col-lg-8">
      <Link to="/examensportal/login">Weiter zur Login-Seite</Link>
      </div>
    </div>
  </div>
    );
}

export default RegisterSuccess;