import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password'
import { toast } from 'react-toastify';
import { userService } from '../services';


export const ConfirmPassword = () => {
    const params = useParams();
    const [ok, setOk] = useState(false);

    YupPassword(Yup);

    const formik = useFormik({
		initialValues: {passwort: "", passwort1: ""},
		validationSchema: Yup.object({
			passwort: Yup.string()
				.min(8, "Das Passwort muss mindestens aus 8 Buchstaben bestehen")
				.minUppercase(1, "Das Passwort muss mindestens einen Großbuchstaben enthalten")
				.minLowercase(1, "Das Passwort muss mindestens einen Kleinbuchstaben enthalten")
				.minNumbers(1, "Das Passwort muss mindestens eine Zahl enthalten")
				.minSymbols(1, "Das Passwort muss mindestens ein Sonderzeichen enthalten")
				.required('Das Feld darf micht leer sein'),
			passwort1: Yup.string().oneOf([Yup.ref('passwort'), null], 'Die Passwörter müssen übereinstimmen')
		}),
		onSubmit: values => {
			userService.resetPassword(values.passwort, params.token)
			.then(
				res => {
          setOk(true);
				}	
            ).catch(
				res => {
					toast.error("Das Passwort konnte nicht geändert werden.")
				}
            );
			
		},
	  });
	

    if(!ok){

	return (
    <div className="container container-small-view">
		<form onSubmit={formik.handleSubmit}>
			<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
				<div className="hinweisText">
					<p>
						Hier können Sie Ihr Passwort ändern. Das Passwort muss folgende Eigenschaften besitzen:
					</p>
          <ul>
							<li>Das Passwort muss mindestens aus 8 Buchstaben bestehen</li>
							<li>Das Passwort muss mindestens einen Großbuchstaben enthalten</li>
							<li>Das Passwort muss mindestens einen Kleinbuchstaben enthalten</li>
							<li>Das Passwort muss mindestens eine Zahl enthalten</li>
							<li>Das Passwort muss mindestens ein Sonderzeichen enthalten</li>
						</ul>
				</div>

				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

					<h2 style={{ paddingTop: '25px' }}>Passwort ändern</h2>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Passwort *</span>
							<input type="password" name="passwort" autoComplete="off" value={formik.values.passwort} className="inputText100percent" onChange={formik.handleChange} onBlur={formik.handleBlur} />
							{formik.touched.passwort && formik.errors.passwort ? (
								<div className='pflichfeld'>{formik.errors.passwort}</div>
							) : null}
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Passwort wiederholen *</span>
							<input type="password" name="passwort1" autoComplete="off" value={formik.values.passwort1} className="inputText100percent" onChange={formik.handleChange} onBlur={formik.handleBlur} />
							{formik.touched.passwort1 && formik.errors.passwort1 ? (
								<div className='pflichfeld'>{formik.errors.passwort1}</div>
							) : null}
						</div>
					</div>


					<div className="row">
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
						</div>
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
					</div>

					<br />

				</div>

				<div className="row" style={{ marginTop: '10px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<button type="submit" className="nonPrintable button">Weiter</button>
					</div>
				</div>

			</div>
		</form>
    </div>
	);
  }else{
    return(
    <div className="container container-small-view">
    <div className="row">
      <div className="col-12 col-sm-12 col-md-10 col-lg-8">
        <h1 style={{marginBbottom: '0.2em'}}>Sie haben das Passwort erfolgreich geändert!</h1>
        <p>Sie können sich nun mit Ihrer E-Mail-Adresse und Ihrem neuen Passwort im Portal anmelden.</p>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-10 col-lg-8">
      <Link to="/examensportal/login">Weiter zur Login-Seite</Link>
      </div>
    </div>
  </div>
    )
  }

};
