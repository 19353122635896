import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userActions } from '../actions';
import { userService } from '../services';



export const ConfirmEmail = () => {
    const params = useParams("token");
    const [ok, setOk] = useState(false);

    useEffect(() => {
      userService.confirmRegistration(params.token).then(res => {
        setOk(true);
      }).catch(res => {
        toast.error("Die E-Mail konnte nicht bestätigt werden.")
      });
      
    }, []);

    if(!ok){
      return(null);
    }else{
      return (
        <div className="container container-small-view">
    <div className="row">
      <div className="col-12 col-sm-12 col-md-10 col-lg-8">
        <h1 style={{marginBbottom: '0.2em'}}>Vielen Dank, dass Sie Ihre Registrierung bestätigt haben!</h1>
        <p>
          Ihr Konto wurde für Sie freigeschaltet. 
          Sie können sich nun mit Ihrer E-Mail-Adresse und 
          Ihrem Passwort im Onlineportal „Wirtschaftsprüfungsexamen“ anmelden.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-10 col-lg-8">
      <Link to="/examensportal/login">Weiter zur Login-Seite</Link>
      </div>
    </div>
  </div>
    );
    }
    
}