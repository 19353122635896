
import './fileupload.scss';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash} from '@fortawesome/free-solid-svg-icons';

const FileUploadListDocumentFolder = ({files, setFiles, label}) => {

    const deleteFile = (fileName) => {
        setFiles(files.filter((item) => {
            return item.file.name != fileName;
        }));
    }

    const changeType = (e, index) => {
        const ar = [...files];
        const item = ar[index];
        item.type = e.target.value;
        setFiles(ar);
    };

    return(
        <div className="wpop-list-margin">
            {files && files.length > 0 && (
                <div className="row" style={{"marginTop":"20px", "marginBottom":"5px"}}>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span className="formLabel">{`Folgende ${label ? label : "Dateien"} haben Sie aktuell ausgewählt:`}</span>
                    </div>
                </div>
            )}
        
            {files ? files.map((item, index) => {
                return(
                    <div className="row wpop-list-margin" key={index}>
                        <div className="col-5 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            {item.file.name}
                        </div>
                        <div className="col-5 col-xs-10 col-sm-10 col-md-5 col-lg-5">
                            <select key={index} value={item.type} onChange={(e) => changeType(e, index)}>
                                <option value="LEBENSLAUF">Lebenslauf</option>
                                <option value="ATTEST">Attest</option>
                                <option value="ZEUGNIS">Zeugnis</option>
                                <option value="AGBESCHEINIGUNG">Arbeitgebernachweis Vorbildung</option>
                                <option value="STBESCHEINIGUNG">Bescheinigung der Steuerberaterkammer</option>
                                <option value="AGBESCHEINIGUNG2">Arbeitgeberbescheinigung Tätigkeit</option>
                                <option value="VERKUERZUNG">Bescheinigungen zur Verkürzung</option>
                                <option value="STAATSANGEHOERIGKEIT">Nachweis der Staatsangehörigkeit</option>
                            </select>
                        </div>
                        <div className="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1">
                        <FontAwesomeIcon icon={faTrash} onClick={() => deleteFile(item.file.name)} />
                        </div>
                    </div>
                )}
            ) : ""}
        </div>
    );

}

export default FileUploadListDocumentFolder;


