import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password'
import { toast } from 'react-toastify';
import { userService } from '../services';

export const Zugangsdaten = () => {
	//const [schritt, setSchritt] = useState(1);
	const s = useSelector(state => state.rightMenu);
	const user = useSelector((state) => state.authentication.user);
	const [schritt, setSchritt] = useState(s);
	YupPassword(Yup);

	return (
		<>
			{schritt === 1 && <Schritt1 schritt={schritt} setSchritt={setSchritt} />}
		</>
	);
};

const Schritt1 = ({ schritt, setSchritt }) => {
	const user = useSelector((state) => state.authentication.user);

	const formik = useFormik({
		initialValues: { passwort: "", passwort1: "" },
		validationSchema: Yup.object({
			passwort: Yup.string()
				.min(8, "Das Passwort muss mindestens aus 8 Buchstaben bestehen")
				.minUppercase(1, "Das Passwort muss mindestens einen Großbuchstaben enthalten")
				.minLowercase(1, "Das Passwort muss mindestens einen Kleinbuchstaben enthalten")
				.minNumbers(1, "Das Passwort muss mindestens eine Zahl enthalten")
				.minSymbols(1, "Das Passwort muss mindestens ein Sonderzeichen enthalten")
				.required('Das Feld darf micht leer sein'),
			passwort1: Yup.string().oneOf([Yup.ref('passwort'), null], 'Die Passwörter müssen übereinstimmen')
		}),
		onSubmit: values => {
			userService.changePassword(values.passwort)
				.then(
					res => {
						toast.info("Das Passwort wurde geändert.")
					}
				).catch(
					res => {
						toast.info("Das Passwort konnte nicht geändert werden.")
					}
				);

		},
	});

	const formikEmail = useFormik({
		initialValues: { email: user.email },
		validationSchema: Yup.object({
			email: Yup.string().email('Die E-Mail-Adresse ist nicht gültig').required('Das Feld darf nicht leer sein')
		}),
		onSubmit: values => {
			userService.changeEmail(values.email)
				.then(
					res => {
						toast.info("Ihre Kennung (E-Mail) wurde nun auch geändert, Ihre alte Kennung ist ungültig")
					}
				).catch(
					res => {
						toast.info("Die E-Mail konnte nicht geändert werden.")
					}
				);

		},
	});

	return (
		<>
		<form onSubmit={formik.handleSubmit}>
			<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
				<div className="hinweisText">
					<p>
						Hier können Sie Ihre Zugangsdaten ändern.
					</p>
					<p>
					Bitte beachten Sie: wenn Sie eine EMail-Änderung anzeigen wollen, so ändert sich damit auch Ihre Kennung. Ihre alte EMail als Kennung wird damit ungültig.
					</p>
					<p>
					Ihr Passwort muss mindestens:
					</p>
					<ul>
						<li>aus 8 Zeichen bestehen sowie</li>
						<li>einen Großbuchstaben,</li>
						<li>einen Kleinbuchstaben,</li>
						<li>eine Ziffer und</li>
						<li>ein Sonderzeichen enthalten.</li>
					</ul>
				</div>

				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

					<h2 style={{ paddingTop: '25px' }}>Passwort ändern</h2>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Passwort *</span>
							<input type="password" name="passwort" autoComplete="off" value={formik.values.passwort} className="inputText100percent" onChange={formik.handleChange} onBlur={formik.handleBlur} />
							{formik.touched.passwort && formik.errors.passwort ? (
								<div className='pflichfeld'>{formik.errors.passwort}</div>
							) : null}
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Passwort wiederholen *</span>
							<input type="password" name="passwort1" autoComplete="off" value={formik.values.passwort1} className="inputText100percent" onChange={formik.handleChange} onBlur={formik.handleBlur} />
							{formik.touched.passwort1 && formik.errors.passwort1 ? (
								<div className='pflichfeld'>{formik.errors.passwort1}</div>
							) : null}
						</div>
					</div>


					<div className="row">
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
						</div>
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
					</div>

					<br />

				</div>

				<div className="row" style={{ marginTop: '10px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<button type="submit" className="nonPrintable button">Weiter</button>
					</div>
				</div>

			</div>
		</form>
		<form onSubmit={formikEmail.handleSubmit}>
			<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8 abstand20">
				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

					<h2 style={{ paddingTop: '25px' }}>E-Mail ändern</h2>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">E-Mail *</span>
							<input type="email" name="email" autoComplete="off" value={formikEmail.values.email} className="inputText100percent" onChange={formikEmail.handleChange} onBlur={formikEmail.handleBlur} />
							{formikEmail.touched.email && formikEmail.errors.email ? (
								<div className='pflichfeld'>{formikEmail.errors.email}</div>
							) : null}
						</div>
					</div>


					<div className="row">
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
						</div>
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
					</div>

					<br />

				</div>

				<div className="row" style={{ marginTop: '10px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<button type="submit" className="nonPrintable button">Weiter</button>
					</div>
				</div>

			</div>
		</form>
		</>
	);

}
