import { authHeader } from '../helpers';
import axios from "axios";
import { userService } from './user.service';

export const modulService = {
  getModulList,
  getModulEPList,
  saveEventInfos,
  saveKontaktdaten,
  saveAnrechnung,
  saveVerhindert,
  saveKontaktdatenEignung,
  saveVerhindertEignung
};

function getModulList() {

  const requestOptions = {
    headers: authHeader()
  };

  return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/zulassung/modul`, requestOptions)

}

function getModulEPList() {

  const requestOptions = {
    headers: authHeader()
  };

  // return axios.get(`modul2.json`, requestOptions)
  return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/eignung/modul`, requestOptions)
}

function saveEventInfos(data) {
  var eventArt;
  switch (data.art) {
    case 2:
      eventArt = "ruecknahme";
      break;
    case 3:
      eventArt = "anmeldung";
      break;
    case 4:
      eventArt = "abmeldung";
      break;
    case 5:
      eventArt = "verschiebung";
      break;
    case 8:
      eventArt = "modulruecktritt";
      break;
    case 9:
      eventArt = "pruefungrucktritt";
      break;
    case 13:
      eventArt = "ruecknahmeeignung";
      break;
    case 14:
      eventArt = "pruefungrucktritteignung"
      break;
  }

  // const formData = new FormData();

  const requestOptions = {
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  const body = JSON.stringify(data);

  return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/modul/${eventArt}`, body, requestOptions);
}

function saveKontaktdaten(kontaktdaten, lebenslauf, attest) {
  const formData = new FormData();

  const requestOptions = {
    headers: { ...authHeader() },
  };

  if (lebenslauf && lebenslauf.length && lebenslauf.length > 0) {
    for (let a = 0; a < lebenslauf.length; a++) {
      formData.append("lebenslauf", lebenslauf[a]);
    }
  } else {
    formData.append("lebenslauf", []);
  }

  if (attest && attest.length && attest.length > 0) {
    for (let a = 0; a < attest.length; a++) {
      formData.append("attest", attest[a]);
    }
  } else {
    formData.append("attest", []);
  }

  formData.append("kontaktdaten", JSON.stringify(kontaktdaten));

  return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/modul/kontaktdaten`, formData, requestOptions);
}

function saveKontaktdatenEignung(kontaktdaten, lebenslauf, attest) {
  const formData = new FormData();

  const requestOptions = {
    headers: { ...authHeader() },
  };

  if (lebenslauf && lebenslauf.length && lebenslauf.length > 0) {
    for (let a = 0; a < lebenslauf.length; a++) {
      formData.append("lebenslauf", lebenslauf[a]);
    }
  } else {
    formData.append("lebenslauf", []);
  }

  if (attest && attest.length && attest.length > 0) {
    for (let a = 0; a < attest.length; a++) {
      formData.append("attest", attest[a]);
    }
  } else {
    formData.append("attest", []);
  }

  formData.append("kontaktdaten", JSON.stringify(kontaktdaten));

  return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/modul/kontaktdateneignung`, formData, requestOptions);
}

function saveAnrechnung(anrechnung, masterzeugnis, pruefungsbescheinigung) {
  const formData = new FormData();

  const requestOptions = {
    headers: { ...authHeader() },
  };

  if (masterzeugnis && masterzeugnis.length && masterzeugnis.length > 0) {
    for (let a = 0; a < masterzeugnis.length; a++) {
      formData.append("masterzeugnis", masterzeugnis[a]);
    }
  } else {
    formData.append("masterzeugnis", []);
  }

  if (pruefungsbescheinigung && pruefungsbescheinigung.length && pruefungsbescheinigung.length > 0) {
    for (let a = 0; a < pruefungsbescheinigung.length; a++) {
      formData.append("pruefungsbescheinigung", pruefungsbescheinigung[a]);
    }
  } else {
    formData.append("pruefungsbescheinigung", []);
  }

  formData.append("anrechnung", JSON.stringify(anrechnung));

  return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/modul/anrechnung`, formData, requestOptions);
}

function saveVerhindert(verhindert, zeugnis, grund) {
  const formData = new FormData();

  const requestOptions = {
    headers: { ...authHeader() },
  };

  if (zeugnis && zeugnis.length && zeugnis.length > 0) {
    for (let a = 0; a < zeugnis.length; a++) {
      formData.append("zeugnis", zeugnis[a]);
    }
  } else {
    formData.append("zeugnis", []);
  }

  if (grund && grund.length && grund.length > 0) {
    for (let a = 0; a < grund.length; a++) {
      formData.append("grund", grund[a]);
    }
  } else {
    formData.append("grund", []);
  }

  formData.append("verhindert", JSON.stringify(verhindert));

  return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/modul/triftigergrund`, formData, requestOptions);
}

function saveVerhindertEignung(verhindert, zeugnis, grund) {
  const formData = new FormData();

  const requestOptions = {
    headers: { ...authHeader() },
  };

  if (zeugnis && zeugnis.length && zeugnis.length > 0) {
    for (let a = 0; a < zeugnis.length; a++) {
      formData.append("zeugnis", zeugnis[a]);
    }
  } else {
    formData.append("zeugnis", []);
  }

  if (grund && grund.length && grund.length > 0) {
    for (let a = 0; a < grund.length; a++) {
      formData.append("grund", grund[a]);
    }
  } else {
    formData.append("grund", []);
  }

  formData.append("verhindert", JSON.stringify(verhindert));

  return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/modul/triftigergrundeignung`, formData, requestOptions);
}