import { eventConstants, modulConstants, modulEPConstants } from '../constants';
import { getErrorMessage } from '../helpers';
import { modulService } from '../services';

export const modulActions = {
    getModulList,
    getModulEPList,
    saveEventInfos,
    saveRuecknahmeInfos,
    saveGesamtPruefungInfos,
    saveKontaktdatenInfos,
    saveAnrechnungInfos,
    saveVerhindertInfos
};

function getModulList() {
    return dispatch => {
        dispatch({ type: modulConstants.GET_REQUEST });


        modulService.getModulList()
            .then(res => {
                dispatch({ type: modulConstants.GET_SUCCESS, payload: res })
                dispatch({type : modulConstants.GET_DONE})
            })
            .catch(error => {
                dispatch({ type: modulConstants.GET_FAILURE, payload: getErrorMessage(error)})
                dispatch({type : modulConstants.GET_DONE})
            });
    };
}

function getModulEPList() {
    return dispatch => {
        dispatch({ type: modulConstants.GET_REQUEST });


        modulService.getModulEPList()
            .then(res => {
                dispatch({ type: modulConstants.GET_SUCCESS, payload: res })
                dispatch({ type: modulConstants.GET_DONE })
            })
            .catch(error => {
                dispatch({ type: modulConstants.GET_FAILURE, payload: getErrorMessage(error) })
                dispatch({ type: modulConstants.GET_DONE })
            });
    };
}

function saveEventInfos(modul, modulBefore, modulData) {
    return dispatch => {
        dispatch({ type: modulConstants.SAVE_REQUEST });
        modulService.saveEventInfos(modulData)
            .then(res => {
                dispatch({ type: modulConstants.SAVE_SUCCESS, payload: [res, modulBefore] });
            })
            .catch(error => {
                dispatch({ type: modulConstants.SAVE_FAILURE, payload: [getErrorMessage(error), modul, modulBefore] })
            });
    };
}

function saveRuecknahmeInfos(ruecknahmeData) {
    return dispatch => {
        dispatch({ type: modulConstants.SAVE_RUECKNAHME_REQUEST });
        modulService.saveEventInfos(ruecknahmeData)
            .then(res => {
                dispatch({ type: modulConstants.SAVE_RUECKNAHME_SUCCESS, payload: res });
            })
            .catch(error => {
                dispatch({ type: modulConstants.SAVE_RUECKNAHME_FAILURE, payload: getErrorMessage(error) })
            });
    };
}

function saveGesamtPruefungInfos(gesamtpruefungData) {
    return dispatch => {
        dispatch({ type: modulConstants.SAVE_GESAMTPRUEFUNG_REQUEST });
        modulService.saveEventInfos(gesamtpruefungData)
            .then(res => {
                dispatch({ type: modulConstants.SAVE_GESAMTPRUEFUNG_SUCCESS, payload: res });
            })
            .catch(error => {
                dispatch({ type: modulConstants.SAVE_GESAMTPRUEFUNG_FAILURE, payload: getErrorMessage(error) })
            });
    };
}

function saveKontaktdatenInfos(modul, modulBefore, kontaktdaten, lebenslauf, attest) {
    return dispatch => {       
        if (kontaktdaten.art == eventConstants.MITTEILUNG_KONTAKTDATEN) {
            dispatch({ type: modulConstants.SAVE_REQUEST });
            modulService.saveKontaktdaten(kontaktdaten, lebenslauf, attest)
                .then(res => {
                    dispatch({ type: modulConstants.SAVE_SUCCESS, payload: [res, modulBefore] });
                })
                .catch(error => {
                    dispatch({ type: modulConstants.SAVE_FAILURE, payload: [getErrorMessage(error), modul, modulBefore] })
                });
        } else if (kontaktdaten.art == eventConstants.MITTEILUNG_KONTAKTDATENEP) {
            dispatch({ type: modulEPConstants.SAVE_REQUEST });
            modulService.saveKontaktdatenEignung(kontaktdaten, lebenslauf, attest)
                .then(res => {
                    dispatch({ type: modulEPConstants.SAVE_SUCCESS, payload: [res, modulBefore] });
                })
                .catch(error => {
                    dispatch({ type: modulEPConstants.SAVE_FAILURE, payload: [getErrorMessage(error), modul, modulBefore] })
                });
        }       
    };
}

function saveAnrechnungInfos(modul, modulBefore, anrechnung, masterzeugnis, pruefungsbescheinigung) {
    return dispatch => {
        dispatch({ type: modulConstants.SAVE_REQUEST });
        modulService.saveAnrechnung(anrechnung, masterzeugnis, pruefungsbescheinigung)
            .then(res => {
                dispatch({ type: modulConstants.SAVE_SUCCESS, payload: [res, modulBefore] });
            })
            .catch(error => {
                dispatch({ type: modulConstants.SAVE_FAILURE, payload: [getErrorMessage(error), modul, modulBefore] })
            });
    };
}

function saveVerhindertInfos(modul, modulBefore, verhindert, zeugnis, grund) {
    return dispatch => {
        if (verhindert.art == eventConstants.TRIFFTIGERGRUND) { 
            dispatch({ type: modulConstants.SAVE_REQUEST });
            modulService.saveVerhindert(verhindert, zeugnis, grund)
                .then(res => {
                    dispatch({ type: modulConstants.SAVE_SUCCESS, payload: [res, modulBefore] });
                })
                .catch(error => {
                    dispatch({ type: modulConstants.SAVE_FAILURE, payload: [getErrorMessage(error), modul, modulBefore] })
                });
        } else if (verhindert.art == eventConstants.TRIFFTIGERGRUNDEP) {
            dispatch({ type: modulEPConstants.SAVE_REQUEST });
            modulService.saveVerhindertEignung(verhindert, zeugnis, grund)
                .then(res => {
                    dispatch({ type: modulEPConstants.SAVE_SUCCESS, payload: [res, modulBefore] });
                })
                .catch(error => {
                    dispatch({ type: modulEPConstants.SAVE_FAILURE, payload: [getErrorMessage(error), modul, modulBefore] })
                });
        }        
    };
}