import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DokumentItem } from '.';
import { getErrorMessage } from '../helpers';
import { dokumenteService } from '../services';
import FileUpload2 from './fileupload/fileupload2';
import FileUploadListDocumentFolder from './fileupload/fileuploadlist-digitaleakte';
import { toast } from 'react-toastify';
import { documentActions } from '../actions/document.actions';

export const Dokumente = () => {
	const schritt = useSelector(state => state.rightmenuDokumente);
	const saveDone = useSelector(state => state.document.saveDone);
	const user = useSelector((state) => state.authentication.user);
	const [dokumente, setDokumente] = useState([]);
	const [fehler, setFehler] = useState([]);
	const [reload, setReload] = useState(false);

	const getDokumente = () => {
		dokumenteService.getDokumente()
			.then(
				res => {
					const docs = res.data;
					if (docs) {
						setDokumente(docs);
					}
				}
			)
			.catch(function (error) {
				setFehler(getErrorMessage(error));
			});
	};

	useEffect(() => {
		getDokumente();
	}, []);

	useEffect(() => {
		if(saveDone)
			getDokumente();
	}, [saveDone]);

	return (
		<>
			{schritt === 1 && <Schritt1 {...{ dokumente, setReload, fehler, setFehler }} />}
			{schritt === 2 && <Schritt2 {...{ dokumente, fehler, setFehler }} />}
			{user.art === 'WP' && schritt === 3 && <Schritt3 {...{ dokumente, fehler, setFehler }} />}
			{user.art === 'WP' && schritt === 4 && <Schritt4 {...{ dokumente, fehler, setFehler }} />}
			{user.art === 'WP' && schritt === 5 && <Schritt5 {...{ dokumente, fehler, setFehler }} />}
			{user.art === 'WP' && schritt === 6 && <Schritt6 {...{ dokumente, fehler, setFehler }} />}
			{/* Menü Right */}
		</>
	);
};

const Schritt1 = ({ dokumente, setDokumente, fehler, setFehler }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authentication.user);
	const [lebenslauf, setLebenslauf] = useState([]);
	const [attest, setAttest] = useState([]);
	const [zeugnis, setZeugnis] = useState([]);
	const [agBescheinigung, setAgBescheinigung] = useState([]);
	const [stBescheinigung, setStBescheinigung] = useState([]);
	const [agBescheinigung2, setAgBescheinigung2] = useState([]);
	const [stBescheinigung2, setStBescheinigung2] = useState([]);
	const [verkuerzungbescheinigung, setVerkuerzungbescheinigung] = useState([]);
	const [staatsangehoerigkeit, setStaatsangehoerigkeit] = useState([]);
	const [eignungnachweis, setEignungnachweis] = useState([]);
	const [eignungwahlfach, setEignungwahlfach] = useState([]);

	const [files, setFiles] = useState([]);
	const [fileTypes, setFileTypes] = useState([]);

	useEffect(() => {

		setLebenslauf([]);
		setAttest([]);
		setZeugnis([]);
		setAgBescheinigung([]);
		setStBescheinigung([]);
		setAgBescheinigung2([]);
		setStBescheinigung2([]);
		setVerkuerzungbescheinigung([]);
		setStaatsangehoerigkeit([]);

		if (dokumente && dokumente.length > 0) {
			for (let a = 0; a < dokumente.length; a++) {
				const item = dokumente[a];
				if (item.category === "AKTENEINGANG") {
					if (item.type === "LEBENSLAUF") {
						setLebenslauf(lebenslauf => [...lebenslauf, item]);
					} else if (item.type === "ATTEST") {
						setAttest(attest => [...attest, item]);
					} else if (item.type === "ZEUGNIS") {
						setZeugnis(zeugnis => [...zeugnis, item]);
					} else if (item.type === "AGBESCHEINIGUNG") {
						setAgBescheinigung(agBescheinigung => [...agBescheinigung, item]);
					} else if (item.type === "STBESCHEINIGUNG") {
						setStBescheinigung(stBescheinigung => [...stBescheinigung, item]);
					} else if (item.type === "AGBESCHEINIGUNG2") {
						setAgBescheinigung2(agBescheinigung2 => [...agBescheinigung2, item]);
					} else if (item.type === "STBESCHEINIGUNG2") {
						setStBescheinigung2(stBescheinigung2 => [...stBescheinigung2, item]);
					}else if (item.type === "VERKUERZUNG") {
						setVerkuerzungbescheinigung(verkuerzungbescheinigung => [...verkuerzungbescheinigung, item]);
					} else if (item.type === "STAATSANGEHOERIGKEIT") {
						setStaatsangehoerigkeit(staatsangehoerigkeit => [...staatsangehoerigkeit, item]);
					} else if (item.type === "EIGNUNG_NACHWEIS") {
						setEignungnachweis(eignungnachweis => [...eignungnachweis, item])
					} else if (item.type === "EIGNUNG_WAHLFACH") {
						setEignungwahlfach(eignungwahlfach => [...eignungwahlfach, item])
					}
				}				
			}
		}

	}, [dokumente]);

	const handleSubmit = e => {
		e.preventDefault();
		let lebenslauf_ = [];
		let attest_ = [];
		let zeugnis_ = [];
		let agBescheinigung_ = [];
		let stBescheinigung_ = [];
		let agBescheinigung2_ = [];
		let verkuerzungbescheinigung_ = [];
		let staatsangehoerigkeit_ = [];
		let eignungnachweis_ = [];
		let eignungwahlfach_ = [];

		if(files){
			for(let a = 0; a < files.length; a++){
				const item = files[a];

				if (item.type === "LEBENSLAUF") {
					lebenslauf_.push(item);
				} else if (item.type === "ATTEST") {
					attest_.push(item);
				} else if (item.type === "ZEUGNIS") {
					zeugnis_.push(item);
				} else if (item.type === "AGBESCHEINIGUNG") {
					agBescheinigung_.push(item);
				} else if (item.type === "STBESCHEINIGUNG") {
					stBescheinigung_.push(item);
				} else if (item.type === "AGBESCHEINIGUNG2") {
					agBescheinigung2_.push(item);
				}else if (item.type === "VERKUERZUNG") {
					verkuerzungbescheinigung_.push(item);
				} else if (item.type === "STAATSANGEHOERIGKEIT") {
					staatsangehoerigkeit_.push(item);
				} else if (item.type === "EIGNUNG_NACHWEIS") {
					eignungnachweis_.push(item);
				} else if (item.type === "EIGNUNG_WAHLFACH") {
					eignungwahlfach_.push(item)
				}

			}
		}

        dispatch(
            documentActions.saveDokumente(
                lebenslauf_,
                attest_,
                zeugnis_,
                agBescheinigung_,
                stBescheinigung_,
                agBescheinigung2_,
				verkuerzungbescheinigung_,
				staatsangehoerigkeit_
            )
        );
        toast.info("Die Dateien wurden gespeichert.");
		setFiles([]);
	}

	return (
		<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8" style={{ paddingTop: '0px', paddingBottom: '40px' }}>
			<div className="hinweisText">
				<p>
					Hier können Sie Ihre Dokumente (Akteneingang) und Ihre Schreiben der Prüfungsstelle einsehen und herunterladen.
				</p>
				<p>
					Sie können unter "Akteneingang" ergänzend auch Nachweise, Bescheinigungen, Urkunden, Erklärungen oder Atteste hochladen, die zum Beispiel für die weitere Bearbeitung Ihres Zulassungsantrages oder die Berücksichtigung eines triftigen Grundes benötigt werden und die der Prüfungsstelle bisher noch nicht vorliegen.
				</p>
			</div>

			<div className="form-Box-Hellblau" style={{ paddingTop: '0px', paddingBottom: '40px' }}>

				<h2 style={{ paddingTop: '25px' }}>Akteneingang</h2>

				{!fehler ? null :
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"><span className="pflichtfeld2">{fehler}</span></div>
					</div>
				}

				{user && user.art == 'WP' &&
					<>
						<DokumentItem titel="Lebensläufe" items={lebenslauf} setFehler={setFehler} />
						<DokumentItem titel="Atteste" items={attest} setFehler={setFehler} />
						<DokumentItem titel="Zeugnisse" items={zeugnis} setFehler={setFehler} />
						<DokumentItem titel="Arbeitgebernachweise Vorbildung" items={agBescheinigung} setFehler={setFehler} />
						<DokumentItem titel="Bescheinigungen der Steuerberaterkammer" items={stBescheinigung} setFehler={setFehler} />
						<DokumentItem titel="Arbeitgeberbescheinigungen Tätigkeiten / Prüfungstätigkeiten" items={agBescheinigung2} setFehler={setFehler} />
						<DokumentItem titel="Bescheinigungen zur Verkürzung" items={verkuerzungbescheinigung} setFehler={setFehler} />
						<DokumentItem titel="Nachweis der Staatsangehörigkeit" items={staatsangehoerigkeit} setFehler={setFehler} />
					</>
				}

				{user && user.art == 'EIGNUNG' &&
					<>
						<DokumentItem titel="Atteste" items={attest} setFehler={setFehler} />
						<DokumentItem titel="Wahlfach" items={eignungwahlfach} setFehler={setFehler} />
						<DokumentItem titel="Nachweis" items={eignungnachweis} setFehler={setFehler} />
					</>
				}
				
				<form onSubmit={(e) => handleSubmit(e)}>
				<div className="row" style={{"marginTop":"30px"}}>
						<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
						Sie können hier ergänzend Nachweise, Bescheinigungen, Urkunden, Erklärungen oder Atteste hochladen, die zum Beispiel für die weitere Bearbeitung Ihres Zulassungsantrages oder die Berücksichtigung eines triftigen Grundes benötigt werden und die der Prüfungsstelle bisher noch nicht vorliegen.
						</div>
					</div>
					<div className="row" style={{"marginTop":"20px"}}>
						<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Zusätzliche Dokumente</span>
							<FileUpload2 files={files} setFiles={setFiles} />
						</div>
					</div>
					{files && files.length > 0 &&
						<div className="row">
							<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
							<input
								type="submit"
								value="Senden"
								className="nonPrintable button"
								style={{'marginTop':'20px'}}
								onClick={(e) => handleSubmit(e)}
							/>
							</div>
						</div>
					}
					<FileUploadListDocumentFolder files={files} setFiles={setFiles} fileTypes={fileTypes} setFileTypes={setFileTypes} />
				</form>

			</div>



		</div>
	);

}

const Schritt2 = ({ dokumente, fehler, setFehler }) => {
	const [anmeldung, setAnmeldung] = useState([]);
	const [attest, setAttest] = useState([]);
	const [ruecktritt, setRuecktritt] = useState([]);
	const user = useSelector((state) => state.authentication.user);


	useEffect(() => {
		if (dokumente && dokumente.length > 0) {
			for (let a = 0; a < dokumente.length; a++) {
				const item = dokumente[a];
				if (item.category === "MANTELAKTE") {
					if (item.type === "ZULASSUNG") {
						setAnmeldung(anmeldung => [...anmeldung, item]);
					} else if (item.type === "ATTEST") {
						setAttest(attest => [...attest, item]);
					} else if (item.type === "RUECKTRITT") {
						setRuecktritt(ruecktritt => [...ruecktritt, item]);
					}
				}
			}
		}

	}, [dokumente]);


	return (
		<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8" style={{ paddingTop: '0px', paddingBottom: '40px' }}>
			<div className="hinweisText">
				<p>
					Sie können hier Ihre digitale Akte einsehen oder einzelne Dokumente daraus herunterladen und selbst ausdrucken.
				</p>
			</div>

			<div className="form-Box-Hellblau" style={{ paddingTop: '0px', paddingBottom: '40px' }}>

				<h2 style={{ paddingTop: '25px' }}>{user.art === 'EIGNUNG' ? 'Akte' : 'Mantelakte'}</h2>

				{!fehler ? null :
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"><span className="pflichtfeld2">{fehler}</span></div>
					</div>
				}

				<DokumentItem titel="Zulassungen" items={anmeldung} setFehler={setFehler} />
				<DokumentItem titel="Rücktritte" items={ruecktritt} setFehler={setFehler} />
				<DokumentItem titel="Notizen, Akten- und Telefonvermerke" items={attest} setFehler={setFehler} />

			</div>



		</div>
	);

}

const Schritt3 = ({ dokumente, fehler, setFehler }) => {
	const [anmeldung, setAnmeldung] = useState([]);
	const [attest, setAttest] = useState([]);
	const [ruecktritt, setRuecktritt] = useState([]);

	useEffect(() => {
		if (dokumente && dokumente.length > 0) {
			for (let a = 0; a < dokumente.length; a++) {
				const item = dokumente[a];
				if (item.category === "WP") {
					if (item.type === "ANMELDUNG") {
						setAnmeldung(anmeldung => [...anmeldung, item]);
					} else if (item.type === "ATTEST"){
						setAttest(attest => [...attest, item]);
					} else if (item.type === "RUECKTRITT") {
						setRuecktritt(ruecktritt => [...ruecktritt, item]);
					}
				}
			}
		}

	}, [dokumente]);


	return (
		<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8" style={{ paddingTop: '0px', paddingBottom: '40px' }}>
			<div className="hinweisText">
				<p>
				Sie können hier Ihre digitale Akte einsehen oder einzelne Dokumente daraus herunterladen und selbst ausdrucken.
				</p>
			</div>

			<div className="form-Box-Hellblau" style={{ paddingTop: '0px', paddingBottom: '40px' }}>

				<h2 style={{ paddingTop: '25px' }}>Modulprüfung WP</h2>

				{!fehler ? null :
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"><span className="pflichtfeld2">{fehler}</span></div>
					</div>
				}

				<DokumentItem titel="Anmeldungen" items={anmeldung} setFehler={setFehler} />
				<DokumentItem titel="Atteste" items={attest} setFehler={setFehler} />
				<DokumentItem titel="Rücktritte" items={ruecktritt} setFehler={setFehler} />

			</div>



		</div>
	);

}

const Schritt4 = ({ dokumente, fehler, setFehler }) => {
	const [anmeldung, setAnmeldung] = useState([]);
	const [attest, setAttest] = useState([]);
	const [ruecktritt, setRuecktritt] = useState([]);

	useEffect(() => {
		if (dokumente && dokumente.length > 0) {
			for (let a = 0; a < dokumente.length; a++) {
				const item = dokumente[a];
				if (item.category === "WR") {
					if (item.type === "ANMELDUNG") {
						setAnmeldung(anmeldung => [...anmeldung, item]);
					} else if (item.type === "ATTEST"){
						setAttest(attest => [...attest, item]);
					} else if (item.type === "RUECKTRITT") {
						setRuecktritt(ruecktritt => [...ruecktritt, item]);
					}
				}
			}
		}

	}, [dokumente]);


	return (
		<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8" style={{ paddingTop: '0px', paddingBottom: '40px' }}>
			<div className="hinweisText">
				<p>
				Sie können hier Ihre digitale Akte einsehen oder einzelne Dokumente daraus herunterladen und selbst ausdrucken.
				</p>
			</div>

			<div className="form-Box-Hellblau" style={{ paddingTop: '0px', paddingBottom: '40px' }}>

				<h2 style={{ paddingTop: '25px' }}>Modulprüfung WR</h2>

				{!fehler ? null :
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"><span className="pflichtfeld2">{fehler}</span></div>
					</div>
				}

				<DokumentItem titel="Anmeldungen" items={anmeldung} setFehler={setFehler} />
				<DokumentItem titel="Atteste" items={attest} setFehler={setFehler} />
				<DokumentItem titel="Rücktritte" items={ruecktritt} setFehler={setFehler} />

			</div>



		</div>
	);

}

const Schritt5 = ({ dokumente, fehler, setFehler }) => {
	const [anmeldung, setAnmeldung] = useState([]);
	const [attest, setAttest] = useState([]);
	const [ruecktritt, setRuecktritt] = useState([]);

	useEffect(() => {
		if (dokumente && dokumente.length > 0) {
			for (let a = 0; a < dokumente.length; a++) {
				const item = dokumente[a];
				if (item.category === "BWL") {
					if (item.type === "ANMELDUNG") {
						setAnmeldung(anmeldung => [...anmeldung, item]);
					} else if (item.type === "ATTEST"){
						setAttest(attest => [...attest, item]);
					} else if (item.type === "RUECKTRITT") {
						setRuecktritt(ruecktritt => [...ruecktritt, item]);
					}
				}
			}
		}

	}, [dokumente]);


	return (
		<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8" style={{ paddingTop: '0px', paddingBottom: '40px' }}>
			<div className="hinweisText">
				<p>
				Sie können hier Ihre digitale Akte einsehen oder einzelne Dokumente daraus herunterladen und selbst ausdrucken.
				</p>
			</div>

			<div className="form-Box-Hellblau" style={{ paddingTop: '0px', paddingBottom: '40px' }}>

				<h2 style={{ paddingTop: '25px' }}>Modulprüfung BWL</h2>

				{!fehler ? null :
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"><span className="pflichtfeld2">{fehler}</span></div>
					</div>
				}

				<DokumentItem titel="Anmeldungen" items={anmeldung} setFehler={setFehler} />
				<DokumentItem titel="Atteste" items={attest} setFehler={setFehler} />
				<DokumentItem titel="Rücktritte" items={ruecktritt} setFehler={setFehler} />

			</div>



		</div>
	);

}

const Schritt6 = ({ dokumente, fehler, setFehler }) => {
	const [anmeldung, setAnmeldung] = useState([]);
	const [attest, setAttest] = useState([]);
	const [ruecktritt, setRuecktritt] = useState([]);

	useEffect(() => {
		if (dokumente && dokumente.length > 0) {
			for (let a = 0; a < dokumente.length; a++) {
				const item = dokumente[a];
				if (item.category === "ST") {
					if (item.type === "ANMELDUNG") {
						setAnmeldung(anmeldung => [...anmeldung, item]);
					} else if (item.type === "ATTEST") {
						setAttest(attest => [...attest, item]);						
					} else if (item.type === "RUECKTRITT") {
						setRuecktritt(ruecktritt => [...ruecktritt, item]);
					}
				}
			}
		}

	}, [dokumente]);


	return (
		<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8" style={{ paddingTop: '0px', paddingBottom: '40px' }}>
			<div className="hinweisText">
				<p>
				Sie können hier Ihre digitale Akte einsehen oder einzelne Dokumente daraus herunterladen und selbst ausdrucken.
				</p>
			</div>

			<div className="form-Box-Hellblau" style={{ paddingTop: '0px', paddingBottom: '40px' }}>

				<h2 style={{ paddingTop: '25px' }}>Modulprüfung StR</h2>

				{!fehler ? null :
					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12"><span className="pflichtfeld2">{fehler}</span></div>
					</div>
				}

				<DokumentItem titel="Anmeldungen" items={anmeldung} setFehler={setFehler} />
				<DokumentItem titel="Atteste" items={attest} setFehler={setFehler} />
				<DokumentItem titel="Rücktritte" items={ruecktritt} setFehler={setFehler} />

			</div>



		</div>
	);

}


