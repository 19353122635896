import './spinner.scss';
import React, { useState } from "react";
import { SpinnerCircular } from 'spinners-react';

export const Spinner = ({show}) => {
    
    if(show){
        return (
        
            <div className="loading-wrapper">
                <div className="pos">
                    <SpinnerCircular size={'100px'} color={'#FFF'}/>
                </div>
                    
            </div>
        );
    }else{
        return <></>;
    }
    
}