import { zulassungsblockConstants } from '../constants';
import { getErrorMessage } from '../helpers';
import { zulassungService } from '../services';

export const zulassungsblockActions = {
    getZulassungsblock,
};

function getZulassungsblock() {
    return dispatch => {
        dispatch({ type: zulassungsblockConstants.GET_REQUEST });

        zulassungService.getZulassungsblock()
            .then(res => {
                dispatch({ type: zulassungsblockConstants.GET_SUCCESS, payload: res })
            })
            .catch(error => {
                dispatch({ type: zulassungsblockConstants.GET_FAILURE, payload: getErrorMessage(error)})
            });
    };
}