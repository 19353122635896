import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class PasswortZurueckgesetzt extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
          
            <div className="container container-small-view">
                <div className="row">
                <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                    <h1 style={{marginBbottom: '0.2em'}}>Passwort zurücksetzen</h1>
                    <p>Wir haben eine Nachricht an die von Ihnen angegebene E-Mail-Adresse gesendet. In der Nachricht finden Sie einen Link zum Zurücksetzen des Passwortes.</p>
                </div>
                </div>
                <div className="row">
                <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                <Link to="/examensportal/login">Weiter zur Login-Seite</Link>
                </div>
                </div>
            </div>


        );
    }
}

const connectedPasswortZurueckgesetzt = connect(null)(PasswortZurueckgesetzt);
export { connectedPasswortZurueckgesetzt as PasswortZurueckgesetzt }; 
