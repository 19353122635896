import { KontaktdatenClass } from "./kontaktdaten";
import { TaetigkeitClass } from "./taetigkeit";
import { VerkuerzungClass } from "./verkuerzung";
import { VorbildungClass } from "./vorbildung";
import { ZulassungenClass } from "./zulassungen";

export class ZulassungClass {

    constructor(){
        this.id = 0;
        this.zulassungsblock = "";
        this.lgs = 0;
        this.vorzeitigeZulassung = 0;
        this.zwischegespeichert = 0;
        this.wp = false;
        this.wr = false;
        this.wrVorgezogen = false;
        this.bwl = false;
        this.bwlVorgezogen = false;
        this.st = false;
        this.wrVorgezogen = false;
        this.bwlVorgezogen = false;
        this.vorbildung = new VorbildungClass();
        this.kontaktdaten = new KontaktdatenClass();
        this.bishierigeZulassungen = new ZulassungenClass();
        this.taetigkeit = new TaetigkeitClass();
        this.verkuerzung = new VerkuerzungClass();

    }
}

export class VerkuerzungAuswahlClass {
    constructor(){
        this.disabled1 = false;
        this.disabled2 = false;
        this.disabled3 = false;
        this.disabled4 = false;
        this.disabled5 = false;
        this.disabled6 = false;
    }
}

export class TipFehlerClass{
    constructor(){
        this.error = "";
    }
}

export class WPSZulassungRuecknahmeClass {
    constructor(){
        this.zulassungsblock = "";
        this.art = "";
        this.abgesendetAm = "";
    }
}