import { authHeader } from '../helpers';
import axios from "axios";

export const dokumenteService = {
    getDokumente,
    getDokument,
    deleteDokument
};

function getDokumente() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/dokumente`, requestOptions);

}

function getDokument(documentId) {
    const requestOptions = {
        headers: authHeader(),
        responseType: 'arraybuffer',
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/dokumente/download/${documentId}`, requestOptions);

}

function deleteDokument(documentId) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete(`${process.env.REACT_APP_WPOP_BACKEND}/api/dokumente/delete/${documentId}`, requestOptions);

}