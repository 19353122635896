import { zulassungConstants, verkuerzungauswahlConstants, tipfehlerConstants } from '../constants';
import { getErrorMessage } from '../helpers';
import { zulassungService} from '../services';

export const zulassungActions = {
    getZulassung,
    saveZulassung,
    saveTempZulassung,
    checkZulassung,
    getVerkuerzungAuswahl,
    getTipfehler,
    checkPruefungsblock
};

function getZulassung() {

    return dispatch => {
        dispatch({ type: zulassungConstants.GET_REQUEST });

        zulassungService.getZulassung()
            .then(res => {
                dispatch({ type: zulassungConstants.GET_SUCCESS, payload: res })
                dispatch({type : zulassungConstants.GET_DONE})
            })
            .catch(error => {
                dispatch({ type: zulassungConstants.GET_FAILURE, payload: getErrorMessage(error) })
                dispatch({type : zulassungConstants.GET_FAILURE_DONE})
            });
    };
}

function checkPruefungsblock() {
    zulassungService.checkPruefungsblock()
        .then(res => {
            return res.data;

        })
        .catch(res => {
            return "";
        });
}

function saveZulassung(zulassung, lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, stbescheinigungen2, verkuerzungbescheinigungen) {
    return dispatch => {
        dispatch({ type: zulassungConstants.SAVE_REQUEST });
        zulassungService.saveZulassung(zulassung, lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, stbescheinigungen2, verkuerzungbescheinigungen)
            .then(res => {
                dispatch({ type: zulassungConstants.SAVE_SUCCESS, payload: res });

            })
            .catch(error => {
                dispatch({ type: zulassungConstants.SAVE_FAILURE, payload: [getErrorMessage(error), zulassung] })
            });
    };
}

function saveTempZulassung(zulassung, lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, stbescheinigungen2, verkuerzungbescheinigungen) {
    return dispatch => {
        dispatch({ type: zulassungConstants.SAVE_REQUEST });
        zulassungService.saveTempZulassung(zulassung, lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, stbescheinigungen2, verkuerzungbescheinigungen)
            .then(res => {
                dispatch({ type: zulassungConstants.SAVE_TEMP_SUCCESS, payload: res });
            })
            .catch(error => {
                dispatch({ type: zulassungConstants.SAVE_TEMP_FAILURE, payload: [getErrorMessage(error), zulassung] })
            });
    };
}

function checkZulassung() {

    return dispatch => {
        zulassungService.checkZulassung()
            .then(res => {
                dispatch({ type: zulassungConstants.CHECK, payload: !res.data.zugelassen })
            })
            .catch(res => dispatch({ type: zulassungConstants.GET_FAILURE, payload: res }));
    };
}

function getVerkuerzungAuswahl() {

    return dispatch => {
        dispatch({ type: verkuerzungauswahlConstants.GET_REQUEST });
    };
}

function getTipfehler() {

    return dispatch => {
        dispatch({ type: tipfehlerConstants.GET_REQUEST });
    };
}
