import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { document } from './document.reducer';
import { zulassung, verkuerzungauswahl, tipfehler } from './zulassung.reducer';
import { eignung, verkuerzungauswahlEP } from './eignung.reducer';
import { topMenu } from './topMenu.reducer';
import { rightMenu, rightmenuZulassungsAntrag, rightmenuEignungAntrag, rightmenuDokumente } from './rightMenu.reducer';
import { modulList, modulEPList, ruecknahme, gesamtpruefung } from './modul.reducer';
import { zulassungsblock } from './zulassungsblock.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  zulassung,
  eignung,
  alert,
  topMenu,
  rightMenu,
  rightmenuZulassungsAntrag,
  rightmenuEignungAntrag,
  rightmenuDokumente,
  modulList,
  modulEPList,
  verkuerzungauswahl,
  verkuerzungauswahlEP,
  tipfehler,
  document,
  zulassungsblock, 
  ruecknahme,
  gesamtpruefung
});

export default rootReducer;