export const topmenuConstants = {
    ZULASSUNGANTRAG_STELLEN : 'ZULASSUNGANTRAG_STELLEN',
    ZULASSUNGANTRAG_ZURUECKNEHMEN: 'ZULASSUNGANTRAG_ZURUECKNEHMEN',
    MODULPRUEFUNG_ANMELDUNG_FORM : 'MODULPRUEFUNG_ANMELDUNG_FORM',
    MODULPRUEFUNG_ABMELDUNG_FORM : 'MODULPRUEFUNG_ABMEDUNG_FORM',
    MODULPRUEFUNG_ANRECHNEN_FORM : 'MODULPRUEFUNG_ANRECHNEN_FORM',
    MODULPRUEFUNG_VERSCHIEBEN_FORM : 'MODULPRUEFUNG_VERSCHIEBEN_FORM',
    MITTEILUNG_EINES_TRIFTIGEN_GRUNDES : 'MITTEILUNG_EINES_TRIFTIGEN_GRUNDES',
    MODULPRUEFUNG : 'MODULPRUEFUNG',
    GESAMTPRUEFUNG : 'GESAMTPRUEFUNG',
    KONTAKTDATEN_AENDERN : 'KONTAKTDATEN_AENDERN',
    KENNWORT_AENDERN : 'KENNWORT_AENDERN',
    // EINSTELLUNGEN_AENDERN : 'EINSTELLUNGEN_AENDERN',
    DIGITALE_AKTE : 'DIGITALE_AKTE',

    ZULASSUNGANTRAG_STELLEN_NR : 1,
    ZULASSUNGANTRAG_ZURUECKNEHMEN_NR: 2,
    MODULPRUEFUNG_ANMELDUNG_FORM_NR : 3,
    MODULPRUEFUNG_ABMELDUNG_FORM_NR : 4,
    MODULPRUEFUNG_ANRECHNEN_FORM_NR : 5,
    MODULPRUEFUNG_VERSCHIEBEN_FORM_NR : 6,
    MITTEILUNG_EINES_TRIFTIGEN_GRUNDES_NR : 7,
    MODULPRUEFUNG_NR : 8,
    GESAMTPRUEFUNG_NR : 9,
    KONTAKTDATEN_AENDERN_NR : 10,
    KENNWORT_AENDERN_NR : 11,
    // EINSTELLUNGEN_AENDERN : 'EINSTELLUNGEN_AENDERN',
    DIGITALE_AKTE_NR : 12
};