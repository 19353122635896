import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { eignungActions, rightMenuActions } from '../../actions';
import { zulassungConstants, rightmenuEignungAntragConstants } from '../../constants';
import { Spinner } from "../spinner/Spinner";
import { util } from '../../services';
import { ModulNochNichtAnwendbar } from '../HinweisTexte';
import { EULaender, Laender } from './Laender';
import { Laenderkuerzel } from './Laenderkuerzel';
import FileUpload from '../fileupload/fileupload';
import FileUploadList from '../fileupload/fileuploadlist';

const Eignunganmeldung = ({ nachweise,
    wahlfach,
    zeugnisse,
    zulassung, setZulassung}) => {
    
    const user = useSelector((state) => state.authentication.user);
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = useState(false);

    const handleChange = (e) => {
        let v = e.target.value;
        
        if (v == 1) {
            zulassung.bishierigeZulassungenEP.datumNichtBestanden1 = ""; zulassung.bishierigeZulassungenEP.datumNichtBestanden2 = "";
        } else if (v == 2) {
            zulassung.bishierigeZulassungenEP.datumNichtBestanden2 = "";
        } else if (v == 3) {
            zulassung.bishierigeZulassungenEP.datumNichtBestanden1 = "";
        }

        if (e.target.name.indexOf("bishierigeZulassungenEP") > -1) {
            setZulassung({
                ...zulassung,
                bishierigeZulassungenEP: {
                    ...zulassung.bishierigeZulassungenEP,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            eignungActions.saveTempEignung(
                zulassung,
                nachweise,
                wahlfach,
                zeugnisse
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.KONTAKTDATEN))
    };

    const ModulanmeldungHinweisText = () => {
        return (
            <div className="hinweisText" style={{ marginBottom: '20px' }}>
                <p>
                Sie können hier Ihre Zulassung zur Eignungsprüfung als Wirtschaftsprüfer nach dem Neunten Teil der Wirtschaftsprüferordnung (§§ 131g ff. WPO) beantragen.
                </p>
                <p>
                Sie können hier eine Erklärung abgeben, ob Sie bereits zur Eignungsprüfung zugelassen waren (§ 25 Abs. 2 Nr. 5 Wirtschaftsprüferprüfungsverordnung [WiPrPrüfV]).
                </p>
            </div>
        );
    }


    if (!zulassung) {
        return (
            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <ModulanmeldungHinweisText />
                <ModulNochNichtAnwendbar />
            </div>
        );
    }
    else {
        if (zulassung.zwischegespeichert !== 0) {
            return (
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <ModulanmeldungHinweisText />
                    <ModulNochNichtAnwendbar />
                </div>
            );
        } else if (zulassung.zwischegespeichert === 0) {
            return (
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Spinner show={showSpinner} />
                    <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">

                        <ModulanmeldungHinweisText />

                        <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                            <h2 style={{ paddingTop: "25px" }}>
                                Zulassungsantrag
                            </h2>
                            
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Prüfungstermin*</span>
                                    <div>{util.changeFormatSemesterEignung(zulassung.zulassungsblock)}</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Abschlussprüfungsqualifikation erworben in*</span>
                                    <EULaender
                                        name="land"
                                        autoComplete="off"
                                        value={zulassung.land}
                                        sonstigerStaat={false}
                                        onChange={(e) => handleChange(e)}
                                        
                                    />
                                </div>
                            </div>

                            <h2 style={{ paddingTop: "25px" }}>
                            Erklärung über bisherige Zulassungsanträge
                            </h2>

                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Ich bin...*</span>
                                    <input
                                        type="radio"
                                        name="bishierigeZulassungenEP.art"
                                        value={"1"}
                                        style={{ marginRight: "15px" }}
                                        checked={
                                            zulassung.bishierigeZulassungenEP.art == "1" ? true : false
                                        }
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    noch nie zur Eignungsprüfung zugelassen worden. <br />
                                    <input
                                        type="radio"
                                        name="bishierigeZulassungenEP.art"
                                        value={"2"}
                                        style={{ marginRight: "15px" }}
                                        checked={
                                            zulassung.bishierigeZulassungenEP.art == "2" ? true : false
                                        }
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    bereits einmal zur Eignungsprüfung zugelassen worden
                                    und habe die Prüfung nicht bestanden.
                                    <br />
                                    <input
                                        type="radio"
                                        name="bishierigeZulassungenEP.art"
                                        value={"3"}
                                        style={{ marginRight: "15px" }}
                                        checked={
                                            zulassung.bishierigeZulassungenEP.art == "3" ? true : false
                                        }
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    bereits zweimal zur Eignungsprüfung zugelassen worden
                                    und habe die Prüfung nicht bestanden.
                                </div>
                            </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">1. Prüfung nicht bestanden am</span>
                                <input
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                        name="bishierigeZulassungenEP.datumNichtBestanden1"
                                    className="inputText100percent"
                                        value={zulassung.bishierigeZulassungenEP.datumNichtBestanden1
                                        ? util.changeDateENGFormat(zulassung.bishierigeZulassungenEP.datumNichtBestanden1)
                                        : ""
                                    }
                                    onChange={(e) => handleChange(e)}
                                    disabled={(zulassung.bishierigeZulassungenEP.art != "2" && zulassung.bishierigeZulassungenEP.art != "3") ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">2. Prüfung nicht bestanden am</span>
                                <input
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                        name="bishierigezulassungenEP.datumNichtBestanden2"
                                    className="inputText100percent"
                                        value={zulassung.bishierigeZulassungenEP.datumNichtBestanden2
                                        ? util.changeDateENGFormat(zulassung.bishierigeZulassungenEP.datumNichtBestanden2)
                                        : ""
                                    }
                                    onChange={(e) => handleChange(e)}
                                    disabled={(zulassung.bishierigeZulassungenEP.art != "3") ? true : false}
                                />
                            </div>
                        </div>

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <span style={{ color: "#b94a48" }} className="formLabel">
                                        * Pflichtfelder
                                    </span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                            </div>

                            <br />
                        </div>

                        <div
                            className="row"
                            style={{ marginTop: "10px", marginBottom: "20px" }}
                        >
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="submit"
                                    value="Weiter"
                                    className="nonPrintable button"
                                    onClick={(e) => handleSubmit(e)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            );
        } else{
            return (
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <ModulanmeldungHinweisText />
                    <ModulNochNichtAnwendbar />
                </div>
            );
        }
    }
};

const Kontaktdaten = ({ 
    setNachweise, 
    nachweise,
    wahlfach,
    zeugnisse,
    zulassung, 
    setZulassung, 
    tipfehler, 
    setTipfehler }) => {
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("kontaktdaten") > -1) {
            setZulassung({ ...zulassung, kontaktdaten: { ...zulassung.kontaktdaten, [e.target.name.split(".")[1]]: v } })
        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            eignungActions.saveTempEignung(
                zulassung,
                nachweise,
                wahlfach,
                zeugnisse
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.VERKUERZUNG))
        //setSchritt(3);
    };

    const handleBack = (e) => {
        e.preventDefault();

        dispatch(
            eignungActions.saveTempEignung(
                zulassung,
                nachweise,
                wahlfach,
                zeugnisse
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ZULASSUNGSANTRAG))
        //setSchritt(1);
    };

    const feldPruefen = (e) => {

        setTipfehler({ ...tipfehler, ["error"]: "" })

        if (e.target.name === "kontaktdaten.plz") {
            if (zulassung.kontaktdaten.plz && !zulassung.kontaktdaten.plz.match(/^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/)) {
                //console.log("Schreib meinen Fehler")
                setTipfehler({ ...tipfehler, ["error"]: "PLZ ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.email") {
            if (zulassung.kontaktdaten.email && !zulassung.kontaktdaten.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setTipfehler({ ...tipfehler, ["error"]: "Email ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.telefon") {
            if (zulassung.kontaktdaten.telefon && !zulassung.kontaktdaten.telefon.match(/\b[\+]?[(]?[0-9]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{3,15}\b/m)) {
                setTipfehler({ ...tipfehler, ["error"]: "Telefon ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.bic") {
            if (zulassung.kontaktdaten.bic && !zulassung.kontaktdaten.bic.match(/([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/g)) {
                setTipfehler({ ...tipfehler, ["error"]: "BIC ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.iban") {
            if (zulassung.kontaktdaten.iban && !zulassung.kontaktdaten.iban.match(/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/)) {
                setTipfehler({ ...tipfehler, ["error"]: "IBAN ist nicht korrekt" })
            }
        }
    };

    if (!zulassung || !tipfehler) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                    <p>
                        Bitte ergänzen Sie hier Ihre persönlichen Daten und Ihre Kontaktdaten und geben weitere für das Zulassungs- und Prüfungsverfahren benötigte Daten an. Dazu gehört auch der Upload Ihres aktuellen und vollständigen Lebenslaufs.
                    </p>
                    <p>
                        Wenn Sie wegen einer Behinderung einen Nachteilsausgleich beantragen wollen (§ 29 Abs. 2 WiPrPrüfV), können Sie Ihren Antrag und den entsprechenden Nachweis, zum Beispiel ein amtsärztliches Attest, hier hochladen.
                    </p>
                    <p>
                        Auch die von der zuständigen Behörde oder Organisation ausgestellte Bescheinigung, durch die nachgewiesen wird, dass Sie Abschlussprüfer/-in sind (§ 25 Abs. 2 Nr. 2 WiPrPrüfV), wird hier hochgeladen. Gleiches gilt für Unterlagen, aus denen sich Ihre Staatsangehörigkeit ergibt (§ 25 Abs. 2 Nr. 6 WiPrPrüfV).
                    </p>
                    <p>
                        Bitte laden Sie die dazugehörigen Nachweise, Bescheinigungen, Urkunden, Erklärungen oder Atteste hier hoch (max. 30MB, Dateitypen *.jpg, *.jpeg, *.png, *.gif, *.pdf).
                    </p>
                    <p>
                        Sie können entweder nur eine, oder aber gleichzeitig mehrere Dateien hochladen.
                    </p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>Persönliche Daten und Kontaktdaten</h2>
                        <p style={{ color: "rgb(185, 74, 72)" }}>{tipfehler.error}</p>
                        <div className="row">
                            <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                <span className="formLabel">Anrede*</span>
                                <select
                                    name="kontaktdaten.anrede"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.anrede}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value=""></option>
                                    <option value="Frau">Frau</option>
                                    <option value="Herr">Herr</option>
                                    <option value="KeineAnrede">Keine Anrede</option>
                                </select>
                            </div>
                            <div className="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                <span className="formLabel">Vorname*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.vorname"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.vorname}
                                    className="inputText100percent"
                                    onChange={(e) => { handleChange(e) }}
                                />
                            </div>
                            <div className="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                <span className="formLabel">Nachname*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.nachname"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.nachname}
                                    className="inputText100percent"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Weitere Vornamen</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.weitereVornamen"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.weitereVornamen}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Geburtsname</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.geburtsname"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.geburtsname}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Geburtsdatum*</span>
                                <input
                                    id="kontaktdaten.geburtsdatum"
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    name="kontaktdaten.geburtsdatum"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.geburtsdatum}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}

                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Geburtsort*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.geburtsort"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.geburtsort}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Staatsangehörigkeit</span>
                                    <Laender
                                        name="kontaktdaten.staatsangehoerigkeit"
                                        className="inputText100percent"
                                        autoComplete="off"
                                        sonstigerStaat={true}
                                        value={zulassung.kontaktdaten.staatsangehoerigkeit}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Aktueller Arbeitgeber</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.aktArbeitgeber"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.aktArbeitgeber}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Nachteilsausgleich</h3>
                                <input
                                    style={{ marginRight: "15px" }}
                                    type="checkbox"
                                    name="kontaktdaten.behinderung"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.behinderung}
                                    checked={zulassung.kontaktdaten.behinderung}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                <span style={{ color: "#353535", fontWeight: "bold" }}>Behinderung</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Wohnort</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Straße*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.strasse"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.strasse}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Hausnr.*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.hausnummer"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.hausnummer}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Land*</span>
                                <Laenderkuerzel
                                    name="kontaktdaten.land"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.land}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                <span className="formLabel">PLZ*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.plz"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.plz}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                <span className="formLabel">Ort*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.ort"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.ort}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Verbindungsdaten</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 col-xs-4 col-sm-4 col-md-6 col-lg-6">
                                <span className="formLabel">E-Mail*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.email"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.email}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                            <div className="col-4 col-xs-4 col-sm-4 col-md-6 col-lg-6">
                                <span className="formLabel">Telefon*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.telefon"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.telefon}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Bankverbindung</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">BIC</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.bic"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.bic}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">IBAN*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.iban"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.iban}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Dokumente/Anlagen</h3>                               
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Nachweise</span>
                                <FileUpload files={nachweise} setFiles={setNachweise} />
                            </div>
                        </div>
                        <FileUploadList files={nachweise} setFiles={setNachweise} label={'Nachweise'} />

                        <div className="row">
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <br />
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />

                            <input
                                type="submit"
                                value="Weiter"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const Verkuerzung = ({ setWahlfach, setZeugnisse, nachweise,
    wahlfach,
    zeugnisse,
    zulassung, setZulassung,
    verkuerzungauswahl, setVerkuerzungauswahl}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (zulassung.verkuerzungEP.verkuerzung1) {
            setVerkuerzungauswahl({ disabled1: false, disabled2: true, disabled3: true, disabled4: true, disabled5: true });
        } else if (zulassung.verkuerzungEP.verkuerzung2) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: false, disabled3: true, disabled4: false, disabled5: false });
        } else if (zulassung.verkuerzungEP.verkuerzung2 && zulassung.verkuerzungEP.verkuerzung4) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: false, disabled3: true, disabled4: false, disabled5: true });
        } else if (zulassung.verkuerzungEP.verkuerzung2 && zulassung.verkuerzungEP.verkuerzung5) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: false, disabled3: true, disabled4: true, disabled5: false });
        } else if (zulassung.verkuerzungEP.verkuerzung3) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: false, disabled4: false, disabled5: false });
        } else if (zulassung.verkuerzungEP.verkuerzung3 && zulassung.verkuerzungEP.verkuerzung4) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: false, disabled4: false, disabled5: true });
        } else if (zulassung.verkuerzungEP.verkuerzung3 && zulassung.verkuerzungEP.verkuerzung5) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: false, disabled4: true, disabled5: false });
        } else if (zulassung.verkuerzungEP.verkuerzung4 && !zulassung.verkuerzungEP.verkuerzung2 && !zulassung.verkuerzungEP.verkuerzung3) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: true, disabled4: false, disabled5: true });
        } else if (zulassung.verkuerzungEP.verkuerzung5 && !zulassung.verkuerzungEP.verkuerzung2 && !zulassung.verkuerzungEP.verkuerzung3) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: true, disabled4: true, disabled5: false });
        } else {
            setVerkuerzungauswahl({ disabled1: false, disabled2: false, disabled3: false, disabled4: false, disabled5: false });
        }



    }, []);

    const handleChange = (e) => {
        // setZulassung({ ...zulassung, [e.target.name]: v });

        let v;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            } else if (e.target.value === "true" || e.target.value === true){
                v = false;
            } else {
                v = e.target.value;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("verkuerzungEP") > -1) {
            setZulassung({
                ...zulassung,
                verkuerzungEP: {
                    ...zulassung.verkuerzungEP,
                    [e.target.name.split(".")[1]]: v,
                },
            });

            FelderDeaktivieren(e.target.name, v);
        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }        
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            eignungActions.saveTempEignung(
                zulassung,
                nachweise,
                wahlfach,
                zeugnisse
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ABSENDEN))
        // setSchritt(4);
    };

    const handleBack = (e) => {
        e.preventDefault();

        dispatch(
            eignungActions.saveTempEignung(
                zulassung,
                nachweise,
                wahlfach,
                zeugnisse
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.KONTAKTDATEN))
        //setSchritt(2);
    };

    const FelderDeaktivieren = (feld, v) => {
        //Disabling Auswahl von Verkuerzung
        // Fuer Vollpruefung
        if (feld.split(".")[1] === "verkuerzung1" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: true, ["disabled3"]: true, ["disabled4"]: true, ["disabled5"]: true })
        } else if (feld.split(".")[1] === "verkuerzung1" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
        }

        // Fuer verkuerzung2
        if (feld.split(".")[1] === "verkuerzung2" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: true, ["disabled4"]: false, ["disabled5"]: false })
        } else if (feld.split(".")[1] === "verkuerzung2" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
            setZulassung({ ...zulassung, verkuerzungEP: { ...zulassung.verkuerzungEP, ["verkuerzung2"]: false, ["verkuerzung4"]: false, ["verkuerzung5"]: false}})
        } else if (zulassung.verkuerzungEP.verkuerzung2 && feld.split(".")[1] === "verkuerzung4" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: true, ["disabled4"]: false, ["disabled5"]: true })
        } else if (zulassung.verkuerzungEP.verkuerzung2 && feld.split(".")[1] === "verkuerzung4" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: true, ["disabled4"]: false, ["disabled5"]: false })
        } else if (zulassung.verkuerzungEP.verkuerzung2 && feld.split(".")[1] === "verkuerzung5" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: true, ["disabled4"]: true, ["disabled5"]: false })
        } else if (zulassung.verkuerzungEP.verkuerzung2 && feld.split(".")[1] === "verkuerzung5" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: true, ["disabled4"]: false, ["disabled5"]: false })
        }

        // Fuer verkuerzung3
        if (feld.split(".")[1] === "verkuerzung3" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
        } else if (feld.split(".")[1] === "verkuerzung3" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
            setZulassung({ ...zulassung, verkuerzungEP: { ...zulassung.verkuerzungEP, ["verkuerzung3"]: false, ["verkuerzung4"]: false, ["verkuerzung5"]: false } })
        } else if (zulassung.verkuerzungEP.verkuerzung3 && feld.split(".")[1] === "verkuerzung4" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: true })
        } else if (zulassung.verkuerzungEP.verkuerzung3 && feld.split(".")[1] === "verkuerzung4" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
        } else if (zulassung.verkuerzungEP.verkuerzung3 && feld.split(".")[1] === "verkuerzung5" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: false, ["disabled4"]: true, ["disabled5"]: false })
        } else if (zulassung.verkuerzungEP.verkuerzung3 && feld.split(".")[1] === "verkuerzung5" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
        }

        // Fuer verkuerzung4
        if (feld.split(".")[1] === "verkuerzung4" && v === true && !zulassung.verkuerzungEP.verkuerzung2 && !zulassung.verkuerzungEP.verkuerzung3) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: true, ["disabled4"]: false, ["disabled5"]: true })
        } else if (feld.split(".")[1] === "verkuerzung4" && v === false && !zulassung.verkuerzungEP.verkuerzung2 && !zulassung.verkuerzungEP.verkuerzung3) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
            setZulassung({ ...zulassung, verkuerzungEP: { ...zulassung.verkuerzungEP, ["verkuerzung4"]: false, ["verkuerzung2"]: false, ["verkuerzung3"]: false }})
        }

        // Fuer verkuerzung5
        if (feld.split(".")[1] === "verkuerzung5" && v === true && !zulassung.verkuerzungEP.verkuerzung2 && !zulassung.verkuerzungEP.verkuerzung3) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: true, ["disabled4"]: true, ["disabled5"]: false })
        } else if (feld.split(".")[1] === "verkuerzung5" && v === false && !zulassung.verkuerzungEP.verkuerzung2 && !zulassung.verkuerzungEP.verkuerzung3) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false })
            setZulassung({ ...zulassung, verkuerzungEP: { ...zulassung.verkuerzungEP, ["verkuerzung5"]: false, ["verkuerzung2"]: false, ["verkuerzung3"]: false }})
        }
    }

    const auswaehleZuruecksetzen = (e) => {
        e.preventDefault();

        setVerkuerzungauswahl({ disabled1: false, disabled2: false, disabled3: false, disabled4: false, disabled5: false });
        setZulassung({ ...zulassung, verkuerzungEP: { ...zulassung.verkuerzungEP, ["verkuerzung1"]: false, ["verkuerzung2"]: false, ["verkuerzung3"]: false, ["verkuerzung4"]: false, ["verkuerzung5"]: false }})
    }

    if (!zulassung) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText" style={{ marginBottom: '20px' }}>
                        <p>
                        Sie müssen eine Erklärung über das Wahlfach für die mündliche Prüfung abgeben (§ 25 Abs. 2 Nr. 4 WiPrPrüfV). Diese Erklärung können Sie hier hochladen.
                        </p>
                        <p>
                            Sie können hier eine Erklärung abgeben, ob Sie die Prüfung in verkürzter Form ablegen möchten (§ 28 i. Abs. 1 V. m. § 6 WiPrPrüfV, §§ 8a, 13 und 13b WPO; § 28 Abs. 2 und 3 WiPrPrüfV), und die dafür nötigen Nachweise hochladen.
                        </p>
                    </div>
                    
                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px", marginBottom: 20 }}>
                        <div className="row" style={{ marginBottom: 20 }}>
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">                               
                                <h2 style={{ paddingTop: "25px" }}>
                                    Erklärung über Wahlfach und Verkürzung
                                </h2>
                                <span className="formLabel">Wahlfach</span>
                                <input
                                    type="radio"
                                    name="wahlfach"
                                    value={"1"}
                                    style={{ marginRight: "15px" }}
                                    checked={
                                        zulassung.wahlfach == "1" ? true : false
                                    }
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                Steuerrecht II (Erbschaft-, Gewerbe-, Grundsteuer)
                                <br />
                                <input
                                    type="radio"
                                    name="wahlfach"
                                    value={"2"}
                                    style={{ marginRight: "15px" }}
                                    checked={
                                        zulassung.wahlfach == "2" ? true : false
                                    }
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                Insolvenzrecht
                                <br />
                                <input
                                    type="radio"
                                    name="wahlfach"
                                    value={"3"}
                                    style={{ marginRight: "15px" }}
                                    checked={
                                        zulassung.wahlfach == "3" ? true : false
                                    }
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                Grundzüge des Kapitalmarktrechts
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Erklärung über Verkürzung der Prüfung</h3>
                                <span className="formLabel">Ich möchte/ Ich bin ...*</span>
                                <input
                                    type="checkbox"
                                    name="verkuerzungEP.verkuerzung1"
                                    value={zulassung.verkuerzungEP.verkuerzung1}
                                    checked={zulassung.verkuerzungEP.verkuerzung1}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled1}
                                />{" "}
                                meine Prüfung nicht in verkürzter Form ablegen. 
                                <br />
                                <input
                                    type="checkbox"
                                    name="verkuerzungEP.verkuerzung2"
                                    value={zulassung.verkuerzungEP.verkuerzung2}
                                    checked={zulassung.verkuerzungEP.verkuerzung2}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled2}
                                />{" "}
                                Steuerberater/-in und will die Eignungsprüfung als Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche Prüfung in Steuerrecht I und mündliche Wahlfachprüfung in Steuerrecht II, § 13 WPO) ablegen.
                                <br />
                                <input
                                    type="checkbox"
                                    name="verkuerzungEP.verkuerzung3"
                                    value={zulassung.verkuerzungEP.verkuerzung3}
                                    checked={zulassung.verkuerzungEP.verkuerzung3}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled3}
                                />{" "}
                                Bewerber/-in, der/die die Steuerberaterprüfung bestanden hat, und will die Eignungsprüfung als Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche Prüfung in Steuerrecht I und mündliche Wahlfachprüfung in Steuerrecht II, § 13 WPO) ablegen.
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="verkuerzungEP.verkuerzung4"
                                    value={zulassung.verkuerzungEP.verkuerzung4}
                                    checked={zulassung.verkuerzungEP.verkuerzung4}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled4}
                                />{" "}
                                Bewerber/-in, der/die durch ein Prüfungszeugnis nachweisen kann, dass ich in meiner bisherigen Ausbildung in einem Prüfungsgebiet die für die Ausübung des Berufs in der Bundesrepublik Deutschland erforderlichen Kenntnisse in diesem Prüfungsgebiet erworben habe (§ 28 Abs. 2 WiPrPrüfV).
                                <br />
                                <input
                                    type="checkbox"
                                    name="verkuerzungEP.verkuerzung5"
                                    value={zulassung.verkuerzungEP.verkuerzung5}
                                    checked={zulassung.verkuerzungEP.verkuerzung5}
                                    style={{ marginRight: "15px" }}                                  
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled5}
                                />{" "}
                                Bewerber/-in, der/die nachweisen kann, dass ich durch Berufserfahrung einen wesentlichen Teil der Kenntnisse erworben habe, die durch die erlassenen Prüfungsleistungen gefordert werden (§ 28 Abs. 3 WiPrPrüfV). Hierzu lege ich geeignete Nachweise vor.
                                <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <input
                                    type="submit"
                                    value="Auswahl zurücksetzen"
                                    className="nonPrintable button"
                                    style={{ fontSize: 11 }}
                                    onClick={(e) => auswaehleZuruecksetzen(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <br />
                    </div>
                    <div className="form-Box-Hellblau">
                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Zu den Erklärungen lege ich geeignete Nachweise bei:</span>
                                <FileUpload files={wahlfach} setFiles={setWahlfach} />
                            </div>
                        </div>
                        <FileUploadList files={wahlfach} setFiles={setWahlfach} label={'Wahlfach'} />
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />
                            <input
                                type="submit"
                                value="Weiter"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const Absenden = ({
    nachweise,
    wahlfach,
    zeugnisse,
    zulassung, setZulassung, tipfehler, setTipfehler, user
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const d = useSelector((state) => state.zulassung.done);
    const e = useSelector((state) => state.zulassung.error);
    const [done, setDone] = useState(d)
    const [error, setError] = useState(e);
    const [versicherung1, setVersicherung1] = useState(false);
    const [versicherung2, setVersicherung2] = useState(false);
    const [versicherung3, setVersicherung3] = useState(false);
    const [fehler, setFehler] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [fehlerListe, setFehlerListe] = useState({});

    const scrollToTop = () => {
        window.scrollTo({
            top: 100,
            left: 0,
            behavior: "smooth",
        });
    }

    useEffect(() => {
        if (done) {
            if (error) {
                scrollToTop();
                setFehler(false)
                setShowSpinner(false);
                dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ABSENDEN));             
                toast.error("Der Zulassungsantrag wurde nicht erfolgreich abgeschickt");
            } else {
                setShowSpinner(false);
                dispatch({type : zulassungConstants.GET_DONE})
                dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ZULASSUNGSANTRAG));
                dispatch(eignungActions.checkEignung());
                toast.success("Ihr Zulassungsantrag wurde abgeschickt");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleChange = (e) => {
        if (e.target.name === "versicherung1") setVersicherung1(!versicherung1);
        else if (e.target.name === "versicherung2")
            setVersicherung2(!versicherung2);
        else setVersicherung3(!versicherung3);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let f = false;
        let fl = {};

        if (zulassung.lgs == "0") {
            fl = { ...fl, lgs: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.land == "") {
            fl = { ...fl, land: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.zulassungsblock === "") {
            fl = { ...fl, zulassungsblock: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.anrede === "") {
            fl = { ...fl, anrede: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.vorname === "") {
            fl = { ...fl, vorname: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.geburtsdatum === "") {
            fl = { ...fl, geburtsdatum: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.strasse === "") {
            fl = { ...fl, strasse: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.hausnummer === "") {
            fl = { ...fl, hausnummer: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.plz === "") {
            fl = { ...fl, plz: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        } else if (zulassung.kontaktdaten.plz.length != 5) {
            fl = { ...fl, plz: "X" };
            f = true;
        }

        if (zulassung.kontaktdaten.ort === "") {
            fl = { ...fl, ort: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.telefon === "") {
            fl = { ...fl, telefon: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.email === "") {
            fl = { ...fl, email: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.iban === "") {
            fl = { ...fl, iban: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        } else if (zulassung.kontaktdaten.iban.length < 15 || zulassung.kontaktdaten.iban.length > 34) {
            fl = { ...fl, iban: "X" };
            f = true;
        }

        if (zulassung.bishierigeZulassungenEP.art == "0") {
            fl = { ...fl, art: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (
            (zulassung.bishierigeZulassungenEP.art == "2" || zulassung.bishierigeZulassungenEP.art == "3") &&
            !zulassung.datumNichtBestanden1
        ) {
            fl = {
                ...fl,
                datumNichtBestanden1: "Das Pflichtfeld wurde nicht angegeben",
            };
            f = true;
        }

        if (
            zulassung.bishierigeZulassungenEP.art == "3" &&
            !zulassung.datumNichtBestanden2
        ) {
            fl = {
                ...fl,
                datumNichtBestanden2: "Das Pflichtfeld wurde nicht angegeben",
            };
            f = true;
        }

        if (!versicherung1) {
            fl = { ...fl, versicherung1: "Bitte bestätigen" };
            f = true;
        }

        if (!versicherung2) {
            fl = { ...fl, versicherung2: "Bitte bestätigen" };
            f = true;
        }

        if (!versicherung3) {
            fl = { ...fl, versicherung3: "Bitte bestätigen" };
            f = true;
        }

        if (f) {
            scrollToTop();
            toast.error(
                "Ihr Zulassungsantrag ist nicht korrekt oder nicht vollständig"
            );
            setFehlerListe(fl);
            setFehler(f);
        } else {
            setShowSpinner(true);
            dispatch(
                eignungActions.saveEignung(
                    zulassung,
                    nachweise,
                    wahlfach,
                    zeugnisse
                )
            );
            dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ZULASSUNGSANTRAG));
            if (user && user.art && user.art === 'WP') {
                dispatch(eignungActions.checkZulassung());
            }

            toast.success("Ihr Zulassungsantrag wurde abgeschickt");
            history.push("/examensportal/start");
        }
    };

    const handleBack = (e) => {
        dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.VERKUERZUNG))
    };



    if (!zulassung) return <div />;
    else {

        console.log("FEHLER", fehler, fehlerListe);

        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <Spinner show={showSpinner} />
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                        <p>
                            Alle Informationen zur Antragstellung werden hier in einem
                            Dokument zusammengefasst. Bitte prüfen Sie die Angaben erneut,
                            bevor Sie Ihren Antrag verbindlich absenden.
                        </p>
                        <p>
                            Sie können hier Ihr Einverständnis zur Datenerhebung und
                            Datenverarbeitung geben. Was mit Ihren Daten geschieht, können Sie
                            {" "}
                            <a href="https://www.wpk.de/datenschutz/" target="_blank">
                                hier
                            </a>{" "}nachlesen. Weitere Hinweise zum Datenschutz bei der WPK
                            finden Sie{" "}
                            <a href="https://www.wpk.de/datenschutz/" target="_blank">
                                hier
                            </a>{"."}
                        </p>

                        <p>
                            Ihre Antragstellung wird in der digitalen Akte durch
                            eine Eingangsbestätigung (PDF-Dokument) in der Mantelakte dokumentiert.
                        </p>
                        <p>
                            Mit dem Abschicken Ihres Zulassungsantrags entstehen die Zulassungs- und
                            die Prüfungsgebühr. Die Zulassungsgebühr beträgt 500 Euro.
                            Die Prüfungsgebühr beträgt 1.000 Euro.
                        </p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>
                            Zusammenfassung/Datenschutzerklärungen
                        </h2>

                        {!fehler ? null : (
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="pflichtfeld2">
                                        Ihr Zulassungsantrag ist nicht korrekt (X) oder nicht
                                        vollständig. Die fehlenden oder nicht korrekten Eintragungen
                                        sind rot markiert. Bitte korrigieren Sie Ihre Eintragungen
                                        und senden Sie den Zulassungsantrag erneut ab.{" "}
                                    </span>
                                </div>
                            </div>
                        )}

                        {!error ? null : (
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="pflichtfeld2">
                                        {error}
                                    </span>
                                </div>
                            </div>
                        )}
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Anmeldung Zulassungsantrag</b>
                        </h3>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Prüfungstermin:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.zulassungsblock !== ""
                                    ? util.changeFormatSemesterEignung(zulassung.zulassungsblock)
                                    : ""}
                                {fehlerListe.zulassungsblock ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.zulassungsblock}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Abschlussprüfungsqualifikation erworben in:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.land ? zulassung.land : ""}
                                {fehlerListe.land ? (
                                    <span className="pflichtfeld2">{fehlerListe.land}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Landesgeschäftsstelle:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                Berlin
                            </div>
                        </div>

                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin noch nie zur Eignungsprüfung zugelassen
                                    worden:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.bishierigeZulassungenEP.art == "1" ? "ja" : "nein"}
                                {fehlerListe.art ? (
                                    <span className="pflichtfeld2">{fehlerListe.art}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin bereits einmal zur Eignungsprüfung
                                    zugelassen worden und habe die Prüfung nicht bestanden:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.bishierigeZulassungenEP.art == "2" ? "ja" : "nein"}
                                {fehlerListe.art ? (
                                    <span className="pflichtfeld2">{fehlerListe.art}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin bereits zweimal zur Eignungsprüfung
                                    zugelassen worden und habe die Prüfung nicht bestanden:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.bishierigeZulassungenEP.art == "3" ? "ja" : "nein"}
                                {fehlerListe.art ? (
                                    <span className="pflichtfeld2">{fehlerListe.art}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>1. Prüfung nicht bestanden am:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {util.changeDateDEFormat(zulassung.bishierigeZulassungenEP.datumNichtBestanden1) === "DATUM" ? "" : util.changeDateDEFormat(zulassung.bishierigeZulassungenEP.datumNichtBestanden1)}
                                {fehlerListe.datumNichtBestanden1 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.datumNichtBestanden1}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>2. Prüfung nicht bestanden am:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {util.changeDateDEFormat(zulassung.bishierigeZulassungenEP.datumNichtBestanden2) === "DATUM" ? "" : util.changeDateDEFormat(zulassung.bishierigeZulassungenEP.datumNichtBestanden2)}
                                {fehlerListe.datumNichtBestanden2 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.datumNichtBestanden2}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <br />
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Kontaktdaten</b>
                        </h3>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Anrede:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.anrede}
                                {fehlerListe.anrede ? (
                                    <span className="pflichtfeld2">{fehlerListe.anrede}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Vorname:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.vorname}
                                {fehlerListe.vorname ? (
                                    <span className="pflichtfeld2">{fehlerListe.vorname}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Nachname:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.nachname}
                                {fehlerListe.nachname ? (
                                    <span className="pflichtfeld2">{fehlerListe.nachname}</span>
                                ) : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Weitere Vornamen:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.weitereVornamen}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Geburtsname:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.geburtsname}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Geburtsdatum:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {util.changeDateDEFormat(zulassung.kontaktdaten.geburtsdatum)}
                                {fehlerListe.geburtsdatum ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.geburtsdatum}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Geburtsort:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.geburtsort}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Staatsangehörigkeit:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.staatsangehoerigkeit}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Straße:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.strasse}
                                {fehlerListe.strasse ? (
                                    <span className="pflichtfeld2">{fehlerListe.strasse}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Hausnr:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.hausnummer}
                                {fehlerListe.hausnummer ? (
                                    <span className="pflichtfeld2">{fehlerListe.hausnummer}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Land:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.land}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>PLZ:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.plz}
                                {" "}
                                {fehlerListe.plz ? (
                                    <span className="pflichtfeld2">{fehlerListe.plz}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Ort:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.ort}
                                {fehlerListe.ort ? (
                                    <span className="pflichtfeld2">{fehlerListe.ort}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>E-Mail:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.email}
                                {fehlerListe.email ? (
                                    <span className="pflichtfeld2">{fehlerListe.email}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Telefon:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.telefon}
                                {fehlerListe.telefon ? (
                                    <span className="pflichtfeld2">{fehlerListe.telefon}</span>
                                ) : null}
                            </div>
                        </div>

                        {/*                         <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Fax:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.fax}
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>BIC:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.bic}
                                {" "}
                                {fehlerListe.bic ? (
                                    <span className="pflichtfeld2">{fehlerListe.bic}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>IBAN:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.iban}
                                {" "}
                                {fehlerListe.iban ? (
                                    <span className="pflichtfeld2">{fehlerListe.iban}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Behinderung:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.behinderung ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Aktueller Arbeitgeber:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.aktArbeitgeber}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Nachweise:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {nachweise
                                    ? nachweise.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>
                            
                        <br />
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Zulassungsantrag</b>
                        </h3>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Wahlfach:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.wahlfach == "1" ? "Steuerrecht II (Erbschaft-, Gewerbe-, Grundsteuer)" : ""}
                                {zulassung.wahlfach == "2" ? "Insolvenzrecht" : ""}
                                {zulassung.wahlfach == "3" ? "Grundzüge des Kapitalmarktrechts" : ""}
                            </div>
                        </div>

                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Zu den Erklärungen lege ich geeignete Nachweise bei.</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {wahlfach
                                    ? wahlfach.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>

                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                Ich möchte meine Prüfung nicht in verkürzter Form ablegen.
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzungEP.verkuerzung1 ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                Ich bin Steuerberater/-in und will die Eignungsprüfung als Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche Prüfung in Steuerrecht I und mündliche Wahlfachprüfung in Steuerrecht II, § 13 WPO) ablegen.
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzungEP.verkuerzung2 ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                Ich bin Bewerber/-in, der/die die Steuerberaterprüfung bestanden hat, und will die Eignungsprüfung als Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche Prüfung in Steuerrecht I und mündliche Wahlfachprüfung in Steuerrecht II, § 13 WPO) ablegen.
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzungEP.verkuerzung3 ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                Ich bin Bewerber/-in, der/die mit einen Prüfungszeugnis nachweisen kann, dass ich in meiner bisherigen Ausbildung in einem Prüfungsgebiet die für die Ausübung des Berufs in der Bundesrepublik Deutschland erforderlichen Kenntnisse in diesem Prüfungsgebiet erworben habe (§ 28 Abs. 2 WiPrPrüfV).
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzungEP.verkuerzung4 ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                Ich bin Bewerber/-in, der/die nachweisen kann, dass ich durch Berufserfahrung einen wesentlichen Teil der Kenntnisse erworben habe, die durch die erlassenen Prüfungsleistungen gefordert werden (§ 28 Abs. 3 WiPrPrüfV). Hierzu lege ich geeignete Nachweise vor.
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzungEP.verkuerzung5 ? "ja" : "nein"}
                            </div>
                        </div>

                        {/* <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Zeugnisse Hochschule / Urkunden Hochschule / Nachweise
                                    Regelstudienzeiten:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zeugnisse
                                    ? zeugnisse.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div> */}
                        
                        
                        <br />
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Kenntnisnahme, Datenschutz, Absenden</b>
                        </h3>
                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="versicherung1"
                                    style={{ marginRight: "15px" }}
                                    value={versicherung1}
                                    checked={versicherung1}
                                    onChange={(e) => handleChange(e)}
                                />
                                *{" "}
                                {fehlerListe.versicherung1 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.versicherung1}
                                    </span>
                                ) : null}
                                <p>
                                    Ich versichere, dass ich die Angaben in diesem Antrag richtig
                                    und vollständig gemacht habe und beigefügte Dokumente mit den
                                    Original-Dokumenten übereinstimmen.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="versicherung2"
                                    style={{ marginRight: "15px" }}
                                    value={versicherung2}
                                    checked={versicherung2}
                                    onChange={(e) => handleChange(e)}
                                />
                                *{" "}
                                {fehlerListe.versicherung2 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.versicherung2}
                                    </span>
                                ) : null}
                                <p>
                                    Mir ist bekannt, dass unrichtige und unvollständige Angaben zu
                                    einer Rücknahme der Zulassung zur Eignungsprüfung
                                    führen können. Die Rücknahme der Zulassung zur Prüfung hat
                                    auch die Rücknahme von Prüfungsentscheidungen zur Folge.
                                    Die Eignungsprüfung gilt in diesem Fall als nicht bestanden. Die Bestellung
                                    als Wirtschaftsprüfer oder als Wirtschaftsprüferin würde
                                    erlöschen.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="versicherung3"
                                    style={{ marginRight: "15px" }}
                                    value={versicherung3}
                                    checked={versicherung3}
                                    onChange={(e) => handleChange(e)}
                                />
                                *{" "}
                                {fehlerListe.versicherung3 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.versicherung3}
                                    </span>
                                ) : null}
                                <p>
                                    Hiermit gebe ich mein Einverständnis zur Datenerhebung und
                                    Datenverarbeitung gemäß den Vorgaben der WPK. Was mit Ihren
                                    Daten geschieht, können Sie{" "}
                                    <a href="https://www.wpk.de/datenschutz/" target="_blank">
                                        hier
                                    </a>{" "}
                                    nachlesen. Weitere Hinweise zum Datenschutz bei der WPK finden
                                    Sie{" "}
                                    <a href="https://www.wpk.de/datenschutz" target="_blank">
                                        hier
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />
                            <input
                                type="submit"
                                value="Abschicken"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const MenuRight = ({
    schritt,
    nachweise,
    wahlfach,
    zeugnisse,
    eignung
}) => {

    const dispatch = useDispatch();

    const zwischenspeichern = () => {
        dispatch(eignungActions.saveTempEignung(eignung, nachweise, wahlfach, zeugnisse))
        toast.info("Die Daten wurden zwischengespeichert.")
    }

    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide">
            <p className="menu_right_header">Zulassungsantrag Eignungsprüfung</p>
            <ul id="menu_right">
                <li style={schritt === 1 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ZULASSUNGSANTRAG))
                            zwischenspeichern()
                        }}
                        style={schritt === 1 ? { background: "#a0bcd1" } : {}}
                    >
                        Zulassungsantrag
                    </a>
                </li>
                <li style={schritt === 2 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.KONTAKTDATEN))
                            zwischenspeichern()
                        }}
                        style={schritt === 2 ? { background: "#a0bcd1" } : {}}
                    >
                        Persönliche Daten
                    </a>
                </li>
                <li style={schritt === 3 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.VERKUERZUNG))
                            zwischenspeichern()
                        }}
                        style={schritt === 3 ? { background: "#a0bcd1" } : {}}
                    >
                        Erklärung über Wahlfach und Verkürzung
                    </a>
                </li>
                <li style={schritt === 4 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ABSENDEN))
                            zwischenspeichern()
                        }}
                        style={schritt === 4 ? { background: "#a0bcd1" } : {}}
                    >
                        Kenntnisnahme, Datenschutz, Absenden
                    </a>
                </li>
            </ul>
            <br />
            <br />
        </div>
    );
}


export const EignungAntragStellen = ({ 
    nachweise,
    wahlfach,
    zeugnisse,
    setNachweise,
    setWahlfach,
    setZeugnisse,
    verkuerzungauswahl,
    setVerkuerzungauswahl
 }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authentication.user);
    const schritt = useSelector(state => state.rightmenuEignungAntrag);
    const z = useSelector(state => state.eignung.eignung);
    const [zulassung, setZulassung] = useState(z);
    const loading = useSelector((state) => state.eignung.loading);
    const [showSpinner, setShowSpinner] = useState(true);
    const t = useSelector(state => state.tipfehler.tipfehler);
    const [tipfehler, setTipfehler] = useState(t);

    useEffect(() => {
        dispatch(eignungActions.getTipfehler());
        dispatch(eignungActions.getVerkuerzungAuswahlEP());
    }, []);

    useEffect(() => {
        setZulassung(z);
    }, [z]);

    useEffect(() => {
        if (!loading) {
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [loading]);

    useEffect(() => {
        setTipfehler(t);
    }, [t]);

    //console.log("SCHRITT", schritt);
    //console.log("ZULASSUNG", zulassung);
    //console.log("LOADING", loading);

    if (!zulassung) return (<div/>);
    else return (loading ? <></> :
        <>
            {schritt === 1 && (
                <Eignunganmeldung                   
                    nachweise={nachweise}
                    wahlfach={wahlfach}
                    zeugnisse={zeugnisse}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                />
            )}
            {schritt === 2 && (
                <Kontaktdaten
                    setNachweise={setNachweise}
                    nachweise={nachweise}
                    wahlfach={wahlfach}
                    zeugnisse={zeugnisse}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                    tipfehler={tipfehler}
                    setTipfehler={setTipfehler}
                />
            )}
            {schritt === 3 && (
                <Verkuerzung
                setWahlfach={setWahlfach}
                setZeugnisse={setZeugnisse}
                nachweise={nachweise}
                wahlfach={wahlfach}
                zeugnisse={zeugnisse}
                    
                zulassung={zulassung}
                setZulassung={setZulassung}
                tipfehler={tipfehler}
                setTipfehler={setTipfehler}
                verkuerzungauswahl={verkuerzungauswahl}
                setVerkuerzungauswahl={setVerkuerzungauswahl}    
                />
            )}
            
            {schritt === 4 && (
                <Absenden
                    setNachweise={setNachweise}
                    setWahlfach={setWahlfach}
                    setZeugnisse={setZeugnisse}
                    nachweise={nachweise}
                    wahlfach={wahlfach}
                    zeugnisse={zeugnisse}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                    tipfehler={tipfehler}
                    setTipfehler={setTipfehler}
                    user={user}
                />
            )}

            {schritt === 1 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        nachweise={nachweise}
                        wahlfach={wahlfach}
                        zeugnisse={zeugnisse}
                        eignung={zulassung}
                    />
                )}
            {schritt === 2 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        nachweise={nachweise}
                        wahlfach={wahlfach}
                        zeugnisse={zeugnisse}
                        eignung={zulassung}
                    />
                )}
            {schritt === 3 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        nachweise={nachweise}
                        wahlfach={wahlfach}
                        zeugnisse={zeugnisse}
                        eignung={zulassung}
                    />
                )}
            
            {schritt === 4 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        nachweise={nachweise}
                        wahlfach={wahlfach}
                        zeugnisse={zeugnisse}
                        eignung={zulassung}
                    />
                )}
        </>
    );

};