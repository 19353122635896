import { zulassungsblockConstants } from "../constants/zulassungsblock.constants";


export function zulassungsblock(state = {}, action) {
    switch (action.type) {
        case zulassungsblockConstants.GET_REQUEST:
            return {
                zulassungsblock: "",
                done: false,
                error: undefined
            };
        case zulassungsblockConstants.GET_SUCCESS:
            return {
                zulassungsblock : action.payload.data,
                done: true,
                error: undefined,
            };
        case zulassungsblockConstants.GET_FAILURE:
            return {
                zulassungsblock: "",
                done: true,
                error: action.payload,
            };
        default:
            return state;
    }
}