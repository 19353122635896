import { eignungConstants, tipfehlerConstants, verkuerzungauswahlConstants } from '../constants';
import { getErrorMessage } from '../helpers';
import { eignungService } from '../services';

export const eignungActions = {
    getEignung,
    saveEignung,
    saveTempEignung,
    checkEignung,
    getTipfehler,
    getVerkuerzungAuswahlEP
};

function getEignung() {

    return dispatch => {
        dispatch({ type: eignungConstants.GET_REQUEST });

        eignungService.getEignung()
        .then(res => {
                dispatch({ type: eignungConstants.GET_SUCCESS, payload: res })
                dispatch({type : eignungConstants.GET_DONE})
            })
            .catch(error => {
                dispatch({ type: eignungConstants.GET_FAILURE, payload: getErrorMessage(error) })
                dispatch({type : eignungConstants.GET_FAILURE_DONE})
            });
    };
}

function saveEignung(zulassung, nachweise, wahlfach, zeugnisse) {
    return dispatch => {
        dispatch({ type: eignungConstants.SAVE_REQUEST });
        eignungService.saveEignung(zulassung, nachweise, wahlfach, zeugnisse)
            .then(res => {
                dispatch({ type: eignungConstants.SAVE_SUCCESS, payload: res });

            })
            .catch(error => {
                dispatch({ type: eignungConstants.SAVE_FAILURE, payload: [getErrorMessage(error), zulassung] })
            });
    };
}

function saveTempEignung(zulassung, nachweise, wahlfach, zeugnisse) {
    return dispatch => {
        dispatch({ type: eignungConstants.SAVE_REQUEST });
        eignungService.saveTempEignung(zulassung, nachweise, wahlfach, zeugnisse)
            .then(res => {
                dispatch({ type: eignungConstants.SAVE_TEMP_SUCCESS, payload: res });
            })
            .catch(error => {
                dispatch({ type: eignungConstants.SAVE_TEMP_FAILURE, payload: [getErrorMessage(error), zulassung] })
            });
    };
}

function checkEignung() {

    return dispatch => {
        eignungService.checkEignung()
            .then(res => {
                dispatch({ type: eignungConstants.CHECK, payload: !res.data.zugelassen })
            })
            .catch(res => dispatch({ type: eignungConstants.GET_FAILURE, payload: res }));
    };
}

function getTipfehler() {

    return dispatch => {
        dispatch({ type: tipfehlerConstants.GET_REQUEST });
    };
}

function getVerkuerzungAuswahlEP() {

    return dispatch => {
        dispatch({ type: verkuerzungauswahlConstants.GET_REQUEST_FOR_EP });
    };
}
