import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { zulassungActions, rightMenuActions } from '../../actions';
import { zulassungConstants, rightmenuZulassungsAntragConstants } from '../../constants';
import { Spinner } from "../spinner/Spinner";
import { util, zulassungService } from '../../services';
import { ModulNochNichtAnwendbar } from '../HinweisTexte';
import { Laenderkuerzel } from './Laenderkuerzel';
import FileUpload from '../fileupload/fileupload';
import FileUploadList from '../fileupload/fileuploadlist';

const Modulanmeldung = ({ lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen,
    zulassung, setZulassung}) => {
    
    const user = useSelector((state) => state.authentication.user);
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = useState(false);

    const handleChange = (e) => {
        if (e.target.type === "radio") {
            let v = false;
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }

            setZulassung({ ...zulassung, [e.target.name]: v });

            if (e.target.name == "vorzeitigeZulassung") {
                setZulassung({ ...zulassung, wp: false, wr: false, bwl: false, st: false});
            }
        } else {
            setZulassung({ ...zulassung, [e.target.name]: e.target.value });
        }

        if (e.target.type === "checkbox") {
            let v = false;
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }

            if (e.target.name == "wp") {
                setZulassung({ ...zulassung, wpVorgezogen: false, [e.target.name]: v });

            }else if (e.target.name == "wpVorgezogen") {
                setZulassung({ ...zulassung, wp: false, [e.target.name]: v });

            }else if (e.target.name == "st") {
                setZulassung({ ...zulassung, stVorgezogen: false, [e.target.name]: v });

            }else if (e.target.name == "stVorgezogen") {
                setZulassung({ ...zulassung, st: false, [e.target.name]: v });

            }else if (e.target.name == "wr") {
                setZulassung({ ...zulassung, wrVorgezogen: false, [e.target.name]: v });

            }else if (e.target.name == "wrVorgezogen") {
                setZulassung({ ...zulassung, wr: false, [e.target.name]: v });

            }else if (e.target.name == "bwl") {
                setZulassung({ ...zulassung, bwlVorgezogen: false, [e.target.name]: v });

            }else if (e.target.name == "bwlVorgezogen") {
                setZulassung({ ...zulassung, bwl: false, [e.target.name]: v });
            }else{
                setZulassung({ ...zulassung, [e.target.name]: v });
            }

            
        } else {

            if (e.target.name == "vorzeitigeZulassung" && e.target.value == "6") {

                setZulassung({
                    ...zulassung,
                    [e.target.name]: e.target.value,
                    vorbildung: {
                        ...zulassung.vorbildung,
                        hochschulstudium: true,
                    },
                    verkuerzung: {
                        ...zulassung.verkuerzung,
                        par8a: true,
                    }
                });

            } else {
                setZulassung({ ...zulassung, [e.target.name]: e.target.value });
            }
        }
    };

    const zweiterPruefungsblock = (z) => {
        return z.zulassungsblock.split("/")[1] === "2";
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.KONTAKTDATEN))
        // setSchritt(2);
    };

    const ModulanmeldungHinweisText = () => {
        return (
            <div className="hinweisText" style={{ marginBottom: '20px' }}>
                <p>
                    Sie können hier Ihre Zulassung zum Wirtschaftsprüfungsexamen
                    beantragen. Legen Sie bitte die Landesgeschäftsstelle fest,
                    bei der Sie den Antrag stellen wollen.
                </p>
                <p><b>Vermerken Sie bitte,</b> wenn Sie</p>
                <ul>
                    <li>
                        eine vorgezogene Zulassung gemäß § 9 Abs. 7 WPO beantragen wollen.
                    </li>
                    <li>
                        ein Masterstudium nach § 8a WPO abgeschlossen haben; wenn Sie in diesem Fall die für die Zulassung erforderliche Tätigkeit und Prüfungstätigkeit noch nicht vollständig nachweisen können und gemäß § 9 Abs. 6 WPO zugelassen werden möchten, können Sie das unter "Praktische Vorbildung - Prüfungstätigkeit" angeben.
                    </li>
                </ul>
                <p>
                    Sie müssen mindestens eine Modulprüfung auswählen, mit der Sie beginnen wollen.
                </p>
                <p>
                    Sie können sich aber auch direkt zu mehreren Modulprüfungen anmelden.
                </p>
            </div>
        );
    }

    console.log(zulassung)


    if (!zulassung) {
        return (
            <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                <ModulanmeldungHinweisText />
                <ModulNochNichtAnwendbar />
            </div>
        );
    }
    else {
        if (zulassung.zwischegespeichert !== 0) {
            return (
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <ModulanmeldungHinweisText />
                    <ModulNochNichtAnwendbar />
                </div>
            );
        } else if (zulassung.zwischegespeichert === 0) {
            return (
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Spinner show={showSpinner} />
                    <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">

                        <ModulanmeldungHinweisText />

                        <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                            <h2 style={{ paddingTop: "25px" }}>
                                Zulassungsantrag und Modulanmeldung
                            </h2>

                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Prüfungstermin*</span>
                                    <div>{util.changeFormatSemester(zulassung.zulassungsblock)}</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Landesgeschäftsstelle*</span>
                                    {user.art === 'WP' ?
                                        <select name="lgs" onChange={(e) => handleChange(e)}value={zulassung.lgs}>
                                            <option value="0">bitte wählen...</option>
                                            <option value="1">Berlin</option>
                                            <option value="3">Düsseldorf</option>
                                            <option value="4">Frankfurt</option>
                                            <option value="2">Hamburg</option>
                                            <option value="5">München</option>
                                            <option value="6">Stuttgart</option>
                                        </select> : <span>'Berlin'</span>
                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="formLabel">Vorgezogene Zulassung/Teilnahme und Verkürzung der Prüfung nach § 8a WPO</span>
                                    <input
                                        type="radio"
                                        name="vorzeitigeZulassung"
                                        value={"0"}
                                        checked={
                                            zulassung.vorzeitigeZulassung == "0" ? true : false
                                        }
                                        style={{ marginRight: "15px" }}
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    Ohne vorgezogene Zulassung/Teilnahme <br />
                                    <input
                                        type="radio"
                                        name="vorzeitigeZulassung"
                                        value={"7"}
                                        checked={
                                            zulassung.vorzeitigeZulassung == "7" ? true : false
                                        }
                                        style={{ marginRight: "15px", marginTop: "5px" }}
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    Vorgezogene Zulassung/Teilnahme (§ 9 Abs. 7 WPO) <br />
                                    <input
                                        type="radio"
                                        name="vorzeitigeZulassung"
                                        value={"6"}
                                        checked={
                                            zulassung.vorzeitigeZulassung == "6" ? true : false
                                        }
                                        style={{ marginRight: "15px" }}
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    Ich habe ein Masterstudium nach § 8a WPO abgeschlossen <br />
                                </div>
                            </div>
                            

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                    <span className="formLabel">Modulprüfungsanmeldungen*</span>
                                </div>
                            </div>
                            {zweiterPruefungsblock(zulassung) ?
                            <div style={{padding:'0 0 20px 0'}}>
                                <div className="row">
                                    <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input
                                            type="checkbox"
                                            name="wpVorgezogen"
                                            style={{ marginRight: "15px" }}
                                            value={zulassung.wp ? false : zulassung.wpVorgezogen}
                                            checked={zulassung.wp ? false : zulassung.wpVorgezogen}
                                            onChange={(e) => handleChange(e)}
                                            disabled={zulassung.vorzeitigeZulassung == "7" ? true : false}
                                        />{" "}
                                        Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht {zweiterPruefungsblock(zulassung) ? "(Juni " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input
                                            type="checkbox"
                                            name="wrVorgezogen"
                                            style={{ marginRight: "15px" }}
                                            value={zulassung.wr ? false : zulassung.wrVorgezogen}
                                            checked={zulassung.wr ? false : zulassung.wrVorgezogen}
                                            onChange={(e) => handleChange(e)}
                                            disabled={zulassung.vorzeitigeZulassung == "6" ? true : false}
                                        />{" "}
                                        Wirtschaftsrecht {zweiterPruefungsblock(zulassung) ? "(Juni " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input
                                            type="checkbox"
                                            name="bwlVorgezogen"
                                            style={{ marginRight: "15px" }}
                                            value={zulassung.bwlVorgezogen}
                                            checked={zulassung.bwlVorgezogen}
                                            onChange={(e) => handleChange(e)}
                                            disabled={zulassung.vorzeitigeZulassung == "6" ? true : false}
                                        />{" "}
                                        Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre {zweiterPruefungsblock(zulassung) ? "(Juni " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <input
                                            type="checkbox"
                                            name="stVorgezogen"
                                            style={{ marginRight: "15px" }}
                                            value={zulassung.st ? false : zulassung.stVorgezogen}
                                            checked={zulassung.st ? false : zulassung.stVorgezogen}
                                            onChange={(e) => handleChange(e)}
                                        />{" "}
                                        Steuerrecht {zweiterPruefungsblock(zulassung) ? "(Juni " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                    </div>
                                </div>
                            </div>
                            : null}
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input
                                        type="checkbox"
                                        name="wp"
                                        style={{ marginRight: "15px" }}
                                        value={zulassung.wp}
                                        checked={zulassung.wp}
                                        onChange={(e) => handleChange(e)}
                                        disabled={zulassung.vorzeitigeZulassung == "7" ? true : false}
                                    />{" "}
                                    Wirtschaftliches Prüfungswesen, Unternehmensbewertung und Berufsrecht {zweiterPruefungsblock(zulassung) ? "(August " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input
                                        type="checkbox"
                                        name="wr"
                                        style={{ marginRight: "15px" }}
                                        value={zulassung.wr}
                                        checked={zulassung.wr}
                                        onChange={(e) => handleChange(e)}
                                        disabled={zulassung.vorzeitigeZulassung == "6" ? true : false}
                                    />{" "}
                                    Wirtschaftsrecht {zweiterPruefungsblock(zulassung) ? "(August " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input
                                        type="checkbox"
                                        name="bwl"
                                        style={{ marginRight: "15px" }}
                                        value={zulassung.bwl}
                                        checked={zulassung.bwl}
                                        onChange={(e) => handleChange(e)}
                                        disabled={zulassung.vorzeitigeZulassung == "6" ? true : false}
                                    />{" "}
                                    Angewandte Betriebswirtschaftslehre, Volkswirtschaftslehre {zweiterPruefungsblock(zulassung) ? "(August " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <input
                                        type="checkbox"
                                        name="st"
                                        style={{ marginRight: "15px" }}
                                        value={zulassung.st}
                                        checked={zulassung.st}
                                        onChange={(e) => handleChange(e)}
                                    />{" "}
                                    Steuerrecht {zweiterPruefungsblock(zulassung) ? "(August " + util.getJahr(zulassung.zulassungsblock) + ")" : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span style={{ color: "#b94a48" }} className="formLabel">
                                        * Pflichtfelder
                                    </span>
                                </div>
                                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12"></div>
                            </div>

                            <br />
                        </div>

                        <div
                            className="row"
                            style={{ marginTop: "10px", marginBottom: "20px" }}
                        >
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="submit"
                                    value="Weiter"
                                    className="nonPrintable button"
                                    onClick={(e) => handleSubmit(e)}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            );
        } else{
            return (
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <ModulanmeldungHinweisText />
                    <ModulNochNichtAnwendbar />
                </div>
            );
        }
    }
};

const Kontaktdaten = ({ setAttest, setLebenslauf, lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen, zulassung, setZulassung, tipfehler, setTipfehler }) => {
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("kontaktdaten") > -1) {
            setZulassung({ ...zulassung, kontaktdaten: { ...zulassung.kontaktdaten, [e.target.name.split(".")[1]]: v } })
        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.BISHERIGE_ZULASSUNGSANTRAEGE))
        //setSchritt(3);
    };

    const handleBack = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.MODULANMELDUNG))
        //setSchritt(1);
    };

    const feldPruefen = (e) => {

        setTipfehler({ ...tipfehler, ["error"]: "" })

        if (e.target.name === "kontaktdaten.plz") {
            if (zulassung.kontaktdaten.plz && !zulassung.kontaktdaten.plz.match(/^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/)) {
                //console.log("Schreib meinen Fehler")
                setTipfehler({ ...tipfehler, ["error"]: "PLZ ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.email") {
            if (zulassung.kontaktdaten.email && !zulassung.kontaktdaten.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setTipfehler({ ...tipfehler, ["error"]: "Email ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.telefon") {
            if (zulassung.kontaktdaten.telefon && !zulassung.kontaktdaten.telefon.match(/\b[\+]?[(]?[0-9]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{3,15}\b/m)) {
                setTipfehler({ ...tipfehler, ["error"]: "Telefon ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.bic") {
            if (zulassung.kontaktdaten.bic && !zulassung.kontaktdaten.bic.match(/([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/g)) {
                setTipfehler({ ...tipfehler, ["error"]: "BIC ist nicht korrekt" })
            }
        } else if (e.target.name === "kontaktdaten.iban") {
            if (zulassung.kontaktdaten.iban && !zulassung.kontaktdaten.iban.match(/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/)) {
                setTipfehler({ ...tipfehler, ["error"]: "IBAN ist nicht korrekt" })
            }
        }
    };

    if (!zulassung || !tipfehler) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                        <p>
                            Bitte ergänzen Sie hier Ihre persönlichen Daten und Ihre Kontaktdaten und
                            geben weitere für das Zulassungs- und Prüfungsverfahren benötigte Daten an.
                            Dazu gehört auch der Upload Ihres aktuellen und vollständigen Lebenslaufs.
                        </p>
                        <p>
                            Wenn Sie wegen einer Behinderung einen Nachteilsausgleich beantragen wollen
                            (§ 7 Abs. 2 Wirtschaftsprüferprüfungsverordnung),
                            können Sie Ihren Antrag und den entsprechenden Nachweis,
                            zum Beispiel ein amtsärztliches Attest, hier hochladen.
                        </p>
                        <p>
                            Bitte laden Sie die dazugehörigen Nachweise, Bescheinigungen, Urkunden, Erklärungen
                            oder Atteste hier hoch (max. 30MB, Dateitypen *.jpg, *.jpeg, *.png, *.gif, *.pdf).
                        </p>
                        <p>
                            Sie können entweder nur eine, oder aber gleichzeitig mehrere Dateien hochladen.
                        </p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>Persönliche Daten und Kontaktdaten</h2>
                        <p style={{ color: "rgb(185, 74, 72)" }}>{tipfehler.error}</p>
                        <div className="row">
                            <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                <span className="formLabel">Anrede*</span>
                                <select
                                    name="kontaktdaten.anrede"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.anrede}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value=""></option>
                                    <option value="Frau">Frau</option>
                                    <option value="Herr">Herr</option>
                                    <option value="KeineAnrede">Keine Anrede</option>
                                </select>
                            </div>
                            <div className="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                <span className="formLabel">Vorname*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.vorname"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.vorname}
                                    className="inputText100percent"
                                    onChange={(e) => { handleChange(e) }}
                                />
                            </div>
                            <div className="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                <span className="formLabel">Nachname*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.nachname"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.nachname}
                                    className="inputText100percent"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Weitere Vornamen</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.weitereVornamen"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.weitereVornamen}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Geburtsname</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.geburtsname"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.geburtsname}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Geburtsdatum*</span>
                                <input
                                    id="kontaktdaten.geburtsdatum"
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    name="kontaktdaten.geburtsdatum"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.geburtsdatum}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}

                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Geburtsort*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.geburtsort"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.geburtsort}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Aktueller Arbeitgeber</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.aktArbeitgeber"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.aktArbeitgeber}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Lebenslauf</span>
                                <FileUpload files={lebenslauf} setFiles={setLebenslauf} />
                            </div>
                        </div>
                        <FileUploadList files={lebenslauf} setFiles={setLebenslauf} label={'Lebensläufe'} />

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Nachteilsausgleich</h3>
                                <input
                                    style={{ marginRight: "15px" }}
                                    type="checkbox"
                                    name="kontaktdaten.behinderung"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.behinderung}
                                    checked={zulassung.kontaktdaten.behinderung}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                <span style={{ color: "#353535", fontWeight: "bold" }}>Behinderung</span>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: 10}}>
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Attest/Nachweis</span>
                                <FileUpload files={attest} setFiles={setAttest} />
                            </div>
                        </div>
                        <FileUploadList files={attest} setFiles={setAttest} label={'Atteste'} />

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Wohnort</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Straße*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.strasse"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.strasse}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Hausnr.*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.hausnummer"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.hausnummer}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">Land*</span>
                                <Laenderkuerzel
                                    name="kontaktdaten.land"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.land}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                            <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                <span className="formLabel">PLZ*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.plz"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.plz}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                <span className="formLabel">Ort*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.ort"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.ort}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Verbindungsdaten</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 col-xs-4 col-sm-4 col-md-6 col-lg-6">
                                <span className="formLabel">E-Mail</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.email"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.email}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                    readOnly
                                />
                            </div>
                            <div className="col-4 col-xs-4 col-sm-4 col-md-6 col-lg-6">
                                <span className="formLabel">Telefon*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.telefon"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.telefon}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Bankverbindung</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">BIC</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.bic"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.bic}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <span className="formLabel">IBAN*</span>
                                <input
                                    type="text"
                                    name="kontaktdaten.iban"
                                    autoComplete="off"
                                    value={zulassung.kontaktdaten.iban}
                                    className="inputText100percent"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => feldPruefen(e)}
                                />
                            </div>
                        </div>

                        {/*<div className="row">
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div> */}

                        {/*<div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <h3>Dokumente/Anlagen</h3>
                            </div>
                        </div> */}

                        {/*<div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Lebenslauf</span>
                                <FileUpload files={lebenslauf} setFiles={setLebenslauf} />
                            </div>
                        </div>
                        <FileUploadList files={lebenslauf} setFiles={setLebenslauf} label={'Lebensläufe'} /> */}

                        <div className="row" style={{marginTop: 20}}>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <br />
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />

                            <input
                                type="submit"
                                value="Weiter"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const BisherigeZulassungsantraege = ({ lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen, zulassung, setZulassung, tipfehler, setTipfehler }) => {
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let v = e.target.value;

        // reset datums 
        if (v == 1) {
            zulassung.bishierigeZulassungen.datumNichtBestanden1 = ""; zulassung.bishierigeZulassungen.datumNichtBestanden2 = "";
        } else if (v == 2) {
            zulassung.bishierigeZulassungen.datumNichtBestanden2 = "";
        } else if (v == 3) {
            zulassung.bishierigeZulassungen.datumNichtBestanden1 = "";
        }

        if (e.target.name.indexOf("bishierigeZulassungen") > -1) {
            setZulassung({
                ...zulassung,
                bishierigeZulassungen: {
                    ...zulassung.bishierigeZulassungen,
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.AKADEMISCHE_VORBILDUNG))
        // setSchritt(4);
    };

    const handleBack = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen

            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.KONTAKTDATEN))
        //setSchritt(2);
    };

    if (!zulassung) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                        <p>
                            Sie können hier eine Erklärung abgeben,  ob Sie bereits zum Wirtschaftsprüfungsexamen
                            zugelassen waren (§ 1 Abs. 1 Satz 2 Nr. 3 Wirtschaftsprüferprüfungsverordnung).
                        </p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>
                            Erklärung über bisherige Zulassungsanträge
                        </h2>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Ich bin...*</span>
                                <input
                                    type="radio"
                                    name="bishierigeZulassungen.art"
                                    value={"1"}
                                    style={{ marginRight: "15px" }}
                                    checked={
                                        zulassung.bishierigeZulassungen.art == "1" ? true : false
                                    }
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                noch nie zum Wirtschaftsprüfungsexamen zugelassen worden <br />
                                <input
                                    type="radio"
                                    name="bishierigeZulassungen.art"
                                    value={"2"}
                                    style={{ marginRight: "15px" }}
                                    checked={
                                        zulassung.bishierigeZulassungen.art == "2" ? true : false
                                    }
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                bereits einmal zum Wirtschaftsprüfungsexamen zugelassen worden
                                und habe die Prüfung nicht bestanden
                                <br />
                                <input
                                    type="radio"
                                    name="bishierigeZulassungen.art"
                                    value={"3"}
                                    style={{ marginRight: "15px" }}
                                    checked={
                                        zulassung.bishierigeZulassungen.art == "3" ? true : false
                                    }
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                bereits zweimal zum Wirtschaftsprüfungsexamen zugelassen worden
                                und habe die Prüfung nicht bestanden
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">1. Prüfung nicht bestanden am</span>
                                <input
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    name="bishierigeZulassungen.datumNichtBestanden1"
                                    className="inputText100percent"
                                    value={zulassung.bishierigeZulassungen.datumNichtBestanden1
                                        ? util.changeDateENGFormat(zulassung.bishierigeZulassungen.datumNichtBestanden1)
                                        : ""
                                    }
                                    onChange={(e) => handleChange(e)}
                                    disabled={(zulassung.bishierigeZulassungen.art != "2" && zulassung.bishierigeZulassungen.art != "3") ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">2. Prüfung nicht bestanden am</span>
                                <input
                                    type="date"
                                    pattern="\d{4}-\d{2}-\d{2}"
                                    name="bishierigeZulassungen.datumNichtBestanden2"
                                    className="inputText100percent"
                                    value={zulassung.bishierigeZulassungen.datumNichtBestanden2
                                        ? util.changeDateENGFormat(zulassung.bishierigeZulassungen.datumNichtBestanden2)
                                        : ""
                                    }
                                    onChange={(e) => handleChange(e)}
                                    disabled={(zulassung.bishierigeZulassungen.art != "3") ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <br />
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />
                            <input
                                type="submit"
                                value="Weiter"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const AkademischeVorbildung = ({
    setZeugnisse,
    setAgbescheinigungen,
    setStbescheinigungen,
    lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen, zulassung, setZulassung, tipfehler, setTipfehler
}) => {
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("vorbildung") > -1) {
            setZulassung({
                ...zulassung,
                vorbildung: {
                    ...zulassung.vorbildung,
                    [e.target.name.split(".")[1]]: v,
                },
            });

            if ([e.target.name.split(".")[1]] == "hochschulstudium" && v == false) {
                setZeugnisse([]);
            }

            if ([e.target.name.split(".")[1]] == "zehnjaehrigeTaetigkeit" && v == false) {
                setAgbescheinigungen([]);
            }

            if ([e.target.name.split(".")[1]] == "fuenfjaehrigeBerufserfahrung" && v == false) {
                setStbescheinigungen([]);
            }

        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        //console.log(zulassung);
        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.PRAKTISCHE_VORBILDUNG))
        // setSchritt(5);
    };

    const handleBack = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        //console.log(zulassung);
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.BISHERIGE_ZULASSUNGSANTRAEGE))
        toast.info("Die Daten wurden zwischengespeichert.");
        // setSchritt(3);
    };

    const handleZeugnisse = (e) => {
        if (e.target.files) {
            const ar = [];
            for (let i = 0; i < e.target.files.length; i++) {
                ar.push(e.target.files[i]);
            }

            setZeugnisse(ar);
        }
    };

    const handleAgbescheinigungen = (e) => {
        if (e.target.files) {
            const ar = [];
            for (let i = 0; i < e.target.files.length; i++) {
                ar.push(e.target.files[i]);
            }

            setAgbescheinigungen(ar);
        }
    };

    const handleStbescheinigungen = (e) => {
        if (e.target.files) {
            const ar = [];
            for (let i = 0; i < e.target.files.length; i++) {
                ar.push(e.target.files[i]);
            }

            setStbescheinigungen(ar);
        }
    };

    if (!zulassung) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                        <p>
                            Sie können hier eine Erklärung über Ihre Vorbildung (§ 8 WPO)
                            abgeben und die dafür nötigen Nachweise hochladen.
                        </p>
                        <p>
                            Bitte laden Sie Zeugnisse und Urkunden für jedes von Ihnen abgeschlossene
                            Hochschulstudium hoch.
                        </p>

                        <p>Sie müssen eine der drei Möglichkeiten zur Vorbildung auswählen.</p>

                        <p>Wenn das auf Sie zutrifft, können Sie auch mehr als eine Möglichkeit auswählen. Laden Sie dann bitte für alle ausgewählten Möglichkeiten die dafür nötigen Nachweise hoch.</p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>
                            Erklärung zur Vorbildung*
                        </h2>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Ich habe ...</span>
                                <input
                                    type="checkbox"
                                    name="vorbildung.hochschulstudium"
                                    value={zulassung.vorbildung.hochschulstudium}
                                    checked={zulassung.vorbildung.hochschulstudium}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                (mindestens) ein Hochschulstudium mit Erfolg abgeschlossen{" "}
                                <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Zeugnisse Hochschule / Urkunden Hochschule / Nachweise Regelstudienzeiten</span>
                                <FileUpload files={zeugnisse} setFiles={setZeugnisse} disabled={!zulassung.vorbildung.hochschulstudium} />
                            </div>
                        </div>
                        <FileUploadList files={zeugnisse} setFiles={setZeugnisse} label={'Zeugnisse'} />

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Ich habe ...</span>
                                <input
                                    type="checkbox"
                                    name="vorbildung.zehnjaehrigeTaetigkeit"
                                    value={zulassung.vorbildung.zehnjaehrigeTaetigkeit}
                                    checked={zulassung.vorbildung.zehnjaehrigeTaetigkeit}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                mich in einer mindestens zehnjährigen Tätigkeit bei einer der in
                                § 8 Abs. 2 Nr. 1 WPO genannten Stellen bewährt <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Arbeitgeberbescheinigung(en)</span>
                                <FileUpload files={agbescheinigungen} setFiles={setAgbescheinigungen} disabled={!zulassung.vorbildung.zehnjaehrigeTaetigkeit} />
                            </div>
                        </div>
                        <FileUploadList files={agbescheinigungen} setFiles={setAgbescheinigungen} label={'Arbeitgeberbescheinigungen'} />

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Ich habe ...</span>
                                <input
                                    type="checkbox"
                                    name="vorbildung.fuenfjaehrigeBerufserfahrung"
                                    value={zulassung.vorbildung.fuenfjaehrigeBerufserfahrung}
                                    style={{ marginRight: "15px" }}
                                    checked={zulassung.vorbildung.fuenfjaehrigeBerufserfahrung}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                mindestens fünf Jahre den Beruf <br />
                                <select
                                    name="vorbildung.fuenfjaehrigeBerufserfahrungArt"
                                    value={zulassung.vorbildung.fuenfjaehrigeBerufserfahrungArt}
                                    onChange={(e) => handleChange(e)}
                                    disabled={!zulassung.vorbildung.fuenfjaehrigeBerufserfahrung}
                                >
                                    <option value="0">bitte wählen...</option>
                                    <option value="1">
                                        als vereidigter Buchprüfer oder vereidigte Buchprüferin
                                    </option>
                                    <option value="2">
                                        als Steuerberater oder Steuerberaterin
                                    </option>
                                </select>
                                <div>ausgeübt.</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Bescheinigung der zuständigen Steuerberaterkammer(n)</span>
                                <FileUpload files={stbescheinigungen} setFiles={setStbescheinigungen} disabled={!zulassung.vorbildung.fuenfjaehrigeBerufserfahrung} />
                            </div>
                        </div>
                        <FileUploadList files={stbescheinigungen} setFiles={setStbescheinigungen} label={'Bescheinigungen'} />

                        <div className="row">
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <br />
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />
                            <input
                                type="submit"
                                value="Weiter"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const PraktischeVorbildung = ({
    setAgbescheinigungen2,
    setStbescheinigungen2,
    lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen, zulassung, setZulassung
}) => {
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let v = e.target.value;

        if (e.target.name.indexOf("taetigkeit") > -1) {
            setZulassung({
                ...zulassung,
                taetigkeit: {
                    ...zulassung.taetigkeit,
                    taetigkeit: e.target.name === 'taetigkeit' ?  v : '',
                    berufserfahrung: e.target.name === 'berufserfahrung' ?  v : '',
                    [e.target.name.split(".")[1]]: v,
                },
            });
        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        //console.log(zulassung);
        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.PRUEFUNGSVERUERZUNG))
        //setSchritt(6);
    };

    const handleBack = (e) => {
        e.preventDefault();

        //console.log(zulassung);
        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.AKADEMISCHE_VORBILDUNG))
        //setSchritt(4);
    };

    if (!zulassung) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                        <p>
                            Sie können hier eine Erklärung über Ihre praktische Ausbildung (Tätigkeit)
                            und Prüfungstätigkeit oder zum Wegfall des Nachweises der Tätigkeit
                            und Prüfungstätigkeit (§ 9 WPO) abgeben und die dafür nötigen Nachweise hochladen.
                        </p>
                        <p>
                            Ein Muster für einen Arbeitgebernachweis finden Sie <a target="_blank" rel="noreferrer" href="https://www.wpk.de/fileadmin/documents/Nachwuchs/Pruefungsstelle/Vordruck-Muster.pdf#page=10">hier</a>.
                        </p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>
                            Erklärung zur Tätigkeit und Prüfungstätigkeit
                        </h2>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Ich kann ...</span>
                                <input
                                    type="radio"
                                    name="taetigkeit.taetigkeit"
                                    value={"1"}
                                    checked={
                                        zulassung.taetigkeit.taetigkeit == "1" ? true : false
                                    }
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                Tätigkeit und Prüfungstätigkeit vollständig nachweisen. <br />
                                <input
                                    type="radio"
                                    name="taetigkeit.taetigkeit"
                                    value={"2"}
                                    checked={
                                        zulassung.taetigkeit.taetigkeit == "2" ? true : false
                                    }
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                Tätigkeit und Prüfungstätigkeit noch nicht vollständig nachweisen,
                                habe aber ein Masterstudium nach § 8a WPO abgeschlossen (§ 9 Abs. 6 WPO). <br />
                                <input
                                    type="radio"
                                    name="taetigkeit.taetigkeit"
                                    value={"3"}
                                    checked={
                                        zulassung.taetigkeit.taetigkeit == "3" ? true : false
                                    }
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                mindestens sechs Monate Tätigkeit bei einer der in § 8 Abs. 2
                                Nr. 1 WPO genannten Stellen für die vorzeitige Teilnahme an der
                                Prüfung (§ 9 Abs. 7 WPO) nachweisen. <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Arbeitgebernachweis(e) Berufstätigkeit / Arbeitgebernachweis(e) Prüfungstätigkeiten</span>
                                <FileUpload files={agbescheinigungen2} setFiles={setAgbescheinigungen2} />
                            </div>
                        </div>
                        <FileUploadList files={agbescheinigungen2} setFiles={setAgbescheinigungen2} label={'Bescheinigungen'} />

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Ich versichere, dass ich seit mindestens 15 Jahren den Beruf als ...</span>
                                <input
                                    type="radio"
                                    name="taetigkeit.berufserfahrung"
                                    value={"1"}
                                    checked={
                                        zulassung.taetigkeit.berufserfahrung == "1" ? true : false
                                    }
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                Steuerberater oder Steuerberaterin oder <br />
                                <input
                                    type="radio"
                                    name="taetigkeit.berufserfahrung"
                                    value={"2"}
                                    checked={
                                        zulassung.taetigkeit.berufserfahrung == "2" ? true : false
                                    }
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                />{" "}
                                vereidigter Buchprüfer oder vereidigte Buchprüferin
                                <br />
                                ausübe; dabei können bis zu zehn Jahre Berufstätigkeit als Steuerbevollmächtigter oder
                                Steuerbevollmächtigte angerechnet werden.<br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Bescheinigung der zuständigen Steuerberaterkammer(n)</span>
                                <FileUpload files={stbescheinigungen2} setFiles={setStbescheinigungen2} />
                            </div>
                        </div>
                        <FileUploadList files={stbescheinigungen2} setFiles={setStbescheinigungen2} label={'Bescheinigungen'} />

                        <div className="row">
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <span style={{ color: "#b94a48" }} className="formLabel">
                                    * Pflichtfelder
                                </span>
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <br />
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />
                            <input
                                type="submit"
                                value="Weiter"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const PruefungsVerkuerzung = ({ lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,verkuerzungbescheinigungen, setVerkuerzungbescheinigungen, zulassung, setZulassung, tipfehler, setTipfehler, setVerkuerzungauswahl, verkuerzungauswahl }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (zulassung.verkuerzung.vollPruefung) {
            setVerkuerzungauswahl({ disabled1: false, disabled2: true, disabled3: true, disabled4: true, disabled5: true, disabled6: true });
        } else if (zulassung.verkuerzung.par8a) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: false, disabled3: false, disabled4: false, disabled5: true, disabled6: true });
        } else if (zulassung.verkuerzung.par8a && zulassung.verkuerzung.par131) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: false, disabled3: false, disabled4: true, disabled5: true, disabled6: true });
        } else if (zulassung.verkuerzung.par8a && zulassung.verkuerzung.par132) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: false, disabled3: true, disabled4: false, disabled5: true, disabled6: true });
        } else if (zulassung.verkuerzung.par131 && !zulassung.verkuerzung.par8a) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: false, disabled4: true, disabled5: false, disabled6: false });
        } else if (zulassung.verkuerzung.par132 && !zulassung.verkuerzung.par8a) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: true, disabled4: false, disabled5: false, disabled6: false });
        } else if (zulassung.verkuerzung.par13b1 && !zulassung.verkuerzung.par131 && !zulassung.verkuerzung.par132) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: true, disabled4: true, disabled5: false, disabled6: false });
        } else if (zulassung.verkuerzung.par13b2 && !zulassung.verkuerzung.par131 && !zulassung.verkuerzung.par132 && !zulassung.verkuerzung.par13b1) {
            setVerkuerzungauswahl({ disabled1: true, disabled2: true, disabled3: true, disabled4: true, disabled5: true, disabled6: false });
        } else {
            setVerkuerzungauswahl({ disabled1: false, disabled2: false, disabled3: false, disabled4: false, disabled5: false, disabled6: false });
        }



    }, []);

    const handleChange = (e) => {

        let v = false;
        if (e.target.type === "checkbox" || e.target.type === "radio") {
            if (e.target.value === "false" || e.target.value === false) {
                v = true;
            }
        } else {
            v = e.target.value;
        }

        if (e.target.name.indexOf("verkuerzung") > -1) {
            setZulassung({
                ...zulassung,
                verkuerzung: {
                    ...zulassung.verkuerzung,
                    [e.target.name.split(".")[1]]: v,
                },
            });

            FelderDeaktivieren(e.target.name, v);

        } else {
            setZulassung({ ...zulassung, [e.target.name]: v });
        }
    };

    const FelderDeaktivieren = (feld, v) => {
        //Disabling Auswahl von Verkuerzung
        // Fuer Vollpruefung
        if (feld.split(".")[1] === "vollPruefung" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: true, ["disabled3"]: true, ["disabled4"]: true, ["disabled5"]: true, ["disabled6"]: true })
        } else if (feld.split(".")[1] === "vollPruefung" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false, ["disabled6"]: false })
        }

        // Fuer par8a
        if (feld.split(".")[1] === "par8a" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: true, ["disabled6"]: true })
        } else if (feld.split(".")[1] === "par8a" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false, ["disabled6"]: false })
            setZulassung({ ...zulassung, verkuerzung: { ...zulassung.verkuerzung, ["par8a"]: false, ["par131"]: false, ["par132"]: false, }, })
        } else if (zulassung.verkuerzung.par8a && feld.split(".")[1] === "par131" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: true, ["disabled5"]: true, ["disabled6"]: true })
        } else if (zulassung.verkuerzung.par8a && feld.split(".")[1] === "par131" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: true, ["disabled6"]: true })
        } else if (zulassung.verkuerzung.par8a && feld.split(".")[1] === "par132" && v === true) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: true, ["disabled4"]: false, ["disabled5"]: true, ["disabled6"]: true })
        } else if (zulassung.verkuerzung.par8a && feld.split(".")[1] === "par132" && v === false) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: true, ["disabled6"]: true })
        }

        // Fuer par131
        if (feld.split(".")[1] === "par131" && v === true && !zulassung.verkuerzung.par8a) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: false, ["disabled4"]: true, ["disabled5"]: false, ["disabled6"]: false })
        } else if (feld.split(".")[1] === "par131" && v === false && !zulassung.verkuerzung.par8a) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false, ["disabled6"]: false })
            setZulassung({ ...zulassung, verkuerzung: { ...zulassung.verkuerzung, ["par131"]: false, ["par13b1"]: false, ["par13b2"]: false, }, })
        }

        // Fuer par132
        if (feld.split(".")[1] === "par132" && v === true && !zulassung.verkuerzung.par8a) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: true, ["disabled4"]: false, ["disabled5"]: false, ["disabled6"]: false })
        } else if (feld.split(".")[1] === "par132" && v === false && !zulassung.verkuerzung.par8a) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false, ["disabled6"]: false })
            setZulassung({ ...zulassung, verkuerzung: { ...zulassung.verkuerzung, ["par132"]: false, ["par13b1"]: false, ["par13b2"]: false, }, })
        }

        // Fuer par13b1
        if (feld.split(".")[1] === "par13b1" && v === true && !zulassung.verkuerzung.par131 && !zulassung.verkuerzung.par132) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: true, ["disabled4"]: true, ["disabled5"]: false, ["disabled6"]: false })
        } else if (feld.split(".")[1] === "par13b1" && v === false && !zulassung.verkuerzung.par131 && !zulassung.verkuerzung.par132) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false, ["disabled6"]: false })
            setZulassung({ ...zulassung, verkuerzung: { ...zulassung.verkuerzung, ["par13b1"]: false, ["par13b2"]: false, }, })
        }

        // Fuer par13b2
        if (feld.split(".")[1] === "par13b2" && v === true && !zulassung.verkuerzung.par131 && !zulassung.verkuerzung.par132 && !zulassung.verkuerzung.par13b1) {
            setVerkuerzungauswahl({ ["disabled1"]: true, ["disabled2"]: true, ["disabled3"]: true, ["disabled4"]: true, ["disabled5"]: true, ["disabled6"]: false })
        } else if (feld.split(".")[1] === "par13b2" && v === false && !zulassung.verkuerzung.par131 && !zulassung.verkuerzung.par132 && !zulassung.verkuerzung.par13b1) {
            setVerkuerzungauswahl({ ["disabled1"]: false, ["disabled2"]: false, ["disabled3"]: false, ["disabled4"]: false, ["disabled5"]: false, ["disabled6"]: false })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.ABSENDEN))
        // setSchritt(7);
    };

    const handleBack = (e) => {
        e.preventDefault();

        dispatch(
            zulassungActions.saveTempZulassung(
                zulassung,
                lebenslauf,
                attest,
                zeugnisse,
                agbescheinigungen,
                stbescheinigungen,
                agbescheinigungen2,
                stbescheinigungen2,
                verkuerzungbescheinigungen
            )
        );
        toast.info("Die Daten wurden zwischengespeichert.");
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.PRAKTISCHE_VORBILDUNG))
        // setSchritt(5);
    };

    const auswaehleZuruecksetzen = (e) => {
        e.preventDefault();

        setVerkuerzungauswahl({ disabled1: false, disabled2: false, disabled3: false, disabled4: false, disabled5: false, disabled6: false });
        setZulassung({ ...zulassung, verkuerzung: { ...zulassung.verkuerzung, ["vollPruefung"]: false, ["par8a"]: false, ["par131"]: false, ["par132"]: false, ["par13b1"]: false, ["par13b2"]: false } })
    }

    if (!zulassung || !verkuerzungauswahl) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                        <p>
                            Sie können hier eine Erklärung abgeben, ob Sie die Prüfung in verkürzter Form ablegen möchten
                            (§ 1 Abs. 1 Satz 2 Nr. 8 WiPrPrüfV, §§ 8a, 13 und 13b WPO), und die dafür nötigen Nachweise hochladen.
                        </p>
                        <p>
                            Wenn Sie die Voraussetzungen für die Verkürzung der Prüfung jetzt noch nicht erfüllen,
                            können Sie die Erklärung über die Verkürzung der Prüfung - zum Beispiel nach Bestehen
                            der Steuerberaterprüfung - auch noch nach Ihrer Zulassung zur Prüfung abgeben (§ 1 Abs. 4 WiPrPrüfV).
                            Nutzen Sie hierfür dann in dem Menü "Modulprüfung" die Funktion "Anrechnung".
                        </p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>
                            Erklärung über Verkürzung der Prüfung
                        </h2>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">
                                    Ich möchte/ich habe/ich bin ...*
                                </span>
                                <input
                                    type="checkbox"
                                    name="verkuerzung.vollPruefung"
                                    value={zulassung.verkuerzung.vollPruefung}
                                    checked={zulassung.verkuerzung.vollPruefung}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled1}
                                />{" "}
                                meine Prüfung nicht in verkürzter Form ablegen. <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="verkuerzung.par8a"
                                    value={zulassung.verkuerzung.par8a}
                                    checked={zulassung.verkuerzung.par8a}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled2}
                                />{" "}
                                einen als zur Ausbildung von Wirtschaftsprüfern/-innen besonders
                                geeigneten Masterstudiengang mit Erfolg abgeschlossen und will
                                die Prüfung als Wirtschaftsprüfer/-in in verkürzter Form (ohne
                                schriftliche und mündliche Prüfung in Angewandter
                                Betriebswirtschaftslehre, Volkswirtschaftslehre und
                                Wirtschaftsrecht, § 8a WPO) ablegen. <br />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="verkuerzung.par131"
                                    value={zulassung.verkuerzung.par131}
                                    checked={zulassung.verkuerzung.par131}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled3}
                                />{" "}
                                Steuerberater/-in und will die Prüfung als Wirtschaftsprüfer/-in
                                in verkürzter Form (ohne schriftliche und mündliche Prüfung im
                                Steuerrecht, § 13 WPO) ablegen. <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="verkuerzung.par132"
                                    value={zulassung.verkuerzung.par132}
                                    checked={zulassung.verkuerzung.par132}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled4}
                                />{" "}
                                Bewerber/-in, der/die die Steuerberater-Prüfung bestanden hat,
                                und will die Prüfung als Wirtschaftsprüfer/-in in verkürzter
                                Form (ohne schriftliche und mündliche Prüfung im Steuerrecht, §
                                13 WPO) ablegen. <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="verkuerzung.par13b1"
                                    value={zulassung.verkuerzung.par13b1}
                                    checked={zulassung.verkuerzung.par13b1}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled5}
                                />{" "}
                                im Rahmen einer Hochschulausbildung gleichwertige
                                Prüfungsleistungen erbracht und will die Prüfung als
                                Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche und
                                mündliche Prüfung in Angewandter Betriebswirtschaftslehre,
                                Volkswirtschaftslehre § 13b WPO) ablegen. <br />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="verkuerzung.par13b2"
                                    value={zulassung.verkuerzung.par13b2}
                                    checked={zulassung.verkuerzung.par13b2}
                                    style={{ marginRight: "15px" }}
                                    onChange={(e) => handleChange(e)}
                                    disabled={verkuerzungauswahl.disabled6}
                                />{" "}
                                im Rahmen einer Hochschulausbildung gleichwertige
                                Prüfungsleistungen erbracht und will die Prüfung als
                                Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche und
                                mündliche Prüfung im Wirtschaftsrecht § 13b WPO) ablegen. <br />
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 20}}>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <input
                                    type="submit"
                                    value="Auswahl zurücksetzen"
                                    className="nonPrintable button"
                                    onClick={(e) => auswaehleZuruecksetzen(e)}
                                />
                            </div>
                            <div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
                        </div>

                        <div className="row">
                            <div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span className="formLabel">Nachweise zur Verkürzung der Prüfung</span>
                                <FileUpload files={verkuerzungbescheinigungen} setFiles={setVerkuerzungbescheinigungen} />
                            </div>
                        </div>
                        <FileUploadList files={verkuerzungbescheinigungen} setFiles={setVerkuerzungbescheinigungen} label={'Bescheinigungen'} />
                        <br />
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />
                            <input
                                type="submit"
                                value="Weiter"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const Absenden = ({
    lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen, zulassung, setZulassung, tipfehler, setTipfehler,
    zusatzinformationen, setZusatzinformationen
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const d = useSelector((state) => state.zulassung.done);
    const e = useSelector((state) => state.zulassung.error);
    const [done, setDone] = useState(d)
    const [error, setError] = useState(e);
    const [versicherung1, setVersicherung1] = useState(false);
    const [versicherung2, setVersicherung2] = useState(false);
    const [versicherung3, setVersicherung3] = useState(false);
    const [fehler, setFehler] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [zulassungPB, setZulassungPB] = useState(false);
    const [fehlerListe, setFehlerListe] = useState({});

    const scrollToTop = () => {
        window.scrollTo({
            top: 100,
            left: 0,
            behavior: "smooth",
        });
    }

    const zweiterPruefungsblock = (z) => {
        return z.zulassungsblock.split("/")[1] === "2";
    }

    useEffect(() => {
        if (done) {
            if (error) {
                scrollToTop();
                setFehler(false)
                setShowSpinner(false);
                dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.ABSENDEN));             
                toast.error("Der Zulassungsantrag wurde nicht erfolgreich abgeschickt.");
            } else {
                setShowSpinner(false);
                dispatch({type : zulassungConstants.GET_DONE})
                dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.MODULANMELDUNG));
                dispatch(zulassungActions.checkZulassung());
                toast.success("Ihr Zulassungsantrag wurde abgeschickt.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

    const handleChange = (e) => {
        if (e.target.name === "versicherung1") setVersicherung1(!versicherung1);
        else if (e.target.name === "versicherung2")
            setVersicherung2(!versicherung2);
        else setVersicherung3(!versicherung3);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let f = false;
        let fl = {};

        if (zulassung.lgs == "0") {
            fl = { ...fl, lgs: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.zulassungsblock === "") {
            fl = { ...fl, zulassungsblock: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (!zulassung.wp && !zulassung.wr && !zulassung.bwl && !zulassung.st && !zulassung.wpVorgezogen && !zulassung.wrVorgezogen && !zulassung.bwlVorgezogen && !zulassung.stVorgezogen) {
            fl = { ...fl, modul: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.anrede === "") {
            fl = { ...fl, anrede: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.vorname === "") {
            fl = { ...fl, vorname: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.geburtsdatum === "") {
            fl = { ...fl, geburtsdatum: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.strasse === "") {
            fl = { ...fl, strasse: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.hausnummer === "") {
            fl = { ...fl, hausnummer: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.plz === "") {
            fl = { ...fl, plz: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        } else if (zulassung.kontaktdaten.plz.length != 5) {
            fl = { ...fl, plz: "X" };
            f = true;
        }

        if (zulassung.kontaktdaten.ort === "") {
            fl = { ...fl, ort: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.telefon === "") {
            fl = { ...fl, telefon: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.email === "") {
            fl = { ...fl, email: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (zulassung.kontaktdaten.iban === "") {
            fl = { ...fl, iban: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        } else if (zulassung.kontaktdaten.iban.length < 15 || zulassung.kontaktdaten.iban.length > 34) {
            fl = { ...fl, iban: "X" };
            f = true;
        }

        if (zulassung.bishierigeZulassungen.art == "0") {
            fl = { ...fl, art: "Das Pflichtfeld wurde nicht angegeben" };
            f = true;
        }

        if (
            zulassung.bishierigeZulassungen.art == "2" &&
            !zulassung.bishierigeZulassungen.datumNichtBestanden1
        ) {
            fl = {
                ...fl,
                datumNichtBestanden1: "Das Pflichtfeld wurde nicht angegeben",
            };
            f = true;
        }

        if (
            zulassung.bishierigeZulassungen.art == "3" &&
            !zulassung.bishierigeZulassungen.datumNichtBestanden2
        ) {
            fl = {
                ...fl,
                datumNichtBestanden2: "Das Pflichtfeld wurde nicht angegeben",
            };
            f = true;
        }
        /*         if (zulassung.verkuerzung.art == "0") {
                    fl = { ...fl, verkuerzung: "Das Pflichtfeld wurde nicht angegeben" };
                    f = true;
                } */

        if (!versicherung1) {
            fl = { ...fl, versicherung1: "Bitte bestätigen" };
            f = true;
        }

        if (!versicherung2) {
            fl = { ...fl, versicherung2: "Bitte bestätigen" };
            f = true;
        }

        if (!versicherung3) {
            fl = { ...fl, versicherung3: "Bitte bestätigen" };
            f = true;
        }




        const res = await zulassungService.checkPruefungsblock();
        const pb = res.data;

        if (f) {
            scrollToTop();
            toast.error(
                "Ihr Zulassungsantrag ist nicht korrekt oder nicht vollständig"
            );
            setFehlerListe(fl);
            setFehler(f);
        } else if(!f && zulassung.zulassungsblock !== pb.zulassungsblock){
            scrollToTop();
            setFehler(false);
            setZulassungPB(true);
            setZulassung({...zulassung, zulassungsblock: pb.zulassungsblock});
        } else {
            setShowSpinner(true);
            dispatch(
                zulassungActions.saveZulassung(
                    {...zulassung, zusatzinformationen},
                    lebenslauf,
                    attest,
                    zeugnisse,
                    agbescheinigungen,
                    stbescheinigungen,
                    agbescheinigungen2,
                    stbescheinigungen2,
                    verkuerzungbescheinigungen
                )
            );
            // toast.success("Ihr Zulassungsantrag wurde abgeschickt");
            // history.push("/examensportal/start");
                    
            // dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.MODULANMELDUNG));
            // dispatch(zulassungActions.checkZulassung());
        }
    };

    const handleBack = (e) => {
        dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.PRUEFUNGSVERUERZUNG))
        // setSchritt(6);
    };



    if (!zulassung) return <div />;
    else {
        return (
            <form onSubmit={(e) => handleSubmit(e)}>
                <Spinner show={showSpinner} />
                <div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
                    <div className="hinweisText">
                        <p>
                            Alle Informationen zur Antragstellung werden hier in einem
                            Dokument zusammengefasst. Bitte prüfen Sie die Angaben erneut,
                            bevor Sie Ihren Antrag verbindlich absenden.
                        </p>
                        <p>
                            Sie können hier Ihr Einverständnis zur Datenerhebung und
                            Datenverarbeitung geben. Was mit Ihren Daten geschieht, können Sie
                            {" "}
                            <a href="https://www.wpk.de/datenschutz/" target="_blank">
                                hier
                            </a>{" "}nachlesen. Weitere Hinweise zum Datenschutz bei der WPK
                            finden Sie{" "}
                            <a href="https://www.wpk.de/datenschutz/" target="_blank">
                                hier
                            </a>{"."}
                        </p>

                        <p>
                            Ihre Antragstellung wird in der digitalen Akte durch
                            eine Eingangsbestätigung (PDF-Dokument) in der Mantelakte dokumentiert.
                        </p>
                        <p>
                            Mit dem Abschicken Ihres Zulassungsantrags entstehen die Zulassungs- und
                            die Prüfungsgebühr. Die Zulassungsgebühr beträgt 500 Euro.
                            Für jede von Ihnen anzufertigende Klausur beträgt die Prüfungsgebühr auch 500 Euro.
                        </p>
                    </div>

                    <div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
                        <h2 style={{ paddingTop: "25px" }}>
                            Zusammenfassung/Datenschutzerklärungen
                        </h2>

                        {!fehler ? null : (
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="pflichtfeld2">
                                        Ihr Zulassungsantrag ist nicht korrekt (X) oder nicht
                                        vollständig. Die fehlenden oder nicht korrekten Eintragungen
                                        sind rot markiert. Bitte korrigieren Sie Ihre Eintragungen
                                        und senden Sie den Zulassungsantrag erneut ab.{" "}
                                    </span>
                                </div>
                            </div>
                        )}

                        {!error ? null : (
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="pflichtfeld2">
                                        {error}
                                    </span>
                                </div>
                            </div>
                        )}

                        {!zulassungPB ? null : (
                            <div className="row">
                                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span className="pflichtfeld2">
                                        <b>
                                            <p>Der Antragszeitraum für den von Ihnen gewünschten Prüfungstermin ist beendet!</p>
                                            <p>Sie können aber einen Zulassungsantrag für den folgenden Prüfungstermin stellen. Wenn Sie jetzt Ihre Zulassung für diesen Prüfungstermin beantragen möchten, müssen Sie Ihren Zulassungsantrag noch einmal abschicken.</p>
                                        </b>
                                    </span>
                                </div>
                            </div>
                        )}
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Anmeldung Zulassungsantrag und Modul</b>
                        </h3>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Prüfungstermin:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.zulassungsblock !== ""
                                    ? util.changeFormatSemester(zulassung.zulassungsblock)
                                    : ""}
                                {fehlerListe.zulassungsblock ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.zulassungsblock}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Landesgeschäftsstelle:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.lgs == "1" ? "Berlin" : ""}
                                {zulassung.lgs == "3" ? "Düsseldorf" : ""}
                                {zulassung.lgs == "4" ? "Frankfurt" : ""}
                                {zulassung.lgs == "2" ? "Hamburg" : ""}
                                {zulassung.lgs == "5" ? "München" : ""}
                                {zulassung.lgs == "6" ? "Stuttgart" : ""}
                                {fehlerListe.lgs ? (
                                    <span className="pflichtfeld2">{fehlerListe.lgs}</span>
                                ) : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ohne vorgezogene Zulassung/Teilnahme <br />
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.vorzeitigeZulassung == 0 ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Vorgezogene Zulassung/Teilnahme <br />
                                    [§ 9 Abs. 6 WPO]:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.vorzeitigeZulassung == 6 ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Vorgezogene Zulassung/Teilnahme <br />
                                    [§ 9 Abs. 7 WPO]:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.vorzeitigeZulassung == 7 ? "ja" : "nein"}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Modulprüfungsanmeldungen:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {fehlerListe.modul ? (
                                    <span className="pflichtfeld2">{fehlerListe.modul}</span>
                                ) : null}
                            </div>
                        </div>
                        {zweiterPruefungsblock(zulassung) ?
                        <div style={{padding:'0 0 20px 0'}}>
                            <div className="row">
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <b>WPW (Juni):</b>
                                </div>
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    {zulassung.wpVorgezogen ? "ja" : "nein"}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <b>WR (Juni):</b>
                                </div>
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    {zulassung.wrVorgezogen ? "ja" : "nein"}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <b>ABWL, VWL (Juni):</b>
                                </div>
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    {zulassung.bwlVorgezogen ? "ja" : "nein"}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    <b>StR (Juni):</b>
                                </div>
                                <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                    {zulassung.stVorgezogen ? "ja" : "nein"}
                                </div>
                            </div>
                        </div>
                        : null}
                        
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>WPW (August):</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.wp ? "ja" : "nein"}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>WR (August):</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.wr ? "ja" : "nein"}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>ABWL, VWL (August):</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.bwl ? "ja" : "nein"}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>StR (August):</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.st ? "ja" : "nein"}
                            </div>
                        </div>

                        <br />
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Kontaktdaten</b>
                        </h3>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Anrede:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.anrede}
                                {fehlerListe.anrede ? (
                                    <span className="pflichtfeld2">{fehlerListe.anrede}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Vorname:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.vorname}
                                {fehlerListe.vorname ? (
                                    <span className="pflichtfeld2">{fehlerListe.vorname}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Nachname:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.nachname}
                                {fehlerListe.nachname ? (
                                    <span className="pflichtfeld2">{fehlerListe.nachname}</span>
                                ) : null}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Weitere Vornamen:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.weitereVornamen}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Geburtsname:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.geburtsname}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Geburtsdatum:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {util.changeDateDEFormat(zulassung.kontaktdaten.geburtsdatum)}
                                {fehlerListe.geburtsdatum ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.geburtsdatum}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Geburtsort:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.geburtsort}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Straße:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.strasse}
                                {fehlerListe.strasse ? (
                                    <span className="pflichtfeld2">{fehlerListe.strasse}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Hausnr:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.hausnummer}
                                {fehlerListe.hausnummer ? (
                                    <span className="pflichtfeld2">{fehlerListe.hausnummer}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Land:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.land}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>PLZ:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.plz}
                                {" "}
                                {fehlerListe.plz ? (
                                    <span className="pflichtfeld2">{fehlerListe.plz}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Ort:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.ort}
                                {fehlerListe.ort ? (
                                    <span className="pflichtfeld2">{fehlerListe.ort}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>E-Mail:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.email}
                                {fehlerListe.email ? (
                                    <span className="pflichtfeld2">{fehlerListe.email}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Telefon:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.telefon}
                                {fehlerListe.telefon ? (
                                    <span className="pflichtfeld2">{fehlerListe.telefon}</span>
                                ) : null}
                            </div>
                        </div>

                        {/*                         <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Fax:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.fax}
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>BIC:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.bic}
                                {" "}
                                {fehlerListe.bic ? (
                                    <span className="pflichtfeld2">{fehlerListe.bic}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>IBAN:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.iban}
                                {" "}
                                {fehlerListe.iban ? (
                                    <span className="pflichtfeld2">{fehlerListe.iban}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Behinderung:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.behinderung ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Attest/Nachweis:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {attest
                                    ? attest.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Aktueller Arbeitgeber:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.kontaktdaten.aktArbeitgeber}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Lebenslauf:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {lebenslauf
                                    ? lebenslauf.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>

                        {/* <br /> */}
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Bisherige Zulassungsanträge</b>
                        </h3>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin noch nie zum Wirtschaftsprüfungsexamen zugelassen
                                    worden:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.bishierigeZulassungen.art == "1" ? "ja" : ""}
                                {fehlerListe.art ? (
                                    <span className="pflichtfeld2">{fehlerListe.art}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin bereits einmal zum Wirtschaftsprüfungsexamen
                                    zugelassen worden und habe die Prüfung nicht bestanden:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.bishierigeZulassungen.art == "2" ? "ja" : ""}
                                {fehlerListe.art ? (
                                    <span className="pflichtfeld2">{fehlerListe.art}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin bereits zweimal zum Wirtschaftsprüfungsexamen
                                    zugelassen worden und habe die Prüfung nicht bestanden:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.bishierigeZulassungen.art == "3" ? "ja" : ""}
                                {fehlerListe.art ? (
                                    <span className="pflichtfeld2">{fehlerListe.art}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>1. Prüfung nicht bestanden am:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {util.changeDateDEFormat(zulassung.bishierigeZulassungen.datumNichtBestanden1) === "DATUM" ? "" : util.changeDateDEFormat(zulassung.bishierigeZulassungen.datumNichtBestanden1)}
                                {fehlerListe.datumNichtBestanden1 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.datumNichtBestanden1}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>2. Prüfung nicht bestanden am:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {util.changeDateDEFormat(zulassung.bishierigeZulassungen.datumNichtBestanden2) === "DATUM" ? "" : util.changeDateDEFormat(zulassung.bishierigeZulassungen.datumNichtBestanden2)}
                                {fehlerListe.datumNichtBestanden2 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.datumNichtBestanden2}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <br />
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Akademische Vorbildung</b>
                        </h3>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich habe (mindestens) ein Hochschulstudium mit Erfolg
                                    abgeschlossen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.vorbildung.hochschulstudium ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Zeugnisse Hochschule / Urkunden Hochschule / Nachweise
                                    Regelstudienzeiten:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zeugnisse
                                    ? zeugnisse.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich habe mich in einer mindestens zehnjährigen Tätigkeit bei
                                    einer der in § 8 Abs. 2 Nr. 1 WPO genannten Stellen bewährt:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.vorbildung.zehnjaehrigeTaetigkeit ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Arbeitgeberbescheinigung(en):</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {agbescheinigungen
                                    ? agbescheinigungen.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Ich habe mindestens fünf Jahre den Beruf ausgeübt:</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.vorbildung.fuenfjaehrigeBerufserfahrungArt == "1"
                                    ? "als vereidigter Buchprüfer oder vereidigte Buchprüferin"
                                    : ""}
                                {zulassung.vorbildung.fuenfjaehrigeBerufserfahrungArt == "2"
                                    ? "als Steuerberater oder Steuerberaterin"
                                    : ""}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Bescheinigung der zuständigen Steuerberaterkammer(n):</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {stbescheinigungen
                                    ? stbescheinigungen.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>

                        <br />
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Praktische Vorbildung - Prüfungstätigkeit</b>
                        </h3>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich kann Tätigkeit und Prüfungstätigkeit vollständig
                                    nachweisen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.taetigkeit.taetigkeit == "1" ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich kann Tätigkeit und Prüfungstätigkeit noch nicht vollständig nachweisen,
                                    habe aber ein Masterstudium nach §8a WPO abgeschlossen (§ 9 Abs. 6 WPO):
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.taetigkeit.taetigkeit == "2" ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich kann mindestens sechs Monate Tätigkeit bei einer der in §
                                    8 Abs. 2 Nr. 1 WPO genannten Stellen für die vorzeitige
                                    Teilnahme an der Prüfung (§ 9 Abs. 7 WPO) nachweisen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.taetigkeit.taetigkeit == "3" ? "ja" : "nein"}
                            </div>
                        </div>

                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Arbeitgebernachweis(e) Berufstätigkeit /
                                    Arbeitgebernachweis(e) Prüfungstätigkeiten:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {agbescheinigungen2
                                    ? agbescheinigungen2.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich versichere, dass ich seit mindestens 15 Jahren den Beruf
                                    als Steuerberater oder Steuerberaterin:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.taetigkeit.berufserfahrung == "1" ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich versichere, dass ich seit mindestens 15 Jahren als vereidigter Buchprüfer oder
                                    vereidigte Buchprüferin ausgeübt habe; dabei können bis zu zehn Jahre Berufstätigkeit
                                    als Steuerbevollmächtigter oder Steuerbevollmächtigte angerechnet werden:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.taetigkeit.berufserfahrung == "2" ? "ja" : "nein"}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>Bescheinigung der zuständigen Steuerberaterkammer(n):</b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {stbescheinigungen2
                                    ? stbescheinigungen2.map((f) => {
                                        return <div>{f.name}</div>;
                                    })
                                    : null}
                            </div>
                        </div>

                        <br />
                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Prüfungsverkürzung</b>
                        </h3>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich möchte meine Prüfungen nicht in verkürzter Form ablegen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzung.vollPruefung ? "ja" : ""}
                                {fehlerListe.verkuerzung ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.verkuerzung}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich habe einen als zur Ausbildung von
                                    Wirtschaftsprüfern/-innen besonders geeigneten
                                    Masterstudiengang mit Erfolg abgeschlossen und will die
                                    Prüfung als Wirtschaftsprüfer/-in in verkürzter Form (ohne
                                    schriftliche und mündliche Prüfung in Angewandter
                                    Betriebswirtschaftslehre, Volkswirtschaftslehre und
                                    Wirtschaftsrecht, § 8a WPO) ablegen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzung.par8a ? "ja" : ""}
                                {fehlerListe.verkuerzung ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.verkuerzung}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin Steuerberater/-in und will die Prüfung als
                                    Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche
                                    und mündliche Prüfung im Steuerrecht, § 13 WPO) ablegen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzung.par131 ? "ja" : ""}
                                {fehlerListe.verkuerzung ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.verkuerzung}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich bin Bewerber/-in, der/die die Steuerberater-Prüfung
                                    bestanden hat, und will die Prüfung als Wirtschaftsprüfer/-in
                                    in verkürzter Form (ohne schriftliche und mündliche Prüfung im
                                    Steuerrecht, § 13 WPO) ablegen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzung.par132 ? "ja" : ""}
                                {fehlerListe.verkuerzung ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.verkuerzung}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row abstand10">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich habe im Rahmen einer Hochschulausbildung gleichwertige
                                    Prüfungsleistungen erbracht und will die Prüfung als
                                    Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche
                                    und mündliche Prüfung in Angewandter Betriebswirtschaftslehre,
                                    Volkswirtschaftslehre § 13b WPO) ablegen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzung.par13b1 ? "ja" : ""}
                                {fehlerListe.verkuerzung ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.verkuerzung}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <b>
                                    Ich habe im Rahmen einer Hochschulausbildung gleichwertige
                                    Prüfungsleistungen erbracht und will die Prüfung als
                                    Wirtschaftsprüfer/-in in verkürzter Form (ohne schriftliche
                                    und mündliche Prüfung im Wirtschaftsrecht § 13b WPO) ablegen:
                                </b>
                            </div>
                            <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                {zulassung.verkuerzung.par13b2 ? "ja" : ""}
                                {fehlerListe.verkuerzung ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.verkuerzung}
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Zusatzinformationen</b>
                        </h3>
                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <textarea name="zusatzinformationen"
                                    className="inputText100percent"
                                    style={{ marginRight: "15px", height: "100px"}}
                                    value={zusatzinformationen}
                                    onChange={(e) => setZusatzinformationen(e.target.value)}
                                />
                            </div>
                        </div>

                        <h3 style={{ paddingTop: "25px" }}>
                            <b>Kenntnisnahme, Datenschutz, Absenden</b>
                        </h3>
                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="versicherung1"
                                    style={{ marginRight: "15px" }}
                                    value={versicherung1}
                                    checked={versicherung1}
                                    onChange={(e) => handleChange(e)}
                                />
                                *{" "}
                                {fehlerListe.versicherung1 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.versicherung1}
                                    </span>
                                ) : null}
                                <p>
                                    Ich versichere, dass ich die Angaben in diesem Antrag richtig
                                    und vollständig gemacht habe und beigefügte Dokumente mit den
                                    Original-Dokumenten übereinstimmen.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="versicherung2"
                                    style={{ marginRight: "15px" }}
                                    value={versicherung2}
                                    checked={versicherung2}
                                    onChange={(e) => handleChange(e)}
                                />
                                *{" "}
                                {fehlerListe.versicherung2 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.versicherung2}
                                    </span>
                                ) : null}
                                <p>
                                    Mir ist bekannt, dass unrichtige und unvollständige Angaben zu
                                    einer Rücknahme der Zulassung zum Wirtschaftsprüfungsexamen
                                    führen können. Die Rücknahme der Zulassung zur Prüfung hat
                                    auch die Rücknahme von Prüfungsentscheidungen zur Folge.
                                    Modulprüfungen und die Prüfung - das Wirtschaftsprüfungsexamen
                                    - gelten in diesem Fall als nicht bestanden. Die Bestellung
                                    als Wirtschaftsprüfer oder als Wirtschaftsprüferin würde
                                    erlöschen.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <input
                                    type="checkbox"
                                    name="versicherung3"
                                    style={{ marginRight: "15px" }}
                                    value={versicherung3}
                                    checked={versicherung3}
                                    onChange={(e) => handleChange(e)}
                                />
                                *{" "}
                                {fehlerListe.versicherung3 ? (
                                    <span className="pflichtfeld2">
                                        {fehlerListe.versicherung3}
                                    </span>
                                ) : null}
                                <p>
                                    Hiermit gebe ich mein Einverständnis zur Datenerhebung und
                                    Datenverarbeitung gemäß den Vorgaben der WPK. Was mit Ihren
                                    Daten geschieht, können Sie{" "}
                                    <a href="https://www.wpk.de/datenschutz/" target="_blank">
                                        hier
                                    </a>{" "}
                                    nachlesen. Weitere Hinweise zum Datenschutz bei der WPK finden
                                    Sie{" "}
                                    <a href="https://www.wpk.de/datenschutz" target="_blank">
                                        hier
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="row"
                        style={{ marginTop: "10px", marginBottom: "20px" }}
                    >
                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <input
                                type="submit"
                                value="Zurück"
                                className="nonPrintable button"
                                onClick={(e) => handleBack(e)}
                            />
                            <input
                                type="submit"
                                value="Abschicken"
                                className="nonPrintable button"
                                onClick={(e) => handleSubmit(e)}
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
};

const MenuRight = ({
    schritt,
    zulassung,
    lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen
}) => {

    const dispatch = useDispatch();

    const zwischenspeichern = () => {
        dispatch(zulassungActions.saveTempZulassung(zulassung, lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, stbescheinigungen2, verkuerzungbescheinigungen))
        toast.info("Die Daten wurden zwischengespeichert.")
    }

    return (
        <div className="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 menu_right_border small-hide">
            <p className="menu_right_header">Zulassungsantrag</p>
            <ul id="menu_right">
                <li style={schritt === 1 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.MODULANMELDUNG))
                            zwischenspeichern()
                        }}
                        style={schritt === 1 ? { background: "#a0bcd1" } : {}}
                    >
                        Zulassungsantrag und Modulanmeldung
                    </a>
                </li>
                <li style={schritt === 2 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.KONTAKTDATEN))
                            zwischenspeichern()
                        }}
                        style={schritt === 2 ? { background: "#a0bcd1" } : {}}
                    >
                        Kontaktdaten
                    </a>
                </li>
                <li style={schritt === 3 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.BISHERIGE_ZULASSUNGSANTRAEGE))
                            zwischenspeichern()
                        }}
                        style={schritt === 3 ? { background: "#a0bcd1" } : {}}
                    >
                        Bisherige Zulassungsanträge
                    </a>
                </li>
                <li style={schritt === 4 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.AKADEMISCHE_VORBILDUNG))
                            zwischenspeichern()
                        }}
                        style={schritt === 4 ? { background: "#a0bcd1" } : {}}
                    >
                        Akademische Vorbildung
                    </a>
                </li>
                <li style={schritt === 5 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.PRAKTISCHE_VORBILDUNG))
                            zwischenspeichern()
                        }}
                        style={schritt === 5 ? { background: "#a0bcd1" } : {}}
                    >
                        Praktische Vorbildung - Prüfungstätigkeit
                    </a>
                </li>
                <li style={schritt === 6 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.PRUEFUNGSVERUERZUNG))
                            zwischenspeichern()
                        }}
                        style={schritt === 6 ? { background: "#a0bcd1" } : {}}
                    >
                        Prüfungsverkürzung
                    </a>
                </li>
                <li style={schritt === 7 ? { background: "#a0bcd1" } : {}}>
                    <a
                        href="#"
                        onClick={() => {
                            dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.ABSENDEN))
                            zwischenspeichern()
                        }}
                        style={schritt === 7 ? { background: "#a0bcd1" } : {}}
                    >
                        Kenntnisnahme, Datenschutz, Absenden
                    </a>
                </li>
            </ul>
            <br />
            <br />
        </div>
    );
}


export const ZulassungAntragStellen = ({ lebenslauf,
    attest,
    zeugnisse,
    agbescheinigungen,
    stbescheinigungen,
    agbescheinigungen2,
    stbescheinigungen2,
    verkuerzungbescheinigungen,
    setLebenslauf,
    setAttest,
    setZeugnisse,
    setAgbescheinigungen,
    setStbescheinigungen,
    setAgbescheinigungen2,
    setStbescheinigungen2,
    setVerkuerzungbescheinigungen, verkuerzungauswahl, setVerkuerzungauswahl }) => {
    const dispatch = useDispatch();
    const schritt = useSelector(state => state.rightmenuZulassungsAntrag);
    const z = useSelector(state => state.zulassung.zulassung);
    const [zulassung, setZulassung] = useState(z);
    const modul = useSelector(state => state.modulList.modulListBefore);
    const loading = useSelector((state) => state.zulassung.loading);
    const [showSpinner, setShowSpinner] = useState(true);
    const t = useSelector(state => state.tipfehler.tipfehler);
    const [tipfehler, setTipfehler] = useState(t);
    const [zusatzinformationen, setZusatzinformationen] = useState('');

    useEffect(() => {
        setShowSpinner(true);
        dispatch(zulassungActions.getVerkuerzungAuswahl());
        dispatch(zulassungActions.getTipfehler());
    }, []);

    useEffect(() => {
		setZulassung(z);
	}, [z]);

    useEffect(() => {
        if (!loading) {
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }
    }, [loading]);

    useEffect(() => {
        setTipfehler(t);
    }, [t]);

    if (!zulassung) return (<div />);
    else return (loading ? <></> :
        <>
            {schritt === 1 && (
                <Modulanmeldung                   
                    lebenslauf={lebenslauf}
                    attest={attest}
                    zeugnisse={zeugnisse}
                    agbescheinigungen={agbescheinigungen}
                    stbescheinigungen={stbescheinigungen}
                    agbescheinigungen2={agbescheinigungen2}
                    stbescheinigungen2={stbescheinigungen2}
                    verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                />
            )}
            {schritt === 2 && (
                <Kontaktdaten
                    setLebenslauf={setLebenslauf}
                    setAttest={setAttest}
                    lebenslauf={lebenslauf}
                    attest={attest}
                    zeugnisse={zeugnisse}
                    agbescheinigungen={agbescheinigungen}
                    stbescheinigungen={stbescheinigungen}
                    agbescheinigungen2={agbescheinigungen2}
                    stbescheinigungen2={stbescheinigungen2}
                    verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                    tipfehler={tipfehler}
                    setTipfehler={setTipfehler}
                />
            )}
            {schritt === 3 && (
                <BisherigeZulassungsantraege
                    lebenslauf={lebenslauf}
                    attest={attest}
                    zeugnisse={zeugnisse}
                    agbescheinigungen={agbescheinigungen}
                    stbescheinigungen={stbescheinigungen}
                    agbescheinigungen2={agbescheinigungen2}
                    stbescheinigungen2={stbescheinigungen2}
                    verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                    tipfehler={tipfehler}
                    setTipfehler={setTipfehler}
                />
            )}
            {schritt === 4 && (
                <AkademischeVorbildung
                    setZeugnisse={setZeugnisse}
                    setAgbescheinigungen={setAgbescheinigungen}
                    setStbescheinigungen={setStbescheinigungen}
                    lebenslauf={lebenslauf}
                    attest={attest}
                    zeugnisse={zeugnisse}
                    agbescheinigungen={agbescheinigungen}
                    stbescheinigungen={stbescheinigungen}
                    agbescheinigungen2={agbescheinigungen2}
                    stbescheinigungen2={stbescheinigungen2}
                    verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                    tipfehler={tipfehler}
                    setTipfehler={setTipfehler}
                />
            )}
            {schritt === 5 && (
                <PraktischeVorbildung
                    setAgbescheinigungen2={setAgbescheinigungen2}
                    setStbescheinigungen2={setStbescheinigungen2}
                    lebenslauf={lebenslauf}
                    attest={attest}
                    zeugnisse={zeugnisse}
                    agbescheinigungen={agbescheinigungen}
                    stbescheinigungen={stbescheinigungen}
                    agbescheinigungen2={agbescheinigungen2}
                    stbescheinigungen2={stbescheinigungen2}
                    verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                />
            )}
            {schritt === 6 && (
                <PruefungsVerkuerzung
                    lebenslauf={lebenslauf}
                    attest={attest}
                    zeugnisse={zeugnisse}
                    agbescheinigungen={agbescheinigungen}
                    stbescheinigungen={stbescheinigungen}
                    agbescheinigungen2={agbescheinigungen2}
                    stbescheinigungen2={stbescheinigungen2}
                    verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                    setVerkuerzungbescheinigungen={setVerkuerzungbescheinigungen}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                    tipfehler={tipfehler}
                    setTipfehler={setTipfehler}
                    setVerkuerzungauswahl={setVerkuerzungauswahl}
                    verkuerzungauswahl={verkuerzungauswahl}
                />
            )}
            {schritt === 7 && (
                <Absenden
                    lebenslauf={lebenslauf}
                    attest={attest}
                    zeugnisse={zeugnisse}
                    agbescheinigungen={agbescheinigungen}
                    stbescheinigungen={stbescheinigungen}
                    agbescheinigungen2={agbescheinigungen2}
                    stbescheinigungen2={stbescheinigungen2}
                    verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                    zulassung={zulassung}
                    setZulassung={setZulassung}
                    tipfehler={tipfehler}
                    setTipfehler={setTipfehler}
                    zusatzinformationen={zusatzinformationen}
                    setZusatzinformationen={setZusatzinformationen}
                />
            )}

            {schritt === 1 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        lebenslauf={lebenslauf}
                        attest={attest}
                        zeugnisse={zeugnisse}
                        agbescheinigungen={agbescheinigungen}
                        stbescheinigungen={stbescheinigungen}
                        agbescheinigungen2={agbescheinigungen2}
                        stbescheinigungen2={stbescheinigungen2}
                        verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                        zulassung={zulassung}
                    />
                )}
            {schritt === 2 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        lebenslauf={lebenslauf}
                        attest={attest}
                        zeugnisse={zeugnisse}
                        agbescheinigungen={agbescheinigungen}
                        stbescheinigungen={stbescheinigungen}
                        agbescheinigungen2={agbescheinigungen2}
                        stbescheinigungen2={stbescheinigungen2}
                        verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                        zulassung={zulassung}
                    />
                )}
            {schritt === 3 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        lebenslauf={lebenslauf}
                        attest={attest}
                        zeugnisse={zeugnisse}
                        agbescheinigungen={agbescheinigungen}
                        stbescheinigungen={stbescheinigungen}
                        agbescheinigungen2={agbescheinigungen2}
                        stbescheinigungen2={stbescheinigungen2}
                        verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                        zulassung={zulassung}
                    />
                )}
            {schritt === 4 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        lebenslauf={lebenslauf}
                        attest={attest}
                        zeugnisse={zeugnisse}
                        agbescheinigungen={agbescheinigungen}
                        stbescheinigungen={stbescheinigungen}
                        agbescheinigungen2={agbescheinigungen2}
                        stbescheinigungen2={stbescheinigungen2}
                        verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                        zulassung={zulassung}
                    />
                )}
            {schritt === 5 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        lebenslauf={lebenslauf}
                        attest={attest}
                        zeugnisse={zeugnisse}
                        agbescheinigungen={agbescheinigungen}
                        stbescheinigungen={stbescheinigungen}
                        agbescheinigungen2={agbescheinigungen2}
                        stbescheinigungen2={stbescheinigungen2}
                        verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                        zulassung={zulassung}
                    />
                )}
            {schritt === 6 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        lebenslauf={lebenslauf}
                        attest={attest}
                        zeugnisse={zeugnisse}
                        agbescheinigungen={agbescheinigungen}
                        stbescheinigungen={stbescheinigungen}
                        agbescheinigungen2={agbescheinigungen2}
                        stbescheinigungen2={stbescheinigungen2}
                        verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                        zulassung={zulassung}
                    />
                )}
            {schritt === 7 && zulassung.zwischegespeichert === 0 &&
                (
                    <MenuRight
                        schritt={schritt}
                        lebenslauf={lebenslauf}
                        attest={attest}
                        zeugnisse={zeugnisse}
                        agbescheinigungen={agbescheinigungen}
                        stbescheinigungen={stbescheinigungen}
                        agbescheinigungen2={agbescheinigungen2}
                        stbescheinigungen2={stbescheinigungen2}
                        verkuerzungbescheinigungen={verkuerzungbescheinigungen}
                        zulassung={zulassung}
                    />
                )}
        </>
    );

};