import React, {useState} from 'react';
import { history } from '../helpers';
import { userService } from '../services';

export const PasswortVergessen = () => {

  const [email, setEmail] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmail(value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (email) {
            userService.requestNewPassword(email).then(res => { 
              history.push('/examensportal/passwortzurueckgesetzt');
          }).catch(res => { 
            history.push('/examensportal/passwortzurueckgesetzt');
          });
        }
    }

    return (
      <div className="container container-small-view">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
          <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
  
            <h1 style={{marginBbottom: '0.2em'}}>Haben Sie Ihr Passwort vergessen?</h1>
  
            <p>Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passwortes zu.</p>
            
  
            <div style={{marginBottom: '0.5em', paddingBottom: '1em'}} className="col-12 col-sm-12 col-md-12 col-lg-12 form-Box-Hellblau">
              <div className="row">
                <div style={{paddingTop: '0.3em'}} className="col-3 col-xs-4 col-sm-3 col-md-3 col-lg-3">E-Mail
                </div>
                <div style={{paddingTop: '0.3em'}} className="col-6 col-xs-5 col-sm-6 col-md-6 col-lg-6"><input id="email" type="text" name="email" className="inputText90percent" title="Bitte geben Sie ihre E-Mail ein." onChange={(e) => handleChange(e)} />	
                </div>
                <div style={{paddingTop: '0.3em'}} className="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1"><button className="button">Senden</button>										
                </div>
              </div>
  
              
            </div>
  </form>
  
        </div>
      </div>
    </div>
    );
}
