import { authHeader } from '../helpers';
import axios from "axios";

export const userService = {
    login,
    logout,
    getUser,
    saveUser,
    register,
    resetPassword,
    confirmRegistration,
    changePassword,
    changeEmail,
    requestNewPassword
};

function login(email, passwort, token) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify({ email, passwort, token });
    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/login`, body, requestOptions);
        
}

function register(newUser) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify(newUser);

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/register`, body, requestOptions);
}

function resetPassword(passwort, token) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify({passwort, token});

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/resetpassword`, body, requestOptions);
        
}

function requestNewPassword(email) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify({email});

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/requestnewpassword`, body, requestOptions);
        
}

function changePassword(passwort) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify({passwort});

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/changepassword`, body, requestOptions);
        
}

function changeEmail(email) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify({email});

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/changeemail`, body, requestOptions);
        
}


function confirmRegistration(token) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' }
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/confirmregistration/${token}`, null, requestOptions);
}

function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('user');
}

function getUser() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${process.env.REACT_APP_WPOP_BACKEND}/api/user`, requestOptions);
}

function saveUser(user) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify(user);

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/user`, body, requestOptions);
}
