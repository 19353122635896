import { rightmenuConstants, rightmenuZulassungsAntragConstants, rightmenuDokumenteConstants, rightmenuEignungAntragConstants } from "../constants/rightmenu.constants";

export const rightMenu = (state = 1, action) => {
    switch (action.type) {
        case rightmenuConstants.AUSWAHL_MODUL:
            return 1;

        case rightmenuConstants.ZUSAMMENFASSUNG:
            return 2;

        default:
            return state;
    }
}

export const rightmenuZulassungsAntrag = (state = 1, action) => {
    switch (action.type) {
        case rightmenuZulassungsAntragConstants.MODULANMELDUNG:
            return 1;

        case rightmenuZulassungsAntragConstants.KONTAKTDATEN:
            return 2;

        case rightmenuZulassungsAntragConstants.BISHERIGE_ZULASSUNGSANTRAEGE:
            return 3;

        case rightmenuZulassungsAntragConstants.AKADEMISCHE_VORBILDUNG:
            return 4;

        case rightmenuZulassungsAntragConstants.PRAKTISCHE_VORBILDUNG:
            return 5;

        case rightmenuZulassungsAntragConstants.PRUEFUNGSVERUERZUNG:
            return 6;

        case rightmenuZulassungsAntragConstants.ABSENDEN:
            return 7;

        default:
            return state;
    }
}

export const rightmenuEignungAntrag = (state = 1, action) => {
    switch (action.type) {
        case rightmenuEignungAntragConstants.ZULASSUNGSANTRAG:
            return 1;

        case rightmenuEignungAntragConstants.KONTAKTDATEN:
            return 2;

        case rightmenuEignungAntragConstants.VERKUERZUNG:
            return 3;

        case rightmenuEignungAntragConstants.ABSENDEN:
            return 4;

        default:
            return state;
    }
}

export const rightmenuDokumente = (state = 1, action) => {
    switch (action.type) {
        case rightmenuDokumenteConstants.AKTENEINGANG:
            return 1;

        case rightmenuDokumenteConstants.MANTELAKTE:
            return 2;

        case rightmenuDokumenteConstants.MODULPRUEFUNG_WP:
            return 3;

        case rightmenuDokumenteConstants.MODULPRUEFUNG_WR:
            return 4;

        case rightmenuDokumenteConstants.MODULPRUEFUNG_BWL:
            return 5;

        case rightmenuDokumenteConstants.MODULPRUEFUNG_STR:
            return 6;

        default:
            return state;
    }
}