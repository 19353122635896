import { authHeader } from '../helpers';
import axios from "axios";

export const documentService = {
    saveDokumente
};

function saveDokumente(lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, verkuerzungbescheinigung, staatsangehoerigkeit) {
    const formData = new FormData();

    const requestOptions = {
        headers: { ...authHeader() },
    };

    if(lebenslauf){
        for(let a = 0; a < lebenslauf.length; a++){
            formData.append("lebenslauf", lebenslauf[a].file);
        }
    }else{
        formData.append("lebenslauf", []);
    }

    if(zeugnisse){
        for(let a = 0; a < zeugnisse.length; a++){
            formData.append("zeugnis", zeugnisse[a].file);
        }
    }else{
        formData.append("zeugnis", []);
    }

    if(attest){
        for(let a = 0; a < attest.length; a++){
            formData.append("attest", attest[a].file);
        }
    }else{
        formData.append("attest", []);
    }

    if(agbescheinigungen){
        for(let a = 0; a < agbescheinigungen.length; a++){
            formData.append("agbescheinigung", agbescheinigungen[a].file);
        }
    }else{
        formData.append("agbescheinigung", []);
    }

    if(stbescheinigungen){
        for(let a = 0; a < stbescheinigungen.length; a++){
            formData.append("stbescheinigung", stbescheinigungen[a].file);
        }
    }else{
        formData.append("stbescheinigung", []);
    }

    if(agbescheinigungen2){
        for(let a = 0; a < agbescheinigungen2.length; a++){
            formData.append("agbescheinigung2", agbescheinigungen2[a].file);
        }
    }else{
        formData.append("agbescheinigung2", []);
    }

    if(verkuerzungbescheinigung){
        for(let a = 0; a < verkuerzungbescheinigung.length; a++){
            formData.append("verkuerzungbescheinigung", verkuerzungbescheinigung[a].file);
        }
    }else{
        formData.append("verkuerzungbescheinigung", []);
    }

    if(staatsangehoerigkeit){
        for(let a = 0; a < staatsangehoerigkeit.length; a++){
            formData.append("staatsangehoerigkeit", staatsangehoerigkeit[a].file);
        }
    }else{
        formData.append("staatsangehoerigkeit", []);
    }

    return axios.post(`${process.env.REACT_APP_WPOP_BACKEND}/api/dokumente/files`, formData, requestOptions);
}

