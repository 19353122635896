import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { zulassungActions, modulpruefungTopMenuActions, rightMenuActions, zulassungsblockActions, modulActions, topMenuActions } from '../actions';
import { AnmeldungForm } from './modulpruefung/Form/AnmeldungForm';
import { AbmeldungForm } from './modulpruefung/Form/AbmeldungForm';
import { VerschiebenForm } from './modulpruefung/Form/VerschiebenForm';
import { AnrechnenForm } from './modulpruefung/Form/AnrechnenForm';
import { MenuRight } from './MenuRight';
import { Spinner } from "./spinner/Spinner";
import { rightmenuConstants, topmenuConstants } from '../constants';
import { ErlaueterungForAuswahlAnmeldung, ErlaueterungForAuswahlAbmeldung, VerschiebungText, AnrechnenText, ModulNochNichtAnwendbar } from './HinweisTexte';




export const Modulpruefung = () => {
	const dispatch = useDispatch();
	//const [modul, setModul] = useState(1);
	const form = useSelector(state => state.topMenu);
	const tab = useSelector(state => state.rightMenu);
	const z = useSelector(state => state.zulassung.zulassung);
	const [zulassung, setZulassung] = useState(z);
	const m = useSelector(state => state.modulList.modulListBefore);
	const [modul, setModul] = useState(m);
	const [showSpinner, setShowSpinner] = useState(true);
	const loading = useSelector((state) => state.zulassung.loading);
	var subTitle = "";

	switch (form) {
		case topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM_NR:
			subTitle = "Anmeldung zur Modulprüfung";
			break;

		case topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM_NR:
			subTitle = "Rücknahme der Anmeldung zu einer Modulprüfung";
			break;
		case topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM_NR:
			subTitle = "Verschiebung einer Modulprüfung";
			break;
		case topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM_NR:
			subTitle = "Prüfungsäquivalente Leistung anrechnen";
			break;
		default:
			subTitle = "";
	}

	useEffect(() => {
		dispatch(zulassungsblockActions.getZulassungsblock());
		dispatch(zulassungActions.getZulassung());
		dispatch(modulActions.getModulList());
		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); 
		dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM))
	}, []);

	useEffect(() => {
		setModul(m);
	}, [m]);

	useEffect(() => {
		setZulassung(z);
	}, [z]);

	useEffect(() => {
		if (!loading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [loading]);

	//console.log(form);
	//console.log(tab);

	/* 
		Zulassung.zwischengespeichert = 0 : Zulassungsantrag noch nicht abgesendet
		Zulassung.zwischengespeichert = 1 : Zulassungsantrag schon abgesendet aber noch nicht geprüft
		Zulassung.zwischengespeichert = 2 : Zulassungsantrag schon abgesendet und schon geprüft
	*/
	if (!modul) {
		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
								{form === topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM_NR && <ErlaueterungForAuswahlAnmeldung />}
								{form === topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM_NR && <ErlaueterungForAuswahlAbmeldung />}
								{form === topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM_NR && <VerschiebungText />}
								{form === topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM_NR && <AnrechnenText />}
								<ModulNochNichtAnwendbar />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	else {
		if (modul && modul.zwischegespeichert && modul.zwischegespeichert === 2) {
			return (
				<div className="container  container-small-view">
					<div className="row">

						<div className="col-xs-12">
							<h1>{subTitle}</h1>
						</div>
						<div className="container  container-small-view">
							<div className="row">
								<Spinner show={showSpinner} />
								{/* Form Typ */}
								{form === topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM_NR && <AnmeldungForm />}
								{form === topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM_NR && <AbmeldungForm />}
								{form === topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM_NR && <VerschiebenForm />}
								{form === topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM_NR && <AnrechnenForm />}
							</div>
						</div>
					</div>
				</div>
			);
		}

		// For Security purpose
		if (zulassung && (!zulassung.zwischegespeichert && zulassung.zwischegespeichert === 0 ||
			(zulassung.zwischegespeichert && zulassung.zwischegespeichert === 1))) {
			return (
				<div className="container  container-small-view">
					<div className="row">
						<div className="col-xs-12">
							<h1>{subTitle}</h1>
						</div>
						<div className="container  container-small-view">
							<div className="row">
								<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
									{form === topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM_NR && <ErlaueterungForAuswahlAnmeldung />}
									{form === topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM_NR && <ErlaueterungForAuswahlAbmeldung />}
									{form === topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM_NR && <VerschiebungText />}
									{form === topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM_NR && <AnrechnenText />}
									<ModulNochNichtAnwendbar />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container  container-small-view">
				<div className="row">

					<div className="col-xs-12">
						<h1>{subTitle}</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<Spinner show={showSpinner} />
							{/* Form Typ */}
							{form === topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM_NR && <AnmeldungForm />}
							{form === topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM_NR && <AbmeldungForm />}
							{form === topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM_NR && <VerschiebenForm />}
							{form === topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM_NR && <AnrechnenForm />}
						</div>
					</div>
				</div>
			</div>
		);
	}
};