import moment from 'moment';
import { dateConstants } from '../constants';

export const util = {
    updateSemester,
    changeFormatSemester,
    changeFormatSemester2,
    changeSecDateDEFormat,
    changeDateDEFormat,
    changeDateENGFormat,
    changeDateENGToDEFormat,
    changeDateDEToENGFormat,
    changeDateENGWithHoursFormat,
    changeFormatSemesterEignung,
    existsDatum,
    hasReachedGivenDate,
    isWithinThreeDays,
    getJahr
};

function updateSemester(date, SemesterZahl){
    if(date === "SEM"){
        return "SEM";
    }

    var monthToAdd = SemesterZahl % 2;
    var yearToAdd = Math.floor(SemesterZahl/2);

    const dateInfo = date.split('/');
    var semester = parseInt(dateInfo[0], 10) + monthToAdd;
    var year = parseInt(dateInfo[1], 10) + yearToAdd;

    if(semester === 3){
        semester = 1;
        year = year + 1;
    }

    var neuDateFormat = semester + "/" + year;

    return neuDateFormat;
}

function changeFormatSemester(semester){
    if(!semester){
        return "SEM"
    }

    const dateInfo = semester.split('/');
    var year = parseInt(dateInfo[0], 10).toString();
    var sem = parseInt(dateInfo[1], 10).toString();
    var neuFormat;

    if(year.length <= 2 && sem.length === 4){
        neuFormat = year + "/" + sem;
    }else if(sem.length <= 2 && year.length === 4){
        neuFormat = sem + "/" + year;
    }else{
        return "SEM"
    }

    return neuFormat;
}

function changeFormatSemester2(semester){
    if(semester){
        const dateInfo = semester.split('/');
        return dateInfo[1] + "/" + dateInfo[0];
    }

    return "";
}

function changeFormatSemesterEignung(semester){
    if(semester){
        const dateInfo = semester.split('/');
        return dateInfo[0];
    }

    return "";    
}

function getJahr(semester){
    if(semester){
        const dateInfo = semester.split('/');
        if(dateInfo[0].length === 4){
            return dateInfo[0];
        }else if(dateInfo[1].length === 4){
            return dateInfo[1];
        }
    }

    return "";    
}

function changeDateDEFormat(date){
    if(!date){
        return "DATUM";
    }

    return moment(date).format("DD.MM.YYYY")
}

function changeSecDateDEFormat(date){
    if(!date){
        return "DATUM";
    }

    return moment.unix(date/1000).format("DD.MM.YYYY")
}

function changeDateENGFormat(date){
    if(!date){
        return "DATUM";
    }

    return moment(date).format("YYYY-MM-DD")
}

function changeDateENGWithHoursFormat(date){
    if(!date){
        return "DATUM";
    }

    return moment(date).format("YYYY-MM-DD HH:mm")
}

function changeDateENGToDEFormat(date){
    if(!date){
        return "DATUM";
    }

    return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD")
}

function changeDateDEToENGFormat(date){
    if(!date){
        return "DATUM";
    }

    return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY")
}

function existsDatum(datum) {
    if (datum && (datum != "" || datum != null || datum.length >= 8)) {
        return true;
    } else {
        return false;
    }
}

function hasReachedGivenDate(givenDate) {
    if (!existsDatum(givenDate)) {
        // console.log("NOT CORRECT FORMAT");
        return dateConstants.NO_CORRECT_FORMAT;
    }
    const currentDate = moment();
    // console.log(currentDate);

    const dateToCompare = moment(givenDate);
    // console.log(dateToCompare);

    if (currentDate.isSameOrAfter(dateToCompare)) {
        // console.log("After");
        return dateConstants.AFTER_REACHING;
    } else {
        // console.log("Before");
        return dateConstants.BEFORE_REACHING;
    }
}

function isWithinThreeDays(givenDate) {
    if (!existsDatum(givenDate)) {
        // console.log("NOT CORRECT FORMAT");
        return dateConstants.NO_CORRECT_FORMAT;
    }

    const momentGivenDate = moment(givenDate);

    const currentDate = moment();

    const threeDaysFromGiven = momentGivenDate.add(3, 'days');

    if (currentDate.isSameOrBefore(threeDaysFromGiven, 'day')) {
        // console.log("After");
        return dateConstants.BEFORE_REACHING;
    } else {
        // console.log("Before");
        return dateConstants.AFTER_REACHING; 
    }
}