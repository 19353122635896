import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { LoggedInInfo } from '.';
import { toast } from 'react-toastify';
import { rightMenuActions, topMenuActions, userActions, zulassungActions } from '../actions';
import { rightmenuConstants, rightmenuEignungAntragConstants, rightmenuZulassungsAntragConstants, topmenuConstants } from '../constants';
import Logo from '../assets/logo-wirtschaftsprueferkammer.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const Menu = (props) => {

  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const user = useSelector((state) => state.authentication.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedIn)
      if (user && user.art && user.art === 'WP') {
        dispatch(zulassungActions.checkZulassung());
      }     
  }, [loggedIn]);

  useEffect(() => {
    dispatch(userActions.session());
  }, []);

  return (
    <div>
      {loggedIn && user ? <div><LoggedInMenu /><LoggedInInfo /></div> : <UnLoggedMenu />}
    </div>
  );
}

const LoggedInMenu = () => {
  const user = useSelector((state) => state.authentication.user);
  const [showMenu1, setShowMenu1] = useState(false);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);
  const [showMenu4, setShowMenu4] = useState(false);
  const [showMenu5, setShowMenu5] = useState(false);
  const [aktMenu, setAktMenu] = useState('');
  let showZulassung = useSelector((state) => state.zulassung.showMenu);

  showZulassung = true;

  function toggleMenu(e) {

    if (e.target.id !== aktMenu) {
      setShowMenu1(false);
      setShowMenu2(false);
      setShowMenu3(false);
      setShowMenu4(false);
      setShowMenu5(false);
    }

    if (e.target.id === 'menu1') {
      setShowMenu1(showMenu1 => !showMenu1);
    } else if (e.target.id === 'menu2' && user && user.art === 'WP') {
      setShowMenu2(showMenu2 => !showMenu2);
    } else if (e.target.id === 'menu3') {
      setShowMenu3(showMenu3 => !showMenu3);
    } else if (e.target.id === 'menu4') {
      setShowMenu4(showMenu4 => !showMenu4);
    } else if (e.target.id === 'menu5') {
      setShowMenu5(showMenu5 => !showMenu5);
    }
    setAktMenu(e.target.id);

  }

  return (
    <div>
      <div className="logo small-hide">

        <div className="container">

          <div className="row">

            <div className="col-9 col-sm-12 col-md-3 col-lg-3">

              <div className="medium-logo">
                <Link to="/examensportal/start"><img id="wpk_img" className="kammer-logo-width" alt="" src={Logo} /></Link>
              </div>
            </div>

            <div className="col-3 col-sm-12 col-md-9 col-lg-9 no-margin-right">

              {/*  ////////////////// HAUPTMENU START  ////////////////// */}
              <div id="menu">
                <ul>
                  {user && user.art && user.art === 'WP' && showZulassung && <li className={showMenu1 ? 'current_page_item_3A6380' : 'dropdown color_3A6380'}><Link to="/examensportal/zulassung" className="menuBlock" id="menu1" onClick={e => toggleMenu(e)}>Zulassungsantrag</Link></li>}
                  {user && user.art && user.art === 'EIGNUNG' && showZulassung && <li className={showMenu1 ? 'current_page_item_3A6380' : 'dropdown color_3A6380'}><Link to="/examensportal/zulassungeignung" className="menuBlock" id="menu1" onClick={e => toggleMenu(e)}>Zulassungsantrag</Link></li>}
                
                  {user && user.art && user.art === 'WP' && <li className={showMenu2 ? 'current_page_item_4C82A8' : 'dropdown color_4C82A8'}><Link to="/examensportal/modulpruefung" className="menuBlock" id="menu2" onClick={e => toggleMenu(e)}>Modulprüfung</Link></li>}

                  {user && user.art && user.art === 'WP' && <li className={showMenu4 ? 'current_page_item_68B2E6' : 'dropdown color_68B2E6'}><Link to="/examensportal/triftigergrund" className="menuBlock" id="menu4" onClick={e => toggleMenu(e)}>Triftiger Grund</Link></li>}
                  {user && user.art && user.art === 'EIGNUNG' && <li className={showMenu4 ? 'current_page_item_68B2E6' : 'dropdown color_68B2E6'}><Link to="/examensportal/triftigergrundeignung" className="menuBlock" id="menu4" onClick={e => toggleMenu(e)}>Triftiger Grund</Link></li>}

                  {user && user.art && user.art === 'WP' && <li className={showMenu3 ? 'current_page_item_5794BF' : 'dropdown color_5794BF'}><Link to="/examensportal/ruecktritt" className="menuBlock" id="menu3" onClick={e => toggleMenu(e)}>Rücktritt</Link></li>}

                  <li className={showMenu5 ? 'current_page_item_74C6FF' : 'dropdown color_74C6FF'}><Link to="/examensportal/meinedaten" className="menuBlock" id="menu5" onClick={e => toggleMenu(e)}>Meine Daten</Link></li>
                </ul>
              </div>

              {/*  ////////////////// MENU ENDE  ////////////////// */}
            </div>
          </div>
        </div>

        {/*}  ////////////////// Horizontale Untermen&uuml;s START  ////////////////// */}
        <div className="small-hide">

          <div className="container">

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">

                {showMenu1 ? <Menu1 /> : null}
                {showMenu2 ? <Menu2 /> : null}
                {showMenu3 ? <Menu3 /> : null}
                {showMenu4 ? <Menu4 /> : null}
                {showMenu5 ? <Menu5 /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ clear: 'both' }}></div>

    </div>
  );
}

const UnLoggedMenu = () => {
  return (
    <div className="navbar_extern">
      <div className="container">
        <div className="medium-logo">
          <Link to="/examensportal"><img id="wpk_img" className="kammer-logo-width" alt="" src={Logo} /></Link>
        </div>
      </div>
    </div>
  );
}

const Menu1 = () => {
  const user = useSelector((state) => state.authentication.user);
  const dispatch = useDispatch();
  const history = useHistory();
  // dispatch(topMenuActions.changeForm(topmenuConstants.ZULASSUNGANTRAG_STELLEN));
  // dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.MODULANMELDUNG));


  return (
    <div className="menu_horizontal career-background-colors submenu-setting" id="career-options">
      <ul>
      {user && user.art && user.art === 'WP' && <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/zulassung"); dispatch(rightMenuActions.changeTab(rightmenuZulassungsAntragConstants.MODULANMELDUNG)); dispatch(topMenuActions.changeForm(topmenuConstants.ZULASSUNGANTRAG_STELLEN)) }}>Zulassungsantrag stellen</a></li>}
      {user && user.art && user.art === 'EIGNUNG' && <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/zulassungeignung"); dispatch(rightMenuActions.changeTab(rightmenuEignungAntragConstants.ZULASSUNGSANTRAG)); dispatch(topMenuActions.changeForm(topmenuConstants.ZULASSUNGANTRAG_STELLEN)) }}>Zulassungsantrag stellen</a></li>}
      {user && user.art && user.art === 'WP' && <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/zulassung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN)) }}>Zulassungsantrag zurücknehmen</a></li>}
      {user && user.art && user.art === 'EIGNUNG' && <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/zulassungeignung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.ZULASSUNGANTRAG_ZURUECKNEHMEN)) }}>Zulassungsantrag zurücknehmen</a></li>}
        {user && user.art && user.art === 'EIGNUNG' && <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/zulassungeignung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.GESAMTPRUEFUNG)) }}>Rücktritt</a></li>}
      </ul>
    </div>
  );
}

const Menu2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM));
  // dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));

  return (
    <div id="member-options" className="menu_horizontal searchservice-background-colors submenu-setting">
      <ul>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/modulpruefung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG_ANMELDUNG_FORM)) }}>Anmeldung</a></li>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/modulpruefung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG_ABMELDUNG_FORM)) }}>Abmeldung</a></li>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/modulpruefung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG_VERSCHIEBEN_FORM)) }}>Verschiebung</a></li>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/modulpruefung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG_ANRECHNEN_FORM)) }}>Anrechnung</a></li>
      </ul>
    </div>
  );
}

const Menu3 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG));
  // dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));

  return (
    <div id="digitalrequests-options" className="menu_horizontal digitalrequests-background-colors submenu-setting">
      <ul>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/ruecktritt"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.MODULPRUEFUNG)) }}>Modulprüfungsrücktritt</a></li>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/ruecktritt"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.GESAMTPRUEFUNG)) }}>Prüfungsrücktritt</a></li>
      </ul>
    </div>
  );
}

const Menu4 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.authentication.user);
  // dispatch(topMenuActions.changeForm(topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES));
  // dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));

  return (
    <div id="service-options" className="menu_horizontal service-background-colors submenu-setting">
      <ul>
        {user && user.art && user.art === 'WP' && <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/triftigergrund"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES)) }}>Mitteilung eines triftigen Grundes</a></li>}
        {user && user.art && user.art === 'EIGNUNG' && <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/triftigergrundeignung"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES)) }}>Mitteilung eines triftigen Grundes</a></li>}
      </ul>
    </div>
  );
}

const Menu5 = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  // const user = useSelector((state) => state.authentication.user);
  // dispatch(topMenuActions.changeForm(topmenuConstants.DIGITALE_AKTE));
  // dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));

  function Logout() {
    dispatch(userActions.logout());
    toast.info("Sie wurden abgemeldet.");
  }

  return (
    <div id="antimoneylaundring-options" className="menu_horizontal antimoneylaundering-background-colors submenu-setting">
      <ul>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/meinedaten"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.DIGITALE_AKTE)) }}>Digitale Akte</a></li>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/meinedaten"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.KONTAKTDATEN_AENDERN)) }}>Kontaktdaten ändern</a></li>
        <li className="color_3A6380"><a href="#" onClick={() => { history.push("/examensportal/meinedaten"); dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.KENNWORT_AENDERN)) }}>Zugangsdaten ändern</a></li>
        {/* <li className="color_3A6380"><a href="#" onClick={() => { dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL)); dispatch(topMenuActions.changeForm(topmenuConstants.EINSTELLUNGEN_AENDERN)) }}>Einstellungen</a></li> */}
        <li className="color_3A6380"><a href="#" onClick={() => Logout()}>Abmelden</a></li>
      </ul>
    </div>
  );
}

