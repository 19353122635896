import { documentConstants } from '../constants/document.constants';
import { documentService } from '../services/document.service';

export const documentActions = {
    saveDokumente
};

function saveDokumente(lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, verkuerzungbescheinigung, staatsangehoerigkeit) {
    return dispatch => {
        dispatch({ type: documentConstants.SAVE_REQUEST });
        documentService.saveDokumente(lebenslauf, attest, zeugnisse, agbescheinigungen, stbescheinigungen, agbescheinigungen2, verkuerzungbescheinigung, staatsangehoerigkeit)
            .then(res => {
                dispatch({ type: documentConstants.SAVE_SUCCESS, payload: res });
            })
            .catch(res => {
                dispatch({ type: documentConstants.SAVE_FAILURE, payload: res })
            });
    };
}
