export const eventConstants = {
    ZULASSUNG_ANTRAG : 1,
    ZULASSUNG_RUECKNAHME : 2,
    MODULPRUEFUNG_ANMELDUNG : 3,
    MODULPRUEFUNG_ABMELDUNG : 4,
    MODULPRUEFUNG_VERSCHIEBUNG : 5,
    MODULPRUEFUNG_ANRECHNUNG : 6,
    TRIFFTIGERGRUND : 7,
    MODULPRUEFUNG_RUECKTRITT : 8,
    PRUEFUNG_RUECKTRITT : 9,
    MITTEILUNG_KONTAKTDATEN: 10,
    ZULASSUNGEP_ANTRAG: 12,
    ZULASSUNGEP_RUECKNAHME: 13,
    PRUEFUNGEP_RUECKTRITT: 14,
    TRIFFTIGERGRUNDEP: 15,
    MITTEILUNG_KONTAKTDATENEP: 16
}