import React from 'react';
import { useSelector } from 'react-redux'

export const LoggedInInfo = (props) => {
    const user = useSelector((state) => state.authentication.user);
    
    if(user){
        return(
            <div className="container small-hide">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
    
                        <div className="divLoginInfo">
                            <div className="col-12 col-sm-7 col-md-7 col-lg-8" style={{paddingLeft: '1px'}}>angemeldet als:
                                <br />
                                <span style={{fontWeight: 'bold'}}>{user.vorname} {user.nachname}</span>
                            </div>
    
                            <div className="col-12 col-sm-5 col-md-5 col-lg-4" style={{paddingRight: '0px', paddingLeft: '0px'}}>
                                <div style={{display: 'block', paddingRight: '2px', float: 'right'}}>letzter Login:
                                    <br /><span className="loginLabelBold">{user.lastLogin} Uhr</span>
                                </div>
                            </div>
                        </div>
                        
                        <div style={{clear: 'both'}}></div>
                    
                    </div>
                </div>
            </div>
        );
    }
}
