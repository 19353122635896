import { modulConstants, modulEPConstants } from '../constants';
import { getErrorMessage } from '../helpers';
import { modulService } from '../services';

export const modulEPActions = {
    getModulEPList,
};

function getModulEPList() {
    return dispatch => {
        dispatch({ type: modulEPConstants.GET_REQUEST });

        modulService.getModulEPList()
            .then(res => {
                dispatch({ type: modulEPConstants.GET_SUCCESS, payload: res })
                dispatch({ type: modulEPConstants.GET_DONE })
            })
            .catch(error => {
                dispatch({ type: modulEPConstants.GET_FAILURE, payload: getErrorMessage(error) })
                dispatch({ type: modulEPConstants.GET_DONE })
            });
    };
}