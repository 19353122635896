export class VerkuerzungClass {

    constructor(){
        this.id = 0;
        this.vollPruefung = false;
        this.par8a = false;
        this.par131 = false;
        this.par132 = false;
        this.par13b1 = false;
        this.par13b2 = false;
    }
}