import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { modulEPActions, userActions } from '../actions';
import { toast } from 'react-toastify';
import { modulActions, zulassungActions, modulpruefungTopMenuActions, rightMenuActions } from '../actions';
import { eventConstants, modulConstants, modulEPConstants, rightmenuConstants } from '../constants';
import { util, modulService } from '../services';
import { set } from 'date-fns';
import { Spinner } from "./spinner/Spinner";
import { ModulNochNichtAnwendbar, KontaktdatenHinweisText } from './HinweisTexte';
import FileUpload from './fileupload/fileupload';
import FileUploadList from './fileupload/fileuploadlist';
import { WPSModulKontaktdatenClass } from '../models/modul';
import { Laenderkuerzel } from './zulassung/Laenderkuerzel';
import { Laender } from './zulassung/Laender';

const Schritt1 = ({
	modulEP,
	setModulEP,
	modulEPBefore,
	lebenslauf,
	setLebenslauf,
	attest,
	setAttest,
	tipfehler,
	setTipfehler }) => {

	const dispatch = useDispatch();
	const user = useSelector((state) => state.authentication.user);

	const handleChange = (e) => {
		let v = false;
		if (e.target.type === "checkbox" || e.target.type === "radio") {
			if (e.target.value === "false" || e.target.value === false) {
				v = true;
			}
		} else {
			v = e.target.value;
		}

		if (e.target.name.indexOf("kontaktdaten") > -1) {
			setModulEP(prev => ({ ...prev, kontaktdaten: { ...prev.kontaktdaten, [e.target.name.split(".")[1]]: v } }))
		} else {
			setModulEP({ ...modulEP, [e.target.name]: v });
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		//dispatch(zulassungActions.saveTempZulassung(zulassung));
		toast.info("Die Daten wurden zwischengespeichert.");
		dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG))
	};

	const handleBack = (e) => {
		e.preventDefault();

		//dispatch(zulassungActions.saveTempZulassung(zulassung));
		toast.info("Die Daten wurden zwischengespeichert.");
	};

/* 	const handleLebenslauf = (e) => {
		if (e.target.files) {
			const ar = [];
			for (let i = 0; i < e.target.files.length; i++) {
				ar.push(e.target.files[i]);
			}

			setLebenslauf(ar);
		}
	};

	const handleAttest = (e) => {
		if (e.target.files) {
			const ar = [];
			for (let i = 0; i < e.target.files.length; i++) {
				ar.push(e.target.files[i]);
			}

			setAttest(ar);
		}
	}; */

	const feldPruefen = (e) => {
		var pruefen = true;

		if (pruefen) {
			setTipfehler({ ...tipfehler, ["error"]: "" })

			if (e.target.name == "kontaktdaten.plz") {
				if (modulEP.kontaktdaten.plz && !modulEP.kontaktdaten.plz.match(/^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/)) {
					setTipfehler({ ...tipfehler, ["error"]: "PLZ ist nicht korrekt" })
				}
			}


			if (e.target.name == "kontaktdaten.email") {
				if (modulEP.kontaktdaten.email && !modulEP.kontaktdaten.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
					setTipfehler({ ...tipfehler, ["error"]: "Email ist nicht korrekt" })
				}
			}

			if (e.target.name == "kontaktdaten.telefon") {
				if (modulEP.kontaktdaten.telefon && !modulEP.kontaktdaten.telefon.match(/\b[\+]?[(]?[0-9]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{3,15}\b/m)) {
					setTipfehler({ ...tipfehler, ["error"]: "Telefon ist nicht korrekt" })
				}
			}

			if (e.target.name == "kontaktdaten.bic") {
				if (modulEP.kontaktdaten.bic && !modulEP.kontaktdaten.bic.match(/([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)/g)) {
					setTipfehler({ ...tipfehler, ["error"]: "BIC ist nicht korrekt" })
				}
			}

			if (e.target.name == "kontaktdaten.iban") {
				if (modulEP.kontaktdaten.iban && !modulEP.kontaktdaten.iban.match(/^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/)) {
					setTipfehler({ ...tipfehler, ["error"]: "IBAN ist nicht korrekt" })
				}
			}

		}


	}

	if (!modulEPBefore || !tipfehler) return (<div />);
	else {
		if (user && user.art && user.art === 'EIGNUNG') {
			if (modulEPBefore.zwischegespeichert === 0) {
				return (
					<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
						<KontaktdatenHinweisText />

						<ModulNochNichtAnwendbar />
					</div>
				);
			} else if (modulEPBefore.zwischegespeichert && modulEPBefore.zwischegespeichert > 0) {
				return (
					<form onSubmit={(e) => handleSubmit(e)}>
						<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
							<KontaktdatenHinweisText />

							<div className="form-Box-Hellblau" style={{ paddingTop: "0px" }}>
								<h2 style={{ paddingTop: "25px" }}>Meine persönlichen Daten</h2>
								<p style={{ color: "rgb(185, 74, 72)" }}>{tipfehler.error}</p>
								<div className="row">
									<div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2">
										<span className="formLabel">Anrede*</span>
										<select
											name="kontaktdaten.anrede"
											autoComplete="off"
											value={modulEP.kontaktdaten.anrede}
											onChange={(e) => handleChange(e)}
										>
											<option value=""></option>
											<option value="Herr">Herr</option>
											<option value="Frau">Frau</option>
										</select>
									</div>
									<div className="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5">
										<span className="formLabel">Vorname*</span>
										<input
											type="text"
											name="kontaktdaten.vorname"
											autoComplete="off"
											value={modulEP.kontaktdaten.vorname}
											className="inputText100percent"
											onChange={(e) => { handleChange(e) }}
										/>
									</div>
									<div className="col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5">
										<span className="formLabel">Nachname*</span>
										<input
											type="text"
											name="kontaktdaten.name"
											autoComplete="off"
											value={modulEP.kontaktdaten.name}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">Weitere Vornamen</span>
										<input
											type="text"
											name="kontaktdaten.weitereVornamen"
											autoComplete="off"
											value={modulEP.kontaktdaten.weitereVornamen}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">Geburtsname</span>
										<input
											type="text"
											name="kontaktdaten.geburtsname"
											autoComplete="off"
											value={modulEP.kontaktdaten.geburtsname}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">Geburtsdatum*</span>
										<input
											id="kontaktdaten.geburtsdatum"
											type="date"
											name="kontaktdaten.geburtsdatum"
											autoComplete="off"
											value={modulEP.kontaktdaten.geburtsdatum}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}

										/>
									</div>
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">Geburtsort*</span>
										<input
											type="text"
											name="kontaktdaten.geburtsort"
											autoComplete="off"
											value={modulEP.kontaktdaten.geburtsort}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<span className="formLabel">Staatsangehörigkeit</span>
										<Laender
											name="kontaktdaten.staatsangehoerigkeit"
											className="inputText100percent"
											autoComplete="off"
											sonstigerStaat={true}
											value={modulEP.kontaktdaten.staatsangehoerigkeit}
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<span className="formLabel">Aktueller Arbeitgeber</span>
										<input
											type="text"
											name="kontaktdaten.aktArbeitgeber"
											autoComplete="off"
											value={modulEP.kontaktdaten.aktArbeitgeber}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
										<h3>Nachteilsausgleich</h3>
										<input
											style={{ marginRight: "15px" }}
											type="checkbox"
											name="kontaktdaten.behinderung"
											autoComplete="off"
											value={modulEP.kontaktdaten.behinderung}
											checked={modulEP.kontaktdaten.behinderung}
											onChange={(e) => handleChange(e)}
										/>{" "}
										<span style={{ color: "#353535", fontWeight: "bold" }}>Behinderung</span>
									</div>
								</div>
								<div className="row" style={{ marginTop: 10 }}>
									<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
										<span className="formLabel">Attest/Nachweis</span>
										<FileUpload files={attest} setFiles={setAttest} />
									</div>
								</div>
								<FileUploadList files={attest} setFiles={setAttest} label={'Atteste'} />

								<div className="row">
									<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<h3>Wohnort</h3>
									</div>
								</div>

								<div className="row">
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">Straße*</span>
										<input
											type="text"
											name="kontaktdaten.strasse"
											autoComplete="off"
											value={modulEP.kontaktdaten.strasse}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">Hausnr.*</span>
										<input
											type="text"
											name="kontaktdaten.hausnummer"
											autoComplete="off"
											value={modulEP.kontaktdaten.hausnummer}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">Land*</span>
										<Laenderkuerzel
											name="kontaktdaten.land"
											autoComplete="off"
											value={modulEP.kontaktdaten.land}
											onChange={(e) => handleChange(e)}
										/>
										{/* <select
										name="kontaktdaten.land"
										autoComplete="off"
										value={modulEP.kontaktdaten.land}
										onChange={(e) => handleChange(e)}
									>
										<option value="DE">DE</option>
									</select> */}
									</div>
									<div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2">
										<span className="formLabel">PLZ*</span>
										<input
											type="text"
											name="kontaktdaten.plz"
											autoComplete="off"
											value={modulEP.kontaktdaten.plz}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
											onKeyUp={(e) => feldPruefen(e)}
										/>
									</div>
									<div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4">
										<span className="formLabel">Ort*</span>
										<input
											type="text"
											name="kontaktdaten.ort"
											autoComplete="off"
											value={modulEP.kontaktdaten.ort}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<h3>Verbindungsdaten</h3>
									</div>
								</div>

								<div className="row">
									<div className="col-4 col-xs-4 col-sm-4 col-md-6 col-lg-6">
										<span className="formLabel">E-Mail*</span>
										<input
											type="text"
											name="kontaktdaten.email"
											autoComplete="off"
											value={modulEP.kontaktdaten.email}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
											onKeyUp={(e) => feldPruefen(e)}
										/>
									</div>
									<div className="col-4 col-xs-4 col-sm-4 col-md-6 col-lg-6">
										<span className="formLabel">Telefon*</span>
										<input
											type="text"
											name="kontaktdaten.telefon"
											autoComplete="off"
											value={modulEP.kontaktdaten.telefon}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
											onKeyUp={(e) => feldPruefen(e)}
										/>
									</div>
									{/* 							<div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4">
								<span className="formLabel">Fax</span>
								<input
									type="text"
									name="kontaktdaten.fax"
									value={formik.values.fax}
									className="inputText100percent"
									onChange={(e) => handleChange(e)}
								/>
							</div> */}
								</div>

								<div className="row">
									<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<h3>Bankverbindung</h3>
									</div>
								</div>

								<div className="row">
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">BIC</span>
										<input
											type="text"
											name="kontaktdaten.bic"
											autoComplete="off"
											value={modulEP.kontaktdaten.bic}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
											onKeyUp={(e) => feldPruefen(e)}
										/>
									</div>
									<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
										<span className="formLabel">IBAN*</span>
										<input
											type="text"
											name="kontaktdaten.iban"
											autoComplete="off"
											value={modulEP.kontaktdaten.iban}
											className="inputText100percent"
											onChange={(e) => handleChange(e)}
											onKeyUp={(e) => feldPruefen(e)}
										/>
									</div>
								</div>

								{/* <div className="row">
								<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<h3>Dokumente/Anlagen</h3>
								</div>
							</div> */}

								{/* <div className="row">
								<div className="col-12 ol-xs-12 col-sm-12 col-md-12 col-lg-12">
									<span className="formLabel">Lebenslauf</span>
									<FileUpload files={lebenslauf} setFiles={setLebenslauf} />
								</div>
							</div>
							<FileUploadList files={lebenslauf} setFiles={setLebenslauf} label={'Lebensläufe'} /> */}
								<div className="row" style={{ marginTop: 20 }}>
									<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
										<span style={{ color: "#b94a48" }} className="formLabel">
											* Pflichtfelder
										</span>
									</div>
									<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
								</div>

								<br />
							</div>

							<div
								className="row"
								style={{ marginTop: "10px", marginBottom: "20px" }}
							>
								<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<input
										type="submit"
										value="Zurück"
										className="nonPrintable button"
										onClick={(e) => handleBack(e)}
									/>

									<input
										type="submit"
										value="Weiter"
										className="nonPrintable button"
										onClick={(e) => handleSubmit(e)}
									/>
								</div>
							</div>
						</div>
					</form>
				);
			}
		}
	}
};

const Schritt2 = ({
	modulEP,
	modulEPBefore,
	lebenslauf,
	attest,
	aktuelSemester,
	showSpinner,
	setShowSpinner
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const d = useSelector((state) => state.modulEPList.done);
    const [done, setDone] = useState(d)
    const e = useSelector((state) => state.modulEPList.error);
    const [error, setError] = useState(e);

	useEffect(() => {
        if (d) {
            if (e) {
                setShowSpinner(false);
                dispatch({ type: modulEPConstants.GET_FAILURE_DONE });
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.error("Ihre Kontaktdaten wurden nicht erfolgreich übermittelt.");
            } else {
                setShowSpinner(false);
                dispatch({ type: modulEPConstants.GET_DONE });
                dispatch(modulEPActions.getModulEPList());
                dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
                toast.success("Ihre Kontaktdaten wurden erfolgreich übermittelt.");
                history.push("/examensportal/start");
            }
        }
    }, [d, history]);

	const handleSubmit = (e) => {
		e.preventDefault();
		
		var kd = new WPSModulKontaktdatenClass();
        kd.art = eventConstants.MITTEILUNG_KONTAKTDATENEP;
        kd.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
        kd.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());

		kd.name = modulEP.kontaktdaten.name;
        kd.vorname = modulEP.kontaktdaten.vorname;
        kd.weitereVornamen = modulEP.kontaktdaten.weitereVornamen;
        kd.geburtsname = modulEP.kontaktdaten.geburtsname;
        kd.anrede = modulEP.kontaktdaten.anrede; 
        kd.geburtsdatum = util.changeDateENGWithHoursFormat(modulEP.kontaktdaten.geburtsdatum);
        kd.geburtsort = modulEP.kontaktdaten.geburtsort;
        kd.strasse = modulEP.kontaktdaten.strasse;
		kd.hausnummer = modulEP.kontaktdaten.hausnummer;
		kd.staatsangehoerigkeit = modulEP.kontaktdaten.staatsangehoerigkeit;
        kd.plz = modulEP.kontaktdaten.plz;
        kd.ort = modulEP.kontaktdaten.ort;
        kd.land = modulEP.kontaktdaten.land;
        kd.telefon = modulEP.kontaktdaten.telefon;
        kd.mobil = modulEP.kontaktdaten.mobil;
        kd.email = modulEP.kontaktdaten.email;
        kd.iban = modulEP.kontaktdaten.iban;
        kd.bic = modulEP.kontaktdaten.bic; 
        kd.behinderung = modulEP.kontaktdaten.behinderung;
        kd.aktArbeitgeber = modulEP.kontaktdaten.aktArbeitgeber;

        const replacer = (key, value) => {
            if (typeof value === 'undefined')
                return false
            else return value;
        }
        var kontaktdatenInfos = JSON.parse(JSON.stringify(kd, replacer));

        setShowSpinner(true);
        dispatch(modulActions.saveKontaktdatenInfos(modulEP, modulEPBefore, kontaktdatenInfos, lebenslauf, attest));
        // toast.success("Ihre Kontaktdaten wurde erfolgreich geändert");
        // history.push("/examensportal/start");
	};

	const handleBack = (e) => {
		e.preventDefault();
		//dispatch(modulActions.saveTempModul(modul));
		toast.info("Die Daten wurden zwischengespeichert.");
		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL))
	};

	if (!modulEP || !modulEPBefore) return <div />;
	else {
		return (
			<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
				<Spinner show={showSpinner} />
				<div className="hinweisText">
					<p>
						Sie können Ihre aktualisierten Daten hier noch einmal prüfen.
					</p>
					<p>
						Die Aktualisierung Ihrer Daten wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.
					</p>
				</div>

				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
					<h2 style={{ paddingTop: "25px" }}>Zusammenfassung und Abschicken</h2>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Anrede:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.anrede}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Vorname:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.vorname}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Nachname:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.name}
						</div>
					</div>

					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Weitere Vornamen:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.weitereVornamen}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Geburtsname:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.geburtsname}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Geburtsdatum:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{util.changeDateDEToENGFormat(modulEP.kontaktdaten.geburtsdatum)}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Staatsangehörigkeit:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.staatsangehoerigkeit}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Geburtsort:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.geburtsort}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Straße:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.strasse}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Hausnr:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.hausnummer}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Land:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.land}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>PLZ:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.plz}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Ort:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.ort}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>E-Mail:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.email}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Telefon:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.telefon}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Fax:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.fax}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>BIC:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.bic}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>IBAN:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.iban}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Behinderung:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.behinderung ? "ja" : "nein"}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Attest/Nachweis:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{attest
								? attest.map((f) => {
									return <div>{f.name}</div>;
								})
								: null}
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Aktueller Arbeitgeber:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{modulEP.kontaktdaten.aktArbeitgeber}
						</div>
					</div>
					{/* <div className="row">
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							<b>Lebenslauf:</b>
						</div>
						<div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
							{lebenslauf
								? lebenslauf.map((f) => {
									return <div>{f.name}</div>;
								})
								: null}
						</div>
					</div> */}
				</div>

				<div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input
							type="submit"
							value="Zurück"
							className="nonPrintable button"
							onClick={(e) => handleBack(e)}
						/>
						<input
							type="button"
							value="Abschicken"
							className="nonPrintable button"
							onClick={e => handleSubmit(e)} />
					</div>
				</div>

			</div>
		);
	}
}

export const KontaktdatenEP = () => {
	const dispatch = useDispatch();
	const mlep = useSelector(state => state.modulEPList.modulEPList);
	const [modulEP, setModulEP] = useState(mlep);
	const mlbep = useSelector(state => state.modulEPList.modulEPListBefore);
	const [modulEPBefore, setModulEPBefore] = useState(mlbep)
	const schritt = useSelector(state => state.rightMenu);
	const [lebenslauf, setLebenslauf] = useState([]);
	const [attest, setAttest] = useState([]);
	const modulLoading = useSelector((state) => state.modulEPList.loading);
	const eignungLoading = useSelector((state) => state.eignung.loading);
	const [showSpinner, setShowSpinner] = useState(true);
	const t = useSelector(state => state.tipfehler.tipfehler);
	const [tipfehler, setTipfehler] = useState(t);
	const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
	const [zulassungsblock, setZulassungsblock] = useState(zb);

	const e = useSelector(state => state.eignung.eignung);
	const [eignung, setEignung] = useState(e);
	var aktuelSemester;
	if(zulassungsblock){
		aktuelSemester = zulassungsblock.zulassungsblock;
	}else{
		aktuelSemester = "SEM"
	}
	

	useEffect(() => {
		setShowSpinner(true);

		/*
			Zulassung und Modul werden nur von der Startseite geladen
			Nach jeder Änderung und Absenden von Daten soll/muss man wieder auf die StartSeite landen
			dispatch(zulassungActions.getZulassung()); 
			dispatch(modulActions.getModulList());
		*/

		dispatch(zulassungActions.getTipfehler());
	}, []);

	useEffect(() => {
		setModulEP(mlep);
	}, [mlep]);

	useEffect(() => {
		setTipfehler(t);
	}, [t]);

	useEffect(() => {
		setZulassungsblock(zb);
	}, [zb]);

	useEffect(() => {
		if (!modulLoading && !eignungLoading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [modulLoading, eignungLoading]);


	if (!modulEPBefore)
		return (
			<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
				<KontaktdatenHinweisText />

				<ModulNochNichtAnwendbar />
			</div>
		);
	else {

		return (modulLoading && eignungLoading ? <></> :
			<>
				{schritt === 1 && (
					<Schritt1
						modulEP={modulEP}
						setModulEP={setModulEP}
						modulEPBefore={modulEPBefore}
						lebenslauf={lebenslauf}
						setLebenslauf={setLebenslauf}
						attest={attest}
						setAttest={setAttest}
						tipfehler={tipfehler}
						setTipfehler={setTipfehler}
					/>
				)}
				{schritt === 2 && (
					<Schritt2
						modulEP={modulEP}
						modulEPBefore={modulEPBefore}
						lebenslauf={lebenslauf}
						attest={attest}
						aktuelSemester={aktuelSemester}
						showSpinner={showSpinner}
						setShowSpinner={setShowSpinner}
					/>
				)}

				{/* Menu Right */}
			</>
		);
	}
}
