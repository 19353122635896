import { Button, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { eignungActions, modulActions, modulEPActions, rightMenuActions, topMenuActions, zulassungActions, zulassungsblockActions } from '../actions';
import { MenuRight } from './MenuRight';
import { GrundForm } from './modulpruefung/Form/GrundForm';
import { Spinner } from "./spinner/Spinner";
import { GrundEignungText, GrundText, ModulNochNichtAnwendbar } from './HinweisTexte';
import { rightmenuConstants, topmenuConstants } from '../constants';
import { GrundFormEP } from './modulpruefung/Form/GrundFormEP';


export const TriftigerGrundEP = () => {
	const dispatch = useDispatch();
	const form = useSelector(state => state.topMenu);
	const tab = useSelector(state => state.rightMenu);
	const e = useSelector(state => state.eignung.eignung);
	const [eignung, setEignung] = useState(e);
	const mep = useSelector(state => state.modulEPList.modulEPListBefore);
	const [modulEP, setModulEP] = useState(mep);
	const [showSpinner, setShowSpinner] = useState(true);
	const loading = useSelector((state) => state.eignung.loading);

	useEffect(() => {
		dispatch(zulassungsblockActions.getZulassungsblock());
		dispatch(eignungActions.getEignung());
		dispatch(modulEPActions.getModulEPList());
		dispatch(topMenuActions.changeForm(topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES));
  		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
	}, []);

	useEffect(() => {
		setModulEP(mep);
	}, [mep]);

	useEffect(() => {
		setEignung(e);
	}, [e]);

	useEffect(() => {
		if (!loading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [loading]);

	if (!modulEP) {
		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>Mitteilung eines triftigen Grundes</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
								{form === topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES_NR && <GrundEignungText />}
								<ModulNochNichtAnwendbar />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		if (modulEP && modulEP.zwischegespeichert && modulEP.zwischegespeichert === 2) {
			return (
				<div className="container  container-small-view">
					<div className="row">
						<div className="col-xs-12">
							<h1>Mitteilung eines triftigen Grundes</h1>
						</div>
						<div className="container  container-small-view">
							<div className="row">
								<Spinner show={showSpinner} />
								{form === topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES_NR && <GrundFormEP />}
	
								{tab === 1 && modulEP.zwischegespeichert === 2 && <MenuRight />}
								{tab === 2 && modulEP.zwischegespeichert === 2 && <MenuRight />}
							</div>
						</div>	
					</div>
				</div>
			);
		}

		if (eignung && ((!eignung.zwischegespeichert && eignung.zwischegespeichert === 0) ||
			(eignung.zwischegespeichert && eignung.zwischegespeichert === 1))) {
			return (
				<div className="container  container-small-view">
					<div className="row">
						<div className="col-xs-12">
							<h1>Mitteilung eines triftigen Grundes</h1>
						</div>
						<div className="container  container-small-view">
							<div className="row">
								<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
									{form === topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES_NR && <GrundEignungText />}
									<ModulNochNichtAnwendbar />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="container  container-small-view">
				<div className="row">
					<div className="col-xs-12">
						<h1>Mitteilung eines triftigen Grundes</h1>
					</div>
					<div className="container  container-small-view">
						<div className="row">
							<Spinner show={showSpinner} />
							{form === topmenuConstants.MITTEILUNG_EINES_TRIFTIGEN_GRUNDES_NR && <GrundFormEP />}

							{tab === 1 && (modulEP.zwischegespeichert && modulEP.zwischegespeichert === 2) ? <MenuRight /> : <div/>}
							{tab === 2 && (modulEP.zwischegespeichert && modulEP.zwischegespeichert === 2) ? <MenuRight /> : <div/>}
						</div>
					</div>

				</div>
			</div>
		);
	}

};


