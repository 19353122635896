import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { modulActions, zulassungActions, modulpruefungTopMenuActions, rightMenuActions } from '../../actions';
import { rightmenuConstants, modulConstants, dateConstants } from '../../constants';
import { Spinner } from "../spinner/Spinner";
import { ModulNochNichtAnwendbar } from '../HinweisTexte';
import { util } from '../../services';
import { WPSZulassungRuecknahmeClass } from '../../models/zulassung';
import { eventConstants } from "../../constants";
import Swal from 'sweetalert2';
import 'animate.css';


const AntragRuecknehemen = ({ modulEPBefore, antragsdatum, handleChange, handleSubmit, user }) => {

	if (!modulEPBefore) return (<div />);
	else {
		return (
			<>
				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>

					<h2 style={{ paddingTop: '25px' }}>Erklärung</h2>

					<div className="row">
						<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<span className="formLabel">Ich habe am {antragsdatum} einen Antrag auf Zulassung zur Eignungsprüfung gestellt.</span>
						</div>
					</div>
					<div className="row">
						<div className="col-6 col-xs-12 col-sm-12 col-md-9 col-lg-9">
							<input
								type="checkbox"
								name="ruecknehmen"
								style={{ marginRight: "15px" }}
								value={true} //TODO: later
								checked={true} // TODO:later
								onChange={(e) => handleChange(e)}
							/>{" "}
							Diesen Zulassungsantrag möchte ich hiermit verbindlich zurücknehmen.
						</div>
					</div>

					<div className="row">
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6">
							<span style={{ color: '#b94a48' }} className="formLabel">* Pflichtfelder</span>
						</div>
						<div className="col-6 col-xs-12 col-sm-12 col-md-6 col-lg-6"></div>
					</div>

					<br />

				</div>

				<div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="submit" value="Weiter" className="nonPrintable button" onClick={e => handleSubmit(e)} />
					</div>
				</div>
			</>
		);
	}
}

const Zusammenfassung = ({ modulEPBefore, antragsdatum, aktuelSemester, handleBack, showSpinner, setShowSpinner, user }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const d = useSelector((state) => state.ruecknahme.done);
	const [done, setDone] = useState(d)
	const e = useSelector((state) => state.ruecknahme.error);
	const [error, setError] = useState(e);

	useEffect(() => {
		if (d) {
			if (e) {
				setShowSpinner(false);
				dispatch({ type: modulConstants.GET_RUECKNAHME_FAILURE_DONE });
				dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
				toast.error("Die Rücknahme des Zulassungsantrags wurde nicht erfolgreich abgesendet.");
			} else {
				setShowSpinner(false);
				dispatch({ type: modulConstants.GET_RUECKNAHME_DONE });
				dispatch(modulActions.getModulEPList());
				dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
				toast.success("Die Rücknahme des Zulassungsantrags wurde erfolgreich abgesendet.");
				history.push("/examensportal/start");
			}
		}
	}, [d, history]);

	const handleSubmit = (e) => {
		e.preventDefault();
		var zr = new WPSZulassungRuecknahmeClass();
		zr.art = eventConstants.ZULASSUNGEP_RUECKNAHME;
		zr.zulassungsblock = util.changeFormatSemester2(aktuelSemester);
		zr.abgesendetAm = util.changeDateENGWithHoursFormat(new Date());
		const replacer = (key, value) => {
			if (typeof value === 'undefined')
				return false
			else return value;
		}
		var zulassungRuecknahmeInfos = JSON.parse(JSON.stringify(zr, replacer));

		// console.log(zulassungRuecknahmeInfos);

		setShowSpinner(true);
		dispatch(modulActions.saveRuecknahmeInfos(zulassungRuecknahmeInfos));
		// toast.success("Sie haben sich erfolgreich angemeldet");
		// history.push("/examensportal/start");
	}

	if (!modulEPBefore) return (<div />);
	else {
		return (
			<>
				<div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
					<Spinner show={showSpinner} />
					<h2 style={{ paddingTop: '25px' }}>Zusammenfassung und Absenden</h2>

					<span className="formLabel">Ich habe am {antragsdatum} einen Antrag auf Zulassung zur Eignungsprüfung gestellt. Diesen möchte ich hiermit verbindlich zurücknehmen.</span>

				</div>

				<div className="row" style={{ marginTop: '10px', marginBottom: '20px' }}>
					<div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
						<input type="submit" value="Zurück" className="nonPrintable button" onClick={(e) => handleBack(e)} />
						<input type="submit" value="Abschicken" className="nonPrintable button" onClick={e => handleSubmit(e)} />
					</div>
				</div>
			</>
		);
	}
}

export const EignungAntragRuecknehmen = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authentication.user);
	const tab = useSelector(state => state.rightMenu);
	const mlep = useSelector(state => state.modulEPList.modulEPList);
	const [modulEP, setModulEP] = useState(mlep);
	// const mlb = useSelector(state => state.modulList.modulListBefore);
	const mlbep = useSelector(state => state.modulEPList.modulEPListBefore);
	// const [modulBefore, setModulBefore] = useState(mlb);
	const [modulEPBefore, setModulEPBefore] = useState(mlbep);
	const modulEPLoading = useSelector((state) => state.modulEPList.loading);
	const eignungLoading = useSelector((state) => state.eignung.loading);
	const [showSpinner, setShowSpinner] = useState(true);
	const zb = useSelector((state) => state.zulassungsblock.zulassungsblock);
	const [zulassungsblock, setZulassungsblock] = useState(zb);

	const e = useSelector(state => state.eignung.eignung);
	const [eignung, setEignung] = useState(e);
	var antragsdatum; var aktuelSemester;

	if (modulEPBefore) {
		aktuelSemester = util.changeFormatSemester(zulassungsblock.zulassungsblock);
		antragsdatum = util.changeDateDEToENGFormat(modulEPBefore.antragsdatum);		
	}

	useEffect(() => {
		if (!modulEPLoading && !eignungLoading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [modulEPLoading, eignungLoading]);

	useEffect(() => {
		setEignung(e);
	}, [e]);


	useEffect(() => {
		setModulEP(mlep);
	}, [mlep]);

	useEffect(() => {
		setZulassungsblock(zb);
	}, [zb]);

	const handleChange = (e) => {
		if (e.target.type === 'checkbox' || e.target.type === 'radio') {
			let v = false;
			if (e.target.value === 'false' || e.target.value === false) {
				v = true;
			}
			setEignung({ ...eignung, [e.target.name]: v });
		} else {
			setEignung({ ...eignung, [e.target.name]: e.target.value });
		}


	};

	const handleBack = (e) => {
		e.preventDefault();
		//dispatch(modulActions.saveTempModul(modul));
		toast.info("Die Daten wurden zwischengespeichert.");
		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		//dispatch(zulassungActions.saveTempZulassung(zulassung));
		toast.info("Die Daten wurden zwischengespeichert.");
		dispatch(rightMenuActions.changeTab(rightmenuConstants.ZUSAMMENFASSUNG));
		const iban = modulEPBefore.kontaktdaten.iban;
		const message = 'Ist Ihre Bankverbindung (IBAN <b>' + iban + '</b>) für eine mögliche Gebührenerstattung noch aktuell? <br/> Bitte aktualisieren Sie ggf. nach dem Absenden Ihre Angaben unter <br /> <b>Meine Daten &#x2192 Kontaktdaten ändern</b>';
		Swal.fire({
			html: message,
			width: 800,
			confirmButtonColor: '#4C82A8',
			showClass: {
				popup: 'animate__animated animate__fadeInDown'
			},
			hideClass: {
				popup: 'animate__animated animate__fadeOutUp'
			}
		})
	};


	const ErklaerungText = () => {
		return (
			<div className="hinweisText" style={{ marginBottom: '20px' }}>
				<p>Wenn Sie Ihren Zulassungsantrag zurücknehmen, ermäßigt sich die Zulassungsgebühr auf die Hälfte.</p>

				<p>Sollten Sie die Zulasungsgebühr bereits gezahlt haben, wird Ihnen die Wirtschaftsprüferkammer 250 Euro überweisen; hierzu erhalten Sie ein gesondertes Schreiben.</p>

				<p>Sollten Sie bereits die Prüfungsgebühr gezahlt haben, wird die Wirtschaftsprüferkammer Ihnen diese wieder überweisen; auch hierzu erhalten Sie ein gesondertes Schreiben.</p>
			</div>
		);
	}

	const ZusammenfassungText = () => {
		return (
			<div className="hinweisText" style={{ marginBottom: '20px' }}>
				<p>
					Ihre Rücknahme des Zulassungsantrages wird hier zusammengefasst.
					Bitte prüfen Sie die Angaben, bevor Sie Ihre Rücknahme verbindlich abschicken.
				</p>

				<p>Ihre Rücknahme wird in der digitalen Akte durch eine Eingangsbestätigung (PDF-Dokument) dokumentiert.</p>
			</div>
		);
	}

	if (!modulEPBefore) {
		return (
			<form onSubmit={e => handleSubmit(e)}>
				<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
					<ErklaerungText />

					<ModulNochNichtAnwendbar />
				</div>
			</form>
		);
	} else {
		// For WPOP-NORMAL
		if (user && user.art && user.art === 'EIGNUNG') {
			if ((util.existsDatum(modulEPBefore.antragsdatum)) &&
				((modulEPBefore.zwischegespeichert && modulEPBefore.zwischegespeichert == 1) ||
				(modulEPBefore.zwischegespeichert && modulEPBefore.zwischegespeichert == 2 && !util.existsDatum(modulEPBefore.ersteszulassungsdatum)) ||
				(modulEPBefore.zwischegespeichert && modulEPBefore.zwischegespeichert == 2 && util.isWithinThreeDays(modulEPBefore.ersteszulassungsdatum) == dateConstants.BEFORE_REACHING))
			) {
				return (
					<form onSubmit={e => handleSubmit(e)}>
						<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
							{tab === 1 && <ErklaerungText />}
							{tab === 2 && <ZusammenfassungText />}

							{tab === 1 && <AntragRuecknehemen
								modulEPBefore={modulEPBefore}
								antragsdatum={antragsdatum}
								handleChange={handleChange}
								handleSubmit={handleSubmit}
								user={user}
							/>}
							{tab === 2 && <Zusammenfassung
								modulEPBefore={modulEPBefore}
								antragsdatum={antragsdatum}
								aktuelSemester={aktuelSemester}
								handleBack={handleBack}
								showSpinner={showSpinner}
								setShowSpinner={setShowSpinner}
								user={user}
							/>}
						</div>
					</form>
				);
			} else {
				return (
					<form onSubmit={e => handleSubmit(e)}>
						<div className="col-12 col-xs-12 col-sm-8 col-md-8 col-lg-8">
							<ErklaerungText />

							<ModulNochNichtAnwendbar />
						</div>
					</form>
				);
			}
		}
	}
}