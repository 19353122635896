import { modulConstants, modulEPConstants } from '../constants';
import { ModulClass, ModulEPClass } from '../models/modul';


export function modulList(state = {}, action) {
    switch (action.type) {
        case modulConstants.GET_REQUEST:
            return {
                modulList: new ModulClass(),
                modulListBefore: new ModulClass(),
                loading: true,
                done: false,
                error: undefined
            };
        case modulConstants.GET_SUCCESS:
            return {
                modulList: action.payload.data,
                modulListBefore: action.payload.data,
                loading: false,
                done: true,
                error: undefined,
            };
        case modulConstants.GET_FAILURE:
            return {
                error: action.payload,
                modulList: Object.keys({ ...state.modulList }).length === 0 ? "" : { ...state.modulList },
                modulListBefore: Object.keys({ ...state.modulListBefore }).length === 0 ? "" : { ...state.modulListBefore },
                loading: false,
                done: true,
            };
        case modulConstants.GET_DONE:
            return {
                modulList: Object.keys({ ...state.modulList }).length === 0 ? "" : { ...state.modulList },
                modulListBefore: Object.keys({ ...state.modulListBefore }).length === 0 ? "" : { ...state.modulListBefore },
                loading: false,
                done: false,
                error: undefined
            };
        case modulConstants.GET_FAILURE_DONE:
            return {
                modulList: Object.keys({ ...state.modulList }).length === 0 ? "" : { ...state.modulList },
                modulListBefore: Object.keys({ ...state.modulListBefore }).length === 0 ? "" : { ...state.modulListBefore },
                loading: false,
                done: false,
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error }
            };
        case modulConstants.SAVE_SUCCESS:
            return {
                modulList: action.payload[0].data,
                modulListBefore: action.payload[1],
                loading: false,
                done: true,
                error: undefined
            };
        case modulConstants.SAVE_REQUEST:
            return {
                modulList: action.payload,
                loading: true,
                done: false,
                error: undefined
            };
        case modulConstants.SAVE_FAILURE:
            return {
                error: action.payload[0],
                modulList: action.payload[1],
                modulListBefore: action.payload[2],
                loading: false,
                done: true
            };
        case modulConstants.SAVE_TEMP:
            return {
                modulList: action.payload,
                loading: false
            };
        case modulConstants.CLEAR:
            return [];
        default:
            return state
    }
}

export function modulEPList(state = {}, action) {
    switch (action.type) {
        case modulEPConstants.GET_REQUEST:
            return {
                modulEPList: new ModulEPClass(),
                modulEPListBefore: new ModulEPClass(),
                loading: true,
                done: false,
                error: undefined
            };
        case modulEPConstants.GET_SUCCESS:
            return {
                modulEPList: action.payload.data,
                modulEPListBefore: action.payload.data,
                loading: false,
                done: true,
                error: undefined,
            };
        case modulEPConstants.GET_FAILURE:
            return {
                error: action.payload,
                modulEPList: Object.keys({ ...state.modulEPList }).length === 0 ? "" : { ...state.modulList },
                modulEPListBefore: Object.keys({ ...state.modulEPListBefore }).length === 0 ? "" : { ...state.modulEPListBefore },
                loading: false,
                done: true,
            };
        case modulEPConstants.GET_DONE:
            return {
                modulEPList: Object.keys({ ...state.modulEPList }).length === 0 ? "" : { ...state.modulEPList },
                modulEPListBefore: Object.keys({ ...state.modulEPListBefore }).length === 0 ? "" : { ...state.modulEPListBefore },
                loading: false,
                done: false,
                error: undefined
            };
        case modulEPConstants.GET_FAILURE_DONE:
            return {
                modulEPList: Object.keys({ ...state.modulEPList }).length === 0 ? "" : { ...state.modulEPList },
                modulEPListBefore: Object.keys({ ...state.modulEPListBefore }).length === 0 ? "" : { ...state.modulEPListBefore },
                loading: false,
                done: false,
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error }
            };
        case modulEPConstants.SAVE_SUCCESS:
            return {
                modulEPList: action.payload[0].data,
                modulEPListBefore: action.payload[1],
                loading: false,
                done: true,
                error: undefined
            };
        case modulEPConstants.SAVE_REQUEST:
            return {
                modulEPList: action.payload,
                loading: true,
                done: false,
                error: undefined
            };
        case modulEPConstants.SAVE_FAILURE:
            return {
                error: action.payload[0],
                modulEPList: action.payload[1],
                modulEPListBefore: action.payload[2],
                loading: false,
                done: true
            };
        case modulEPConstants.SAVE_TEMP:
            return {
                modulEPList: action.payload,
                loading: false
            };
        case modulEPConstants.CLEAR:
            return [];
        default:
            return state
    }
}

export function ruecknahme(state = {}, action) {
    switch (action.type) {
        case modulConstants.SAVE_RUECKNAHME_SUCCESS:
            return {
                ruecknahme: action.payload.data,
                loading: false,
                done: true,
                error: undefined
            };
        case modulConstants.SAVE_RUECKNAHME_REQUEST:
            return {
                ruecknahme: "",
                loading: true,
                done: false,
                error: undefined
            };
        case modulConstants.SAVE_RUECKNAHME_FAILURE:
            return {
                ruecknahme: "",
                error: action.payload,
                loading: false,
                done: true
            };
        case modulConstants.GET_RUECKNAHME_DONE:
            return {
                ruecknahme: Object.keys({ ...state.ruecknahme }).length === 0 ? "" : { ...state.ruecknahme },
                loading: false,
                done: false,
                error: undefined
            };
        case modulConstants.GET_RUECKNAHME_FAILURE_DONE:
            return {
                ruecknahme: Object.keys({ ...state.ruecknahme }).length === 0 ? "" : { ...state.ruecknahme },
                loading: false,
                done: false,
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error }
            };
        default:
            return state
    }
}

export function gesamtpruefung(state = {}, action) {
    switch (action.type) {
        case modulConstants.SAVE_GESAMTPRUEFUNG_SUCCESS:
            return {
                gesamtpruefung: action.payload.data,
                loading: false,
                done: true,
                error: undefined
            };
        case modulConstants.SAVE_GESAMTPRUEFUNG_REQUEST:
            return {
                gesamtpruefung: "",
                loading: true,
                done: false,
                error: undefined
            };
        case modulConstants.SAVE_GESAMTPRUEFUNG_FAILURE:
            return {
                gesamtpruefung: "",
                error: action.payload,
                loading: false,
                done: true
            };
        case modulConstants.GET_GESAMTPRUEFUNG_DONE:
            return {
                gesamtpruefung: Object.keys({ ...state.gesamtpruefung }).length === 0 ? "" : { ...state.gesamtpruefung },
                loading: false,
                done: false,
                error: undefined
            };
        case modulConstants.GET_GESAMTPRUEFUNG_FAILURE_DONE:
            return {
                gesamtpruefung: Object.keys({ ...state.gesamtpruefung }).length === 0 ? "" : { ...state.gesamtpruefung },
                loading: false,
                done: false,
                error: Object.keys({ ...state.error }).length === 0 ? "" : { ...state.error }
            };
        default:
            return state
    }
}