
import './fileupload.scss';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const FileUploadList = ({files, setFiles, label}) => {

    const deleteFile = (fileName) => {
        setFiles(files.filter((item) => {
            return item.name != fileName;
        }));
    }

    const folgend = (eingabe) => {
        if (eingabe == "Grund" ) {
            return "Folgender ";
        } else if (eingabe == "Zeugnis" || eingabe == "Wahlfach") {
            return "Folgendes ";
        } else {
            return "Folgende ";
        }
    }

    return(
        <div className="wpop-list-margin">
            {files && files.length == 1 && (
                <div className="row wpop-list-margin" >
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span className="formLabel">{folgend(label)} {`${label} haben Sie aktuell hochgeladen:`}</span>
                    </div>
                </div>
            )}

            {files && files.length > 1 && (
                <div className="row wpop-list-margin" >
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span className="formLabel">{`Folgende ${label} haben Sie aktuell hochgeladen:`}</span>
                    </div>
                </div>
            )}
        
            {files ? files.map((item, index) => {
                return(
                    <div className="row" key={index}>
                        <div className="col-11 col-xs-11 col-sm-11 col-md-11 col-lg-11">
                            {item.name}
                        </div>
                        <div className="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1">
                        <FontAwesomeIcon icon={faTrash} onClick={() => deleteFile(item.name)} />
                        </div>
                    </div>
                )}
            ) : ""}
        </div>
    );

}

export default FileUploadList;


