export const modulEPConstants = {
    SAVE_REQUEST: 'EP_MODUL_SAVE_REQUEST',
    SAVE_SUCCESS: 'EP_MODUL_SAVE_SUCCESS',
    SAVE_FAILURE: 'EP_MODUL_SAVE_FAILURE',

    SAVE_RUECKNAHME_REQUEST: 'EP_RUECKNAHME_SAVE_REQUEST',
    SAVE_RUECKNAHME_SUCCESS: 'EP_RUECKNAHME_SAVE_SUCCESS',
    SAVE_RUECKNAHME_FAILURE: 'EP_RUECKNAHME_SAVE_FAILURE',

    SAVE_GESAMTPRUEFUNG_REQUEST: 'EP_GESAMTPRUEFUNG_SAVE_REQUEST',
    SAVE_GESAMTPRUEFUNG_SUCCESS: 'EP_GESAMTPRUEFUNG_SAVE_SUCCESS',
    SAVE_GESAMTPRUEFUNG_FAILURE: 'EP_GESAMTPRUEFUNG_SAVE_FAILURE',

    GET_REQUEST: 'EP_MODUL_GET_REQUEST',
    GET_SUCCESS: 'EP_MODUL_GET_SUCCESS',
    GET_FAILURE: 'EP_MODUL_GET_FAILURE',

    GET_DONE: 'EP_MODUL_GET_DONE',
    GET_FAILURE_DONE: 'EP_MODUL_GET_FAILURE_DONE',

    GET_RUECKNAHME_DONE: 'EP_GET_RUECKNAHME_DONE',
    GET_RUECKNAHME_FAILURE_DONE: 'EP_GET_RUECKNAHME_FAILURE_DONE',

    GET_GESAMTPRUEFUNG_DONE: 'EP_GET_GESAMTPRUEFUNG_DONE',
    GET_GESAMTPRUEFUNG_FAILURE_DONE: 'EP_GET_GESAMTPRUEFUNG_FAILURE_DONE',

    SAVE_TEMP: 'EP_MODUL_TEMPSAVE',
};