import './fileupload.scss';
import React from "react";
import { FileUploader } from 'react-drag-drop-files';
import { UploadDisplay } from './fileupload';
import { toast } from 'react-toastify';

const FileUpload2 = ({files, setFiles, disabled}) =>{

    const fileTypes = ["JPG","JPEG", "PNG","PDF"];

    const handleChange = (list) => {
        if (list) {
            let ar = [...files];
            for(var i = 0; i < list.length; i++){
                const item = {file:list[i], type: 'LEBENSLAUF'};
                ar.push(item);
            }
            setFiles(ar);
        } 
    };

    const handleSizeError = (file) => {
        if(file.indexOf('small') > -1){
            toast.error('Die Datei ist leider zu klein.');
        }
        if(file.indexOf('big') > -1){
            toast.error('Die Datei ist leider zu groß.');
        }
    };

    return(
        <FileUploader
            children={<UploadDisplay />}
            multiple={true}
            handleChange={handleChange}
            types={fileTypes}
            style={{"width":"100%"}}
            maxSize={20}
            minSize={0.0001}
            onSizeError={(file) => handleSizeError(file)}
            label={'Dateien hier ablegen'}
            classes={'fileupload-area'}
            disabled={disabled ? disabled: false}
        />
    );
}

export default FileUpload2;