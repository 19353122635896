export const ModulNochNichtAnwendbar = () => {
    return (
        <>
            <div className="form-Box-Hellblau" style={{ paddingTop: '0px' }}>
                <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span className="formLabel">Diese Funktion ist für Sie zur Zeit nicht anwendbar.</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export const ModulanmeldungHinweisText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Sie können hier Ihre Zulassung zum Wirtschaftsprüfungsexamen
                beantragen. Legen Sie bitte die Landesgeschäftsstelle fest,
                bei der Sie den Antrag stellen wollen.
            </p>
            <p><b>Vermerken Sie bitte,</b> wenn Sie</p>
            <ul>
                <li>
                    eine vorgezogene Zulassung gemäß § 9 Abs. 7 WPO beantragen wollen.
                </li>
                <li>
                    ein Masterstudium nach § 8a WPO abgeschlossen haben; wenn Sie in diesem Fall die für die Zulassung erforderliche Tätigkeit und Prüfungstätigkeit noch nicht vollständig nachweisen können und gemäß § 9 Abs. 6 WPO zugelassen werden möchten, können Sie das unter "Praktische Vorbildung - Prüfungstätigkeit" angeben.
                </li>
            </ul>
            <p>
                Sie müssen mindestens eine Modulprüfung auswählen, mit der Sie beginnen wollen.
            </p>
            <p>
                Sie können sich aber auch direkt zu mehreren Modulprüfungen anmelden.
            </p>
        </div>
    );
}

export const ModulanmeldungHinweisTextEP = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Sie können hier Ihre Zulassung zur Eignungsprüfung als Wirtschaftsprüfer nach dem Neunten Teil der Wirtschaftsprüferordnung (§§ 131g ff. WPO) beantragen.
            </p>
            <p>
                Sie können hier eine Erklärung abgeben, ob Sie bereits zur Eignungsprüfung zugelassen waren (§ 25 Abs. 2 Nr. 5 Wirtschaftsprüferprüfungsverordnung [WiPrPrüfV]).
            </p>
        </div>
    );
}

export const ErklaerungTextZulassungRueckname = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>Wenn Sie Ihren Zulassungsantrag zurücknehmen, ermäßigt sich die Zulassungsgebühr auf die Hälfte.</p>

            <p>Sollten Sie die die Zulasungsgebühr bereits gezahlt haben, wird Ihnen die Wirtschaftsprüferkammer 250 Euro überweisen; hierzu erhalten Sie ein gesondertes Schreiben.</p>

            <p>Sollten Sie bereits die Prüfungsgebühr gezahlt haben, wird die Wirtschaftsprüferkammer Ihnen diese wieder überweisen; auch hierzu erhalten Sie ein gesondertes Schreiben.</p>            
        </div>
    );
}

export const ErlaueterungForAuswahlAnmeldung = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>Sie können sich zu einer oder mehreren weiteren Modulprüfungen/en oder zur Wiederholung einer oder mehrerer weiterer Modulprüfung/en anmelden.</p>

            <p><b>Bitte beachten Sie, </b>dass Ihre Anmeldung vor Ablauf der <b>Anmeldefrist</b> – 28. (29.) Februar bzw. 31. August – erfolgen muss und nur für den <b>kommenden Prüfungstermin</b> möglich ist.</p>

            <p>Zu der Modulprüfung, die Sie <b>bereits in Ihrem Zulassungsantrag ausgewählt</b> haben, müssen Sie sich <b>nicht noch einmal anmelden!</b></p>
        </div>
    );
}

export const ErlaueterungForAuswahlAbmeldung = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>Wenn Sie Ihre Modulanmeldung zurücknehmen, können Sie in diesem Prüfungstermin nicht an der Prüfung in diesem Modul teilnehmen.</p>
            <p><b>Bitte beachten Sie</b></p>
            <ul>
                <li>
                    <p>
                        die Sechs-Jahres-Frist gemäß § 5 Abs. 2 Satz 4 WiPrPrüfV,
                        nach deren Ablauf keine Anmeldungen zu Modulprüfungen mehr möglich sind
                        und das Wirtschaftsprüfungsexamen dann nicht bestanden ist;
                    </p>
                </li>
                <li>
                    <p>
                        wenn Sie noch nicht zur Prüfung zugelassen sind und alle Modulanmeldungen zurücknehmen,
                        gilt damit automatisch auch Ihr Antrag auf Zulassung zum Wirtschaftsprüfungsexamen als zurückgenommen.
                        Sollten Sie bereits die Prüfungsgebühr gezahlt haben, wird die Wirtschaftsprüferkammer Ihnen diese wieder überweisen;
                        hierzu erhalten Sie ein gesondertes Schreiben. Ab dem Prüfungstermin 2/2024 wird die Prüfungsgebühr abzüglich einer Bearbeitungsgebühr in Höhe von 20% erstattet.
                    </p>
                </li>
            </ul>
        </div>
    );
}

export const VerschiebungText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Wenn Sie an einer Modulprüfung nicht teilgenommen haben und hierfür ein triftiger Grund vorlag, setzen Sie diese Prüfung im nächsten Prüfungstermin fort. Sie haben die Möglichkeit, die Fortsetzung einmalig um einen Prüfungstermin zu verschieben.
            </p>
            <p>
                Wenn Sie an einer mündlichen Modulprüfung nicht teilgenommen haben und hierfür ein triftiger Grund vorlag, kann diese unter bestimmten Voraussetzungen im Einzelfall in demselben Prüfungstermin nachgeholt werden. Sie haben aber die Möglichkeit, die Fortsetzung einmalig auf den nächsten oder übernächten Prüfungstermin zu verschieben.
            </p>
            <p>
                Wenn Sie an Aufsichtsarbeiten im Juni nicht teilnehmen konnten und hierfür ein triftiger Grund vorlag, holen Sie diese Aufsichtsarbeiten in demselben Prüfungstermin im August nach. Sie haben aber die Möglichkeit, die Fortsetzung einmalig auf den nächsten oder übernachsten Prüfungstermin zu verschieben.
            </p>
            <p>
                <b>Bitte beachten Sie, </b>dass eine weitere Verschiebung nicht möglich ist.
            </p>
        </div>
    );
}

export const AnrechnenText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Wenn Sie nach Ihrer Zulassung zur Prüfung,
            </p>
            <ul>
                <li><p>die Steuerberaterprüfung bestanden haben oder als Steuerberater oder Steuerberaterin bestellt worden sind,</p></li>
                <li><p>einen Masterstudiengang nach § 8a WPO mit Erfolg abgeschlossen haben oder</p></li>
                <li><p>im Rahmen eines Hochschulstudiums gleichwertige Prüfungsleistungen (§ 13b WPO) erbracht haben,</p></li>
            </ul>
            <p>können Sie hier erklären, dass Sie die weitere Prüfung in verkürzter Form ablegen möchten.
            </p>
            <p>
                <b>Bitte beachten Sie,</b> dass das nur möglich ist, solange die Anmeldung zu einem Modul, um das die Prüfung verkürzt werden soll, nicht ausgeschlossen ist (§ 22 Absatz 1 Satz 1 oder Absatz 2 Satz 1 WiPrPrüfV).
            </p>
        </div>
    );
}

export const GrundText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Wenn Sie an einer Modulprüfung nicht teilnehmen können und es hierfür einen triftigen Grund gibt,
                müssen Sie das der Prüfungsstelle unverzüglich mitteilen <b>und</b> den triftigen Grund nachweisen (§ 21 Abs. 2 WiPrPrüfV).
                Bei einer Erkrankung müssen Sie diese durch ein amtsärztliches Zeugnis nachweisen.
            </p>
        </div>
    );
}

export const GrundEignungText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Wenn Sie an der schriftlichen oder der mündlichen Prüfung nicht teilnehmen können und es hierfür einen triftigen Grund gibt,
                müssen Sie das der Prüfungsstelle unverzüglich mitteilen <b>und</b> den triftigen Grund nachweisen (§ 32 Satz 3 i. V. m. § 21 Abs. 2 WiPrPrüfV).
                Bei einer Erkrankung müssen Sie diese durch ein amtsärztliches Zeugnis nachweisen.
            </p>
        </div>
    );
}

export const GesamtPruefungText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>Wenn Sie von der gesamten Prüfung zurücktreten, ist dieser Prüfungsversuch beendet.</p>

            <p>War dies Ihr erster Prüfungsversuch, können Sie die gesamte Prüfung noch einmal wiederholen.</p>

            <p>War dies Ihr zweiter Prüfungsversuch, haben Sie das Wirtschaftsprüfungsexamen <b>endgültig nicht bestanden.</b></p>

            <p>Eine Ausnahme gilt, wenn Sie das Wirtschaftsprüfungsexamen nach der bis zum 15. Februar 2019 geltenden Fassung der Wirtschaftsprüferprüfungsverordnung ein- oder zweimal nicht bestanden haben. Sie können dann das Wirtschaftsprüfungsexamen noch zwei- bzw. einmal wiederholen, haben also weiterhin - wie nach altem Prüfungsrecht - drei Versuche für das Wirtschaftsprüfungsexamen.</p>
        </div>
    );
}

export const GesamtPruefungEignungText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>Wenn Sie von der Prüfung zurücktreten, ist dieser Prüfungsversuch beendet.</p>

            <p>War dies Ihr erster Prüfungsversuch, können Sie die Prüfung noch zweimal wiederholen.</p>

            <p>War dies Ihr zweiter Prüfungsversuch, können Sie die Prüfung noch einmal widerholen.</p>
        </div>
    );
}

export const RuecktrittText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Wenn Sie von dieser Modulprüfung zurücktreten,
                ist dieser Modulprüfungsversuch erfolglos beendet. Sollte dies Ihr dritter Modulprüfungsversuch sein,
                ist diese Modulprüfung damit <b>endgültig nicht bestanden</b> mit der Folge, dass auch die gesamte Prüfung
                <b> endgültig nicht bestanden</b> ist.
            </p>
        </div>
    );
}

export const KontaktdatenHinweisText = () => {
    return (
        <div className="hinweisText" style={{ marginBottom: '20px' }}>
            <p>
                Hier können Sie Ihre persönlichen Daten während des gesamten Prüfungsverlaufes online ändern.
            </p>
            <p>
                Weitere Nachweise, Bescheinigungen, Urkunden, Erklärungen, Atteste oder Notizen laden Sie bitte in der Digitalen Akte hoch.
            </p>
            <p>
                Eine Änderung Ihrer EMail/Kennung erfolgt unter "Zugangsdaten ändern" im Menu "Meine Daten".
            </p>
        </div>
    );
}