import { eignungConstants, tipfehlerConstants, verkuerzungauswahlConstants } from '../constants';
import { TipFehlerClass } from '../models/zulassung';
import { EignungClass, VerkuerzungAuswahlEPClass } from '../models/eignung';

export function eignung(state = { loading: false, done: false, error: undefined, showMenu: false }, action) {
  switch (action.type) {
    case eignungConstants.GET_REQUEST:
      return {
        eignung: new EignungClass(),
        done: false,
        loading: true,
        error: undefined,
      };
    case eignungConstants.GET_SUCCESS:
      return {
        eignung: action.payload.data,
        loading: false,
        done: true,
        error: undefined,
      };
    case eignungConstants.GET_FAILURE:
      return {
        error: action.payload,
        eignung: Object.keys({ ...state.eignung }).length === 0 ?  "" : { ...state.eignung },
        done: true,
        loading: false
      };
    case eignungConstants.GET_FAILURE_DONE:
      return {
        eignung: Object.keys({ ...state.eignung }).length === 0 ?  "" : { ...state.eignung },
        error: Object.keys({ ...state.error }).length === 0 ?  "" : { ...state.error },
        loading: false,
        done: false,
      };
    case eignungConstants.SAVE_REQUEST:
      return {
        eignung: action.payload,
        loading: true,
        done: false,
        error: undefined
      };
    case eignungConstants.SAVE_SUCCESS:
      return {
        eignung: action.payload.data,
        loading: false,
        done: true,
        error: undefined
      };
    case eignungConstants.SAVE_TEMP_SUCCESS:
      return {
        eignung: action.payload.data,
        loading: false,
        done: false,
        error: undefined
      };
    case eignungConstants.SAVE_FAILURE:
      return {
        error: action.payload[0],
        eignung: action.payload[1],
        loading: false,
        done: true
      };
    case eignungConstants.SAVE_TEMP_FAILURE:
      return {
        error: action.payload[0],
        eignung: action.payload[1],
        loading: false,
        done: false
      };
    case eignungConstants.GET_DONE:
      return {
        eignung: Object.keys({ ...state.eignung }).length === 0 ?  "" : { ...state.eignung },
        error: undefined,
        loading: false,
        done: false,
      };
    case eignungConstants.SAVE_FAILURE_DONE:
      return {
        error: Object.keys({ ...state.error }).length === 0 ?  "" : { ...state.error },
        eignung: Object.keys({ ...state.eignung }).length === 0 ?  "" : { ...state.eignung },
        loading: false,
        done: false
      };
    case eignungConstants.SAVE_TEMP:
      return {
        eignung: action.payload,
        loading: false
      };
    case eignungConstants.CLEAR:
      return {};
    case eignungConstants.CHECK:
      return {
        done: true,
        loading: false,
        error: Object.keys({ ...state.error }).length === 0 ?  "" : { ...state.error },
        eignung: Object.keys({ ...state.eignung }).length === 0 ?  "" : { ...state.eignung },
        showMenu: action.payload
      };
    default:
      return state
  }
}

export function verkuerzungauswahlEP(state = {}, action) {
  switch (action.type) {
    case verkuerzungauswahlConstants.GET_REQUEST_FOR_EP:
      return {
        verkuerzungauswahl: new VerkuerzungAuswahlEPClass(),
      };
    default:
      return state
  }
}

export function tipfehler(state = {}, action) {
  switch (action.type) {
    case tipfehlerConstants.GET_REQUEST:
      return {
        tipfehler: new TipFehlerClass(),
      };
    default:
      return state
  }
}