import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Kontaktdaten } from './Kontaktdaten';
import { Zugangsdaten } from './Zugangsdaten';
import { Dokumente } from './Dokumente';
import { Spinner } from "./spinner/Spinner";
import { MenuRight } from './MenuRight';
import { KontaktdatenHinweisText, ModulNochNichtAnwendbar } from './HinweisTexte';
import { rightmenuConstants, topmenuConstants } from '../constants';
import { eignungActions, modulActions, modulEPActions, rightMenuActions, topMenuActions, zulassungActions, zulassungsblockActions } from '../actions';
import { KontaktdatenEP } from './KontaktdatenEP';

export const Meinedaten = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authentication.user);
	const f = useSelector(state => state.topMenu);
	const [form, setForm] = useState(f)
	const t = useSelector(state => state.rightMenu);
	const [tab, setTab] = useState(t);
	const td = useSelector(state => state.rightmenuDokumente)
	const [tabDokumente, setTabDokumente] = useState(td)
	const z = useSelector(state => (user && user.art && user.art === 'WP') ? state.zulassung.zulassung : state.eignung.eignung);
	const [zulassung, setZulassung] = useState(z);
	const ml = useSelector(state => state.modulList.modulList);
	const mlep = useSelector(state => state.modulEPList.modulEPList);
	const [modul, setModul] = useState(ml);
	const [modulEP, setModulEP] = useState(mlep);
	const m = useSelector(state => state.modulList.modulListBefore);
	const mep = useSelector(state => state.modulEPList.modulEPListBefore);
	const [modulBefore, setModulBefore] = useState(m);
	const [modulEPBefore, setModulEPBefore] = useState(mep);
	const [showSpinner, setShowSpinner] = useState(true);
	const loading = useSelector((state) => state.zulassung.loading);
	var subTitle = "";

	switch (form) {
		case topmenuConstants.KONTAKTDATEN_AENDERN_NR:
			subTitle = "Kontaktdaten ändern";
			break;

		case topmenuConstants.KENNWORT_AENDERN_NR:
			subTitle = "Zugangsdaten ändern";
			break;
		// case 11:
		// 	subTitle = "Einstellungen";
		// 	break;
		case topmenuConstants.DIGITALE_AKTE_NR:
			subTitle = "Digitale Akte";
			break;
		default:
			subTitle = "";
	}

	useEffect(() => {
		dispatch(zulassungsblockActions.getZulassungsblock());
		(user && user.art && user.art === 'WP') ? dispatch(zulassungActions.getZulassung()) : dispatch(eignungActions.getEignung());
		(user && user.art && user.art === 'WP') ? dispatch(modulActions.getModulList()) : dispatch(modulEPActions.getModulEPList());
		dispatch(rightMenuActions.changeTab(rightmenuConstants.AUSWAHL_MODUL));
		dispatch(topMenuActions.changeForm(topmenuConstants.DIGITALE_AKTE));
	}, []);

	useEffect(() => {
		setModulBefore(m);
	}, [m]);

	useEffect(() => {
		setModulEPBefore(mep);
	}, [mep]);

	useEffect(() => {
		setModul(ml);
	}, [ml]);

	useEffect(() => {
		setModulEP(mlep);
	}, [mlep]);

	useEffect(() => {
		setZulassung(z);
	}, [z]);

	useEffect(() => {
		setForm(f);
	}, [f]);

	useEffect(() => {
		setTab(t);
	}, [t]);

	useEffect(() => {
		setTabDokumente(td)
	}, [td])

	useEffect(() => {
		if (!loading) {
			setShowSpinner(false);
		} else {
			setShowSpinner(true);
		}
	}, [loading]);

	return (
		<div className="container  container-small-view">
			<div className="row">
				<div className="col-xs-12">
					<h1>{subTitle}</h1>
				</div>
				<div className="container  container-small-view">
					<div className="row">
						<Spinner show={showSpinner} />
						{/* Form Typ */}
						{user && user.art && user.art === 'WP' && form === topmenuConstants.KONTAKTDATEN_AENDERN_NR && <Kontaktdaten />}
						{user && user.art && user.art === 'EIGNUNG' && form === topmenuConstants.KONTAKTDATEN_AENDERN_NR && <KontaktdatenEP />}
						{form === topmenuConstants.KENNWORT_AENDERN_NR && <Zugangsdaten />}
						{/* {form === 11 &&  <Einstellungen />} */}
						{form === topmenuConstants.DIGITALE_AKTE_NR && <Dokumente />}

						{/* Menu Tab */}
						{form === topmenuConstants.KENNWORT_AENDERN_NR && tab === 1 && <MenuRight />}
						{form === topmenuConstants.DIGITALE_AKTE_NR && tabDokumente === 1 && <MenuRight />}
						{form === topmenuConstants.DIGITALE_AKTE_NR && tabDokumente === 2 && <MenuRight />}
						{form === topmenuConstants.DIGITALE_AKTE_NR && tabDokumente === 3 && <MenuRight />}
						{form === topmenuConstants.DIGITALE_AKTE_NR && tabDokumente === 4 && <MenuRight />}
						{form === topmenuConstants.DIGITALE_AKTE_NR && tabDokumente === 5 && <MenuRight />}
						{form === topmenuConstants.DIGITALE_AKTE_NR && tabDokumente === 6 && <MenuRight />}
					</div>
				</div>
			</div>
		</div>
	);
};
