export class KontaktdatenClass {

    constructor(){
        this.id = 0;
        this.anrede = "";
        this.vorname = "";
        this.nachname = "";
        this.weitereVornamen = "";
        this.geburtsname = "";
        this.geburtsdatum = "";
        this.geburtsort = "";
        this.staatsangehoerigkeit = "";
        this.strasse = "";
        this.hausnummer = "";
        this.plz = "";
        this.ort = "";
        this.land = "";
        this.telefon = "";
        this.mobil = "";
        this.email = "";
        this.iban = "";
        this.bic = "";
        this.behinderung = false;
        this.aktArbeitgeber = "";
        this.lebenslauf = "";
        this.attest = "";
    }
}